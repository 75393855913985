import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';


@Component({
  selector: 'spot-fire-tv',
  templateUrl: './spot-fire-tv.component.html',
  styleUrls: ['./spot-fire-tv.component.css']
})
export class SpotFireTVComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SpotFireTVComponent>, public dialog: MatDialog) { }

  ngOnInit() {
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
  openURL()
  {
    
    window.open(" https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/DEA-TV/EXPLOITATION/TPM_REPORTING_TUM")
  }

  openProjectURL()
  {
    window.open("https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/DEA-TV/PROJECT/TPM_REPORTING_NEW_REPORT_DEA-TV")
  }


  openDocumentationURL()
  {
    window.open(" https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/DEA-TV/SUIVI_DE_DOCUMENTATION/TPM_REPORTING_TUM")
  }

}
