import { EngineActivity } from './engineActivity.model';

export class EvEngine {
    id: number;
    engineActivity: EngineActivity;
    testSupport: string;
    realNb: string;
    test: boolean;
    organisationId: number[];
}
