import { Testbedtype } from '../testbedtype/testbedtype.model';
import { TestbedComplement } from '../testbedcomplement/testbedcomplement.model';

export class TestbedComplementAssociation {
    id: number;
    testbedtype: Testbedtype;
    testbedcomplement: TestbedComplement;    
    organisationId: number[];
    active: Boolean;
    createDate: Date;
    updateDate: Date;
    ipn: String;    
}