export class MacroActivity {
    public id: number;
    public label: string;
    public organisationId: number[];
    public active: boolean;
    public createDate: Date;
    public updateDate: Date;
    public ipn: string;
}

export enum errorMessages {
    alreadyExist = 'Errormessage.macro_existing',
    fail = 'F',
}