import { Means } from '../../referentiel/means/means.model';
import { Procedure } from '../procedure/procedure.model';
import { Prestation } from '../prestation/prestation.model';
import { MacroActivity } from '../macro-activity/macro-activity.model';

export class PrestationAdmin {
    id: number;
    means: Means;
    procedures: Procedure;
    prestations: Prestation;
    organisationId: number[];
    active: Boolean;
    createDate: Date;
    updateDate: Date;
    ipn: String;
    macroActivity: MacroActivity;

}
