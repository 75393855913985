import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {OAuthService, UserInfo} from 'angular-oauth2-oidc';
import {RnltErrorService} from '../../error/rnlt.error.service';
import {FORBIDDEN} from '../../error/rnlt.error.model';
import {AuthorizationService} from '../authorization.service';
import {EnvironmentService} from '../../environment/environment.service';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { OrganisationMapModel } from '../../../common/common-button/organisation-map-btn/organisation-map.model';
import { OrganisationMapService } from '../../../common/common-button/organisation-map-btn/organisation-map.service';
import { Roles } from '../../../common/roles/roles.model';
import { RolesService } from '../../../common/roles/roles.service';
import { SnackBarService } from '../../../common/snackbar/snackbar.component.service';
import { CommonServices } from '../../../common/common.services';
import { MessageService } from '../../../common/message.service';
import { HelpService } from '../../../common/help/help.service';
import { Directory } from '../../../common/help/help.model';

@Component({
    selector: 'app-open-id-callback',
    templateUrl: 'open-id-call-back.component.html',
    styleUrls: ['open-id-call-back.component.css']
  }
)
export class OpenIdCallBackComponent implements OnInit, AfterViewInit, OnDestroy {
  //private redirectUrl = '/rnlt/dashboard';
  private redirectUrl = 'tp2';
  //private redirectUrl = '/';
  public rolesArr: string[];
  public roles : string;
  userInfo: UserInfo;
  doneAuthenticatingSubscription: Subscription;

  constructor(private oAuthService: OAuthService,
              private rnltErrorService: RnltErrorService,
              private authorizationService: AuthorizationService,
              private orgMapService: OrganisationMapService,
              private rolesService: RolesService,
              private commonService: CommonServices,
              private messageService: MessageService,
              private helpService: HelpService,
              private environmentService: EnvironmentService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // 1- if insecure mode
    if (!this.environmentService.isOAuthEnabled()) {
      console.log('[oAuth security disabled !]');
      return;
    }

    // We should wait for the Authentication process to terminate
    this.doneAuthenticatingSubscription = this.authorizationService.whenDoneAuthenticating()
      .subscribe(() => {

        // 2- if we have an access token but it is not valid => redirect to FORBIDDEN
        if (this.oAuthService.getAccessToken() && !this.oAuthService.hasValidAccessToken()) {
          console.log('[OpenIdCallBackComponent - OIDC]: ', 'Access token is invalid');
          this.rnltErrorService.redirect(FORBIDDEN);
          return;
        } else {
          console.log('[OpenIdCallBackComponent - OIDC]: ', 'Access token is valid');
        }

        // 3- If we have a valid access token ==> get user identity
        if (this.oAuthService.getAccessToken() && this.oAuthService.hasValidAccessToken()) {
          try {
            this.userInfo = this.authorizationService.getUserInfo();
            console.log('userInfo 54... ' + this.userInfo);
          } catch (e) {
            this.userInfo = null;
            console.error('Can get user info');
          }
        }

        // 4- Manage after IDP login
        if (this.userInfo) {
          console.log('Redirecting to ' + this.redirectUrl);

          sessionStorage.setItem('roleAction', '');

          sessionStorage.setItem('uid', this.userInfo.sub);
          sessionStorage.setItem('userName', (this.userInfo.firstName + ' ' + this.userInfo.lastName));

          console.log('openCallBack userInfo firstName..'+ this.userInfo.firstName);

          var roles = this.userInfo.roles;

          console.log('openCallBack 91 roles.'+ roles);

          console.log('openCallBack 93 json roles..'+ JSON.stringify(roles));

          console.log('... sessionstorage role not null '+sessionStorage.getItem('role'));

          if(sessionStorage.getItem('role') == null || sessionStorage.getItem('role') == undefined) {

            console.log('... sessionstorage role is null or undefined ');

            sessionStorage.setItem('role', JSON.stringify(roles));
          }
          if(this.userInfo.roles) {

            this.getRolesFromComment(roles);
            this.commonService.currentUserRole = sessionStorage.getItem('role');
            this.messageService.sendMessage('false');
            this.getOrganisationRegId();
            this.getRolesNames();

          } else {
            console.log('No rolls for the user');
            this.authorizationService.logout();
          }

          console.log(' before Redirecting to 103 ' + this.redirectUrl);

          this.authorizationService.afterAuth(this.redirectUrl, this.userInfo);
        }
      });
  }

  getRolesFromComment(roles) {
    if(this.userInfo.sub!=null && sessionStorage.getItem('envName')!='OPE') {

      this.helpService.getDirectoryComment(this.userInfo.sub).subscribe(
        (directory : Directory) => {

          console.log(' 96...getRolesFromComment comment ...'+directory.comment);

          if(directory.comment!=null && directory.comment.startsWith('["TPM2')) {
            console.log('openCallBack starts with getRolesFromComment setting comment  ');
            sessionStorage.setItem('role',''+ directory.comment);
            this.roles = directory.comment.toString();
          }
          else {
            console.log('not starts with  getRolesFromComment not setting from comment  ');

            sessionStorage.setItem('role', JSON.stringify(roles));
          }

        });
    }
    else {

      console.log('openCallBack else condition getRolesFromComment not setting from comment  ');

      sessionStorage.setItem('role', JSON.stringify(roles));

      this.roles = JSON.stringify(roles);

    }
  }

  getOrganisationRegId() {
    console.log('.. getOrganisationRegId...'+sessionStorage.getItem('role'))
    const rolesArr: string[] = [];
    let organisationId: number[];
    // sessionStorage.setItem('roleAction', '');
    const sessionRoles = JSON.parse(sessionStorage.getItem('role')) || [];
    sessionRoles.forEach(role => {
      const roleVal = role.substring(5).toUpperCase();
      rolesArr.push(roleVal);
    });
    const organisationMapModel: OrganisationMapModel = new OrganisationMapModel();
    organisationMapModel.organisationNames = rolesArr;
    this.orgMapService.getOrganisationRegId(organisationMapModel).subscribe((response) => {
      organisationId = response;
      if (response === undefined || response == null) {
        sessionStorage.setItem('isOrgIdEmpty', 'true');
      } else {
        sessionStorage.setItem('isOrgIdEmpty', 'false');
      }
      sessionStorage.setItem('organisationIDs', JSON.stringify(organisationId));
      this.orgMapService.setOrganizationIds(organisationId);
    });
  }

  getRolesNames() {
    const roles: Roles = new Roles();
    roles.rolesList = this.getRolesArr();
    this.rolesService.isOnlyRolesExists(roles).subscribe((response) => {
      if (response) {
        sessionStorage.setItem('onlyRoles', 'true');
      } else {
        sessionStorage.setItem('onlyRoles', 'false');
      }
    });
  }

  getRolesArr(): string[] {
    this.rolesArr = [];
    const sessionRoles = JSON.parse(sessionStorage.getItem('role')) || [];
    sessionRoles.forEach(role => {
      const roleVal = role.substring(5).toUpperCase();
      this.rolesArr.push(role);
    });
    return this.rolesArr;
  }



  ngOnDestroy(): void {
    if (this.doneAuthenticatingSubscription) {
      this.doneAuthenticatingSubscription.unsubscribe();
    }
  }
}
