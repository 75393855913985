import { Site } from 'src/app/administration/referentiel/sites/site.model';
export class BarProgramLines {
    id: number;
    label: String;
    createDate: Date;
    updateDate: Date;
    ipn: String;
    active: Boolean;
    organisationId: number[];
    sites: Site;

}
