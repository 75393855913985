import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Level0 } from './level0.model';
import { GridOptions } from 'ag-grid-community';
import { LevelsAdmin } from '../levelsadmin/levelsadmin.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Level0Service {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public level0: Level0;
  public btnName: string;
  public id: number;
  public alreadyUsedData: Boolean;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';

  }

  getLevel0Data(level0: Level0) {
    return this.httpClient.post<Level0[]>(this.apiURL + '/getLevel0List', level0).pipe(map(res => res));
  }

  updateLevel0OrganisationList(level0: Level0) {
    return this.httpClient.put<Level0>(this.apiURL + '/updateLevel0OrganisationList', level0, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(level0) {
    this.getLevel0Data(level0).subscribe((result: Level0[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  createLevel0List(level0: Level0) {
    return this.httpClient.post<Level0>(this.apiURL + '/saveLevel0', level0, {
      observe: 'response'
    });
  }

  checkDuplicateLevel0(level0: Level0) {

    return this.httpClient.post<Level0>(this.apiURL + '/checkDuplicateLevel0', level0, {

      observe: 'response'

    });
  }

  updateLevel0List(level0: Level0) {
    return this.httpClient.put<Level0>(this.apiURL + '/updateLevel0', level0, {
      observe: 'response'
    });
  }

  setLevel0(gridLevel0: Level0) {
    this.level0 = gridLevel0;
    if (this.level0 != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateLevel0() {
    return this.level0;
  }
  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
  checkAlreadyAssociated(levelsAdmin: LevelsAdmin): Observable<boolean> {
    return this.httpClient.post<boolean>(this.apiURL + '/checkAlreadyAssociated', levelsAdmin).pipe(map(res => res));
  }
  getAlreadyUsedData() {
    return this.alreadyUsedData;
  }
  setAlreadyUsedData(alreadyUsedData: Boolean) {
    this.alreadyUsedData = alreadyUsedData;
  }
}
