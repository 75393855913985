import { Injectable } from '@angular/core';

@Injectable()
export class OrganisationMapModel {
    id: number;
    label: string;
    organisationNames: string[];

    private gridID: number;
    private tableName: string;
    private alreadyMappedOrgId: Array<number>;

    public enableOrgBtn: boolean = true;

    enableOrganisationBtn(condition: boolean){
        this.enableOrgBtn = condition;
    }

    setGridId(param: number, alreadyMappedOrgId: Array<number>, tableName: string) {
        this.tableName = tableName;
        this.gridID = param;
        this.alreadyMappedOrgId = alreadyMappedOrgId;
    }

    getGridId() {
        return this.gridID;
    }

    getTableName() {
        return this.tableName;
    }

    getAlreadyMappedOrgId(): Array<number> {
        return this.alreadyMappedOrgId;
    }
}