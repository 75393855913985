import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { CallbackComponent } from './auth/callback/callback.component';
import { OpenidComponent } from './auth/openid/openid.component';
import { HomeComponent } from './common/home/home.component';
import { LogoutpageComponent } from './common/logoutpage/logoutpage.component';
import { ValidationSpecimenComponent } from './validation-specimen/validation-specimen.component';

import {OpenIdCallBackComponent} from './shared/authorization/callback/open-id-call-back.component';
import {RnltErrorsComponent} from './shared/error/rnlt-errors.component';
import {AuthGuard} from './shared/authorization/authguard/authguard.service';
import {Authorization} from './shared/authorization/authorization.model';
import {TemplateComponent} from './shared/template/template.component';
import {LogoutComponent} from './shared/authorization/logout/logout.component';
import { RedirectactionComponent } from './common/redirectaction/redirectaction.component';


const routes: Routes = [
  // {
  //   path: 'administration',
  //   loadChildren: './administration/administration.module#AdministrationModule',
  // },
  // {
  //   path: 'help',
  //   loadChildren: './common/help/help.module#HelpModule',
  // },
  //  {
  //   path: 'Input',
  //   loadChildren: './saisie/saisie.module#SaisieModule',
  // },
  // {
  //   path: 'MPM',
  //   loadChildren: './mpm/mpm.module#MpmModule',
  // },
  // {
  //   path: 'TestMeansStatus',
  //   loadChildren: './etat/etat.module#Etatmodule',
  // },
  // {
  //   path: 'ValidationSpecimen',
  //   // component: ValidationSpecimenComponent,
  //   loadChildren: './validation-specimen/validation-specimen.module#ValidationSpecimenModule'
  // },
  { path: 'tp2', component: RedirectactionComponent },
  // { path: 'tp2/callback', component: OpenIdCallBackComponent },
  { path: 'callback', component: OpenIdCallBackComponent },
  // { path: 'logout', component: LogoutComponent },
  //{ path: '', component: OpenIdCallBackComponent },
  { path: '', redirectTo: 'tp2', pathMatch: 'full' },
  { path: 'openId', component: OpenIdCallBackComponent },
  {path: 'error/:errorType', component: RnltErrorsComponent},
  {path: 'error', component: RnltErrorsComponent},
  {path: '**', redirectTo: 'tp2'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
 }
 )],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
    console.log(' 1. AppRoutingModule ');
  }
 }
