import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Site } from '../sites/site.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Means } from './means.model';
import { MapModel } from './map.model';
import { GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class MeansService {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public mean: Means;
  public btnName: string;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllMeans(means: Means) {
    return this.httpClient.post<Means[]>(this.apiURL + '/getMeanList', means).pipe(map(res => res));
  }

  getAllMaps(): Observable<MapModel[]> {
    return this.httpClient.get<MapModel[]>(this.apiURL + '/getMapNames').pipe(map(res => res));
  }

  getMapList(): Observable<MapModel[]> {
    return this.httpClient.get<MapModel[]>(this.apiURL + '/getMapList').pipe(map(res => res));
  }

  updateMeanOrganisationList(mean: Means) {
    return this.httpClient.put<Means>(this.apiURL + '/updateMeanOrganisationList', mean, {
      observe: 'response'
    });
  }

  createMeanList(mean: Means) {
    return this.httpClient.post<Means>(this.apiURL + '/createMeanList', mean, {
      observe: 'response'
    });
  }

  getActiveMeanList(mean: Means) {
    return this.httpClient.post<Means[]>(this.apiURL + '/getActiveMeanByOrganisation', mean);
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }
  updateMeanList(mean: Means) {
    return this.httpClient.put<Means>(this.apiURL + '/updateMeanList', mean, {
      observe: 'response'
    });
  }

  refreshGrid(means: Means) {
    this.getAllMeans(means).subscribe((result: Means[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  setMean(gridMean: Means) {
    this.mean = gridMean;

    if (this.mean != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateMean() {
    return this.mean;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
