import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PrestationAdmin } from 'src/app/administration/prestationsandprocedures/prestationadmin/prestationadmin.model';
import { map } from 'rxjs/operators';
import { GridOptions } from 'ag-grid-community';
import { SuiviMoteur, WrapperDataSuivi, FetchWeekData, SuiviWeek1Wrapper, SuiviWeek1Grid, SuiviWeek1Field } from '../model/suivi-moteur.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuiviMoteurService {
  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();
  private baseString: string;
  initTabGridDirty: boolean;
  public activityRateWeek2: any;
  public surfeWeek2: any;
  public concernWeek2: any;
  public activityRateWeek3: any;
  public surfeWeek3: any;
  public concernWeek3: any;
  suiviInfoData: SuiviMoteur;
  suiviWeek1Data: SuiviWeek1Field;
  suiviWeek1GridData: SuiviWeek1Grid[];
  suiviData: SuiviMoteur;
  public coldCycleWeek2: any;
  public coldCycleWeek3: any;
  public hotCycleWeek2: any;
  public hotCycleWeek3: any;
  public totalDureeWeek2: any;
  public totalDureeWeek3: any;
  public steeringWeek3: any;
  public steeringWeek2: any;
  public gridOptions: GridOptions;
  constructor(private http: HttpClient) {
    this.baseString = environment.apiURL;
  }

  getAllPrestationAdmin(prestationAdmin: PrestationAdmin) {
    return this.http.post<PrestationAdmin[]>(this.baseString + '/getPrestationAdminList', prestationAdmin).pipe(map(res => res));
  }

  getAllSuiviData(suiviData: SuiviMoteur) {
    return this.http.post<SuiviMoteur>(this.baseString + '/getAllSuiviData', suiviData);
  }

  saveSuiviData(weekData: WrapperDataSuivi) {
    return this.http.post<WrapperDataSuivi>(this.baseString + '/saveSuiviData', weekData);
  }

  getAllWeekData(fetchWeekDate: FetchWeekData) {
    return this.http.post<WrapperDataSuivi>(this.baseString + '/getAllWeekData', fetchWeekDate);
  }

  saveSuiviInfo(info: SuiviMoteur) {
    return this.http.post<SuiviMoteur>(this.baseString + '/saveSuiviInfo', info, {
      observe: 'response'
    });
  }

  getAllWeek1Data(fetchWeekDate: FetchWeekData) {
    return this.http.post<SuiviWeek1Wrapper>(this.baseString + '/getAllWeek1Data', fetchWeekDate);
  }

  saveWeek1Data(suiviWeek1Data: SuiviWeek1Wrapper) {
    return this.http.post<any>(this.baseString + '/saveWeek1Data', suiviWeek1Data);
  }

  setSuiviWeek1Data(suiviWeek1Data: SuiviWeek1Field) {
    this.suiviWeek1Data = suiviWeek1Data;
  }

  getSuiviWeek1Data() {
    return this.suiviWeek1Data;
  }

  getInitTabGridDirty() {
    return this.initTabGridDirty;
  }

  setInitTabGridDirty(initTabGridDirty: boolean) {
    this.initTabGridDirty = initTabGridDirty;
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
}
