import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, DateAdapter, MatOption, MatSelect, MatDatepicker, MatDatepickerContent } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { ActivateService } from 'src/app/saisie/activate/activate.service';
import { TestbedGlobalInfo } from 'src/app/administration/testbedreferential/testbed/global-info/testbed-global-info.model';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { Building } from 'src/app/administration/referentiel/buildings/building.model';
import { Means } from 'src/app/administration/referentiel/means/means.model';
import { CommonServices } from '../common.services';
import * as moment from 'moment';
import { TestbedService } from 'src/app/administration/testbedreferential/testbed/testbed-services';
import { TestbedExtractModel } from './tesbed-extract.model';
import { TestbedExtractService } from './testbed-extract.service';
import { TestbedExtractEnum } from './tesbed-extract.model';
import { ActivityEnum } from 'src/app/saisie/activate/activity.model';
import { VhActService } from 'src/app/saisie/vehicle-activities/vh-act.service';;
import * as enlangData from 'src/assets/i18n/en.json';
import * as frlangData from 'src/assets/i18n/fr.json';
import { exportLang, vehicleActivityEnum } from 'src/app/saisie/vehicle-activities/vehicle-activities.model';
import { SnackBarService } from 'src/app/common/snackbar/snackbar.component.service';
@Component({
  selector: 'testbed-extract',
  templateUrl: './testbed-extract.component.html',
  styleUrls: ['./testbed-extract.component.css']
})
export class TestbedExtractComponent implements OnInit {
  testBedComboOne;
  selectedSiteIds: any[];
  selectedSite: any[];
  selectedMean: any[];
  selectedMeanIds: any[];
  selectedTestbedIds: any[];
  selectedTestbed: any[];
  filterTestbedCombo: any[];

  constructor(public dialogRef: MatDialogRef<TestbedExtractComponent>,
    private fb: FormBuilder,
    public translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private activityService: ActivateService,
    private commonService: CommonServices,
    private testBedService: TestbedService,
    private extractService: TestbedExtractService,
    private snackbarService: SnackBarService,
    private vhActService: VhActService) { }
  @ViewChild('closeModalBtn') closeModalBtn: ElementRef;
  public submitted = false;
  public closeControl = false;
  // failureResult: FailureActivity[];
  // tuningResult: TuningEdActivity[];
  // reliabilityResult: EngineReliability[];
  // evResult: EvEngine[];
  testBedData: Testbed[];
  fileName: any;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  public extractForm: FormGroup;
  public today = new Date();
  public month: any;
  public year: any;
  public day: any;
  public minYear: number;
  public startEvent: Date;
  public endEvent: Date;
  public startMinDate: Date;
  public startMaxDate: Date;
  public endMinDate: Date;
  public endMaxDate: Date;
  public activeControl: Boolean;
  public flagForTestBed: Boolean;
  public mapName: any;
  public testBedId: string;
  public showOption: Boolean;
  public showTypeOfTest: Boolean;
  // public testBedCombo: Testbed[];
  // public subject = new Subject<[string, string, any]>();
  public browserLang: String = this.commonService.getLanguage() ? this.commonService.getLanguage() : 'en';
  public siteComboList: TestbedGlobalInfo[];
  public meansComboList: TestbedGlobalInfo[];
  public filterMeanCombo: any[];
  @ViewChild('testbedSelect') public testbedSelect: MatSelect;
  @ViewChild('allSelect') public allSelect: MatOption;
  @ViewChild('singleSelect') public singleSelect: MatOption;
  @ViewChild('picker1') public picker1: MatDatepicker<Date>;
  allCheck: boolean = false;
  finalOrgId: any[];
  enableActivityCheck: boolean;
  

  ngOnInit() {
    this.finalOrgId = JSON.parse(sessionStorage.getItem(TestbedExtractEnum.orgId));
    if (this.finalOrgId.length > 1) {
      this.enableActivityCheck = true;
      this.onActivityTypeChoose(TestbedExtractEnum.deatm);
    } else {
      this.enableActivityCheck = false;
      this.getSiteComboList();
      this.getMeansComboList();
      this.getTestBedComboList();
    }
    this.month = this.today.getMonth();
    this.year = this.today.getFullYear();
    this.day = this.today.getDate();
    this.minYear = this.year - 5;
    this.startMaxDate = new Date(this.year, 0, 1);
    this.startMinDate = new Date(this.minYear, 0, 1);
    this.activeControl = true;
    this.getTestBedData();
    this.extractForm = this.fb.group(
      {
        beginDt: ['', [Validators.required]],
        endDt: ['', Validators.required],
        site: [''],
        means: [''],
        testbed: ['']
      });
    this.dateAdapter.setLocale(this.activityService.getLang());
    this.picker1.startAt = new Date(this.year, 0, 1);

  }
  get f() { return this.extractForm.controls; }
  onClose(): void {
    this.dialogRef.close('Close');
    this.selectedMeanIds = [];
    this.selectedMean = [];
    this.selectedTestbedIds = [];
    this.selectedTestbed = [];
    this.selectedSiteIds = [];
    this.selectedSite = [];
    this.extractService.setMeanId(this.selectedMeanIds);
    this.extractService.setTestbedId(this.selectedTestbedIds);
  }
  getSiteComboList() {
    this.activityService.getSiteComboList().subscribe(data => {
      this.siteComboList = data;
    });
  }
  getEngineSiteComboList() {
    this.activityService.getEngineSiteComboList().subscribe(data => {
      this.siteComboList = data;
    });
  }
  getVehicleSiteComboList() {
    this.activityService.getSiteComboListService().subscribe(data => {
      this.siteComboList = data;
    });
  }
  getMeansComboList() {
    this.activityService.getMeansComboListForExtract().subscribe(data => {
      this.meansComboList = data;
      // this.meanDDVal = data;
    });
  }
  getEngineMeansComboList() {
    this.activityService.getEngineMeansComboList().subscribe(data => {
      this.meansComboList = data;
    });
  }
  getVehicleMeansComboList() {
    this.activityService.getMeansComboListService().subscribe(data => {
      this.meansComboList = data;
    });
  }

  getTestBedComboList(testbed?) {
    if (!testbed) {
      testbed = new Testbed();
      testbed.testbedGlobalInfo = new TestbedGlobalInfo();
      testbed.organisation_reg_id = JSON.parse(sessionStorage.getItem(TestbedExtractEnum.orgId));
      testbed.testbedGlobalInfo.buildingId = new Building();
      testbed.testbedGlobalInfo.meanId = new Means();
    }
    sessionStorage.setItem(TestbedExtractEnum.testbedExtract, TestbedExtractEnum.true);
    this.activityService.getTestbedComboList(testbed).subscribe((response) => {
      this.testBedComboOne = response.body;
      this.testBedComboOne = this.testBedComboOne.filter(res => res[2] !== false);
    });
  }
  getTestBedbyActivity(value: String) {
    let orgId: any[];
    if (value === TestbedExtractEnum.deatm) {
      orgId = [1];
    } else {
      orgId = [2];
    }
    const testbed = new Testbed();
    testbed.testbedGlobalInfo = new TestbedGlobalInfo();
    testbed.organisation_reg_id = orgId;
    testbed.testbedGlobalInfo.buildingId = new Building();
    testbed.testbedGlobalInfo.meanId = new Means();
    sessionStorage.setItem(TestbedExtractEnum.testbedExtract, TestbedExtractEnum.true);
    this.activityService.getTestbedComboList(testbed).subscribe((response) => {
      this.testBedComboOne = response.body;
      this.testBedComboOne = this.testBedComboOne.filter(res => res[2] !== false);
    });
  }
  addStartEvent(e) {
    this.extractForm.patchValue({ endDt: '' });
    this.activeControl = false;
    this.startEvent = new Date(e.target.value);
    this.endMinDate = this.startEvent;
    this.year = this.startEvent.getFullYear() + 1;
    this.month = this.startEvent.getMonth();
    this.day = this.startEvent.getDate();
    this.endMaxDate = new Date(this.year, this.month, this.day);

  }
  onSiteChange(e) {
    const selesite = e.value;
    this.selectedSiteIds = [];
    this.selectedSite = [];
    // for getting the values of all the site chosen in array
    if (selesite.length > 0) {
      selesite.forEach(element => {
        this.selectedSiteIds.push(element[0]);
        this.selectedSite.push(element[1]);
      });
    }
    const extractModel = new TestbedExtractModel();
    extractModel.site = this.selectedSiteIds;
    extractModel.orgId = this.finalOrgId;
    this.allCheck = false;
    this.newMeanFiltering(extractModel);
    this.newTestbedFiltering(extractModel);
  }
  onMeanChange(e) {
    const seleMean = e.value;

    this.showTypeOfTest = false; 
    this.selectedMeanIds = [];
    this.selectedMean = [];
    if (seleMean.length > 0) {
      seleMean.forEach(element => {
        this.selectedMeanIds.push(element[0]);
        this.selectedMean.push(element[1]);

        if(element[1] === 'ELEC PUISSANCE') {
          this.showTypeOfTest = true;
        }
      });
    }
    const extractModel = new TestbedExtractModel();
    extractModel.means = this.selectedMeanIds;
    this.extractService.setMeanId(this.selectedMeanIds);
    extractModel.orgId = this.finalOrgId;
    if (this.selectedSite === undefined || this.selectedSite === ['']) {
      extractModel.site = [];
    } else {
      extractModel.site = this.selectedSiteIds;
    }
    this.allCheck = false;
    this.newTestbedFiltering(extractModel);

  }
  onTestbedChange(e) {
    let seleTestbed = [];
    seleTestbed = e.value;
    this.selectedTestbedIds = [];
    this.selectedTestbed = [];
    if (seleTestbed.length > 0) {
      seleTestbed.forEach(element => {
        this.selectedTestbedIds.push(element[0]);
        this.selectedTestbed.push(element[1]);
      });
    }
    this.extractService.setTestbedId(this.selectedTestbedIds);
  }

  onTestbedclick(e: any) {
    if (e.checked) {
      this.allCheck = true;
      this.testbedSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.allCheck = false;
      this.testbedSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  onSingleClick() {
    this.allCheck = false;
  }

  newMeanFiltering(value: TestbedExtractModel) {
    this.meansComboList = [];
    this.selectedMeanIds = [];
    this.selectedMean = [];
    this.testBedComboOne = [];
    this.selectedTestbedIds = [];
    this.selectedTestbed = [];
    value.testbed = this.selectedTestbedIds;
    value.means = this.selectedMeanIds;
    this.extractService.getFilteredMeans(value).subscribe((data: TestbedGlobalInfo[]) => {
      this.meansComboList = data;
      /* Mean should not clear out when adding new site starts here */
      if (this.extractService.getMeanId()) {
        this.selectedMeanIds = [];
        this.filterMeanCombo = [];
        let dataMean = [];
        let temp = 0;
        this.extractService.getMeanId().forEach(res => {
          if (this.meansComboList.filter(means => means[0] === res).length > 0) {
            this.filterMeanCombo[temp] = this.meansComboList.filter(means => means[0] === res)[0];
            this.selectedMeanIds[temp] = this.meansComboList.filter(means => means[0] === res)[0][0];
            this.selectedMean[temp] = this.meansComboList.filter(means => means[0] === res)[0][1];
            temp++;
          }
        });
        dataMean = this.filterMeanCombo;
        this.extractForm.patchValue({ means: dataMean });
        this.extractService.setMeanId(this.selectedMeanIds);
      } else {
        this.selectedMean = [];
        this.selectedMeanIds = [];
        this.extractForm.patchValue({ means: [] });
        this.extractService.setMeanId(this.selectedMeanIds);
      }
    });
    /* Mean should not clear out when adding new site ends here */
  }
  newTestbedFiltering(value: TestbedExtractModel) {
    this.testBedComboOne = [];
    this.selectedTestbedIds = [];
    this.selectedTestbed = [];
    value.testbed = this.selectedTestbedIds;
    this.extractService.getFilteredTestbed(value).subscribe((data: Testbed[]) => {
      this.testBedComboOne = data;
      /* Testbed should not clear out when adding new site or mean starts here */
      if (this.extractService.getTestbedId()) {
        this.selectedTestbedIds = [];
        this.filterTestbedCombo = [];
        let dataTestbed = [];
        let temp = 0;
        this.extractService.getTestbedId().forEach(res => {
          if (this.testBedComboOne.filter(testbed => testbed[0] === res).length > 0) {
            this.filterTestbedCombo[temp] = this.testBedComboOne.filter(testbed => testbed[0] === res)[0];
            this.selectedTestbedIds[temp] = this.testBedComboOne.filter(testbed => testbed[0] === res)[0][0];
            this.selectedTestbed[temp] = this.testBedComboOne.filter(testbed => testbed[0] === res)[0][1];
            temp++;
          }
        });
        dataTestbed = this.filterTestbedCombo;
        this.extractService.setTestbedId(this.selectedTestbedIds);
        this.extractForm.patchValue({ testbed: dataTestbed });
      } else {
        this.selectedTestbed = [];
        this.selectedTestbedIds = [];
        this.extractService.setTestbedId(this.selectedTestbedIds);
        this.extractForm.patchValue({ testbed: [] });
      }
    });
    /* Testbed should not clear out when adding new site or mean ends here */
  }

  dateSelection() {
    if (this.extractForm.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      let startDt: String;
      let stopDt: String;
      startDt = moment(this.extractForm.controls.beginDt.value).format('YYYY-MM-DD');
      stopDt = moment(this.extractForm.controls.endDt.value).format('YYYY-MM-DD');
      if (!this.selectedTestbedIds || this.selectedTestbedIds.length === 0) {
        this.selectedTestbedIds = this.testBedComboOne.map(res => res[0]);
      }
      // testbed id [1]
      if (this.finalOrgId.length === 1) {
        if (this.finalOrgId[0] === 1) {
          this.engineActivityExtract(startDt, stopDt);
        } else {
          this.vehicleActivityExtract(startDt, stopDt);
        }
      }
    }
  }

  onActivityTypeChoose(value: String) {
    if (value === TestbedExtractEnum.deatm) {
      this.getEngineSiteComboList();
      this.getEngineMeansComboList();
      this.getTestBedbyActivity(value);
      this.finalOrgId = [1];
    } else {
      this.getVehicleSiteComboList();
      this.getVehicleMeansComboList();
      this.getTestBedbyActivity(value);
      this.finalOrgId = [2];
    }

  }
  engineActivityExtract(startDt: any, stopDt: any) {
    this.activityService.extractNewEvEngineActivity(startDt, stopDt,
       this.selectedTestbedIds).subscribe((result: any) => {
      this.commonService.setEvEngineData(result.evEngine);
      this.commonService.setEngineReliabilityActivityData(result.reliabilityEdActivity);
      this.commonService.setEngineTuningActivityData(result.tuningEdActivity);
      this.commonService.setFailureActivityData(result.failureActivity);
      this.commonService.setBarActivityData(result.barActivity);
      this.commonService.setBarCopActivityData(result.barCopActivity);
      this.commonService.setBarMixActivityData(result.barMixActivity);
      this.commonService.setEmisionFailureActivitesData(result.failureActivityforEmission);
      this.extractData();
      this.onClose();
    },
    (err) => {
      this.snackbarService.showSnackBar('Errormessage.extract_reduce_testbeds_Msg', 'F');
    }
    );

  }
  vehicleActivityExtract(startDt: any, stopDt: any) {
    this.vhActService.extractNewVehicleActivity(startDt, stopDt, this.selectedTestbedIds).subscribe((result: any) => {
      this.commonService.setVehicleActivityData(result.vehicleActivity);
      this.commonService.setVehicleFailiureActivityData(result.failureActivity);
      this.extractVhData();
      this.onClose();
    });
  }
  extractData() {
    let jsonData = {};
    const extracEvData = [];
    const extractReliabilityData = [];
    const extractFailureData = [];
    const extractTuningData = [];
    const extractbarActivityData = [];
    const extractbarCopActivityData = [];
    const extractbarMixActivityData = [];
    const extractfailureActivityforEmissionData = [];
    const evEngineJson = this.commonService.getEvEngineData();
    const reliabilityEngineJson = this.commonService.getEngineReliabilityActivityData();
    const tuningEngineJson = this.commonService.getEngineTuningActivityData();
    const failureactivityJson = this.commonService.getFailureActivityData();
    const barActivityJson = this.commonService.getBarActivityData();
    const barCopActivityJson = this.commonService.getBarCopActivityData();
    const barMixActivityJson = this.commonService.getBarMixActivityData();
    const emissionFailureJson = this.commonService.getEmissionFailureActivity();
    const elecFailureData = [];
    const reliabFailureData = [];
    const tuningFailureData = [];
    const barActivityData = [];
    const barCopActivityData = [];
    const barMixActivityData = [];
    const failureActivityforEmissionData = [];
    if (this.browserLang === 'en') {
      this.fileName = 'Activity';
      if (evEngineJson.length !== 0) {
        for (let i = 0; i < evEngineJson.length; i++) {
          jsonData['Site'] = this.nullcheck(evEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Mean'] = this.nullcheck(evEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Testbed'] = this.getTestBedLabel(evEngineJson[i].engineActivity.activity.testBedId.id);
          jsonData['Date'] = moment(evEngineJson[i].engineActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['Sequence Number'] = evEngineJson[i].engineActivity.activity.seqNum;
          jsonData['Engine hours'] = evEngineJson[i].engineActivity.engHours;
          jsonData['Activity type'] = this.nullcheck(evEngineJson[i].engineActivity.activity.level0);
          jsonData['Activity duration'] = evEngineJson[i].engineActivity.activity.duration;
          jsonData['D.I./Test Number'] = this.emptyValueInsertorforNull(evEngineJson[i].engineActivity.di) +
            this.emptyValueInsertorforNull(evEngineJson[i].engineActivity.essNB);
          jsonData['Exploitable'] = this.nullcheck(evEngineJson[i].engineActivity.activity.exploitable);
          jsonData['Comment'] = evEngineJson[i].engineActivity.activity.comment;
          jsonData['Level 1'] = this.nullcheck(evEngineJson[i].engineActivity.activity.level1);
          jsonData['Level 2'] = this.nullcheck(evEngineJson[i].engineActivity.activity.level2);
          jsonData['Program line'] = evEngineJson[i].engineActivity.programLine;
          jsonData['VS number'] = (evEngineJson[i].engineActivity.engNumber);
          //jsonData['VS number'] = evEngineJson[i].engineActivity.testedVS;
          jsonData['Calibration'] = evEngineJson[i].engineActivity.activity.calibration;
          jsonData['Operator IPN'] = (evEngineJson[i].engineActivity.activity.ipn);
          jsonData['Customer direction'] = this.nullcheck(evEngineJson[i].engineActivity.activity.customerDirection);
          jsonData['Prestation'] = this.nullcheck(evEngineJson[i].engineActivity.activity.prestations);
          jsonData['Macro Activity'] = this.nullcheck(evEngineJson[i].engineActivity.activity.macroActivity);
          jsonData['Procedures/Test type'] = this.nullcheck(evEngineJson[i].engineActivity.activity.procedures);
          jsonData['Campaign'] = this.nullcheck(evEngineJson[i].engineActivity.activity.campaigns);
          if(this.showTypeOfTest) {
            jsonData['Type Of Test'] = evEngineJson[i].engineActivity.activity.typeOfTest;
          }
          jsonData['Perimeter'] = this.nullcheck(evEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.perimeterId);
          jsonData['OT number'] = '';
          jsonData['OT status'] = '';
          jsonData['RS type'] = this.nullcheck(evEngineJson[i].engineActivity.rsTypeId);
          jsonData['ESS Number'] = evEngineJson[i].engineActivity.essNBGps;
          jsonData['Test support'] = evEngineJson[i].testSupport;
          jsonData['Realignment number'] = evEngineJson[i].realNb;
          jsonData['Fuel'] = this.nullcheck(evEngineJson[i].engineActivity.fuel);
          jsonData['Customer request'] = evEngineJson[i].engineActivity.activity.customerRequest;
          // jsonData['Test Number'] = evEngineJson[i].engineActivity.essNB;
          extracEvData[i] = jsonData;
          jsonData = {};
        }
      }
      if (reliabilityEngineJson.length !== 0) {
        for (let i = 0; i < reliabilityEngineJson.length; i++) {
          jsonData['Site'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Mean'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Testbed'] = this.getTestBedLabel(reliabilityEngineJson[i].engineActivity.activity.testBedId.id);
          jsonData['Date'] = moment(reliabilityEngineJson[i].engineActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['Sequence Number'] = reliabilityEngineJson[i].engineActivity.activity.seqNum;
          if (this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId) === "BV") {
            jsonData['Gearbox hours'] = reliabilityEngineJson[i].engineActivity.engHours;
          } else if (this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId) === ActivityEnum.Brap) {
           
            jsonData['KM VH'] = reliabilityEngineJson[i].engineActivity.engHours === 0 ? '0' :
            reliabilityEngineJson[i].engineActivity.engHours== null? '' :
            reliabilityEngineJson[i].engineActivity.engHours;

            
          } else {
            jsonData['Engine hours'] = reliabilityEngineJson[i].engineActivity.engHours;

          }
          jsonData['Activity type'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.level0);
          jsonData['Activity duration'] = reliabilityEngineJson[i].engineActivity.activity.duration;
          jsonData['D.I./Test Number'] = this.emptyValueInsertorforNull(reliabilityEngineJson[i].engineActivity.di) +
            this.emptyValueInsertorforNull(reliabilityEngineJson[i].engineActivity.essNB);
          jsonData['Exploitable'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.exploitable);
          jsonData['Comment'] = reliabilityEngineJson[i].engineActivity.activity.comment;
          jsonData['Level 1'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.level1);
          jsonData['Level 2'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.level2);
          jsonData['Program line'] = reliabilityEngineJson[i].engineActivity.programLine;
          if (this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId) === "BV") {
            jsonData['Gearbox number'] = reliabilityEngineJson[i].engineActivity.engNumber;
          } else if (this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId) === ActivityEnum.Brap) {
            jsonData['QFV number'] = reliabilityEngineJson[i].engineActivity.engNumber;
          } else {
            jsonData['Engine number'] = reliabilityEngineJson[i].engineActivity.engNumber;
          }
          jsonData['VS number'] = reliabilityEngineJson[i].engineActivity.testedVS;
          jsonData['Fuel'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.fuel);

          if (reliabilityEngineJson[i].engineActivity.activity.means.label.toUpperCase() === "BV") {
            jsonData['F.A / D.E number'] = reliabilityEngineJson[i].engineActivity.activity.customerRequest;
          } else {
            jsonData['Customer request'] = reliabilityEngineJson[i].engineActivity.activity.customerRequest;
          }

          // jsonData['F.A / D.E number'] = reliabilityEngineJson[i].cust_req;
          jsonData['Calibration'] = reliabilityEngineJson[i].engineActivity.activity.calibration;
          jsonData['Operator IPN'] = reliabilityEngineJson[i].engineActivity.activity.ipn;
          jsonData['Customer direction'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.customerDirection);
          jsonData['Prestation'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.prestations);
          jsonData['Macro Activity'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.prestations);
          jsonData['Procedures/Test type'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.procedures);
          jsonData['Campaign'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.campaigns);
          jsonData['Perimeter'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.perimeterId);
          jsonData['OT number'] = '';
          jsonData['OT status'] = '';
          jsonData['Soft'] = reliabilityEngineJson[i].soft;
          jsonData['RS type'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.rsTypeId);
          jsonData['ESS Number'] = reliabilityEngineJson[i].engineActivity.essNBGps;
          jsonData['RLCE'] = reliabilityEngineJson[i].engineActivity.bvRlce;
          // jsonData['Test Number'] = reliabilityEngineJson[i].engineActivity.essNB;
          extractReliabilityData[i] = jsonData;
          jsonData = {};
        }
      }
      if (tuningEngineJson.length !== 0) {
        for (let i = 0; i < tuningEngineJson.length; i++) {
          jsonData['Site'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Mean'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Testbed'] = this.getTestBedLabel(tuningEngineJson[i].engineActivity.activity.testBedId.id);
          jsonData['Date'] = moment(tuningEngineJson[i].engineActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['Sequence Number'] = tuningEngineJson[i].engineActivity.activity.seqNum;
          jsonData['Engine hours'] = tuningEngineJson[i].engineActivity.engHours;
          jsonData['Activity type'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.level0);
          jsonData['Activity duration'] = tuningEngineJson[i].engineActivity.activity.duration;
          jsonData['D.I./Test Number'] = this.emptyValueInsertorforNull(tuningEngineJson[i].engineActivity.di) +
            this.emptyValueInsertorforNull(tuningEngineJson[i].engineActivity.essNB);
          jsonData['Exploitable'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.exploitable);
          jsonData['Points number'] = tuningEngineJson[i].pointsNb;
          jsonData['Comment'] = tuningEngineJson[i].engineActivity.activity.comment;
          jsonData['Level 1'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.level1);
          jsonData['Level 2'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.level2);
          jsonData['Program line'] = (tuningEngineJson[i].engineActivity.programLine);
          jsonData['FEB number'] = tuningEngineJson[i].febNb;
          jsonData['Engine number'] = tuningEngineJson[i].engineActivity.engNumber;
          jsonData['VS number'] = tuningEngineJson[i].engineActivity.testedVS;
          jsonData['Engine D.T'] = tuningEngineJson[i].engDt;
          jsonData['RS type'] = this.nullcheck(tuningEngineJson[i].engineActivity.rsTypeId);
          jsonData['Calibration'] = tuningEngineJson[i].engineActivity.activity.calibration;
          jsonData['Operator IPN'] = tuningEngineJson[i].engineActivity.activity.ipn;
          jsonData['Customer direction'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.customerDirection);
          jsonData['Prestation'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.prestations);
          jsonData['Macro Activity'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.macroActivity);
          jsonData['Procedures/Test type'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.procedures);
          jsonData['Campaign'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.campaigns);
          jsonData['Perimeter'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.perimeterId);
          jsonData['OT number'] = '';
          jsonData['OT status'] = '';
          jsonData['Soft'] = tuningEngineJson[i].soft;
          jsonData['DCM'] = tuningEngineJson[i].dcm;
          jsonData['Test CAMEO/TST'] = tuningEngineJson[i].cameo;
          jsonData['ESS Number'] = tuningEngineJson[i].engineActivity.essNBGps;
          if (tuningEngineJson[i].engineActivity.activity.means.maps.label.toUpperCase() !== "Tuning E/D" && tuningEngineJson[i].engineActivity.activity.level0.label !== "MANU" && tuningEngineJson[i].engineActivity.activity.level0.level0_test === true && tuningEngineJson[i].engineActivity.activity.level0.failure === false) {
            jsonData['TOH'] = tuningEngineJson[i].engineActivity.tuningTOH;
          }
          // jsonData['Test Number'] = tuningEngineJson[i].engineActivity.essNB;
          jsonData['Fuel'] = this.nullcheck(tuningEngineJson[i].engineActivity.fuel);
          extractTuningData[i] = jsonData;
          jsonData = {};
        }
      }
      if (failureactivityJson.length !== 0) {
        for (let i = 0; i < failureactivityJson.length; i++) {
          jsonData['Site'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Mean'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Testbed'] = this.getTestBedLabel(failureactivityJson[i].engineActivity.activity.testBedId.id);
          jsonData['Date'] = moment(failureactivityJson[i].engineActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['Sequence Number'] = failureactivityJson[i].engineActivity.activity.seqNum;
          if (failureactivityJson[i].engineActivity.activity.means.label === "BV") {
            jsonData['Gearbox hours'] = failureactivityJson[i].engineActivity.engHours;
          } else if (failureactivityJson[i].engineActivity.activity.means.label === ActivityEnum.Brap) {

            jsonData['KM VH'] = failureactivityJson[i].engineActivity.engHours === 0 ? '0' :
            failureactivityJson[i].engineActivity.engHours== null? '' :
            failureactivityJson[i].engineActivity.engHours;
            
          } else {
            jsonData['Engine hours'] = failureactivityJson[i].engineActivity.engHours;
          }
          jsonData['Activity type'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.level0);
          jsonData['Activity duration'] = failureactivityJson[i].engineActivity.activity.duration;
          jsonData['D.I./Test Number'] = this.emptyValueInsertorforNull(failureactivityJson[i].engineActivity.di) +
            this.emptyValueInsertorforNull(failureactivityJson[i].engineActivity.essNB);
          jsonData['Exploitable'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.exploitable);
          // TODO: Delete jsonData['Points number'] = failureactivityJson[i].pointsNb ? failureactivityJson[i].pointsNb : null;
          jsonData['Comment'] = failureactivityJson[i].engineActivity.activity.comment;
          jsonData['Level 1'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.level1);
          jsonData['Level 2'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.level2);
          jsonData['Program line'] = (failureactivityJson[i].engineActivity.programLine);
          jsonData['FEB number'] = failureactivityJson[i].febNb;
          if (failureactivityJson[i].engineActivity.activity.means.label === "BV" && failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() !== "ELEC E/D") {
            jsonData['Gearbox number'] = failureactivityJson[i].engineActivity.engNumber;
          } else if (failureactivityJson[i].engineActivity.activity.means.label === ActivityEnum.Brap &&
            failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() !== "ELEC E/D") {
            jsonData['QFV number'] = failureactivityJson[i].engineActivity.engNumber;
          }
          else if (failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() === "ELEC E/D") {
            jsonData['VS number'] = failureactivityJson[i].engineActivity.engNumber;
          }
          else {
            jsonData['Engine number'] = failureactivityJson[i].engineActivity.engNumber;
          }
          //Commented for US502//
          //jsonData['VS number'] = failureactivityJson[i].engineActivity.testedVS;
          if (failureactivityJson[i].engineActivity.activity.means.label === "BV" && failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() !== "ELEC E/D") {
            jsonData['Gearbox D.T'] = failureactivityJson[i].engDt;
          }
          else if (failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() === "ELEC E/D") {
            jsonData['VS DT'] = failureactivityJson[i].engDt;
          }
          else {
            jsonData['Engine D.T'] = failureactivityJson[i].engDt;
          }
          jsonData['RS type'] = this.nullcheck(failureactivityJson[i].engineActivity.rsTypeId);
          jsonData['Calibration'] = failureactivityJson[i].engineActivity.activity.calibration;
          jsonData['Operator IPN'] = failureactivityJson[i].engineActivity.activity.ipn;
          jsonData['Customer direction'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.customerDirection);
          jsonData['Prestation'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.prestations);
          jsonData['Macro Activity'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.macroActivity);
          jsonData['Procedures/Test type'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.procedures);
          jsonData['Campaign'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.campaigns);
          jsonData['Perimeter'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.perimeterId);
          //Added for OTglobal changes
          jsonData['OT number'] = failureactivityJson[i].ot ? failureactivityJson[i].ot.label : failureactivityJson[i].otGlobal;
          jsonData['OT status'] = failureactivityJson[i].ot ? failureactivityJson[i].ot.status : '';
          jsonData['Customer request'] = failureactivityJson[i].engineActivity.activity.customerRequest;
          jsonData['Soft'] = failureactivityJson[i].soft;
          jsonData['DCM'] = failureactivityJson[i].dcm;
          jsonData['ESS Number'] = failureactivityJson[i].engineActivity.essNBGps;
          jsonData['Fuel'] = this.nullcheck(failureactivityJson[i].engineActivity.fuel);
          // jsonData['Test Number'] = failureactivityJson[i].engineActivity.essNB;
          if (failureactivityJson[i].engineActivity.activity.means.label.toUpperCase() === "BV" && failureactivityJson[i].engineActivity.activity.means.maps.label === "Reliability E/D") {
            jsonData['RLCE'] = failureactivityJson[i].bvRlce;
          }
          jsonData[''] = failureactivityJson[i].engineActivity.activity;
          extractFailureData[i] = jsonData;
          jsonData = {};
        }
      } if (barActivityJson.length !== 0) {
        for (let i = 0; i < barActivityJson.length; i++) {
          jsonData['Site'] = this.nullcheck(barActivityJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Mean'] = this.nullcheck(barActivityJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Testbed'] = this.getTestBedLabel(barActivityJson[i].rollActivity.activity.testBedId.id);
          jsonData['Date'] = moment(barActivityJson[i].rollActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['Sequence Number'] = barActivityJson[i].rollActivity.activity.seqNum;
          jsonData['Activity type'] = this.nullcheck(barActivityJson[i].rollActivity.activity.level0);
          jsonData['Level 1'] = this.nullcheck(barActivityJson[i].rollActivity.activity.level1);
          jsonData['Level 2'] = this.nullcheck(barActivityJson[i].rollActivity.activity.level2);
          jsonData['Exploitable'] = this.nullcheck(barActivityJson[i].rollActivity.activity.exploitable);
          jsonData['Activity duration'] = barActivityJson[i].rollActivity.activity.duration;
          jsonData['Engine'] = barActivityJson[i].rollActivity.vehDT;
          jsonData['Norm'] = barActivityJson[i].rollActivity.norm;
          jsonData['Astral Request Number'] = barActivityJson[i].rollActivity.astralNumber;
          jsonData['Astral Test Number'] = barActivityJson[i].rollActivity.testNumber;
          jsonData['Calibration'] = barActivityJson[i].rollActivity.activity.calibration;
          jsonData['Vehicle Reference'] = barActivityJson[i].workshop;
          jsonData['Temperature'] = barActivityJson[i].temperature;
          jsonData['Altitude'] = barActivityJson[i].rollActivity.altitude;
          jsonData['Interpolation Family'] = '';
          jsonData['Variante'] = '';
          jsonData['Manufacture Number/Vehicle Type'] = barActivityJson[i].rollActivity.vehReference;
          // jsonData['Vehicle Type'] = barActivityJson[i].rollActivity.vehReference;
          jsonData['Vehicle Mileage'] = barActivityJson[i].rollActivity.mileage;
          jsonData['Order Number'] = barActivityJson[i].rollActivity.orderNumber;
          jsonData['Vetsone N°'] = barActivityJson[i].rollActivity.vetsNumber;
          jsonData['Operator IPN'] = barActivityJson[i].rollActivity.operator;
          jsonData['Prestation'] = this.nullcheck(barActivityJson[i].rollActivity.activity.prestations);
          jsonData['Macro Activity'] = this.nullcheck(barActivityJson[i].rollActivity.activity.macroActivity);
          jsonData['Procedures'] = this.nullcheck(barActivityJson[i].rollActivity.activity.procedures);
          jsonData['Campaign'] = this.nullcheck(barActivityJson[i].rollActivity.activity.campaigns);
          jsonData['Service Client'] = this.nullcheck(barActivityJson[i].rollActivity.activity.customerDirection);
          jsonData['OT number'] = '';
          jsonData['OT status'] = '';
          jsonData['Astral Requester IPN'] = barActivityJson[i].rollActivity.operator;
          jsonData['Comment'] = barActivityJson[i].rollActivity.activity.comment;
          jsonData['Status'] = barActivityJson[i].rollActivity.status;
          jsonData['Customer Comment'] = barActivityJson[i].rollActivity.customerComment;
          jsonData['Requester'] = barActivityJson[i].rollActivity.requester;
          jsonData['Client Validation Message'] = barActivityJson[i].rollActivity.message1;
          jsonData[''] = barActivityJson[i].rollActivity.activity;
          extractbarActivityData[i] = jsonData;
          jsonData = {};
        }
      } if (barCopActivityJson.length !== 0) {
        for (let i = 0; i < barCopActivityJson.length; i++) {
          jsonData['Site'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Mean'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Testbed'] = this.getTestBedLabel(barCopActivityJson[i].rollActivity.activity.testBedId.id);
          jsonData['Date'] = moment(barCopActivityJson[i].rollActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['Sequence Number'] = barCopActivityJson[i].rollActivity.activity.seqNum;
          jsonData['Activity type'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.level0);
          jsonData['Level 1'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.level1);
          jsonData['Level 2'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.level2);
          jsonData['Exploitable'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.exploitable);
          jsonData['Activity duration'] = barCopActivityJson[i].rollActivity.activity.duration;
          jsonData['Engine'] = barCopActivityJson[i].rollActivity.vehDT;
          jsonData['Norm'] = barCopActivityJson[i].rollActivity.norm;
          jsonData['Astral Request Number'] = barCopActivityJson[i].rollActivity.astralNumber;
          jsonData['Astral Test Number'] = barCopActivityJson[i].rollActivity.testNumber >= TestbedExtractEnum.testNumberlimit ?
            barCopActivityJson[i].rollActivity.vetsOneProjectCode : barCopActivityJson[i].rollActivity.testNumber;
          jsonData['Calibration'] = barCopActivityJson[i].rollActivity.activity.calibration;
          jsonData['Vehicle Reference'] = '';
          jsonData['Temperature'] = '';
          jsonData['Altitude'] = '';
          jsonData['Interpolation Family'] = barCopActivityJson[i].interpolFam;
          jsonData['Variante'] = barCopActivityJson[i].variant;
          // jsonData['Vehicle Type'] = '';
          jsonData['Manufacture Number/Vehicle Type'] = barCopActivityJson[i].rollActivity.vehReference;
          jsonData['Vehicle Mileage'] = barCopActivityJson[i].rollActivity.mileage;
          jsonData['Order Number'] = this.nullcheck(barCopActivityJson[i].copProgragmLine);
          jsonData['Vetsone N°'] = barCopActivityJson[i].rollActivity.vetsNumber;
          jsonData['Operator IPN'] = barCopActivityJson[i].rollActivity.operator;
          jsonData['Prestation'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.prestations);
          jsonData['Prestation'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.macroActivity);
          jsonData['Procedures'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.procedures);
          jsonData['Campaign'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.campaigns);
          jsonData['Service Client'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.customerDirection);
          jsonData['OT number'] = '';
          jsonData['OT status'] = '';
          jsonData['Astral Requester IPN'] = barCopActivityJson[i].rollActivity.operator;
          jsonData['Comment'] = barCopActivityJson[i].rollActivity.activity.comment;
          jsonData['Status'] = barCopActivityJson[i].rollActivity.status;
          jsonData['Customer Comment'] = barCopActivityJson[i].rollActivity.customerComment;
          jsonData['Requester'] = barCopActivityJson[i].rollActivity.requester;
          jsonData['Client Validation Message'] = barCopActivityJson[i].rollActivity.message1;
          jsonData[''] = barCopActivityJson[i].rollActivity.activity;
          extractbarCopActivityData[i] = jsonData;
          jsonData = {};
        }
      } if (barMixActivityJson.length !== 0) {
        /*
        Backend & Frontend mapping for Vehicle reference & workshop number
        Data base(Vehicle reference) = Front End(Vehicle type/Manufacturing number)
        Date base(workshop number) = Front End(Vehicle reference)
        */
        for (let i = 0; i < barMixActivityJson.length; i++) {
          jsonData['Site'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Mean'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Testbed'] = this.getTestBedLabel(barMixActivityJson[i].barActivity.rollActivity.activity.testBedId.id);
          jsonData['Date'] = moment(barMixActivityJson[i].barActivity.rollActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['Sequence Number'] = barMixActivityJson[i].barActivity.rollActivity.activity.seqNum;
          jsonData['Activity type'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.level0);
          jsonData['Level 1'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.level1);
          jsonData['Level 2'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.level2);
          jsonData['Exploitable'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.exploitable);
          jsonData['Activity duration'] = barMixActivityJson[i].barActivity.rollActivity.activity.duration;
          jsonData['Engine'] = barMixActivityJson[i].barActivity.rollActivity.vehDT;
          jsonData['Norm'] = barMixActivityJson[i].barActivity.rollActivity.norm;
          jsonData['Astral Request Number'] = barMixActivityJson[i].barActivity.rollActivity.astralNumber;
          jsonData['Astral Test Number'] = barMixActivityJson[i].barActivity.rollActivity.testNumber >= TestbedExtractEnum.testNumberlimit ?
            barMixActivityJson[i].barActivity.rollActivity.vetsOneProjectCode : barMixActivityJson[i].barActivity.rollActivity.testNumber;
          jsonData['Calibration'] = barMixActivityJson[i].barActivity.rollActivity.activity.calibration;
          // jsonData['Vehicle Reference'] = barMixActivityJson[i].barActivity.workshop;
          let mean = barMixActivityJson[i].barActivity.rollActivity.activity.testBedId.testbedGlobalInfo.meanId.label;
          // Added for PEMS activity
          /* if (mean === 'PEMS') {
            jsonData['Vehicle Reference'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
            jsonData['Manufacture Number'] = barMixActivityJson[i].barActivity.workshop;
          } else {
            jsonData['Manufacture Number'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
            jsonData['Vehicle Reference'] = barMixActivityJson[i].barActivity.workshop;
          } */
          jsonData['Manufacture Number/Vehicle Type'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
          jsonData['Vehicle Reference'] = barMixActivityJson[i].barActivity.workshop;
          jsonData['Temperature'] = barMixActivityJson[i].barActivity.temperature;
          jsonData['Altitude'] = barMixActivityJson[i].barActivity.rollActivity.altitude;
          // jsonData['Vehicle Type'] = '';
          jsonData['Interpolation Family'] = barMixActivityJson[i].barCopActivity.interpolFam;
          jsonData['Variante'] = barMixActivityJson[i].barCopActivity.variant;
          // jsonData['Manufacture Number'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
          jsonData['Vehicle Mileage'] = barMixActivityJson[i].barActivity.rollActivity.mileage;
          jsonData['Order Number'] = barMixActivityJson[i].barActivity.rollActivity.orderNumber;
          jsonData['Vetsone N°'] = barMixActivityJson[i].barActivity.rollActivity.vetsNumber;
          jsonData['Operator IPN'] = barMixActivityJson[i].barActivity.rollActivity.operator;
          jsonData['Prestation'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.prestations);
          jsonData['Macro Activity'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.macroActivity);
          jsonData['Procedures'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.procedures);
          jsonData['Campaign'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.campaigns);
          jsonData['Service Client'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.customerDirection);
          jsonData['OT number'] = '';
          jsonData['OT status'] = '';
          jsonData['Astral Requester IPN'] = barMixActivityJson[i].barActivity.rollActivity.operator;
          jsonData['Comment'] = barMixActivityJson[i].barActivity.rollActivity.activity.comment;
          jsonData['Status'] = barMixActivityJson[i].barActivity.rollActivity.status;
          jsonData['Customer Comment'] = barMixActivityJson[i].barActivity.rollActivity.customerComment;
          jsonData['Requester'] = barMixActivityJson[i].barActivity.rollActivity.requester;
          jsonData['Client Validation Message'] = barMixActivityJson[i].barActivity.rollActivity.message1;
          jsonData[''] = barMixActivityJson[i].barActivity.rollActivity.activity;
          extractbarMixActivityData[i] = jsonData;
          jsonData = {};
        }
      } if (emissionFailureJson.length !== 0) {
        for (let i = 0; i < emissionFailureJson.length; i++) {
          jsonData['Site'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Mean'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Testbed'] = this.getTestBedLabel(emissionFailureJson[i].rollActivity.activity.testBedId.id);
          jsonData['Date'] = moment(emissionFailureJson[i].rollActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['Sequence Number'] = emissionFailureJson[i].rollActivity.activity.seqNum;
          jsonData['Activity type'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.level0);
          jsonData['Level 1'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.level1);
          jsonData['Level 2'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.level2);
          jsonData['Exploitable'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.exploitable);
          jsonData['Activity duration'] = emissionFailureJson[i].rollActivity.activity.duration;
          jsonData['Engine'] = emissionFailureJson[i].rollActivity.vehDT;
          jsonData['Norm'] = emissionFailureJson[i].rollActivity.norm;
          jsonData['Astral Request Number'] = emissionFailureJson[i].rollActivity.astralNumber;
          jsonData['Astral Test Number'] = emissionFailureJson[i].rollActivity.testNumber;
          jsonData['Calibration'] = emissionFailureJson[i].rollActivity.activity.calibration;
          jsonData['Vehicle Reference'] = '';
          jsonData['Temperature'] = '';
          jsonData['Altitude'] = '';
          // jsonData['Vehicle Type'] = ''
          jsonData['Interpolation Family'] = '';
          jsonData['Variante'] = '';
          jsonData['Manufacture Number/Vehicle Type'] = emissionFailureJson[i].rollActivity.vehReference;
          jsonData['Vehicle Mileage'] = emissionFailureJson[i].rollActivity.mileage;
          jsonData['Order Number'] = emissionFailureJson[i].rollActivity.orderNumber;
          jsonData['Vetsone N°'] = emissionFailureJson[i].rollActivity.vetsNumber;
          jsonData['Operator IPN'] = emissionFailureJson[i].rollActivity.operator;
          jsonData['Prestation'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.prestations);
          jsonData['Macro Activity'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.macroActivity);
          jsonData['Procedures'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.procedures);
          jsonData['Campaign'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.campaigns);
          jsonData['Service Client'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.customerDirection);
          //Added for OTglobal changes
          jsonData['OT number'] = emissionFailureJson[i].ot ? emissionFailureJson[i].ot.label : emissionFailureJson[i].otGlobal;
          jsonData['OT status'] = emissionFailureJson[i].ot ? emissionFailureJson[i].ot.status : '';
          jsonData['Astral Requester IPN'] = emissionFailureJson[i].rollActivity.operator;
          jsonData['Comment'] = emissionFailureJson[i].rollActivity.activity.comment;
          jsonData['Status'] = emissionFailureJson[i].rollActivity.status;
          jsonData['Customer Comment'] = emissionFailureJson[i].rollActivity.customerComment;
          jsonData['Requester'] = emissionFailureJson[i].rollActivity.requester;
          jsonData['Client Validation Message'] = emissionFailureJson[i].rollActivity.message1;
          jsonData[''] = emissionFailureJson[i].rollActivity.activity;
          extractfailureActivityforEmissionData[i] = jsonData;
          jsonData = {};
        }
      }
    } else {
      this.fileName = 'Activite';
      if (evEngineJson.length !== 0) {
        for (let i = 0; i < evEngineJson.length; i++) {
          jsonData['Site'] = this.nullcheck(evEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Moyen'] = this.nullcheck(evEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Essai Banc'] = this.getTestBedLabel(evEngineJson[i].engineActivity.activity.testBedId.id);
          jsonData['Date'] = moment(evEngineJson[i].engineActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['N° de séquence'] = evEngineJson[i].engineActivity.activity.seqNum;
          jsonData['Heures moteur'] = evEngineJson[i].engineActivity.engHours;
          jsonData['Type activite'] = this.nullcheck(evEngineJson[i].engineActivity.activity.level0);
          jsonData['Duree activite'] = evEngineJson[i].engineActivity.activity.duration;
          jsonData['D.I./Numero Essai'] = this.emptyValueInsertorforNull(evEngineJson[i].engineActivity.di) +
            this.emptyValueInsertorforNull(evEngineJson[i].engineActivity.essNB);
          jsonData['Exploitable'] = this.nullcheck(evEngineJson[i].engineActivity.activity.exploitable);
          jsonData['Commentaire'] = evEngineJson[i].engineActivity.activity.comment;
          jsonData['Niveau 1'] = this.nullcheck(evEngineJson[i].engineActivity.activity.level1);
          jsonData['Niveau 2'] = this.nullcheck(evEngineJson[i].engineActivity.activity.level2);
          // jsonData['Ligne de programme'] = (evEngineJson[i].engineActivity.programLine);
          // jsonData['Numero moteur'] = (evEngineJson[i].engineActivity.engNumber);
          // jsonData['Numero VS'] = evEngineJson[i].engineActivity.testedVS;
          jsonData['Ligne programme'] = (evEngineJson[i].engineActivity.programLine);
          jsonData['Numero de VS'] = (evEngineJson[i].engineActivity.engNumber);
          //jsonData['Numero VS'] = evEngineJson[i].engineActivity.testedVS;
          jsonData['Type RS'] = this.nullcheck(evEngineJson[i].engineActivity.rsTypeId);
          jsonData['Etalonnage'] = evEngineJson[i].engineActivity.activity.calibration;
          jsonData['IPN Operateur'] = (evEngineJson[i].engineActivity.activity.ipn);
          jsonData['Service Client'] = this.nullcheck(evEngineJson[i].engineActivity.activity.customerDirection);
          jsonData['Prestation'] = this.nullcheck(evEngineJson[i].engineActivity.activity.prestations);
          jsonData['Macro Activity'] = this.nullcheck(evEngineJson[i].engineActivity.activity.macroActivity);
          jsonData['Procedures/Type essai'] = this.nullcheck(evEngineJson[i].engineActivity.activity.procedures);
          jsonData['Campagne'] = this.nullcheck(evEngineJson[i].engineActivity.activity.campaigns);
          if(this.showTypeOfTest) {
            jsonData['Type dépreuve'] = evEngineJson[i].engineActivity.activity.typeOfTest;
          }
          jsonData['Perimetre'] = this.nullcheck(evEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.perimeterId);
          jsonData['Numero ESS'] = evEngineJson[i].engineActivity.essNBGps;
          jsonData['Support essai'] = evEngineJson[i].testSupport;
          jsonData['Numero realignement'] = evEngineJson[i].realNb;
          jsonData['Carburant'] = this.nullcheck(evEngineJson[i].engineActivity.fuel);
          jsonData['Demande du client'] = evEngineJson[i].engineActivity.activity.customerRequest;
          // jsonData['Numero Essai'] = evEngineJson[i].engineActivity.essNB;
          extracEvData[i] = jsonData;
          jsonData = {};
        }
      }
      if (reliabilityEngineJson.length !== 0) {
        for (let i = 0; i < reliabilityEngineJson.length; i++) {
          jsonData['Site'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Moyen'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Essai Banc'] = this.getTestBedLabel(reliabilityEngineJson[i].engineActivity.activity.testBedId.id);
          jsonData['Date'] = moment(reliabilityEngineJson[i].engineActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['N° de séquence'] = reliabilityEngineJson[i].engineActivity.activity.seqNum;
          if (this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId) === "BV") {
            jsonData['Heures de Boite'] = reliabilityEngineJson[i].engineActivity.engHours;
          } else if (this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId) === ActivityEnum.Brap) {
          
            jsonData['KM VH'] = reliabilityEngineJson[i].engineActivity.engHours === 0 ? '0' :
            reliabilityEngineJson[i].engineActivity.engHours== null? '' :
            reliabilityEngineJson[i].engineActivity.engHours;
            
           

          } else {
            jsonData['Heures moteur'] = reliabilityEngineJson[i].engineActivity.engHours;
          }
          jsonData['Type activite'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.level0);
          jsonData['Duree activite'] = reliabilityEngineJson[i].engineActivity.activity.duration;
          jsonData['D.I./Numero Essai'] = this.emptyValueInsertorforNull(reliabilityEngineJson[i].engineActivity.di) +
            this.emptyValueInsertorforNull(reliabilityEngineJson[i].engineActivity.essNB);
          jsonData['Exploitable'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.exploitable);
          jsonData['Commentaire'] = reliabilityEngineJson[i].engineActivity.activity.comment;
          jsonData['Niveau 1'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.level1);
          jsonData['Niveau 2'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.level2);
          jsonData['Ligne de programme'] = (reliabilityEngineJson[i].engineActivity.programLine);
          if (this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId) === "BV") {
            jsonData['Nombre de Boite'] = reliabilityEngineJson[i].engineActivity.engNumber;
          } else if (this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId) === ActivityEnum.Brap) {
            jsonData['Numéro QFV'] = reliabilityEngineJson[i].engineActivity.engNumber;
          } else {
            jsonData['Numero moteur'] = reliabilityEngineJson[i].engineActivity.engNumber;
          }
          jsonData['Numero VS'] = reliabilityEngineJson[i].engineActivity.testedVS;

          if (reliabilityEngineJson[i].engineActivity.activity.means.label.toUpperCase() === "BV") {

            jsonData['Numéro F.A / D.E'] = reliabilityEngineJson[i].engineActivity.activity.customerRequest;
          } else {
            jsonData['Demande du client'] = reliabilityEngineJson[i].engineActivity.activity.customerRequest;
          }
          jsonData['Etalonnage'] = reliabilityEngineJson[i].engineActivity.activity.calibration;
          jsonData['IPN Operateur'] = reliabilityEngineJson[i].engineActivity.activity.ipn;
          jsonData['Service Client'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.customerDirection);
          jsonData['Prestation'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.prestations);
          jsonData['Macro Activite'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.macroActivity);
          jsonData['Procedures/Type essai'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.procedures);
          jsonData['Campagne'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.campaigns);
          jsonData['Perimetre'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.perimeterId);
          jsonData['Numero OT'] = '';
          jsonData['Statut OT'] = '';
          jsonData['Soft'] = reliabilityEngineJson[i].soft;
          jsonData['Type RS'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.rsTypeId);
          jsonData['Numero ESS'] = reliabilityEngineJson[i].engineActivity.essNBGps;
          // jsonData['Numero Essai'] = reliabilityEngineJson[i].engineActivity.essNB;
          jsonData['RLCE'] = reliabilityEngineJson[i].engineActivity.bvRlce;
          jsonData['Carburant'] = this.nullcheck(reliabilityEngineJson[i].engineActivity.fuel);
          extractReliabilityData[i] = jsonData;
          jsonData = {};
        }
      }
      if (tuningEngineJson.length !== 0) {
        for (let i = 0; i < tuningEngineJson.length; i++) {
          jsonData['Site'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Moyen'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Essai Banc'] = this.getTestBedLabel(tuningEngineJson[i].engineActivity.activity.testBedId.id);
          jsonData['Date'] = moment(tuningEngineJson[i].engineActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['N° de séquence'] = tuningEngineJson[i].engineActivity.activity.seqNum;
          jsonData['Heures moteur'] = tuningEngineJson[i].engineActivity.engHours;
          jsonData['Type activite'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.level0);
          jsonData['Duree activite'] = tuningEngineJson[i].engineActivity.activity.duration;
          jsonData['D.I./Numero Essai'] = this.emptyValueInsertorforNull(tuningEngineJson[i].engineActivity.di) +
            this.emptyValueInsertorforNull(tuningEngineJson[i].engineActivity.essNB);
          jsonData['Exploitable'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.exploitable);
          jsonData['Nombre de points'] = tuningEngineJson[i].pointsNb;
          jsonData['Commentaire'] = tuningEngineJson[i].engineActivity.activity.comment;
          jsonData['Niveau 1'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.level1);
          jsonData['Niveau 2'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.level2);
          jsonData['Ligne de programme'] = (tuningEngineJson[i].engineActivity.programLine);
          jsonData['Numero FEB'] = (tuningEngineJson[i].febNb);
          jsonData['Numero moteur'] = tuningEngineJson[i].engineActivity.engNumber;
          jsonData['Numero VS'] = tuningEngineJson[i].engineActivity.testedVS;
          jsonData['D.T moteur'] = tuningEngineJson[i].engDt;
          jsonData['Type RS'] = this.nullcheck(tuningEngineJson[i].engineActivity.rsTypeId);
          jsonData['Etalonnage'] = tuningEngineJson[i].engineActivity.activity.calibration;
          jsonData['IPN Operateur'] = tuningEngineJson[i].engineActivity.activity.ipn;
          jsonData['Service Client'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.customerDirection);
          jsonData['Prestation'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.prestations);
          jsonData['Macro Activite'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.macroActivity);
          jsonData['Procedures/Type essai'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.procedures);
          jsonData['Campagne'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.campaigns);
          jsonData['Perimetre'] = this.nullcheck(tuningEngineJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.perimeterId);
          jsonData['Numero OT'] = '';
          jsonData['Statut OT'] = '';
          jsonData['Soft'] = tuningEngineJson[i].soft;
          jsonData['DCM'] = tuningEngineJson[i].dcm;
          jsonData['Essai CAMEO/TST'] = tuningEngineJson[i].cameo;
          jsonData['Numero ESS'] = tuningEngineJson[i].engineActivity.essNBGps;
          jsonData['Carburant'] = this.nullcheck(tuningEngineJson[i].engineActivity.fuel);
          if (tuningEngineJson[i].engineActivity.activity.means.maps.label.toUpperCase() !== "Tuning E/D" && tuningEngineJson[i].engineActivity.activity.level0.label != "MANU" && tuningEngineJson[i].engineActivity.activity.level0.level0_test === true && tuningEngineJson[i].engineActivity.activity.level0.failure === false) {
            jsonData['TOH'] = tuningEngineJson[i].engineActivity.tuningTOH;
          }
          extractTuningData[i] = jsonData;
          jsonData = {};
        }
      }
      if (failureactivityJson.length !== 0) {
        for (let i = 0; i < failureactivityJson.length; i++) {
          jsonData['Site'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Moyen'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Essai Banc'] = this.getTestBedLabel(failureactivityJson[i].engineActivity.activity.testBedId.id);
          jsonData['Date'] = moment(failureactivityJson[i].engineActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['N° de séquence'] = failureactivityJson[i].engineActivity.activity.seqNum;
          if (failureactivityJson[i].engineActivity.activity.means.label === "BV") {
            jsonData['Heures de Boite'] = failureactivityJson[i].engineActivity.engHours;
          } else if (failureactivityJson[i].engineActivity.activity.means.label === ActivityEnum.Brap) {
            
            jsonData['KM VH'] = failureactivityJson[i].engineActivity.engHours === 0 ? '0' :
            failureactivityJson[i].engineActivity.engHours== null? '' :
            failureactivityJson[i].engineActivity.engHours;
           
          } else {
            jsonData['Heures moteur'] = failureactivityJson[i].engineActivity.engHours;
          }

          jsonData['Type activite'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.level0);
          jsonData['Duree activite'] = failureactivityJson[i].engineActivity.activity.duration;
          jsonData['D.I./Numero Essai'] = this.emptyValueInsertorforNull(failureactivityJson[i].engineActivity.di) +
            this.emptyValueInsertorforNull(failureactivityJson[i].engineActivity.essNB);
          jsonData['Exploitable'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.exploitable);
          jsonData['Commentaire'] = failureactivityJson[i].engineActivity.activity.comment;
          // TODO Delete: jsonData['Nombre de points'] = failureactivityJson[i].pointsNb;
          jsonData['Niveau 1'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.level1);
          jsonData['Niveau 2'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.level2);
          jsonData['Ligne de programme'] = (failureactivityJson[i].engineActivity.programLine);
          jsonData['Numero FEB'] = (failureactivityJson[i].febNb);
          if (failureactivityJson[i].engineActivity.activity.means.label === "BV" && failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() !== 'ELEC E/D') {
            jsonData['Nombre de Boite'] = failureactivityJson[i].engineActivity.engNumber;
          } else if (failureactivityJson[i].engineActivity.activity.means.label === ActivityEnum.Brap &&
            failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() !== 'ELEC E/D') {
            jsonData['Numéro QFV'] = failureactivityJson[i].engineActivity.engNumber;
          } else if (failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() === "ELEC E/D") {
            jsonData['Numero de VS'] = failureactivityJson[i].engineActivity.engNumber;
          }
          else {
            jsonData['Numero moteur'] = failureactivityJson[i].engineActivity.engNumber;
          }
          //Commented for US502//
          //jsonData['Numero VS'] = failureactivityJson[i].engineActivity.testedVS;
          if (failureactivityJson[i].engineActivity.activity.means.label === "BV" && failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() === "ELEC E/D") {
            jsonData['D.T Boite'] = failureactivityJson[i].engDt;
          } else if (failureactivityJson[i].engineActivity.activity.means.maps.label.toUpperCase() === "ELEC E/D") {
            jsonData['VS DT'] = failureactivityJson[i].engDt;
          }
          else {
            jsonData['D.T moteur'] = failureactivityJson[i].engDt;
          }
          jsonData['Type RS'] = this.nullcheck(failureactivityJson[i].engineActivity.rsTypeId);
          jsonData['Etalonnage'] = failureactivityJson[i].engineActivity.activity.calibration;
          jsonData['IPN Operateur'] = failureactivityJson[i].engineActivity.activity.ipn;
          jsonData['Service Client'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.customerDirection);
          jsonData['Prestation'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.prestations);
          jsonData['Macro Activite'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.macroActivity);
          jsonData['Procedures/Type essai'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.procedures);
          jsonData['Campagne'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.campaigns);
          jsonData['Perimetre'] = this.nullcheck(failureactivityJson[i].engineActivity.activity.testBedId.testbedGlobalInfo.perimeterId);
          //Added for OTglobal changes
          jsonData['Numero OT'] = failureactivityJson[i].ot ? failureactivityJson[i].ot.label : failureactivityJson[i].otGlobal;
          jsonData['Statut OT'] = failureactivityJson[i].ot ? failureactivityJson[i].ot.status : '';
          jsonData['Demande du client'] = failureactivityJson[i].engineActivity.activity.customerRequest;
          jsonData['Soft'] = failureactivityJson[i].soft;
          jsonData['DCM'] = failureactivityJson[i].dcm;
          jsonData['Numero ESS'] = failureactivityJson[i].engineActivity.essNBGps;
          jsonData['Carburant'] = this.nullcheck(failureactivityJson[i].engineActivity.fuel);
          if (failureactivityJson[i].engineActivity.activity.means.label.toUpperCase() === "BV" && failureactivityJson[i].engineActivity.activity.means.maps.label === "Reliability E/D") {
            jsonData['RLCE'] = failureactivityJson[i].bvRlce;
          }
          jsonData[''] = failureactivityJson[i].engineActivity.activity;

          extractFailureData[i] = jsonData;
          jsonData = {};
        }
      }
      if (barActivityJson.length !== 0) {
        for (let i = 0; i < barActivityJson.length; i++) {
          jsonData['Site'] = this.nullcheck(barActivityJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Moyen'] = this.nullcheck(barActivityJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Essai Banc'] = this.getTestBedLabel(barActivityJson[i].rollActivity.activity.testBedId.id);
          jsonData['Date'] = moment(barActivityJson[i].rollActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['N° de séquence'] = barActivityJson[i].rollActivity.activity.seqNum;
          jsonData['Type activite'] = this.nullcheck(barActivityJson[i].rollActivity.activity.level0);
          jsonData['Niveau 1'] = this.nullcheck(barActivityJson[i].rollActivity.activity.level1);
          jsonData['Niveau 2'] = this.nullcheck(barActivityJson[i].rollActivity.activity.level2);
          jsonData['Exploitable'] = this.nullcheck(barActivityJson[i].rollActivity.activity.exploitable);
          jsonData['Duree activite'] = barActivityJson[i].rollActivity.activity.duration;
          jsonData['D.T. VH'] = barActivityJson[i].rollActivity.vehDT;
          jsonData['Norme'] = barActivityJson[i].rollActivity.norm;
          jsonData['N° Astral Demande'] = barActivityJson[i].rollActivity.astralNumber;
          jsonData['N° Astral Essai'] = barActivityJson[i].rollActivity.testNumber;
          jsonData['Etalonnage'] = barActivityJson[i].rollActivity.activity.calibration;
          jsonData['Temperature'] = barActivityJson[i].temperature;
          jsonData['Altitude'] = barActivityJson[i].rollActivity.altitude;
          jsonData['N° Atelier'] = barActivityJson[i].workshop;
          jsonData['Famille interpolation'] = '';
          jsonData['N° Variante'] = '';
          jsonData['Numéro de fabrication/Référence VH'] = barActivityJson[i].rollActivity.vehReference;
          // jsonData['Référence VH'] = barActivityJson[i].rollActivity.vehReference;
          jsonData['Kilométrage VH'] = barActivityJson[i].rollActivity.mileage;
          jsonData['N° ordre'] = barActivityJson[i].rollActivity.orderNumber;
          jsonData['Vetsone N°'] = barActivityJson[i].rollActivity.vetsNumber;
          jsonData['Operateur'] = barActivityJson[i].rollActivity.operator;
          jsonData['Prestation'] = this.nullcheck(barActivityJson[i].rollActivity.activity.prestations);
          jsonData['Macro Activite'] = this.nullcheck(barActivityJson[i].rollActivity.activity.macroActivity);
          jsonData['Procedures'] = this.nullcheck(barActivityJson[i].rollActivity.activity.procedures);
          jsonData['Campagne'] = this.nullcheck(barActivityJson[i].rollActivity.activity.campaigns);
          jsonData['Service Client'] = this.nullcheck(barActivityJson[i].rollActivity.activity.customerDirection);
          jsonData['Numero OT'] = '';
          jsonData['Statut OT'] = '';
          jsonData['Astral Demandeur'] = barActivityJson[i].rollActivity.operator;
          jsonData['Commentaire'] = barActivityJson[i].rollActivity.activity.comment;
          jsonData['Statut'] = barActivityJson[i].rollActivity.status;
          jsonData['Commentaire du client'] = barActivityJson[i].rollActivity.customerComment;
          jsonData['Demandeur'] = barActivityJson[i].rollActivity.requester;
          jsonData['Message de validation du client'] = barActivityJson[i].rollActivity.message1;
          jsonData[''] = barActivityJson[i].rollActivity.activity;
          extractbarActivityData[i] = jsonData;
          jsonData = {};
        }
      } if (barCopActivityJson.length !== 0) {
        for (let i = 0; i < barCopActivityJson.length; i++) {
          jsonData['Site'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Moyen'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Essai Banc'] = this.getTestBedLabel(barCopActivityJson[i].rollActivity.activity.testBedId.id);
          jsonData['Date'] = moment(barCopActivityJson[i].rollActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['N° de séquence'] = barCopActivityJson[i].rollActivity.activity.seqNum;
          jsonData['Type activite'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.level0);
          jsonData['Niveau 1'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.level1);
          jsonData['Niveau 2'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.level2);
          jsonData['Exploitable'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.exploitable);
          jsonData['Duree activite'] = barCopActivityJson[i].rollActivity.activity.duration;
          jsonData['D.T. VH'] = barCopActivityJson[i].rollActivity.vehDT;
          jsonData['Norme'] = barCopActivityJson[i].rollActivity.norm;
          jsonData['N° Astral Demande'] = barCopActivityJson[i].rollActivity.astralNumber;
          jsonData['N° Astral Essai'] = barCopActivityJson[i].rollActivity.testNumber >= TestbedExtractEnum.testNumberlimit ?
            barCopActivityJson[i].rollActivity.vetsOneProjectCode : barCopActivityJson[i].rollActivity.testNumber;
          jsonData['Etalonnage'] = barCopActivityJson[i].rollActivity.activity.calibration;
          jsonData['Temperature'] = '';
          jsonData['N° Atelier'] = '';
          jsonData['Famille interpolation'] = barCopActivityJson[i].interpolFam;
          jsonData['N° Variante'] = barCopActivityJson[i].variant;
          // jsonData['Référence VH'] = '';
          jsonData['Numéro de fabrication/Référence VH'] = barCopActivityJson[i].rollActivity.vehReference;
          jsonData['Kilométrage VH'] = barCopActivityJson[i].rollActivity.mileage;
          jsonData['N° ordre'] = this.nullcheck(barCopActivityJson[i].copProgragmLine);
          jsonData['Vetsone N°'] = barCopActivityJson[i].rollActivity.vetsNumber;
          jsonData['Operateur'] = barCopActivityJson[i].rollActivity.operator;
          jsonData['Prestation'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.prestations);
          jsonData['Macro Activite'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.macroActivity);
          jsonData['Procedures'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.procedures);
          jsonData['Campagne'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.campaigns);
          jsonData['Service Client'] = this.nullcheck(barCopActivityJson[i].rollActivity.activity.customerDirection);
          jsonData['Numero OT'] = '';
          jsonData['Statut OT'] = '';
          jsonData['Astral Demandeur'] = barCopActivityJson[i].rollActivity.operator;
          jsonData['Commentaire'] = barCopActivityJson[i].rollActivity.activity.comment;
          jsonData['Statut'] = barCopActivityJson[i].rollActivity.status;
          jsonData['Commentaire du client'] = barCopActivityJson[i].rollActivity.customerComment;
          jsonData['Demandeur'] = barCopActivityJson[i].rollActivity.requester;
          jsonData['Message de validation du client'] = barCopActivityJson[i].rollActivity.message1;
          jsonData[''] = barCopActivityJson[i].rollActivity.activity;
          extractbarCopActivityData[i] = jsonData;
          jsonData = {};
        }
      } if (barMixActivityJson.length !== 0) {
        /*
        Backend & Frontend mapping for Vehicle reference & workshop number
        Data base(Vehicle reference) = Front End(Vehicle type/Manufacturing number)
        Date base(workshop number) = Front End(Vehicle reference)
        */
        for (let i = 0; i < barMixActivityJson.length; i++) {
          jsonData['Site'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Moyen'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Essai Banc'] = this.getTestBedLabel(barMixActivityJson[i].barActivity.rollActivity.activity.testBedId.id);
          jsonData['Date'] = moment(barMixActivityJson[i].barActivity.rollActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['N° de séquence'] = barMixActivityJson[i].barActivity.rollActivity.activity.seqNum;
          jsonData['Type activite'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.level0);
          jsonData['Niveau 1'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.level1);
          jsonData['Niveau 2'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.level2);
          jsonData['Exploitable'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.exploitable);
          jsonData['Duree activite'] = barMixActivityJson[i].barActivity.rollActivity.activity.duration;
          jsonData['D.T. VH'] = barMixActivityJson[i].barActivity.rollActivity.vehDT;
          jsonData['Norme'] = barMixActivityJson[i].barActivity.rollActivity.norm;
          jsonData['N° Astral Demande'] = barMixActivityJson[i].barActivity.rollActivity.astralNumber;
          jsonData['N° Astral Essai'] = barMixActivityJson[i].barActivity.rollActivity.testNumber >= TestbedExtractEnum.testNumberlimit ?
            barMixActivityJson[i].barActivity.rollActivity.vetsOneProjectCode : barMixActivityJson[i].barActivity.rollActivity.testNumber;
          jsonData['Etalonnage'] = barMixActivityJson[i].barActivity.rollActivity.activity.calibration;
          jsonData['N° Atelier'] = barMixActivityJson[i].barActivity.workshop;
          jsonData['Temperature'] = barMixActivityJson[i].barActivity.temperature;
          jsonData['Altitude'] = barMixActivityJson[i].barActivity.rollActivity.altitude;
          // jsonData['Référence VH'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
          jsonData['Famille interpolation'] = barMixActivityJson[i].barCopActivity.interpolFam;
          jsonData['N° Variante'] = barMixActivityJson[i].barCopActivity.variant;
          let mean = barMixActivityJson[i].barActivity.rollActivity.activity.testBedId.testbedGlobalInfo.meanId.label;
          // Added for PEMS activity
          /* if (mean === 'PEMS') {
            jsonData['Référence VH'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
            jsonData['Numéro de fabrication'] = barMixActivityJson[i].barActivity.workshop;
          } else {
            jsonData['Numéro de fabrication'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
            jsonData['Référence VH'] = barMixActivityJson[i].barActivity.workshop;
          } */
          jsonData['Numéro de fabrication/Référence VH'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
          // jsonData['Référence VH'] = barMixActivityJson[i].barActivity.workshop;
          // jsonData['Manufacture Number'] = barMixActivityJson[i].barActivity.rollActivity.vehReference;
          jsonData['Kilométrage VH'] = barMixActivityJson[i].barActivity.rollActivity.mileage;
          jsonData['N° ordre'] = barMixActivityJson[i].barActivity.rollActivity.orderNumber;
          jsonData['Vetsone N°'] = barMixActivityJson[i].barActivity.rollActivity.vetsNumber;
          jsonData['Operateur'] = barMixActivityJson[i].barActivity.rollActivity.operator;
          jsonData['Prestation'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.prestations);
          jsonData['Macro Activite'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.macroActivity);
          jsonData['Procedures'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.procedures);
          jsonData['Campagne'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.campaigns);
          jsonData['Service Client'] = this.nullcheck(barMixActivityJson[i].barActivity.rollActivity.activity.customerDirection);
          jsonData['Numero OT'] = '';
          jsonData['Statut OT'] = '';
          jsonData['Astral Demandeur'] = barMixActivityJson[i].barActivity.rollActivity.operator;
          jsonData['Commentaire'] = barMixActivityJson[i].barActivity.rollActivity.activity.comment;
          jsonData['Statut'] = barMixActivityJson[i].barActivity.rollActivity.status;
          jsonData['Commentaire du client'] = barMixActivityJson[i].barActivity.rollActivity.customerComment;
          jsonData['Demandeur'] = barMixActivityJson[i].barActivity.rollActivity.requester;
          jsonData['Message de validation du client'] = barMixActivityJson[i].barActivity.rollActivity.message1;
          jsonData[''] = barMixActivityJson[i].barActivity.rollActivity.activity;
          extractbarMixActivityData[i] = jsonData;
          jsonData = {};
        }
      } if (emissionFailureJson.length !== 0) {
        for (let i = 0; i < emissionFailureJson.length; i++) {
          jsonData['Site'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.siteId);
          jsonData['Moyen'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.testBedId.testbedGlobalInfo.meanId);
          jsonData['Essai Banc'] = this.getTestBedLabel(emissionFailureJson[i].rollActivity.activity.testBedId.id);
          jsonData['Date'] = moment(emissionFailureJson[i].rollActivity.activity.activityDate).format('DD/MM/YYYY');
          jsonData['N° de séquence'] = emissionFailureJson[i].rollActivity.activity.seqNum;
          jsonData['Type activite'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.level0);
          jsonData['Niveau 1'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.level1);
          jsonData['Niveau 2'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.level2);
          jsonData['Exploitable'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.exploitable);
          jsonData['Duree activite'] = emissionFailureJson[i].rollActivity.activity.duration;
          jsonData['D.T. VH'] = emissionFailureJson[i].rollActivity.vehDT;
          jsonData['Norme'] = emissionFailureJson[i].rollActivity.norm;
          jsonData['N° Astral Demande'] = emissionFailureJson[i].rollActivity.astralNumber;
          jsonData['N° Astral Essai'] = emissionFailureJson[i].rollActivity.testNumber;
          jsonData['Etalonnage'] = emissionFailureJson[i].rollActivity.activity.calibration;
          jsonData['N° Atelier'] = '';
          // jsonData['Numéro de fabrication/Référence VH'] = '';
          jsonData['Famille interpolation'] = '';
          jsonData['N° Variante'] = emissionFailureJson[i].rollActivity.vetsNumber;
          jsonData['Temperature'] = '';
          jsonData['Altitude'] = '';
          jsonData['Numéro de fabrication/Référence VH'] = emissionFailureJson[i].rollActivity.vehReference;
          jsonData['Kilométrage VH'] = emissionFailureJson[i].rollActivity.mileage;
          jsonData['N° ordre'] = emissionFailureJson[i].rollActivity.orderNumber;
          jsonData['Vetsone N°'] = '';
          jsonData['Operateur'] = emissionFailureJson[i].rollActivity.operator;
          jsonData['Prestation'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.prestations);
          jsonData['Macro Activite'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.macroActivity);
          jsonData['Procedures'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.procedures);
          jsonData['Campagne'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.campaigns);
          jsonData['Service Client'] = this.nullcheck(emissionFailureJson[i].rollActivity.activity.customerDirection);
          //Added for OTglobal changes
          jsonData['Numero OT'] = emissionFailureJson[i].ot ? emissionFailureJson[i].ot.label : emissionFailureJson[i].otGlobal;
          jsonData['Statut OT'] = emissionFailureJson[i].ot ? emissionFailureJson[i].ot.status : '';
          jsonData['Astral Demandeur'] = emissionFailureJson[i].rollActivity.operator;
          jsonData['Commentaire'] = emissionFailureJson[i].rollActivity.activity.comment;
          jsonData['Statut'] = emissionFailureJson[i].rollActivity.status;
          jsonData['Commentaire du client'] = emissionFailureJson[i].rollActivity.customerComment;
          jsonData['Demandeur'] = emissionFailureJson[i].rollActivity.requester;
          jsonData['Message de validation du client'] = emissionFailureJson[i].rollActivity.message1;
          jsonData[''] = emissionFailureJson[i].rollActivity.activity;
          extractfailureActivityforEmissionData[i] = jsonData;
          jsonData = {};
        }
      }
    }
    if (extractFailureData.length !== 0 && this.browserLang === 'en') {
      let k = 0;
      let j = 0;
      let f = 0;
      for (let i = 0; i < extractFailureData.length; i++) {
        if ((extractFailureData[i][''].means.maps.label) === 'Elec E/D') {
          elecFailureData[f] = extractFailureData[i];
          f++;
        } else if ((extractFailureData[i][''].means.maps.label) === 'Reliability E/D') {
          reliabFailureData[k] = extractFailureData[i];
          k++;
        } else if ((extractFailureData[i][''].means.maps.label) === 'Tuning E/D') {
          tuningFailureData[j] = extractFailureData[i];
          j++;
        }
      }
    } else {
      let k = 0;
      let j = 0;
      let f = 0;
      for (let i = 0; i < extractFailureData.length; i++) {
        if ((extractFailureData[i][''].means.maps.label) === 'Elec E/D') {
          elecFailureData[f] = extractFailureData[i];
          f++;
        } else if ((extractFailureData[i][''].means.maps.label) === 'Reliability E/D') {
          reliabFailureData[k] = extractFailureData[i];
          k++;
        } else if ((extractFailureData[i][''].means.maps.label) === 'Tuning E/D') {
          tuningFailureData[j] = extractFailureData[i];
          j++;
        }
      }

    }
    const evData = extracEvData.concat(elecFailureData);
    const tuningData = extractTuningData.concat(tuningFailureData);
    const reliabilityData = extractReliabilityData.concat(reliabFailureData);
    const sortedEvData = this.sortSheetData(evData);
    const sortedTuningData = this.sortSheetData(tuningData);
    const sortedReliabilityData = this.sortSheetData(reliabilityData);
    const emissionJsonData = extractbarActivityData.concat(extractbarCopActivityData,
      extractbarMixActivityData, extractfailureActivityforEmissionData);
    const sortedEmissionData = this.sortSheetData(emissionJsonData);
    const reliability: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(sortedReliabilityData)));
    const elec: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(sortedEvData)));
    const tuning: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(sortedTuningData)));
    const emission: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(sortedEmissionData)));
    /* US366 - As a user I want to extract data for a specific testbed */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    if (this.flagForTestBed) {
      if (this.mapName === 'Tuning E/D') {
        XLSX.utils.book_append_sheet(wb, tuning, 'Tuning');
        XLSX.writeFile(wb, this.fileName + this.commonService.getFormattedTime() + '.xlsx');
        this.closeControl = true;
        this.dialogRef.close();
      } else if (this.mapName === 'Elec E/D') {
        XLSX.utils.book_append_sheet(wb, elec, 'Elec');
        XLSX.writeFile(wb, this.fileName + this.commonService.getFormattedTime() + '.xlsx');
        this.closeControl = true;
        this.dialogRef.close();
      } else if (this.mapName === 'Reliability E/D') {
        XLSX.utils.book_append_sheet(wb, reliability, 'Reliability');
        XLSX.writeFile(wb, this.fileName + this.commonService.getFormattedTime() + '.xlsx');
        this.closeControl = true;
        this.dialogRef.close();
      } else if (this.mapName === 'Emission C/D') {
        XLSX.utils.book_append_sheet(wb, emission, 'Emission');
        XLSX.writeFile(wb, this.fileName + this.commonService.getFormattedTime() + '.xlsx');
        this.closeControl = true;
        this.dialogRef.close();
      }
    } else {
      XLSX.utils.book_append_sheet(wb, elec, 'Elec');
      XLSX.utils.book_append_sheet(wb, reliability, 'Reliability');
      XLSX.utils.book_append_sheet(wb, tuning, 'Tuning');
      XLSX.utils.book_append_sheet(wb, emission, 'Emission');
      XLSX.writeFile(wb, this.fileName + this.commonService.getFormattedTime() + '.xlsx');
      this.closeControl = true;
      this.dialogRef.close();
    }


  }

  nullcheck(id: any) {
    if (id === null) {
      return null;
    } else {
      return id.label;
    }

  }

  getTestBedLabel(key: any) {
    for (let i = 0; i < this.testBedData.length; i++) {
      // tslint:disable-next-line: triple-equals
      if (this.testBedData[i].id === key) {
        return this.testBedData[i].label;
      }
    }
  }

  getTestBedData() {
    this.testBedService.getTestBedList().subscribe((result: Testbed[]) => {
      this.testBedData = result;
    });
  }

  emptyValueInsertorforNull(testData) {
    if (testData === null) {
      return '';
    } else { return testData; }
  }

  sortSheetData(activityExportedData) {
    if (this.browserLang === 'en') {
      activityExportedData.sort((a, b) => {
        const aStr: string[] = a.Date.toString().split(/\//);
        const dayNum = aStr[0]; const monNum = aStr[1]; const yearNum = aStr[2];
        const aT = a.Testbed; const bT = b.Testbed;
        const bStr: string[] = b.Date.toString().split(/\//);
        const bdayNum = bStr[0]; const bmonNum = bStr[1]; const byearNum = bStr[2];
        const dateA = parseInt(yearNum + monNum + dayNum);
        const dateB = parseInt(byearNum + bmonNum + bdayNum);
        if (aT > bT) { return 1 || (dateA - dateB) || (a['Sequence Number'] - b['Sequence Number']); }
        if (bT > aT) { return -1 || (dateA - dateB) || (a['Sequence Number'] - b['Sequence Number']); } else {
          return 0 || (dateA - dateB) || (a['Sequence Number'] - b['Sequence Number']);
        }
      });
      return activityExportedData;
    } else {
      activityExportedData.sort((a, b) => {
        const aStr: string[] = a.Date.toString().split(/\//);
        const dayNum = aStr[0]; const monNum = aStr[1]; const yearNum = aStr[2];
        const aT = a['Essai Banc']; const bT = b['Essai Banc'];
        const bStr: string[] = b.Date.toString().split(/\//);
        const bdayNum = bStr[0]; const bmonNum = bStr[1]; const byearNum = bStr[2];
        const dateA = parseInt(yearNum + monNum + dayNum);
        const dateB = parseInt(byearNum + bmonNum + bdayNum);
        if (aT > bT) { return 1 || (dateA - dateB) || (a['N° de séquence'] - b['N° de séquence']); }
        if (bT > aT) { return -1 || (dateA - dateB) || (a['N° de séquence'] - b['N° de séquence']); } else {
          return 0 || (dateA - dateB) || (a['N° de séquence'] - b['N° de séquence']);
        }
      });
      return activityExportedData;
    }
  }

  extractVhData() {
    let jsonData = {};
    const extractVehicleData = [];
    const extractFailureData = [];
    const vehicleDataJson = this.commonService.getVehicleActivityData();
    const failureactivityJson = this.commonService.getVehicleFailiureActivityData();
    let headerLabels;
    if (this.browserLang === exportLang.english) {
      this.fileName = exportLang.vehicleActivity_En;
    } else {
      this.fileName = exportLang.vehicleActivity_Fr;
    }
    if (vehicleDataJson.length !== 0) {
      if (this.browserLang === exportLang.english) {
        headerLabels = enlangData;
      } else {
        headerLabels = frlangData;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < vehicleDataJson.length; i++) {
        if (!vehicleDataJson[i].activity.level0.failure) {
          jsonData[headerLabels.VehicleActivity.Site] = this.nullcheck(vehicleDataJson[i].activity.testBedId.testbedGlobalInfo.siteId);
          jsonData[headerLabels.VehicleActivity.Mean] = this.nullcheck(vehicleDataJson[i].activity.testBedId.testbedGlobalInfo.meanId);
          jsonData[headerLabels.VehicleActivity.Testbed] = this.getTestBedLabel(vehicleDataJson[i].activity.testBedId.id);
          jsonData[headerLabels.VehicleActivity.date] = this.activityService.getDateFormat(vehicleDataJson[i].activity.activityDate);
          jsonData[headerLabels.VehicleActivity.Seq_Num] = vehicleDataJson[i].activity.seqNum;
          jsonData[headerLabels.VehicleActivity.activityType] = this.nullcheck(vehicleDataJson[i].activity.level0);
          jsonData[headerLabels.VehicleActivity.duration] = vehicleDataJson[i].activity.duration;
          jsonData[headerLabels.VehicleActivity.testRequestNumber] = this.emptyValueInsertorforNull(vehicleDataJson[i].testRequestNumber);
          jsonData[headerLabels.VehicleActivity.levelOne] = this.nullcheck(vehicleDataJson[i].activity.level1);
          jsonData[headerLabels.VehicleActivity.levelTwo] = this.nullcheck(vehicleDataJson[i].activity.level2);
          jsonData[headerLabels.VehicleActivity.operator] = vehicleDataJson[i].activity.ipn;
          jsonData[headerLabels.VehicleActivity.programLine] = this.emptyValueInsertorforNull(vehicleDataJson[i].programLine);
          jsonData[headerLabels.VehicleActivity.project] = this.emptyValueInsertorforNull(vehicleDataJson[i].project);
          jsonData[headerLabels.VehicleActivity.projectMilestone] = this.emptyValueInsertorforNull(vehicleDataJson[i].projectMilestone);
          jsonData[headerLabels.VehicleActivity.testProcedureNumber] = this.emptyValueInsertorforNull(vehicleDataJson[i].testProcedureNumber);
          jsonData[headerLabels.VehicleActivity.logoSR] = this.emptyValueInsertorforNull(vehicleDataJson[i].logoSR);
          jsonData[headerLabels.VehicleActivity.ddmrsRequest] = this.emptyValueInsertorforNull(vehicleDataJson[i].ddmrsRequest);
          jsonData[headerLabels.VehicleActivity.requesterDepartment] = this.emptyValueInsertorforNull(vehicleDataJson[i].requesterDepartment);
          jsonData[headerLabels.VehicleActivity.vhEntity] = this.emptyValueInsertorforNull(vehicleDataJson[i].vhEntity);
          jsonData[headerLabels.VehicleActivity.testSupportNumber] = this.emptyValueInsertorforNull(vehicleDataJson[i].testSupportNumber);
          jsonData[headerLabels.VehicleActivity.testStart] = this.emptyValueInsertorforNull(this.activityService.
            getDateFormat(vehicleDataJson[i].testStart));
          jsonData[headerLabels.VehicleActivity.testEnd] = this.emptyValueInsertorforNull(this.activityService.
            getDateFormat(vehicleDataJson[i].testEnd));
          jsonData[headerLabels.VehicleActivity.nbofCycles] = this.emptyValueInsertorforNull(vehicleDataJson[i].nbofCycles);
          jsonData[headerLabels.VehicleActivity.nbofParts] = this.emptyValueInsertorforNull(vehicleDataJson[i].nbofParts);
          jsonData[headerLabels.VehicleActivity.softCalibRef] = this.emptyValueInsertorforNull(vehicleDataJson[i].softCalibRef);
          jsonData[headerLabels.VehicleActivity.preparationBox] = this.emptyValueInsertorforNull(vehicleDataJson[i].preparationBox);
          jsonData[headerLabels.VehicleActivity.powerTrain] = this.emptyValueInsertorforNull(vehicleDataJson[i].powerTrain);
          jsonData[headerLabels.VehicleActivity.pollutionRegulation] = this.emptyValueInsertorforNull(vehicleDataJson[i].pollutionRegulation);
          jsonData[headerLabels.VehicleActivity.OTnumber] = '';
          jsonData[headerLabels.VehicleActivity.OTstatus] = '';
          jsonData[headerLabels.VehicleActivity.commentaries] = this.emptyValueInsertorforNull(vehicleDataJson[i].activity.comment);
          extractVehicleData.push(jsonData);
          jsonData = {};
        }
      }
    }
    if (failureactivityJson.length !== 0) {
      if (this.browserLang === exportLang.english) {
        headerLabels = enlangData;
      } else {
        headerLabels = frlangData;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < failureactivityJson.length; i++) {
        jsonData[headerLabels.VehicleActivity.Site] = this.nullcheck(failureactivityJson[i].vehicleActivity.activity.testBedId.
          testbedGlobalInfo.siteId);
        jsonData[headerLabels.VehicleActivity.Mean] = this.nullcheck(failureactivityJson[i].vehicleActivity.activity.testBedId.
          testbedGlobalInfo.meanId);
        jsonData[headerLabels.VehicleActivity.Testbed] = this.getTestBedLabel(failureactivityJson[i].vehicleActivity.activity.testBedId.id);
        jsonData[headerLabels.VehicleActivity.date] = this.activityService.getDateFormat(failureactivityJson[i].vehicleActivity.
          activity.activityDate);
        jsonData[headerLabels.VehicleActivity.Seq_Num] = failureactivityJson[i].vehicleActivity.activity.seqNum;
        jsonData[headerLabels.VehicleActivity.activityType] = this.nullcheck(failureactivityJson[i].vehicleActivity.activity.level0);
        jsonData[headerLabels.VehicleActivity.duration] = failureactivityJson[i].vehicleActivity.activity.duration;
        jsonData[headerLabels.VehicleActivity.testRequestNumber] = this.emptyValueInsertorforNull(failureactivityJson[i].vehicleActivity.
          testRequestNumber);
        jsonData[headerLabels.VehicleActivity.levelOne] = this.nullcheck(failureactivityJson[i].vehicleActivity.activity.level1);
        jsonData[headerLabels.VehicleActivity.levelTwo] = this.nullcheck(failureactivityJson[i].vehicleActivity.activity.level2);
        jsonData[headerLabels.VehicleActivity.operator] = failureactivityJson[i].vehicleActivity.activity.ipn;
        jsonData[headerLabels.VehicleActivity.programLine] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.programLine);
        jsonData[headerLabels.VehicleActivity.project] = this.emptyValueInsertorforNull(failureactivityJson[i].vehicleActivity.project);
        jsonData[headerLabels.VehicleActivity.projectMilestone] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.projectMilestone);
        jsonData[headerLabels.VehicleActivity.testProcedureNumber] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.testProcedureNumber);
        jsonData[headerLabels.VehicleActivity.logoSR] = this.emptyValueInsertorforNull(failureactivityJson[i].vehicleActivity.logoSR);
        jsonData[headerLabels.VehicleActivity.ddmrsRequest] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.ddmrsRequest);
        jsonData[headerLabels.VehicleActivity.requesterDepartment] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.requesterDepartment);
        jsonData[headerLabels.VehicleActivity.vhEntity] = this.emptyValueInsertorforNull(failureactivityJson[i].vehicleActivity.vhEntity);
        jsonData[headerLabels.VehicleActivity.testSupportNumber] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.testSupportNumber);
        jsonData[headerLabels.VehicleActivity.testStart] = this.emptyValueInsertorforNull(this.activityService.
          getDateFormat(failureactivityJson[i].vehicleActivity.testStart));
        jsonData[headerLabels.VehicleActivity.testEnd] = this.emptyValueInsertorforNull(this.activityService.
          getDateFormat(failureactivityJson[i].vehicleActivity.testEnd))
        jsonData[headerLabels.VehicleActivity.nbofCycles] = this.emptyValueInsertorforNull(failureactivityJson[i].vehicleActivity.nbofCycles);
        jsonData[headerLabels.VehicleActivity.nbofParts] = this.emptyValueInsertorforNull(failureactivityJson[i].vehicleActivity.nbofParts);
        jsonData[headerLabels.VehicleActivity.softCalibRef] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.softCalibRef);
        jsonData[headerLabels.VehicleActivity.preparationBox] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.preparationBox);
        jsonData[headerLabels.VehicleActivity.powerTrain] = this.emptyValueInsertorforNull(failureactivityJson[i].vehicleActivity.powerTrain);
        jsonData[headerLabels.VehicleActivity.pollutionRegulation] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.pollutionRegulation);
        jsonData[headerLabels.VehicleActivity.OTnumber] = failureactivityJson[i].ot ? failureactivityJson[i].ot.label :
          failureactivityJson[i].otGlobal;
        jsonData[headerLabels.VehicleActivity.OTstatus] = failureactivityJson[i].ot ? failureactivityJson[i].ot.status : '';
        jsonData[headerLabels.VehicleActivity.commentaries] = this.emptyValueInsertorforNull(failureactivityJson[i].
          vehicleActivity.activity.comment);
        extractFailureData[i] = jsonData;
        jsonData = {};
      }
    }
    const vhData = extractVehicleData.concat(extractFailureData);
    const sortedVhData = this.sortSheetDataVh(vhData);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(sortedVhData)));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, exportLang.vehicleActivity_En);
    XLSX.writeFile(wb, this.fileName + this.commonService.getFormattedTime() + '.xlsx');
    this.closeControl = true;
    this.dialogRef.close();
  }

  sortSheetDataVh(vhActivityExportedData) {
    if (this.browserLang === exportLang.english) {
      vhActivityExportedData.sort((a, b) => {
        const aStr: string[] = a.Date.toString().split(/\//);
        const dayNum = aStr[0]; const monNum = aStr[1]; const yearNum = aStr[2];
        const aT = a.Testbed; const bT = b.Testbed;
        const bStr: string[] = b.Date.toString().split(/\//);
        const bdayNum = bStr[0]; const bmonNum = bStr[1]; const byearNum = bStr[2];
        const dateA = parseInt(yearNum + monNum + dayNum);
        const dateB = parseInt(byearNum + bmonNum + bdayNum);
        if (aT > bT) { return 1 || (dateA - dateB) || (a[exportLang.Sequence_Number_En] - b[exportLang.Sequence_Number_En]); }
        if (bT > aT) {
          return -1 || (dateA - dateB) || (a[exportLang.Sequence_Number_En] - b[exportLang.Sequence_Number_En]);
        } else {
          return 0 || (dateA - dateB) || (a[exportLang.Sequence_Number_En] - b[exportLang.Sequence_Number_En]);
        }
      });
      return vhActivityExportedData;
    } else {
      vhActivityExportedData.sort((a, b) => {
        const aStr: string[] = a.Date.toString().split(/\//);
        const dayNum = aStr[0]; const monNum = aStr[1]; const yearNum = aStr[2];
        const aT = a[vehicleActivityEnum.Essai_Banc]; const bT = b[vehicleActivityEnum.Essai_Banc];
        const bStr: string[] = b.Date.toString().split(/\//);
        const bdayNum = bStr[0]; const bmonNum = bStr[1]; const byearNum = bStr[2];
        const dateA = parseInt(yearNum + monNum + dayNum);
        const dateB = parseInt(byearNum + bmonNum + bdayNum);
        if (aT > bT) { return 1 || (dateA - dateB) || (a[exportLang.Sequence_Number_Fr] - b[exportLang.Sequence_Number_Fr]); }
        if (bT > aT) { return -1 || (dateA - dateB) || (a[exportLang.Sequence_Number_Fr] - b[exportLang.Sequence_Number_Fr]); } else {
          return 0 || (dateA - dateB) || (a[exportLang.Sequence_Number_Fr] - b[exportLang.Sequence_Number_Fr]);
        }
      });
      return vhActivityExportedData;
    }
  }
}
