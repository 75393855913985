export class Campaign {
    id: number;
    label: string;
    sites: string;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: boolean;
    organisationId: number[];
}
