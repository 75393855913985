import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { MessageService } from '../message.service';

@Injectable({
  providedIn: 'root'
})
export class UserIdleTimeOutService {
  public restTimerCheck = true;

  constructor(private userIdle: UserIdleService,
    private messageService: MessageService) {
    sessionStorage.setItem('restTimerCheck', 'true');
  }

  // for userIdle check ---- Start
  setUserIdleCheck() {
    // Start watching for user inactivity.
    this.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      console.log('user idle is starting!', count);
      this.restTimerCheck = true;
      sessionStorage.setItem('restTimerCheck', 'true');
      const userIdelchk = sessionStorage.getItem('userIdelchk');
      if (userIdelchk && userIdelchk === 'true') {
        this.stop();
        sessionStorage.setItem('userIdelchk', 'false');
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log('Time is up!');
      this.restTimerCheck = true;
      this.stop();
      //sessionStorage.clear();
      this.stopWatching();
      this.messageService.sendMessage('logout');
    });
  }

  stop() {
    this.userIdle.stopTimer();
    this.restTimerCheck = false;
    sessionStorage.setItem('restTimerCheck', 'false');
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
    this.restTimerCheck = false;
    sessionStorage.setItem('restTimerCheck', 'false');
  }
  // for userIdle check ---- End
}
