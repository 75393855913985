import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Prestation } from './prestation.model';

@Injectable({
  providedIn: 'root'
})
export class PrestationService {
  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public prestation: Prestation;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
   }

  getAllPrestation(prestation: Prestation) {
    return this.httpClient.post<Prestation[]>(this.apiURL + '/getPrestationList', prestation).pipe(map(res => res));
  }

  getActivePrestation(prestation: Prestation) {
    return this.httpClient.post<Prestation[]>(this.apiURL + '/getActivePrestationList', prestation).pipe(map(res => res));
  }

  updatePrestationOrganisationList(prestation: Prestation) {
    return this.httpClient.put<Prestation>(this.apiURL + '/updatePrestationOrganisationList', prestation, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(presation: Prestation) {
    this.getAllPrestation(presation).subscribe((result: Prestation[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  createPrestationList(prestation: Prestation) {

    return this.httpClient.post<Prestation>(this.apiURL + '/savePrestation', prestation, {
      observe: 'response'
    });
  }
  updatePrestationList(prestation: Prestation) {
    return this.httpClient.put<Prestation>(this.apiURL + '/updatePrestation', prestation, {
      observe: 'response'
    });
  }

  setPrestation(gridPrestation: Prestation) {
    this.prestation = gridPrestation;
    if (this.prestation != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdatePrestation() {
    return this.prestation;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

}
