import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PiwikConfigResolver } from '../piwik/piwik.config.resolver';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material';
import { TestbedExtractComponent } from '../testbed-extract/testbed-extract.component';
import { HomeStaticValues } from './homeStaticFields';
import { SpotFireTVComponent } from '../spot-fire/spot-fire-tv/spot-fire-tv.component'
import { SpotFireComponent } from '../spot-fire/spot-fire.component';
import { StarRatingComponent } from '../star-rating/star-rating.component';
import { StarRatingService } from '../star-rating/star-rating.service';
import { StarRatingModel } from '../star-rating/star-rating.model';
import { I18nLangService } from 'src/app/common/i18n/i18n-lang.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { error } from 'util';


@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  public spotFirebtnTvEnable: boolean;
  public spotFirebtnTmEnable: boolean;
  private subscription: Subscription;
  gcpRedirectionEnvUrl: string;
  constructor(public piwikConfigResolver: PiwikConfigResolver,
    public dialog: MatDialog, public starRatingService: StarRatingService, public langService: I18nLangService, public translate: TranslateService) {
    }

  ngOnInit() {
    const envName: string = sessionStorage.getItem('envName');
    console.log(envName,'envName')
    this.getUrlBasedonEnv(envName);
    if (envName === 'QUA' || envName === 'RE7' || envName === 'OPE') {
      this.piwikInit(envName);
    }

    const orgId :String=sessionStorage.getItem('organisationIDs');
    if(orgId.includes("1"))
    {
      this.spotFirebtnTmEnable=true;
      
    }
    if(orgId.includes("2"))
    {
      
      this.spotFirebtnTvEnable=true;
    }

  }

  getUrlBasedonEnv(envName: string){
     //envName = 'STA';
     console.log(envName, 'envName');
      if (envName === 'DEV') {
        this.gcpRedirectionEnvUrl = 'https://tp2-dev.gke2.dev.gcp.renault.com';
      } else if (envName === 'QUA') {
        this.gcpRedirectionEnvUrl = 'https://tp2-qua.gke2.dev.gcp.renault.com';
      } else if (envName === 'RE7') {
        this.gcpRedirectionEnvUrl = 'https://tp2-re7.gke2.int.gcp.renault.com';
      } else if (envName === 'OPE') {
        this.gcpRedirectionEnvUrl = 'https://tp2-ope.gke2.ope.gcp.renault.com';
      } else if (envName === 'STA') {
        this.gcpRedirectionEnvUrl = 'https://tp2-sta.gke2.dev.gcp.renault.com';
      } else {
        console.log('test1');
        this.gcpRedirectionEnvUrl = 'https://int.tp2.dev.aws.renault.com/';
      }
  }
  ngAfterViewInit() {
    //this.checkStarRating()
    // this.feedback();
  }

  private piwikInit(envName: string, documentTitle?: string) {
    this.piwikConfigResolver.resolve(envName).subscribe(config => {
      const paq = window['_paq'];
      paq.push(['setTrackerUrl', config.url + environment.piwikPhpFile]);
      paq.push(['setSiteId', config.siteid]);
      paq.push(['setUserId', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : 'TPMADMIN']);
      paq.push(['setCustomVariable', 1, 'ZID', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : 'TPMADMIN', 'visit']);
      paq.push(['trackPageView']);
      paq.push(['enableLinkTracking']);
      if (documentTitle) {
        paq.push(['setDocumentTitle', documentTitle]);
      }
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = config.url + environment.piwikJsFile;
      s.parentNode.insertBefore(g, s);
    });
  }

  onTestbedExtract() {
    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(TestbedExtractComponent, {
        width: '500px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }
  }

  generateSpotFireReport(orgName: String) {
    const envName: string = sessionStorage.getItem('envName');
    if (envName === 'QUA' || envName === 'RE7' || envName === 'OPE') {
      this.piwikInit(envName, "SpotFire-TM");
    }
    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(SpotFireComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }



    //For direct Spotfire link

    // const envName: string = sessionStorage.getItem(HomeStaticValues.envName);
    // if (envName === HomeStaticValues.RE7 || envName === HomeStaticValues.OPE) {
    //   window.open(environment.spotFireProdUrl);
    // } else {
    //   window.open(environment.spotFireQuaUrl);
    // }

  }
  generateSpotFireTVReport(orgName: String) {
    const envName: string = sessionStorage.getItem('envName');
    if (envName === 'QUA' || envName === 'RE7' || envName === 'OPE') {
      this.piwikInit(envName, "SpotFire-TV");
    }
    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(SpotFireTVComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }
  }
   checkStarRating() {
    const starRatingModel = new StarRatingModel();
    starRatingModel.ipn = sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : 'User';
    this.starRatingService.checkStarRating(starRatingModel).subscribe((res) => {
      if (res.length > 0) {

      } else {
        this.feedback();
      }
    }, (error) => {
      console.log(`error----> ${error}`);
      this.feedback();
    })
  }
  feedback() {
    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(StarRatingComponent, {
        width: '500px',
        disableClose:true,
        data: {
        }
      },);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }
  } 

}
