import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Procedure } from './procedure.model';
@Injectable({
  providedIn: 'root'
})
export class ProcedureService {
  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public procedure: Procedure;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllProcedure(procedure: Procedure) {
    return this.httpClient.post<Procedure[]>(this.apiURL + '/getProcedureList', procedure).pipe(map(res => res));
  }

  getActiveProcedureList(procedure: Procedure) {
    return this.httpClient.post<Procedure[]>(this.apiURL + '/getActiveProcedureList', procedure).pipe(map(res => res));
  }

  updateProcedureOrganisationList(procedure: Procedure) {
    return this.httpClient.put<Procedure>(this.apiURL + '/updateProcedureOrganisationList', procedure, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(procedure: Procedure) {
    this.getAllProcedure(procedure).subscribe((result: Procedure[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  createProcedureList(procedure: Procedure) {

    return this.httpClient.post<Procedure>(this.apiURL + '/saveProcedure', procedure, {
      observe: 'response'
    });
  }
  updateProcedureList(procedure: Procedure) {
    return this.httpClient.put<Procedure>(this.apiURL + '/updateProcedure', procedure, {
      observe: 'response'
    });
  }
  setProcedure(gridProcedure: Procedure) {
    this.procedure = gridProcedure;
    if (this.procedure != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateProcedure() {
    return this.procedure;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

}
