import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MacroActivity } from './macro-activity.model';
import { constants } from '../../referentiel/referentiel.model';

@Injectable({
  providedIn: 'root'
})
export class MacroActivityService {
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public btnName: string;
  macro: MacroActivity;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getGridOptions() {
    return this.gridOptions;
  }
  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }
  setMacroActivity(gridServices: MacroActivity) {
    this.macro = gridServices;
    if (this.macro != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateMacroActivity() {
    return this.macro;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllMacroActivity(macro: MacroActivity) {
    return this.httpClient.post<MacroActivity[]>(this.apiURL + '/getMacroActivityList', macro).pipe(map(res => res));
  }

  getMacroActivityByLabel(macro: MacroActivity) {
    return this.httpClient.post<MacroActivity>(this.apiURL + '/getMacroActivityByLabel', macro).pipe(map(res => res));
  }

  refreshGrid(macro: MacroActivity) {
    macro.organisationId = JSON.parse(sessionStorage.getItem(constants.orgId));
    this.getAllMacroActivity(macro).subscribe((result: MacroActivity[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  createMacroActivityList(macro: MacroActivity) {
    return this.httpClient.post<MacroActivity>(this.apiURL + '/createMacroActivityList', macro, {
      observe: 'response'
    });
  }

  updateMacroActivityList(macro: MacroActivity) {
    return this.httpClient.put<MacroActivity>(this.apiURL + '/updateMacroActivity', macro, {
      observe: 'response'
    });
  }

  updateMacroActivityOrganisationList(macro: MacroActivity) {
    return this.httpClient.put<MacroActivity>(this.apiURL + '/updateMacroActivityOrganisationList', macro, {
      observe: 'response'
    });
  }
}
