import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CrrppInit } from './model/crrppInit.model';
import { CrrppInitWrapperData } from './model/crrppInitWrapperData.model';
import { CrrppParticipants } from './model/crrppParticipants.model';
import { TestMean } from './model/testMean.model';
import { Rpp } from './model/rpp.model';;
import { DatePipe } from '@angular/common';
import { CommonServices } from 'src/app/common/common.services';
import { CrrppStaticValues } from './model/crrppStaticValues';
import { TbfConsumption } from './model/tbfConsumption.model';
import { AnalyseCampaignWrapper } from './model/analyse-campaign-wrapper.model';
import { TbfDataWrapper } from './model/tbfDataWrapper';
import { RppPlannedAbsences } from './model/RppPlannedAbsences.model';
import { GridOptions } from 'ag-grid-community';
import { map } from 'rxjs/operators';
import { RppDirectory } from './model/rppDirectory.model';

@Injectable({
  providedIn: 'root'
})
export class CrrppService {
  dashboardData: any = [];
  private baseString: string;
  public crrpParticipant: CrrppParticipants[];
  initTabGridDirty: boolean;
  public week: Date;
  public crrppInit: CrrppInit;
  public testMean: TestMean;
  public tbfTrigger: boolean;
  public rpp: Rpp;
  base64URLTbfConsumptionChart: string;
  tbfGraphData: TbfConsumption[];
  tbfWrapperData: TbfDataWrapper;
  base64URLFermeGraph: any;
  base64URLClientGraph: string;
  public rppPlannedAbsences: RppPlannedAbsences[];
  public rppPlannedAbsencesRowSelect: RppPlannedAbsences;
  public gridOptions: GridOptions;

  constructor(private http: HttpClient, private datePipe: DatePipe, private commonServices: CommonServices) {
    this.baseString = environment.apiURL;
  }

  getCrrppInitData(crrppInitRequest: CrrppInit) {
    return this.http.post<CrrppInitWrapperData>(this.baseString + '/getCrrppInitData', crrppInitRequest);
  }

  getCrrpParticipant() {
    return this.crrpParticipant;
  }

  setCrrpParticipant(crrpParticipant: CrrppParticipants[]) {
    this.crrpParticipant = crrpParticipant;
  }

  saveCrrppData(crrppInitWrapperData: CrrppInitWrapperData) {
    return this.http.post<any>(this.baseString + '/saveCrrppInitData', crrppInitWrapperData);
  }

  getInitTabGridDirty() {
    return this.initTabGridDirty;
  }

  setInitTabGridDirty(initTabGridDirty: boolean) {
    this.initTabGridDirty = initTabGridDirty;
  }

  setPrimaryWeek(week: Date) {
    this.week = week;
  }
  getPrimaryWeek(): Date {
    return this.week;
  }

  setCrrppInitData(crrppInit: CrrppInit) {
    this.crrppInit = crrppInit;
  }

  gettterCrrppInitData() {
    return this.crrppInit;
  }

  getTestMeanData(testMean: TestMean) {
    return this.http.post<TestMean>(this.baseString + '/getTestMean', testMean);
  }

  updateTestMean(testMean: TestMean) {
    return this.http.post<TestMean>(this.baseString + '/updateTestMean', testMean, {
      observe: 'response'
    });

  }

  saveRpp(rpp: Rpp) {
    return this.http.post<Rpp>(this.baseString + '/saveRpp', rpp, {
      observe: 'response'
    });
  }

  getRppData(rpp: Rpp) {
    return this.http.post<Rpp>(this.baseString + '/getRppData', rpp);
  }

  getEngineNumber(analyseCampaignWrapper: AnalyseCampaignWrapper) {
    return this.http.post<AnalyseCampaignWrapper>(this.baseString + '/getEngineNumber', analyseCampaignWrapper);
  }

  setDashboardData(febNum: any, qcNum: any, startingWeek: number, startDate: any, endDate: any) {
    this.dashboardData[0] = febNum;
    this.dashboardData[1] = qcNum;
    this.dashboardData[2] = CrrppStaticValues.week + startingWeek;
    this.dashboardData[3] = startDate;
    this.dashboardData[4] = endDate;

  }

  saveRppPlannedAbsences(rppPlannedAbsences: RppPlannedAbsences) {
    return this.http.post<RppPlannedAbsences>(this.baseString + '/saveRppPlannedAbsences' , rppPlannedAbsences, {
      observe: 'response'
    });
  }

  getRppPlannedAbsences (rppPlannedAbsences: RppPlannedAbsences) {
    return this.http.post<RppPlannedAbsences[]>(this.baseString + '/getRppPlannedAbsences', rppPlannedAbsences).pipe(map(res => res));
}

getRppPlannedAbsencesById (rppPlannedAbsences: RppPlannedAbsences) {
  return this.http.post<RppPlannedAbsences>(this.baseString + '/getRppPlannedAbsencesById', rppPlannedAbsences).pipe(map(res => res));
}

updateRppPlannedAbsences(directory: RppDirectory) {
  return this.http.post<RppDirectory>(this.baseString + '/updateRppPlannedAbsences' , directory, {
    observe: 'response'
  });
}

deleteRppPlannedAbsences(rppPlannedAbsences: RppPlannedAbsences) {
  return this.http.post<RppPlannedAbsences>(this.baseString + '/deleteRppPlannedAbsences' , rppPlannedAbsences, {
    observe: 'response'
  });
}
updateNextRppPlannedAbsences(rppPlannedAbsences: RppPlannedAbsences) {
  return this.http.post<RppPlannedAbsences>(this.baseString + '/updateNextRppPlannedAbsences' , rppPlannedAbsences, {
    observe: 'response'
  });
}

}
