export class CustomerDirection {
    id: number;
    label: string;
    costCenter: string;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: boolean;
    organisationId: number[];
}

