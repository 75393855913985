import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GridOptions } from 'ag-grid-community';
import { Shifts } from './shifts.model';

@Injectable({
  providedIn: 'root'
})
export class ShiftsService {

  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public shifts: Shifts;
  public btnName: string;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllShifts(shifts: Shifts) {
    return this.httpClient.post<Shifts[]>(this.apiURL + '/getAllShifts', shifts).pipe(map(res => res));
  }
  updateShiftOrganisationList(shifts: Shifts) {
    return this.httpClient.put<Shifts>(this.apiURL + '/updateShiftOrganisationList', shifts, {
      observe: 'response'
    });
  }
  createShift(shifts: Shifts) {
    return this.httpClient.post<Shifts>(this.apiURL + '/createShift', shifts, {
      observe: 'response'
    });
  }
  updateShifts(shifts: Shifts) {
    return this.httpClient.put<Shifts>(this.apiURL + '/updateShifts', shifts, {
      observe: 'response'
    });
  }
  getGridOptions() {
    return this.gridOptions;
  }
  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }
  refreshGrid(shifts: Shifts) {
    this.getAllShifts(shifts).subscribe((result: Shifts[]) => {
      const extractedResult = result.map((m: any) => { m.shifts = m.teams +"x"+ m.hours; return m; });
      this.gridOptions.api.setRowData(extractedResult);
    });
  }
  setShift(gridShift: Shifts) {
    this.shifts = gridShift;
    if (this.shifts != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateShift() {
    return this.shifts;
  }
  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
