import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { CrrppService } from 'src/app/saisie/crrpp/crrpp.service';
import { SuiviMoteurService } from 'src/app/saisie/crrpp/suivi-moteur/suivi-moteur.service';
import { CrrppStaticValues } from 'src/app/saisie/crrpp/model/crrppStaticValues';

@Component({
  selector: 'custom-aggrid-checkbox',
  templateUrl: './custom-aggrid-checkbox.component.html',
  styleUrls: ['./custom-aggrid-checkbox.component.css']
})
export class CustomAggridCheckboxComponent implements AgRendererComponent {

  constructor(private crrppService: CrrppService,
    private suiviService: SuiviMoteurService) { }
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
  }

  refresh(params: any): boolean {
    if (params.data.crrppDirectoryId) {
      params.data.crrppDirectoryId.active = params.value;
      this.crrppService.setInitTabGridDirty(true);
    }
    if (params.data.week1Id) {
      params.data.clientSatisfaction = params.value;
      this.suiviService.setInitTabGridDirty(true);
      this.suiviService.changeMessage(CrrppStaticValues.refreshMessage);
    }
    params.api.refreshCells(params);
    return false;
  }

}
