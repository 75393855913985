import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonButtonComponent } from './common-button.component';
import { I18nModule } from './../i18n/i18n.module';
import { OrganisationMapComponent } from './organisation-map-btn/organisation-btn.component';
import { AgGridModule } from 'ag-grid-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrganisationMapService } from './organisation-map-btn/organisation-map.service';
import { OrganisationMapModel } from './organisation-map-btn/organisation-map.model';
import { HttpConfigInterceptor } from '../interceptor/httpconfig.interceptor';
@NgModule({
  declarations: [
    CommonButtonComponent,
    OrganisationMapComponent,

  ],
  imports: [
    CommonModule, I18nModule,
    HttpClientModule,
    AgGridModule.withComponents([])
  ],
  exports: [
    CommonButtonComponent,
    OrganisationMapComponent
  ],
  providers: [
    OrganisationMapService,
    OrganisationMapModel,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  ]
})
export class CommonButtonModule { }
