import { TestbedGlobalInfo } from './global-info/testbed-global-info.model';
import { TestbedPerformance } from './performance/testbed-performance.model';
import { TestbedTechnicalInfo } from './technical-info/testbed-technical-info.model';

export class Testbed {

    public id: number;
    public label: string;
    public t1Swaying: string;
    public t2Swaying: string;
    public t3Swaying: string;
    public stopStatus: boolean;
    public organisation_reg_id: number[];
    public aLock: Date;
    public active: boolean;
    public creationDate: Date;
    public updateDate: Date;
    public ipn: string;
    public testbedGlobalInfo: TestbedGlobalInfo;
    public testbedPerformance: TestbedPerformance;
    public testbedTechnicalInfo: TestbedTechnicalInfo;
}

export enum TestbedEnum {
    selectedSite = 'selectedSite',
    selectedMean = 'selectedMean',
    selectedTestBed = 'selectedTestBed',
    site = 'Site',
    mean = 'Mean',
    testbed = 'TestBed',

}