import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { map } from 'rxjs/operators';
import { CommonServices } from 'src/app/common/common.services';
import { environment } from 'src/environments/environment';
import { VhTechnicalParam } from './vhtechnicalparam.model';

@Injectable({
  providedIn: 'root'
})
export class VhtechnicalparamService {
  data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public vhTechnicalParam: VhTechnicalParam;
  public btnName: string;

  constructor(private httpClient: HttpClient, private commonServices: CommonServices) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  saveVhTechnicalParam(vhTechnicalParam: VhTechnicalParam) {
    return this.httpClient.post<VhTechnicalParam>(this.apiURL + '/saveVhTechnicalParam', vhTechnicalParam, {
      observe: 'response'
    });
  }

  getAllVhTechnicalParam(vhTechnicalParam: VhTechnicalParam) {
    return this.httpClient.post<VhTechnicalParam[]>(this.apiURL + '/getAllVhTechnicalParam', vhTechnicalParam).pipe(map(res => res));
  }

  updateVhTechParamOrganisationList(vhTechnicalParam: VhTechnicalParam) {
    return this.httpClient.put<VhTechnicalParam>(this.apiURL + '/updateVhTechParamOrganisationList', vhTechnicalParam, {
      observe: 'response'
    });
  }

  refreshGrid(vhTechnicalParam: VhTechnicalParam) {
    this.getAllVhTechnicalParam(vhTechnicalParam).subscribe((result: VhTechnicalParam[]) => {
      this.gridOptions.api.setRowData(result);
      this.commonServices.VhTechnicalParamList = result;
    });
  }

  setVhTechnicalParam(vhTechParam: VhTechnicalParam) {
    this.vhTechnicalParam = vhTechParam;
    if (this.vhTechnicalParam != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

}
