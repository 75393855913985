import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SpotFireExploitationComponent } from './spot-fire-exploitation/spot-fire-exploitation.component';
import { SpotFireMaintenanceComponent } from './spot-fire-maintenance/spot-fire-maintenance.component';
import { SpotFirePerformanceComponent } from './spot-fire-performance/spot-fire-performance.component';
import { SpotFireProjetComponent } from './spot-fire-projet/spot-fire-projet.component';

@Component({
  selector: 'spot-fire',
  templateUrl: './spot-fire.component.html',
  styleUrls: ['./spot-fire.component.css']
})
export class SpotFireComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SpotFireComponent>, public dialog: MatDialog) { }

  ngOnInit() {
  }

  exploitation() {
    this.dialogRef.updateSize('2000px', '200px');
    if (this.dialog.openDialogs.length === 1) {
      const dialogRef = this.dialog.open(SpotFireExploitationComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        } else {
          this.dialogRef.updateSize('2000px', 'auto');
        }
      });
    }
  }

  performance() {
    this.dialogRef.updateSize('2000px', '200px');
    if (this.dialog.openDialogs.length === 1) {
      const dialogRef = this.dialog.open(SpotFirePerformanceComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        } else {
          this.dialogRef.updateSize('2000px', 'auto');
        }
      });
    }
  }

  projet() {
    this.dialogRef.updateSize('2000px', '200px');
    if (this.dialog.openDialogs.length === 1) {
      const dialogRef = this.dialog.open(SpotFireProjetComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        } else {
          this.dialogRef.updateSize('2000px', 'auto');
        }
      });
    }
  }

  maintenance() {
    this.dialogRef.updateSize('2000px', '200px');
    if (this.dialog.openDialogs.length === 1) {
      const dialogRef = this.dialog.open(SpotFireMaintenanceComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        } else {
          this.dialogRef.updateSize('2000px', 'auto');
        }
      });
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
