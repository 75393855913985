// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiURL: 'http://localhost:8181/api',
  apiURL: '/api',
  piwikJsFile: 'piwik.js',
  piwikPhpFile: 'piwik.php',
  spotFireProdUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com',
  spotFireQuaUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com',
  mapEnergeticQuaUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/02_PERFORMANCE/02-1_MAP_NRJ/TPM_PERF_MAP_ENERGETIQUE_PROD_DONNEES_HISTO',
  mapMecaniqueQuaUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/02_PERFORMANCE/02-2_MAP_MECA/TPM_PERF_MAP_MECANIQUE_PROD_DONNEES_HISTO',
  mapElectriqueQuaUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/02_PERFORMANCE/02-3_MAP_ELEC/TPM_PERF_MAP_ELECTRIQUE_PROD_DONNEES_HISTO',
  nonRsGlobalQuaUrl: 'https://abidesign-m4-i0.intra.renault.fr/spotfire/wp/OpenAnalysis?file=/TP2/DESIGN/REPORTS/REPORT_IN_PROGRESS/RNTBCI_VALIDATION/new_prompt/TPM_REPORTING_FORMULES_NON_RS_Global',
  top3NonRsQuaUrl: 'https://abidesign-m4-i0.intra.renault.fr/spotfire/wp/OpenAnalysis?file=/TP2/DESIGN/REPORTS/REPORT_IN_PROGRESS/RNTBCI_VALIDATION/new_prompt/TPM_REPORTING_FORMULES_TOP_3_NON_RS',
  panneSupportClientQuaUrl: 'https://abidesign-m4-i0.intra.renault.fr/spotfire/wp/OpenAnalysis?file=/TP2/DESIGN/REPORTS/REPORT_IN_PROGRESS/RNTBCI_VALIDATION/new_prompt/TPM_REPORTING_FORMULES_Others',

  mapEnergeticOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/02_PERFORMANCE/02-1_MAP_NRJ/TPM_PERF_MAP_ENERGETIQUE_PROD_DONNEES_HISTO',
  mapMecaniqueOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/02_PERFORMANCE/02-2_MAP_MECA/TPM_PERF_MAP_MECANIQUE_PROD_DONNEES_HISTO',
  mapElectriqueOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/02_PERFORMANCE/02-3_MAP_ELEC/TPM_PERF_MAP_ELECTRIQUE_PROD_DONNEES_HISTO',
  nonRsGlobalOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/02_PERFORMANCE/02-4_NON_RS_GLOBAL/TPM_PERFORMANCE_NON_RS_GLOBAL_PROD_DONNEES_HISTO',
  top3NonRsOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/analysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/02_PERFORMANCE/02-4_NON_RS_GLOBAL/TPM_PERFORMANCE_NON_RS_GLOBAL_PROD_DONNEES_HISTO',
  panneSupportClientOpeUrl: 'https://abiope-m4-i0.intra.renault.fr/spotfire/wp/OpenAnalysis?file=/TP2/DESIGN/REPORTS/REPORT_IN_PROGRESS/RNTBCI_VALIDATION/new_prompt/TPM_REPORTING_FORMULES_Others',

  exploitationElectriqueOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-3_MAP_ELEC/TPM_EXPL_REVUE_PERF_ELEC_PROD_DONNEES',
  exploitationMecaniqueOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-2_MAP_MECA/TPM_EXPL_REVUE_PERF_MECA_PROD_DONNEES',
  exploitationEnergetiqueBarOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/BAR/TPM_EXPL_BAR_CTL_RTX_PROD_DONNEES',
  exploitationEnergetiqueBrapOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/BRAP/TPM_EXPL_BRAP_CTL_PROD_DONNEES',
  exploitationEnergetiqueBarCopOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/BAR_COP/TPM_EXPL_BARCOP_CTL_PROD_DONNEES',
  exploitationEnergetiquePemsOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/PEMS/TPM_EXPL_PEMS_CTL_RTX_PROD_DONNEES',
  energetiqueBancMoteurBilanExplOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/BANCS_MOTEUR/TPM_EXPL_PERFORMANCE_PROD_DONNEES',
  energetiqueBancMoteurVsdAndMonitoringOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/BANCS_MOTEUR/TPM_EXPL_SUIVI_MONITORING_VSD_PROD_DONNEES',
  energetiqueBancMoteurRtechOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/BANCS_MOTEUR/TPM_EXPL_R_TECH_PROD_DONNEES',
  energetiqueBancMoteurReceptionUoOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/BANCS_MOTEUR/TPM_EXPL_RECEPTION_UO_PROD_DONNEES',
  energetiqueBancMoteurSdaOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-1_MAP_NRJ/BANCS_MOTEUR/TPM_EXPL_SDA_PROD_DONNEES',
  maintenanceFtrOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/04_MAINTENANCE_QUALITE/04-1_QUALITE_FTR_TNC/TPM_QUALITE_FTR_TNC_PROD_DONNEES',
  maintenanceMoyenOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/04_MAINTENANCE_QUALITE/04-2_MAINTENANCE_MOYEN_ESSAIS-FOURNISSEURS/TPM_MAINT_PROD_DONNEES',
  maintenanceTempsOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/04_MAINTENANCE_QUALITE/04-3_MAINTENANCE_TA/TPM_MAINT_TA_PROD_DONNEES',
  maintenanceLogPumaOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/04_MAINTENANCE_QUALITE/04-4_MAINTENANCE_ANALYSE_LOG_PUMA/TPM_MAINT_ANALYSE_LOGPUMA_PROD_DONNEES',
  exploitationMapMechaniqueMecaOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-2_MAP_MECA/MECA_GMP_BO/TPM_EXPL_REVUE_PERF_MECA_PROD_DONNEES',
  exploitationMapMechaniqueCleonOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-2_MAP_MECA/CLEON/TPM_QUALITE_FTR_PROD_DONNEES',
  exploitationMapMechaniqueNvhOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-2_MAP_MECA/NVH/TPM_EXPL_REVUE_PERF_NVH_PROD_DONNEES',
  exploitationMapMechaniqueReceptionUoOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-2_MAP_MECA/RECEPTION_UO/TPM_EXPL_RECEPTION_UO_MECA_PROD_DONNEES',
  projectAvancementOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/03_PROJET/AVANCEMENT_PROJET/TPM_PROJ_PERFORMANCE_PROD_DONNEES',
  projectPilotageBudgetaireOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/03_PROJET/PILOTAGE_BUDGETAIRE/VERIFICATION_SAISIE_SDA_PROD_DONNEES',
  exploitationMapElectriqueOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/analysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-3_MAP_ELEC/TPM_EXPL_REVUE_PERF_ELEC_PROD_DONNEES&waid=kyIesadXKEWqB31dA4VBb-24010092a4VbEX&wavid=0​',
  exploitationMapElectriqueUOOpeUrl: 'https://spotfire-m8.abi.ope.gcp.renault.com/spotfire/wp/OpenAnalysis?file=/TP2/OPE/REPORTS/REPORT_PUBLISHED/00_RAPPORTS_TPM2/01_EXPLOITATION/01-3_MAP_ELEC/TPM_EXPL_RECEPTION_UO_ELEC_PROD_DONNEES',
  authConfig: {
    loginUrl: 'https://int.tp2.dev.aws.renault.com/tp2/callback',
    redirectUri: 'https://int.tp2.dev.aws.renault.com/tp2/callback',
    clientId: 'irn-70003_uat_pkce_cteusgpoaijp',
    // scope: 'openid arca role-tp2-irn70003&acr_values=secure/name/password/uri&resourceServer=Identity Provider',
    scope: 'openid legacy',
    logoutUrl: 'https://idp2.renault.com/nidp/app/logout',
  },

  clientsId: {
    'int.tp2.dev': '18afe68c-65fe-41a4-9d95-00e6897f9788', // development
    'qua.tp2.dev': '18afe68c-65fe-41a4-9d95-00e6897f9788', // development
    'sta.tp2.dev': '18afe68c-65fe-41a4-9d95-00e6897f9788', // development
    're7.tp2.re7': '1a3ca309-9b09-4622-9129-3b459a8678ab', // re7
    'ope.tp2.ope': 'e1563dfe-d9da-4d3f-aa46-fcfaba60acf1', // production environment,
  },
   redirectUrls: {
    'localhost:4200': 'https://localhost:4200/callback', // development
    'int.tp2.dev': 'https://int.tp2.dev.aws.renault.com/tp2/callback', // development
    'qua.tp2.dev': 'https://qua.tp2.dev.aws.renault.com/tp2/callback', // development
    'sta.tp2.dev': 'https://sta.tp2.dev.aws.renault.com/tp2/callback', // development
    're7.tp2.re7': 'https://re7.tp2.re7.aws.renault.com/tp2/callback', // re7
    'ope.tp2.ope': 'https://ope.tp2.ope.aws.renault.com/tp2/callback', // production environment,
  },
  silentRefreshUrls: {
    'localhost:4200': 'https://localhost:4200/silent-refresh.html', // development
    'int.tp2.dev': 'https://int.tp2.dev.aws.renault.com/tp2/silent-refresh.html', // development
    'qua.tp2.dev': 'https://qua.tp2.dev.aws.renault.com/tp2/silent-refresh.html', // development
    'sta.tp2.dev': 'https://sta.tp2.dev.aws.renault.com/tp2/silent-refresh.html', // development
    're7.tp2.re7': 'https://re7.tp2.re7.aws.renault.com/tp2/silent-refresh.html', // re7
    'ope.tp2.ope': 'https://ope.tp2.ope.aws.renault.com/tp2/silent-refresh.html', // production environment,
  },
  issuerUrls: {
    'localhost:4200': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416',
    'int.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416',
    'qua.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416',
    'sta.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416',
    're7.tp2.re7': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416',
    'ope.tp2.ope': 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417',
  },
  loginUrls: {
    'localhost:4200': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
    'int.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
    'qua.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
    'sta.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
    're7.tp2.re7': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize',
    'ope.tp2.ope': 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/authorize',
  },
  userinfoEndpoints: {
    'localhost:4200': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/userinfo',
    'int.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/userinfo',
    'qua.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/userinfo',
    'sta.tp2.dev': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/userinfo',
    're7.tp2.re7': 'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/userinfo',
    'ope.tp2.ope': 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/userinfo',
  },
  clientIds: {
    'localhost:4200': 'irn-70003_uat_pkce_cteusgpoaijp',
    'int.tp2.dev':'irn-70003_uat_pkce_cteusgpoaijp',
    'qua.tp2.dev': 'irn-70003_uat_pkce_cteusgpoaijp',
    'sta.tp2.dev': 'irn-70003_uat_pkce_cteusgpoaijp',
    're7.tp2.re7': 'irn-70003_uat_pkce_cteusgpoaijp',
    'ope.tp2.ope': 'irn-70003_ope_pkce_2deckrju8wkt',
  },
  // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
  userIdleConfig: {
    idle: 1620, // is 1620 (27 minutes) 1620
    timeout: 180, // is 180 (3 minutes) 180
    ping: 60 // is 120 (2 minutes)
  },

  oAuthEnable: true,
  issuer: '',
  loginUrl: '',
  userinfoEndpoint: '',
  clientId: '',
  scope: 'openid legacy apis.default',
  responseType: 'code',
  redirectUri: '',
  silentRefreshRedirectUri: '',
  useSilentRefresh: true,
  allowedUrls: [ 'https://apis.renault.com' ],
  // Matomo/Piwik settings
  matomoUrl: 'https://piwik.renault-digital.com/',
  matomoId: 1541


};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
