import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Services } from './services.model';
import { map } from 'rxjs/operators';
import { GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public services: Services;
  public btnName: string;


  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getActiveSiteList(service: Services): Observable<Services> {
    return this.httpClient.post<Services>(this.apiURL + '/getActiveServicesByOrganisation', service);
  }

  getAllServices(services: Services) {
    return this.httpClient.post<Services[]>(this.apiURL + '/getServicesList', services).pipe(map(res => res));
  }

  updateServicesOrganisationList(services: Services) {
    return this.httpClient.put<Services>(this.apiURL + '/updateServicesOrganisationList', services, {
      observe: 'response'
    });
  }
  updateServicesList(services: Services) {
    return this.httpClient.put<Services>(this.apiURL + '/updateServicesList', services, {
      observe: 'response'
    });
  }
  getGridOptions() {
    return this.gridOptions;
  }
  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  createServicesList(services: Services) {
    return this.httpClient.post<Services>(this.apiURL + '/createServicesList', services, {
      observe: 'response'
    });
  }

  refreshGrid(services: Services) {
    services.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.getAllServices(services).subscribe((result: Services[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  setServices(gridServices: Services) {
    this.services = gridServices;
    if (this.services != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateServices() {
    return this.services;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

}
