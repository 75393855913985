import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenuModel } from './menu.model';

@Injectable({
    providedIn : 'root'
})
export class MenuService {

    private baseString: string;

    constructor(private http: HttpClient) {
        this.baseString = environment.apiURL;
    }

    getMenuActionByRoles(menuAccess: MenuModel): Observable<MenuModel> {
        return this.http.post<MenuModel>(this.baseString + '/getMenuActionByRoles/', menuAccess);
    }
}
