import { Directive, Input, OnChanges, SimpleChange, ElementRef } from "@angular/core";
import { SpinnerLoadingService } from "../spinner-loading/spinner-loading.service";

@Directive({
 selector: '[p-editor-model]'
})
export class PrimeNgEditorAutoscrollFixDirective implements OnChanges {
 @Input("p-editor-model") content: string;

 ngOnChanges(changes: { [property: string]: SimpleChange }) {
  let change = changes["content"];
  let elemPosition = this.element.nativeElement.getBoundingClientRect().top + document.body.scrollTop;
  let clientHeight = document.documentElement.clientHeight;

  if (change.isFirstChange() || elemPosition > clientHeight) {
   this.element.nativeElement.style.display = 'none';
   this.spinnerService.sendMessage('show');
   setTimeout(() => { this.element.nativeElement.style.display = ''; this.spinnerService.sendMessage('hide'); }, 750);
  }
 }

 constructor(private element: ElementRef, private spinnerService: SpinnerLoadingService) {
 }
}