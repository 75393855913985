import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { MessageService } from '../message.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonServices } from '../common.services';
declare var $: any;
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  uid: string;
  constructor(public translate: TranslateService,
    private oauthService: OAuthService, private messageService: MessageService,
    public dialog: MatDialog, public commonService: CommonServices) { }

  ngOnInit() {
    this.uid = sessionStorage.getItem('uid');
    $('.menu, .overlay').click(function () {
      $('.menu').toggleClass('clicked');
      $('#nav').toggleClass('show');
    });
  }

  confirmLogout(): void {
    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: 'SignOutContent.displayMessage'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.commonService.getFormChanged() || this.commonService.getFilterFormData()) {
            this.UpdateWarning();
          } else{
          this.logout();
          }
        }
        $('.menu').removeClass('clicked');
        $('#nav').removeClass('show');
      });
    }
  }

  logout() {
    sessionStorage.clear();
    this.messageService.sendMessage('logout');
    this.oauthService.logoutUrl = environment.authConfig.logoutUrl;
    this.oauthService.logOut();
  }
  UpdateWarning(): void {
    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: 'ExtractActivity.updateWarning'

            });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.logout();
    }
  });
  }
}
}
