import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../../../../environments/environment';

export const authConfig: AuthConfig = {
    // Urls of EndPoints
    issuer: environment.issuer,
    loginUrl: environment.loginUrl,
    userinfoEndpoint: environment.userinfoEndpoint,
    clientId: environment.clientId,
    scope: environment.scope,
    /* Common conf */
    redirectUri: environment.redirectUri,
    responseType: environment.responseType,
    // silentRefresh
	useSilentRefresh: true,
    silentRefreshRedirectUri:  environment.silentRefreshRedirectUri,
    silentRefreshShowIFrame: true,
    silentRefreshIFrameName: 'rnlt-angular-oauth-oidc-silent-refresh-iframe',
    showDebugInformation: false,
    sessionChecksEnabled: false,
	clearHashAfterLogin: false
};
