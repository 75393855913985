import { Language } from '../language/language.model';

export class Help {
    id: number;
    helpTopic: String;
    languageId: Language;
    createDate: Date;
    updateDate: Date;
    ipn: String;
    organisationId: number[];
}
export class Directory {
    id: number;
    directoryIpn: String;
    fname: String;
    lname: String;
    role: String;
    email: String;
    phoneNo: String;
    comment: String;
    createDate: Date;
    updateDate: Date;
    ipn: String;
    active: Boolean;
    organisationId: number[];
    profiles: number[];
    profileNames: String[];
}

export enum HelpModal {
    pdf = '.pdf',
    docx = '.docx'
}

export enum distributionList {
    fname = 'fname',
    lname = 'lname',
    directoryIpn = 'directoryIpn',
    dailyMailFlag = 'dailyMailFlag'
}
