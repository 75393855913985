export class Milestone {
    public id: number;
    public label: string;
    public color: string;
    public organisationId: number[];
    public active: boolean;
    public createDate: Date;
    public updateDate: Date;
    public ipn: string;
}

export enum colourPatch {
    white = '#ffffff',
    black = '#000000',
    blue = '##0000FF',
    red = '#F1441F'
  }

  export enum errorMessages {
      milestoneExist = 'Administration.Referential.Milestone.Already_Exist',
      colourExist = 'Errormessage.color_existing',
      colourWarning = 'Errormessage.color_warning',
      fail = 'F',
      success = 'S'
  }