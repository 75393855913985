import { MaintenanceSupplier } from '../maintenancesupplier/maintenancesupplier.model';
import { StopDuration } from '../stop-duration/stop-duration.model';

export class MaintenanceTask {
    id: number;
    maintenanceSupplierID: MaintenanceSupplier;
    periodicity: string;
	type: string;
    task: string;
    duration:StopDuration;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: Boolean;
    organisationId: number[];
}