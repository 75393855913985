export class Level2 {
    id: number;
    label: string;
    createDate: Date;
    updateDate: Date;
    ipn: String;
    active: Boolean;
    organisationId: number[];

}
