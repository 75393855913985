import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GridOptions } from 'ag-grid-community';
import { Exploitants } from './exploitants.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExploitantsService {

    public apiURL: string = environment.apiURL;
    public gridOptions: GridOptions;
    private exploitants: Exploitants;
    public btnName: string;

    constructor(private httpClient: HttpClient) {
        this.btnName = 'Common_Buttons.Add_Record';
    }

    getAllExploitant(exploitant: Exploitants) {
        return this.httpClient.post<Exploitants[]>(this.apiURL + '/getExploitantsList', exploitant).pipe(map(res => res));
    }

    createExploitant(exploitant: Exploitants) {
        return this.httpClient.post<Exploitants>(this.apiURL + '/createExploitant', exploitant, {
            observe: 'response'
        });
    }

    updateExploitant(exploitant: Exploitants) {
        return this.httpClient.put<Exploitants>(this.apiURL + '/updateExploitant', exploitant, {
            observe: 'response'
        });
    }

    updateExploitantOrganisationList(exploitant: Exploitants) {
        return this.httpClient.put<Exploitants>(this.apiURL + '/updateExploitantOrganisationList', exploitant, {
            observe: 'response'
        });
    }

    setExploitants(gridExploitants: Exploitants) {
        this.exploitants = gridExploitants;

        if (this.exploitants != null) {
            this.initUpdate();
        } else {
            this.initCreate();
        }
    }

    getGridOptions() {
        return this.gridOptions;
    }

    setGridOptions(gridOptions) {
        this.gridOptions = gridOptions;
    }

    initUpdate() {
        this.btnName = 'Common_Buttons.Update';
    }

    initCreate() {
        this.btnName = 'Common_Buttons.Add_Record';
    }

    getUpdateExploitants() {
        return this.exploitants;
    }

    /*
    This method was written for fetching only active values from the Exploitant table
    Tp105_Exploitant fot the US-US413 - As an administrator I want to be able to
    select the exploitant value while creating a testbed
    */
    getActiveExploitableList(exploitant: Exploitants) {
        return this.httpClient.post<Exploitants>(this.apiURL + '/getAllActiveExploitantList', exploitant);
      }

      checkColorDuplicate(dataList, checkVal): boolean {
        if (dataList) {
          const duplicateList = dataList.filter(dataVal => dataVal.color === checkVal.color);
          if (duplicateList && duplicateList.length > 0) {
        if (duplicateList.length > 1) {
            return true;
          } else if (duplicateList[0].id !== checkVal.id) {
            return true;
          }
        }}
}
}