import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'map-electrique',
  templateUrl: './map-electrique.component.html',
  styleUrls: ['./map-electrique.component.css']
})
export class MapElectriqueComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MapElectriqueComponent>, public dialog: MatDialog) { }

  ngOnInit() {
  }
  onClose(event?: any): void {
    this.dialogRef.close(event);
  }

  expMapElec() {
    window.open(environment.exploitationMapElectriqueOpeUrl);
  }

  receptionUo() {
    window.open(environment.exploitationMapElectriqueUOOpeUrl);
  }
}
