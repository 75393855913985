import { Injectable } from '@angular/core';
import { PrestationAdmin } from './prestationadmin.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GridOptions } from 'ag-grid-community';
import { Testbed } from '../../testbedreferential/testbed/testbed-model';

@Injectable({
  providedIn: 'root'
})
export class PrestationadminService {
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public prestationAdmin: PrestationAdmin;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;


  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllPrestationAdmin(prestationAdmin: PrestationAdmin) {
    return this.httpClient.post<PrestationAdmin[]>(this.apiURL + '/getPrestationAdminList', prestationAdmin).pipe(map(res => res));
  }

  updatePrestationAdminOrganisationList(prestationAdmin: PrestationAdmin) {
    return this.httpClient.put<PrestationAdmin>(this.apiURL + '/updatePrestationAdminOrganisationList', prestationAdmin, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }
  createPrestationAdminList(prestationAdmin: PrestationAdmin) {

    return this.httpClient.post<PrestationAdmin>(this.apiURL + '/savePrestationAdmin', prestationAdmin, {
      observe: 'response'
    });
  }
  updatePrestationAdminList(prestationAdmin: PrestationAdmin) {
    return this.httpClient.put<PrestationAdmin>(this.apiURL + '/updatePrestationAdmin', prestationAdmin, {
      observe: 'response'
    });
  }
  setPrestationAdmin(gridPrestationAdmin: PrestationAdmin) {
    this.prestationAdmin = gridPrestationAdmin;
    if (this.prestationAdmin != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdatePrestationAdmin() {
    return this.prestationAdmin;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  directUpdatePrestationAdmin(prestationAdmin: PrestationAdmin) {
    return this.httpClient.put<PrestationAdmin>(this.apiURL + '/directUpdatePrestationAdmin', prestationAdmin, {
      observe: 'response'
    });
  }

  getAllPrestationAdminByMean(testbed: Testbed) {
    return this.httpClient.post<PrestationAdmin[]>(this.apiURL + '/getAllPrestationAdminByMean', testbed).pipe(map(res => res));
  }
}
