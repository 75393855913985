import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Perimeters } from './perimeters.model';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PerimetersService {

  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public perimeter: Perimeters;
  public btnName: string;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllPerimeters(perimeter: Perimeters) {
    return this.httpClient.post<Perimeters[]>(this.apiURL + '/getPerimeterList', perimeter).pipe(map(res => res));
  }

  updatePerimeterOrganisationList(perimeter: Perimeters) {
    return this.httpClient.put<Perimeters>(this.apiURL + '/updatePerimeterOrganisationList', perimeter, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  createPerimeterList(perimeter: Perimeters) {
    return this.httpClient.post<Perimeters>(this.apiURL + '/createPerimeterList', perimeter, {
      observe: 'response'
    });
  }
  updatePerimeterList(perimeter: Perimeters) {
    return this.httpClient.put<Perimeters>(this.apiURL + '/updatePerimeterList', perimeter, {
      observe: 'response'
    });
  }

  getActivePerimeterList(perimeter: Perimeters) {
    return this.httpClient.post<Perimeters>(this.apiURL + '/getActivePerimeterByOrganisation', perimeter);
  }


  refreshGrid(perimeter: Perimeters) {
    this.getAllPerimeters(perimeter).subscribe((result: Perimeters[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  setPerimeter(gridPerimeter: Perimeters) {
    this.perimeter = gridPerimeter;

    if (this.perimeter != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdatePerimeter() {
    return this.perimeter;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

}
