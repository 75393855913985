import { Component, OnInit } from '@angular/core';
import { SuiviMoteurService } from 'src/app/saisie/crrpp/suivi-moteur/suivi-moteur.service';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { AgRendererComponent } from 'ag-grid-angular';
import { CrrppStaticValues } from 'src/app/saisie/crrpp/model/crrppStaticValues';

@Component({
  selector: 'custom-aggrid-single-checkbox',
  templateUrl: './custom-aggrid-single-checkbox.component.html',
  styleUrls: ['./custom-aggrid-single-checkbox.component.css']
})
export class CustomAggridSingleCheckboxComponent implements AgRendererComponent {

  constructor(private suiviService: SuiviMoteurService) { }
  public params: any;
  public paramValue: any;

  agInit(params: any): void {
    this.params = params;
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
  }

  refresh(params: any): boolean {
    params.data.clientSatisfactionNC = params.value;
    params.api.refreshCells(params);
    this.suiviService.changeMessage(CrrppStaticValues.refreshMessage);
    this.suiviService.setInitTabGridDirty(true);
    return false;
  }
}
