export class TestbedExtractModel {
    site: number[];
    means: number[];
    testbed: number[];
    orgId: number[];
}
export enum TestbedExtractEnum {
    testNumberlimit = 10000,
    deatm = 'deatm',
    deatv = 'deatv',
    orgId = 'organisationIDs',
    testbedExtract = 'testbedExtract',
    true = 'true'
}

