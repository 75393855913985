export class Fuel {
    public id: number;
    public label: string;
    public organisationId: number[];
    public active: boolean;
    public createDate: Date;
    public updateDate: Date;
    public ipn: string;
}

export enum errorMessages {
    fuelExist = 'Administration.Referential.Fuel.Already_Exists',
    fail = 'F',
    successMsg = 'Common_Buttons.Success',
    success = 'S',
    orgId = 'organisationIDs',
    add = 'Common_Buttons.Add_Record',
    update = 'Common_Buttons.Update'
}
