import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { MapModel } from 'src/app/administration/referentiel/means/map.model';
import { MeansService } from 'src/app/administration/referentiel/means/means.service';
import { MultiSelectService } from './multi-select.service';
import { TestbedGlobalInfo } from 'src/app/administration/testbedreferential/testbed/global-info/testbed-global-info.model';
import { ActivateService } from 'src/app/saisie/activate/activate.service';
import { CommonServices } from '../common.services';
import { MultiSelectEnum, MultiSelectModel } from './muti-select.model';
import { Site } from 'src/app/administration/referentiel/sites/site.model';
import { TestBedStatus } from 'src/app/etat/statut/statut.model';
import { PreferencesService } from '../help/preferences/preferences.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Means } from 'src/app/administration/referentiel/means/means.model';


@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements OnInit {

  @ViewChild('selectMap') selectMap: MatSelect;
  @ViewChild('selectSite') selectSite: MatSelect;
  @ViewChild('selectMean') selectMean: MatSelect;
  @ViewChild('selectBuilding') selectBuilding: MatSelect;
  allSelectedMap: boolean = false;
  allSelectedMean: boolean = false;
  allSelectedSite: boolean = false;
  allSelectedBuilding: boolean = false;
  buildingFlag: boolean = false;
  mapTempFlag: any = null;
  siteTempFlag: any = null;
  meanTempFlag: any = null;
  BuildingTempFlag: any = null;

  public map: MapModel[];
  public selectedMap: string[];
  public sites: TestbedGlobalInfo[];
  public buildings: TestbedGlobalInfo[];
  public sitesFullData: Site[];
  public selectedSite: string[];
  public selectedBuilding: string[];
  public selectedMean: string[];
  selectedSiteIds: number[];
  selectedBuildingIds: number[];
  selectedMeanIds: number[];
  selectedMapIds: number[];
  tempSourceVar = '';
  @Output() b = new EventEmitter();
  public multiFilter: FormGroup;
  public means: TestbedGlobalInfo[];
  filterMeanCombo: any[];
  filterSiteCombo: any[];
  filterBuildingCombo: any[];
  constructor(private fb: FormBuilder,
    private meanService: MeansService,
    private multiService: MultiSelectService,
    private activityService: ActivateService,) { }

  ngOnInit() {
    this.mapList();
    this.meansList();
    this.siteList();
    this.buildingList();
    this.buildingFlag=window.location.href.toString().includes('TestbedStatus')?true:false;
    
    this.multiFilter = this.fb.group(
      {
        maps: [''],
        mean: [''],
        site: [''],
        buildings:[''],
      });
  }
  get f() { return this.multiFilter.controls; }

  // getting list of all Maps
  mapList() {
    this.selectedMapIds = [];
    this.selectedMap = [];
    this.meanService.getMapList().subscribe(data => {
      this.map = data.sort((a, b) => a.label.localeCompare(b.label.toString()));
    });
  }
  meansList() {
    this.selectedMeanIds = [];
    this.selectedMean = [];
    this.multiService.getMeansComboListStatus().subscribe(data => {
      this.means = data.sort((a, b) => a[1].localeCompare(b[1].toString()));
    })
  }
  // getting list of Sites that are associated with testbed formed
  siteList() {
    this.selectedSiteIds = [];
    this.selectedSite = [];
    this.activityService.getSiteComboList().subscribe(data => {
      this.sites = data.sort((a, b) => a[1].localeCompare(b[1].toString()));
      this.preferredSite(this.sites);
    });
  }
  buildingList() {
    this.selectedBuildingIds = [];
    this.selectedBuilding = [];
    this.activityService.getBuildingsList().subscribe(data => {
      this.buildings = data.sort((a, b) => a[1].localeCompare(b[1].toString()));
      // this.preferredSite(this.sites);
    });
  }

  // when value in Site dropdown is changed
  onSiteChange(e) {
    this.selectedSiteIds = [];
    let newStatus = true;
    this.selectSite.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedSite = newStatus;
    const selesite = e.value;
    // for getting the values of all the site chosen in array
    if (selesite.length > 0) {
      selesite.forEach(element => {
        this.selectedSiteIds.push(element[0]);
        this.selectedSite.push(element[1]);
      });
    } else {
      this.selectedSiteIds = [];
      this.selectedSite = [];
    }
    // storing the data in the MultiSelect model
    const multiSelect = new MultiSelectModel();
    multiSelect.sites = this.selectedSiteIds;
    multiSelect.orgId = JSON.parse(sessionStorage.getItem(MultiSelectEnum.orgId));
    this.multiService.siteIdMapping = this.selectedSiteIds;
    if (this.selectedMap === undefined || this.selectedMap === ['']) {
      multiSelect.maps = [];
    } else {
      multiSelect.maps = this.selectedMapIds;
    }
    if (this.selectedMean === undefined || this.selectedMean === ['']) {
      multiSelect.mean = [];
    } else {
      multiSelect.mean = this.selectedMeanIds;
    }
    if (this.selectedBuilding===undefined||this.selectedBuilding===[''])
    {
      multiSelect.building=[];
    }else
    {
      multiSelect.building=this.selectedBuildingIds;
    }
    // setting the model data to be used in testbedStatus
    if (this.siteTempFlag !== null) {
      if (this.siteTempFlag === true && this.sites.length !== selesite.length) return;
      else if (this.siteTempFlag === false && selesite.length !== 0) return;
    }
    multiSelect.building=this.newBuildingFilter(multiSelect);
    this.siteTempFlag = null;
    this.multiService.setMapData(multiSelect);
    this.b.emit();
  }

  onBuildingChange(e) {
    this.selectedBuildingIds = [];
    let newStatus = true;
    this.selectBuilding.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedBuilding = newStatus;
    const selcBuilding = e.value;
    // for getting the values of all the site chosen in array
    if (selcBuilding.length > 0) {
      selcBuilding.forEach(element => {
        this.selectedBuildingIds.push(element[0]);
        this.selectedBuilding.push(element[1]);
      });
    } else {
      this.selectedBuildingIds = [];
      this.selectedBuilding = [];
    }
    // storing the data in the MultiSelect model
    const multiSelect = new MultiSelectModel();
    multiSelect.building = this.selectedBuildingIds;
    multiSelect.orgId = JSON.parse(sessionStorage.getItem(MultiSelectEnum.orgId));
    this.multiService.BuildingIdMapping = this.selectedBuildingIds;
    if (this.selectedMap === undefined || this.selectedMap === ['']) {
      multiSelect.maps = [];
    } else {
      multiSelect.maps = this.selectedMapIds;
    }
    if (this.selectedMean === undefined || this.selectedMean === ['']) {
      multiSelect.mean = [];
    } else {
      multiSelect.mean = this.selectedMeanIds;
    }
    if(this.selectedSite=== undefined||this.selectedSite===[''])
    {
      multiSelect.sites=[];
    }
    else
    {
      multiSelect.sites=this.selectedSiteIds; 
    }
    // setting the model data to be used in testbedStatus
    if (this.BuildingTempFlag !== null) {
      if (this.BuildingTempFlag === true && this.buildings.length !== selcBuilding.length) return;
      else if (this.BuildingTempFlag === false && selcBuilding.length !== 0) return;
    }
    this.BuildingTempFlag = null;
    this.multiService.setMapData(multiSelect);
    this.b.emit();
  }

  selectAllMapChange() {
    this.mapTempFlag = this.allSelectedMap;
    if (this.allSelectedMap) {
      this.selectMap.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectMap.options.forEach((item: MatOption) => item.deselect());
    }
  }

  selectAllMeanChange() {
    this.meanTempFlag = this.allSelectedMean;
    if (this.allSelectedMean) {
      this.selectMean.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectMean.options.forEach((item: MatOption) => item.deselect());
    }
  }

  selectAllSiteChange() {
    this.siteTempFlag = this.allSelectedSite;
    if (this.allSelectedSite) {
      this.selectSite.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectSite.options.forEach((item: MatOption) => item.deselect());
    }
  }

  selectAllBuildingChange() {
    this.BuildingTempFlag = this.allSelectedBuilding;
    if (this.allSelectedBuilding) {
      this.selectBuilding.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectBuilding.options.forEach((item: MatOption) => item.deselect());
    }
  }
  // when value in Map dropdown is changed
  onMapChange(e) {
    this.selectedMapIds = [];
    this.selectedMap = [];
    let newStatus = true;
    this.selectMap.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedMap = newStatus;
    this.allSelectedSite = false;
    const selMap = e.value;
    // for getting the values of all the site chosen in array
    if (selMap.length > 0) {
      selMap.forEach(element => {
        this.selectedMapIds.push(element.id);
        this.selectedMap.push(element.label);
      });
    } else {
      this.selectedMapIds = [];
      this.selectedMap = [];
    }
    this.multiService.mapIdMapping = this.selectedMapIds;
    // storing the data in the MultiSelect model
    if (this.mapTempFlag !== null) {
      if (this.mapTempFlag === true && this.map.length !== selMap.length) return;
      else if (this.mapTempFlag === false && selMap.length !== 0) return;
    }
    const multiSelect = new MultiSelectModel();
    multiSelect.maps = this.selectedMapIds;
    multiSelect.orgId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    // once map value is selected Site dropdown is filtered accordingly
    if (this.selectedSite === undefined || this.selectedSite === ['']) {
      multiSelect.sites = [];
    } else {
      multiSelect.sites = this.selectedSiteIds;
    }
    if (this.selectedMean === undefined || this.selectedMean === ['']) {
      multiSelect.mean = [];
    } else {
      multiSelect.mean = this.selectedMeanIds;
    }
    if (this.selectedBuilding===undefined||this.selectedBuilding===[''])
    {
      multiSelect.building=[];
    }else
    {
      multiSelect.building=this.selectedBuildingIds;
    }
    multiSelect.mean = this.newMeanFilter(multiSelect);
    multiSelect.sites = this.newSiteFilter(multiSelect);
    multiSelect.building=this.newBuildingFilter(multiSelect);
    this.mapTempFlag = null;
    this.multiService.setMapData(multiSelect);
    this.b.emit();
  }

  // Site filtered according to the map value
  newSiteFilter(value: any) {
    this.multiService.getFilterSite(value).subscribe((data: TestbedGlobalInfo[]) => {
      this.sites = data.sort((a, b) => a[1].localeCompare(b[1].toString()));
      if (this.multiService.siteIdMapping) {
        this.selectedSiteIds = [];
        this.selectedSite = [];
        this.filterSiteCombo = [];
        let dataSite = [];
        let temp = 0;
        this.multiService.siteIdMapping.forEach(res => {
          if (this.sites.filter(sites => sites[0] === res).length > 0) {
            this.filterSiteCombo[temp] = this.sites.filter(sites => sites[0] === res)[0];
            this.selectedSiteIds[temp] = this.sites.filter(sites => sites[0] === res)[0][0];
            this.selectedSite[temp] = this.sites.filter(sites => sites[0] === res)[0][1];
            temp++;
          }
        });
        dataSite = this.filterSiteCombo;
        this.multiFilter.patchValue({ site: dataSite });
      } else {
        this.selectedSite = [];
        this.selectedSiteIds = [];
        this.multiFilter.patchValue({ site: [] });
      }
    });
    this.multiService.siteIdMapping = this.selectedSiteIds;
    return this.selectedSiteIds;
  }

  preferredSite(data: any) {
    const multiSelectData = this.multiService.getMapData();
    this.selectedSiteIds = [];
    this.selectedSite = [];
    if (multiSelectData) {
      this.multiFilter.patchValue({ site: data.filter((siteData: any) => siteData[0] === multiSelectData.sites[0]) });
      if (multiSelectData.sites.length > 0) {
        this.selectedSiteIds = multiSelectData.sites;
      }
      this.multiService.setMapData(multiSelectData);
      this.b.emit();
    } else {
      this.multiService.setMapData(undefined);
    }
    this.multiService.siteIdMapping = this.selectedSiteIds;
  }

  onMeanChange(e) {
    this.selectedMeanIds = [];
    this.selectedMean = [];
    let newStatus = true;
    this.selectMean.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelectedMean = newStatus;
    const selemean = e.value;
    // for getting the values of all the site chosen in array
    if (selemean.length > 0) {
      selemean.forEach(element => {
        this.selectedMeanIds.push(element[0]);
        this.selectedMean.push(element[1]);
      });
    } else {
      this.selectedMeanIds = [];
      this.selectedMean = [];
    }
    this.multiService.meanIdMapping = this.selectedMeanIds;
    // storing the data in the MultiSelect model
    const multiSelect = new MultiSelectModel();
    multiSelect.mean = this.selectedMeanIds;
    multiSelect.orgId = JSON.parse(sessionStorage.getItem(MultiSelectEnum.orgId));

    if (this.selectedMap === undefined || this.selectedMap === ['']) {
      multiSelect.maps = [];
    } else {
      multiSelect.maps = this.selectedMapIds;
    }
    if (this.selectedSite === undefined || this.selectedSite === ['']) {
      multiSelect.sites = [];
    } else {
      multiSelect.sites = this.selectedSiteIds;
    }
    if (this.selectedBuilding===undefined||this.selectedBuilding===[''])
    {
      multiSelect.building=[];
    }else
    {
      multiSelect.building=this.selectedBuildingIds;
    }
    // setting the model data to be used in testbedStatus
    if (this.meanTempFlag !== null) {
      if (this.meanTempFlag === true && this.sites.length !== selemean.length) return;
      else if (this.meanTempFlag === false && selemean.length !== 0) return;
    }
    multiSelect.sites = this.newSiteFilter(multiSelect);
    multiSelect.building=this.newBuildingFilter(multiSelect);
    this.meanTempFlag = null;
    this.multiService.setMapData(multiSelect);
    this.b.emit();
  }

  newMeanFilter(value: any) {
    this.multiService.getFilterMean(value).subscribe((data: TestbedGlobalInfo[]) => {
      this.means = data.sort((a, b) => a[1].localeCompare(b[1].toString()));
      if (this.multiService.meanIdMapping) {
        this.selectedMeanIds = [];
        this.selectedMean = [];
        this.filterMeanCombo = [];
        let dataMean = [];
        let temp = 0;
        this.multiService.meanIdMapping.forEach(res => {
          if (this.means.filter(means => means[0] === res).length > 0) {
            this.filterMeanCombo[temp] = this.means.filter(means => means[0] === res)[0];
            this.selectedMeanIds[temp] = this.means.filter(means => means[0] === res)[0][0];
            this.selectedMean[temp] = this.means.filter(means => means[0] === res)[0][1];
            temp++;
          }
        });
        dataMean = this.filterMeanCombo;
        this.multiFilter.patchValue({ mean: dataMean });
      }
      else {
        this.selectedMean = [];
        this.selectedMeanIds = [];
        this.multiFilter.patchValue({ mean: [] });
      }

    });
    this.multiService.meanIdMapping = this.selectedMeanIds;
    return this.selectedMeanIds;
  }
  newBuildingFilter(value: any) {
    this.multiService.getFilterBulding(value).subscribe((data: TestbedGlobalInfo[]) => {
      this.buildings = data.sort((a, b) => a[1].localeCompare(b[1].toString()));
      if (this.multiService.BuildingIdMapping) {
        this.selectedBuildingIds = [];
        this.selectedBuilding = [];
        this.filterBuildingCombo = [];
        let databuilding = [];
        let temp = 0;
        this.multiService.BuildingIdMapping.forEach(res => {
          if (this.buildings.filter(buildings => buildings[0] === res).length > 0) {
            this.filterBuildingCombo[temp] = this.buildings.filter(buildings => buildings[0] === res)[0];
            this.selectedBuildingIds[temp] = this.buildings.filter(buildings => buildings[0] === res)[0][0];
            this.selectedBuilding[temp] = this.buildings.filter(buildings => buildings[0] === res)[0][1];
            temp++;
          }
        });
        databuilding = this.filterBuildingCombo;
        this.multiFilter.patchValue({ buildings: databuilding });
      }
      else {
        this.selectedBuilding = [];
        this.selectedBuildingIds = [];
        this.multiFilter.patchValue({ mean: [] });
      }

    });
    this.multiService.BuildingIdMapping = this.selectedBuildingIds;
    return this.selectedBuildingIds;
  }
  // to check
}
