import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BarPrestation } from './bwrprestation.model';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { Site } from '../../referentiel/sites/site.model';
@Injectable({
  providedIn: 'root'
})
export class BarPrestationService {

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public barPrestation: BarPrestation;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;

  getAllBarProgramLines(barPrestationLines: BarPrestation) {
    return this.httpClient.post<BarPrestation[]>(this.apiURL + '/getBarPrestationList', barPrestationLines).pipe(map(res => res));

  }


  updateBarProgramLinesOrganisationList(barPrestationLines: BarPrestation) {
    return this.httpClient.put<BarPrestation>(this.apiURL + '/updateBarprestationOrganisationList', barPrestationLines, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  createBarProgramLinesList(barPrestationLines: BarPrestation) {

    return this.httpClient.post<BarPrestation>(this.apiURL + '/saveBarprestationline', barPrestationLines, {
      observe: 'response'
    });
  }

  updateBarProgramLinesList(barPrestationLines: BarPrestation) {
    return this.httpClient.put<BarPrestation>(this.apiURL + '/updateBarPrestationLines', barPrestationLines, {
      observe: 'response'
    });
  }

  setBarProgramLines(gridbarPrestationLines: BarPrestation) {
    this.barPrestation = gridbarPrestationLines;
    if (this.barPrestation != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateBarProgramLines() {
    return this.barPrestation;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
