export enum TabItems {
  site = 'Site',
  buildingList = 'BuildingList',
  meanList = 'MeanList',
  essaiBancList = 'EssaiBancList',
  campaignList = 'CampaignList',
  clientDirectionList = 'ClientDirectionList',
  teamList = 'TeamList',
  perimeterList = 'PerimeterList',
  batchList = 'BatchList',
  campaignAssociationList = 'CampaignAssociationList',
  servicesList = 'ServicesList',
  specimenTypeList = 'SpecimenTypeList',
  rsTypeList = 'RsTypeList',
  mobileDeviceList = 'MobileDeviceList',
  milestoneList = 'MilestoneList',
  shifts = 'Shifts',
  fuel = 'Fuel'
}
export enum constants {
  rsTypeId = 'RsType',
  mobileDeviceTab = 'MobileDevice',
  orgId = 'organisationIDs',
  create = 'Create',
  update = 'Update',
  milestoneTab = 'Milestone',
  true = 'true',
  successMsg = 'Common_Buttons.Success',
  success = 'S',
  uid = 'uid',
  user = 'User',
  macroActivityTab = 'MacroActivity',
  updateBtn = 'Common_Buttons.Update',
  dash = '-',
  dateFormat = 'DD/MM/YYYY',
  ValidationNumberPatter = '[0-9]{0,10}',
  shifts  = 'Shifts',
  defaultHours = '8'
}