import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { StopDuration } from './stop-duration.model';

@Injectable({
  providedIn: 'root'
})
export class StopDurationService {
  data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public stopDuration: StopDuration;
  public btnName: string;
  public showDeleteBtn: Boolean = true;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }
  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  getStopDurationList(stopDuration: StopDuration) {
    return this.httpClient.post<StopDuration[]>(this.apiURL + '/getStopDurationList', stopDuration).pipe(map(res => res));
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  refreshGrid(stopDuration: StopDuration) {
    this.getStopDurationList(stopDuration).subscribe((result: StopDuration[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  createStopDurationList(stopDuration: StopDuration) {
    return this.httpClient.post<StopDuration>(this.apiURL + '/createStopDurationList', stopDuration, {
      observe: 'response'
    });
  }

  setStopDuration(gridStopDuration: StopDuration) {
    this.stopDuration = gridStopDuration;

    if (this.stopDuration != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateStopDuration() {
    return this.stopDuration;
  }

  updateStopDurationList(stopDuration: StopDuration) {
    return this.httpClient.put<StopDuration>(this.apiURL + '/updateStopDurationList', stopDuration, {
      observe: 'response'
    });
  }

  updateStopDurationOrganisationList(stopDuration: StopDuration) {
    return this.httpClient.put<StopDuration>(this.apiURL + '/updateStopDurationOrganisationList', stopDuration, {
      observe: 'response'
    });
  }
}
