import { Means } from 'src/app/administration/referentiel/means/means.model';
import { Level0 } from 'src/app/administration/levelreferential/level0/level0.model';
import { Level1 } from 'src/app/administration/levelreferential/level1/level1.model';
import { Procedure } from 'src/app/administration/prestationsandprocedures/procedure/procedure.model';
import { Prestation } from 'src/app/administration/prestationsandprocedures/prestation/prestation.model';
import { CustomerDirection } from 'src/app/administration/referentiel/customer/customer.model';
import { Level2 } from 'src/app/administration/levelreferential/level2/level2.model';
import { Campaign } from 'src/app/administration/referentiel/campaign/campaign.model';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { Exploitable } from 'src/app/administration/levelreferential/levelsadmin/exploitable.model';
import { RsType } from 'src/app/administration/referentiel/rs-type/rs-type.model';
import { MacroActivity } from 'src/app/administration/prestationsandprocedures/macro-activity/macro-activity.model';

export class Activity {
    id: number;
    activityDate: Date;
    means: Means;
    level0: Level0;
    level1: Level1;
    level2: Level2;
    procedures: Procedure;
    prestations: Prestation;
    campaigns: Campaign;
    typeOfTest: string;
    duration: number;
    exploitable: Exploitable;
    customerDirection: CustomerDirection;
    calibration: string;
    comment: string;
    testBedId: Testbed;
    validated: boolean;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    organisationId: number[];
    seqNum: number;
    customerRequest: string;
    rsType: RsType;
    macroActivity: MacroActivity;
}

export enum ActivityEnum {
    EmissionCD = 'Emission C/D',
    TuningED = 'Tuning E/D',
    dateFormat = 'DD/MM/YYYY',
    datesuffix = 'T00:00:00',
    stdDateFromat = 'YYYY-MM-DD',
    RefreshGrid = 'refreshGrid',
    dash = '-',
    TuningActivity = 'Tuning E/D',
    FebNum = 'febNb',
    EmissionCDLabel = 'EmissionCD',
    TuningEDLabel = 'TuningED',
    meanFilter = 'MeanFilter',
    siteFilter = 'SiteFilter',
    testbedFilter = 'TestbedFilter',
    create = 'Create',
    multipleDuplicate = 'MultipleDuplicate',
    multipleUpdate = 'MultipleUpdate',
    submit = 'submit',
    crrpp = 'crrpp',
    crrppextract = 'crrppExtract',
    copyActivity = 'copy',
    fix= 'fix',
    delete = 'delete',
    release = 'release',
    astral= 'astral',
    ipn = 'ipn',
    textField = 'textField',
    MONITORING = 'MONITORING',
    VSD = 'VSD',
    ASTREINTE = 'ASTREINTE',
    Brap = 'BRAP',
    BrapEngHours = 'Brap.engHours',
    BrapEngNum = 'Brap.engNum',
    EngineNb = 'engineNb',
    SansOt = 'SANS OT',
    OtDateFormat = 'dd/MM/yyyy',
    astralServiceDateFormat = 'dd-MM-yy',
    CL = 'CL',
    FT = 'FT',
    ELECPUISSANCE = 'ELEC PUISSANCE',
    CARACTERISATION = 'CARACTERISATION'
}


