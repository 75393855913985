import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BredcrumbComponent } from './common-button/bredcrumb/bredcrumb.component';
import { CommonButtonModule } from './common-button/common-button.module';
import { I18nModule } from './i18n/i18n.module';
import { NavTabsModule } from './nav-tabs/nav-tabs.module';
import { HeaderComponent } from './header/header.component';
import { LanguageComponent } from './language/language.component';
import { MenuComponent } from './menu/menu.component';
import { ErrorComponent } from './error/error.component';
import { SpinnerLoadingComponent } from './spinner-loading/spinner-loading.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { CommonTpmRoutingModule } from './common-tpm-routing.module';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { LogoutpageComponent } from './logoutpage/logoutpage.component';
import { HomeComponent } from './home/home.component';
import { AgreementPopupComponent } from './agreement-popup/agreement-popup.component';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { DynamicFormBaseComponent } from './dynamic-form/dynamic-form-base/dynamic-form-base.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { ExportComponent } from './export/export.component';
import { CustomListboxComponent } from './custom-listbox/custom-listbox.component';
import { UpperCaseInputDirective } from './validations/UpperCaseInputDirective';
import { OnlynumberDirective } from './validations/OnlynumberDirective';
import { PrimeNgModule } from './prime-ng/primeNg.module';
import { CustomAutofocusDirective } from './validations/customAutofocusDirective';
import { DisableControlDirective } from './validations/disableControlDirective';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { TestbedExtractComponent } from './testbed-extract/testbed-extract.component';
import { CustomAggridCheckboxComponent } from './custom-aggrid-checkbox/custom-aggrid-checkbox.component';
import { CustomAggridDropdownComponent } from './custom-aggrid-dropdown/custom-aggrid-dropdown.component';
import { CustomAggridSingleCheckboxComponent } from './custom-aggrid-single-checkbox/custom-aggrid-single-checkbox.component';
import { PrimeNgEditorAutoscrollFixDirective } from './directives/PrimeNgEditorAutoscrollFixDirective';
import { SpotFireComponent } from './spot-fire/spot-fire.component';
import { SpotFireExploitationComponent } from './spot-fire/spot-fire-exploitation/spot-fire-exploitation.component';
import { SpotFirePerformanceComponent } from './spot-fire/spot-fire-performance/spot-fire-performance.component';
import { SpotFireProjetComponent } from './spot-fire/spot-fire-projet/spot-fire-projet.component';
import { SpotFireMaintenanceComponent } from './spot-fire/spot-fire-maintenance/spot-fire-maintenance.component';
import { MapEnergetiqueComponent } from './spot-fire/spot-fire-exploitation/map-energetique/map-energetique.component';
import { MapMecaniqueComponent } from './spot-fire/spot-fire-exploitation/map-mecanique/map-mecanique.component';
import { EnergetiqueBancMoteur } from './spot-fire/spot-fire-exploitation/map-energetique/energetique-banc-moteur/energetique-banc-moteur.component';
import { MapElectriqueComponent } from './spot-fire/spot-fire-exploitation/map-electrique/map-electrique.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { SpotFireTVComponent } from './spot-fire/spot-fire-tv/spot-fire-tv.component';
import { DisableCopyPasteDirective } from './validations/disableCopyPasteDirective';
import { RedirectactionComponent } from './redirectaction/redirectaction.component';

@NgModule({
  declarations: [
    BredcrumbComponent,
    HeaderComponent,
    LanguageComponent,
    MenuComponent,
    ErrorComponent,
    SpinnerLoadingComponent,
    SnackbarComponent,
    LogoutComponent,
    LoginComponent,
    LogoutpageComponent,
    HomeComponent, AgreementPopupComponent, ConfirmationDialogComponent, DynamicFormComponent, DynamicFormBaseComponent,
    ExportComponent,
    CustomListboxComponent, UpperCaseInputDirective, DisableCopyPasteDirective,
    OnlynumberDirective,
    CustomAutofocusDirective,
    DisableControlDirective,
    MultiSelectComponent,
    TestbedExtractComponent,
    CustomAggridCheckboxComponent,
    CustomAggridDropdownComponent,
    CustomAggridSingleCheckboxComponent,
    PrimeNgEditorAutoscrollFixDirective,
    SpotFireComponent,
    SpotFireExploitationComponent,
    SpotFirePerformanceComponent,
    SpotFireProjetComponent,
    SpotFireMaintenanceComponent,
    MapEnergetiqueComponent,
    MapMecaniqueComponent,
    EnergetiqueBancMoteur,
    MapElectriqueComponent,
    StarRatingComponent,
    SpotFireTVComponent,
    RedirectactionComponent

  ],
  imports: [
    CommonModule,
    CommonButtonModule,
    I18nModule,
    NavTabsModule,
    CommonTpmRoutingModule,
    MaterialModule,
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    BredcrumbComponent, SpinnerLoadingComponent, HeaderComponent, SnackbarComponent, LanguageComponent,
    DynamicFormComponent, DynamicFormBaseComponent, ExportComponent, CustomListboxComponent,
    UpperCaseInputDirective, OnlynumberDirective, CustomAutofocusDirective, DisableControlDirective,
    DynamicFormComponent, DynamicFormBaseComponent, ExportComponent, CustomListboxComponent,
    UpperCaseInputDirective, OnlynumberDirective, MultiSelectComponent, PrimeNgEditorAutoscrollFixDirective, DisableCopyPasteDirective,
    HomeComponent, RedirectactionComponent
  ],
  entryComponents: [AgreementPopupComponent, ConfirmationDialogComponent, TestbedExtractComponent, CustomAggridCheckboxComponent,
    CustomAggridDropdownComponent, CustomAggridSingleCheckboxComponent, SpotFireComponent, SpotFirePerformanceComponent, SpotFireExploitationComponent,
    SpotFireMaintenanceComponent, SpotFireProjetComponent, EnergetiqueBancMoteur, MapEnergetiqueComponent, MapMecaniqueComponent, MapElectriqueComponent, StarRatingComponent,
    SpotFireTVComponent]

})
export class CommontpmModule { }
