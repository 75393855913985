import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StarRatingModel } from './star-rating.model';
import {MatIconModule} from '@angular/material/icon';
import { StarRatingService } from './star-rating.service';
import { MatDialogRef } from '@angular/material';

 

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css']
})
export class StarRatingComponent implements OnInit {

  rating = 0;
  starCount = 4;
  submitted = false;
  ratingArr: boolean[] = []; // true = solid star; false = empty star
  ratingForm: FormGroup
  commetWithTwoOrLessRating=false;
  constructor(private fb: FormBuilder,
  private starRatingService: StarRatingService,
  public dialogRef: MatDialogRef<StarRatingComponent>) {
  this.ratingArr = Array(this.starCount).fill(false);
   
   }

  ngOnInit() {
    this.ratingForm = this.fb.group({
      star: ['', Validators.required],
      comment: ''
    })
  }

  get f() { return this.ratingForm.controls; }

  returnStar(i: number) {
  
    if (this.rating >= i + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  onClick(i: number) {
    this.submitted = true
    this.rating = i + 1;
    this.ratingForm.patchValue({ star: this.rating });

  }
  onSave() {

    if(this.ratingForm.controls.star.value <= 2 )
    {
      if(this.ratingForm.controls.comment.value=="")
      {
      this.commetWithTwoOrLessRating=true;
      }
      else{
        this.commetWithTwoOrLessRating=false;
      }
    }
    else{
     this.commetWithTwoOrLessRating=false;
   }
   if(this.commetWithTwoOrLessRating==false)
   {
    if (this.ratingForm.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      const starRating = new StarRatingModel();
      starRating.starRating = this.ratingForm.controls.star.value;
      starRating.comment = this.ratingForm.controls.comment.value;
      starRating.ipn = sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : 'User';
      this.starRatingService.saveStarRating(starRating).subscribe(res => {
      this.dialogRef.close();
      })
    }
  }
  }

  onChangeComment()
  {
    
    if(this.ratingForm.controls.star.value <= 2)
    {
      this.commetWithTwoOrLessRating=true;
    }
    else{
     this.commetWithTwoOrLessRating=false;
   }
  }
  onCancel() {
    this.dialogRef.close();
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
