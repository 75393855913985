import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ValidationSpecimen } from './validation-specimen.model';
import { ValidationSpecimenSequence } from './validation-specimen-ts-list/validation-specimen-ts-list.model';

@Injectable({
  providedIn: 'root'
})
export class ValidationSpecimenService {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public validationSpecimen: ValidationSpecimen;
  public btnName: string;
  public seqBtnName: String;
  public id: number;
  public alreadyUsedData: Boolean;
  public isMappedWithActivity: Boolean;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';

  }

  getValidationSpecimenData(validationSpecimen: ValidationSpecimen) {
    return this.httpClient.post<ValidationSpecimen[]>(this.apiURL + '/getValidationSpecimenList', validationSpecimen).pipe(map(res => res));
  }

  updateValidationSpecimenOrganisationList(validationSpecimen: ValidationSpecimen) {
    return this.httpClient.put<ValidationSpecimen>(this.apiURL + '/updateValidationSpecimenOrganisationList', validationSpecimen, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(validationSpecimen) {
    this.getValidationSpecimenData(validationSpecimen).subscribe((result: ValidationSpecimen[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  createValidationSpecimenList(validationSpecimen: ValidationSpecimen) {
    return this.httpClient.post<ValidationSpecimen>(this.apiURL + '/saveValidationSpecimen', validationSpecimen, {
      observe: 'response'
    });
  }

  checkDuplicateValidationSpecimen(validationSpecimen: ValidationSpecimen) {

    return this.httpClient.post<ValidationSpecimen>(this.apiURL + '/checkDuplicateValidationSpecimen', validationSpecimen, {

      observe: 'response'

    });
  }

  updateValidationSpecimenList(validationSpecimen: ValidationSpecimen) {
    return this.httpClient.put<ValidationSpecimen>(this.apiURL + '/updateValidationSpecimen', validationSpecimen, {
      observe: 'response'
    });
  }

  setValidationSpecimen(gridValidationSpecimen: ValidationSpecimen) {
    this.validationSpecimen = gridValidationSpecimen;
    if (this.validationSpecimen != null) {
      this.initUpdate();
      this.initTestSequence();
    } else {
      this.initCreate();
    }

  }
  getUpdateValidationSpecimen() {
    return this.validationSpecimen;
  }
  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
  initTestSequence() {
    this.seqBtnName = 'Common_Buttons.Test_Sequence';
  }

  /*For US563 - As a user I shouldn't be able to modify a VS if it is used by a test activity */

  checkEngNumMapping(validationSpecimen: ValidationSpecimen) {
    return this.httpClient.post<any>(this.apiURL + '/checkEngNumMapping', validationSpecimen, {
      observe: 'response'
    });
  }

  getMappedDataFromActivity() {
    return this.isMappedWithActivity;
  }
  setMappedDataFromActivity(isMappedWithActivity: Boolean) {
    this.isMappedWithActivity = isMappedWithActivity;
  }

  datelocalSuffix(localDate?) {
    if (!localDate) {
      localDate = new Date();
    }
    const dateStr = JSON.stringify(new Date());
    const dateSplitA = dateStr.split('T');
    const replaceDateSrt = 'T' + dateSplitA[1].substr(0, dateSplitA[1].length - 1);
    return replaceDateSrt;
  }


}
