import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Teams } from './teams.model';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public team: Teams;
  public btnName: string;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllTeams(team: Teams) {
    return this.httpClient.post<Teams[]>(this.apiURL + '/getTeamList', team).pipe(map(res => res));
  }

  updateTeamOrganisationList(team: Teams) {
    return this.httpClient.put<Teams>(this.apiURL + '/updateTeamOrganisationList', team, {
      observe: 'response'
    });
  }

  updateTeamList(team: Teams) {
    return this.httpClient.put<Teams>(this.apiURL + '/updateTeamList', team, {
      observe: 'response'
    });
  }

  getActiveTeamList(team: Teams) {
    return this.httpClient.post<Teams>(this.apiURL + '/getActiveTeamByOrganisation', team);
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }


  createTeamsList(team: Teams) {
    return this.httpClient.post<Teams>(this.apiURL + '/createTeamsList', team, {
      observe: 'response'
    });
  }


  refreshGrid(team: Teams) {
    this.getAllTeams(team).subscribe((result: Teams[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  setTeam(gridTeam: Teams) {
    this.team = gridTeam;

    if (this.team != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateTeam() {
    return this.team;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
