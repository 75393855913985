import { Means } from '../../referentiel/means/means.model';
import { Level0 } from '../level0/level0.model';
import { Level1 } from '../level1/level1.model';
import { Level2 } from '../level2/level2.model';
import { Exploitable } from './exploitable.model';

export class LevelsAdmin {
    id: number;
    means: Means;
    level0: Level0;
    level1: Level1;
    level2: Level2;
    organisationId: number[];
    active: Boolean;
    createDate: Date;
    updateDate: Date;
    ipn: String;
    exploitable: Exploitable;
}

export enum constantValues{
    defaultValue = '-'
}
