import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LevelsAdmin } from './levelsadmin.model';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Exploitable } from './exploitable.model';
import { Observable } from 'rxjs';
import { Testbed } from '../../testbedreferential/testbed/testbed-model';

@Injectable({
  providedIn: 'root'
})
export class LevelsAdminService {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public gridApi: GridApi;
  public levelsAdmin: LevelsAdmin;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;
  public exploitableId: Exploitable;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getLevelsAdminData(levelsAdmin: LevelsAdmin) {
    return this.httpClient.post<LevelsAdmin[]>(this.apiURL + '/getLevelsAdminList', levelsAdmin).pipe(map(res => res));
  }

  updateLevelsAdminOrganisationList(levelsAdmin: LevelsAdmin) {
    return this.httpClient.put<LevelsAdmin>(this.apiURL + '/updateLevelsAdminOrganisationList', levelsAdmin, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  createLevelsAdminList(levelsAdmin: LevelsAdmin) {

    return this.httpClient.post<LevelsAdmin>(this.apiURL + '/saveLevelsAdmin', levelsAdmin, {
      observe: 'response'
    });
  }

  updateLevelsAdminList(levelsAdmin: LevelsAdmin) {
    return this.httpClient.put<LevelsAdmin>(this.apiURL + '/updateLevelsAdmin', levelsAdmin, {
      observe: 'response'
    });
  }

  setLevelsAdmin(gridLevelsAdmin: LevelsAdmin) {
    this.levelsAdmin = gridLevelsAdmin;
    if (this.levelsAdmin != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateLevelsAdmin() {
    return this.levelsAdmin;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  directUpdateLevelsAdminList(levelsAdmin: LevelsAdmin) {
    return this.httpClient.put<LevelsAdmin>(this.apiURL + '/directUpdateLevelsAdmin', levelsAdmin, {
      observe: 'response'
    });
  }
/*   getExploitableList(exploitable: Exploitable) {
    return this.httpClient.get<Exploitable[]>(this.apiURL + '/getExploitableList').pipe(map(res => res));
  } */
  getExploitableList(): Observable<Exploitable[]> {
    return this.httpClient.get<Exploitable[]>(this.apiURL + '/getExploitableList').pipe(map(res => res));
  }

  setExploitableId(exploitableId){
    this.exploitableId = exploitableId;
  }

  getExploitableId(){
    return this.exploitableId;
  }
  getLevelsAdminDataByMean(testbed: Testbed) {
    return this.httpClient.post<LevelsAdmin[]>(this.apiURL + '/getLevelsAdminListByMeanId', testbed).pipe(map(res => res));
  }

}
