import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { MqmChannels , constants } from '../model/mqm-channels.model';
import { map } from 'rxjs/operators';
import { Milestone } from 'src/app/administration/referentiel/milestone/milestone.model';

@Injectable({
    providedIn: 'root'
  })
export class MqmChannelService {
    public apiURL: string = environment.apiURL;
    public gridOptions: GridOptions;
    public buttonName: string;
    mqmChannels: MqmChannels;
    constructor(private httpClient: HttpClient) {
        this.buttonName = constants.createBtn;
     }

     getGridOptions() {
        return this.gridOptions;
      }
      setGridOptions(gridOptions) {
        this.gridOptions = gridOptions;
      }

      setMqmChannels(gridServices: MqmChannels) {
        this.mqmChannels = gridServices;
        if (this.mqmChannels != null) {
          this.initUpdate();
        }  else {
          this.initCreate();
        }
      }

      getUpdateMqmChannels() {
        return this.mqmChannels;
      }

      initUpdate() {
        this.buttonName = constants.updateBtn;
      }
      initCreate() {
        this.buttonName = constants.createBtn;
      }

      getAllMqmChannels (mqmChannels: MqmChannels) {
          return this.httpClient.post<MqmChannels[]>(this.apiURL + '/getMqmChannelsList', mqmChannels).pipe(map(res => res));
      }

      refreshGrid(mqmChannels: MqmChannels) {
        this.getAllMqmChannels(mqmChannels).subscribe((result: MqmChannels[]) => {
          this.gridOptions.api.setRowData(result);
        });
      }

      createMqmChannelList(mqmChannels: MqmChannels) {
        return this.httpClient.post<MqmChannels>(this.apiURL + '/createMqmChannelList' , mqmChannels, {
          observe: 'response'
        });
      }

      deleteMqmChannel(mqmChannels: MqmChannels) {
        return this.httpClient.post<MqmChannels>(this.apiURL + '/deleteMqmChannel' , mqmChannels, {
          observe: 'response'
        });
      }
}
