import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MaintenanceSupplier } from './maintenancesupplier.model';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceSupplierService {

  data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public maintenancesupplier: MaintenanceSupplier;
  public btnName: string;
  public showDeleteBtn: Boolean = true;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getMaintenanceSupplierList(maintenancesupplier: MaintenanceSupplier) {
    return this.httpClient.post<MaintenanceSupplier[]>(this.apiURL + '/getMaintenanceSupplierList', maintenancesupplier).pipe(map(res => res));
  }

  updateMaintenanceSupplierOrganisationList(maintenancesupplier: MaintenanceSupplier) {
    return this.httpClient.put<MaintenanceSupplier>(this.apiURL + '/updateMaintenanceSupplierOrganisationList', maintenancesupplier, {
      observe: 'response'
    });
  }
  updateMaintenanceSupplierList(maintenancesupplier: MaintenanceSupplier) {
    return this.httpClient.put<MaintenanceSupplier>(this.apiURL + '/updateMaintenanceSupplierList', maintenancesupplier, {
      observe: 'response'
    });
  }
  // deleteMaintenanceSupplierData(maintenancesupplier: MaintenanceSupplier) {
  //   return this.httpClient.put<MaintenanceSupplier>(this.apiURL + '/deleteMaintenanceSupplierData', maintenancesupplier, {
  //     observe: 'response'
  //   });
  // }

  deleteMaintenanceSupplierData(id: number): Observable<void> {
    console.log('delete',id);
    return this.httpClient.delete<void>(this.apiURL + '/deleteMaintenanceSupplierData/' + id);
  }
  

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  createMaintenanceSupplierList(maintenancesupplier: MaintenanceSupplier) {
    return this.httpClient.post<MaintenanceSupplier>(this.apiURL + '/createMaintenanceSupplierList', maintenancesupplier, {
      observe: 'response'
    });
  }


  refreshGrid(maintenancesupplier: MaintenanceSupplier) {
    this.getMaintenanceSupplierList(maintenancesupplier).subscribe((result: MaintenanceSupplier[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  refreshListGrid(result: MaintenanceSupplier[]) {
    this.gridOptions.api.setRowData(result);
  }
  setMaintenanceSupplier(gridCampaign: MaintenanceSupplier) {

    console.log(' gridCampaign ', gridCampaign);
    this.maintenancesupplier = gridCampaign;

    if (this.maintenancesupplier != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateMaintenanceSupplier() {
    return this.maintenancesupplier;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getMaintainenceSupplierDelete() {
    return this.showDeleteBtn;
  }

  checkColorDuplicate(dataList, checkVal): boolean {
    if (dataList) {
      const duplicateList = dataList.filter(dataVal => dataVal.colour === checkVal.colour);
      if (duplicateList && duplicateList.length > 0) {
    if (duplicateList.length > 1) {
        return true;
      } else if (duplicateList[0].id !== checkVal.id) {
        return true;
      }
    }}
}
}
