import { Injectable } from '@angular/core';
import { UsersModel } from '../../agreement-popup/users-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  public apiURL: string = environment.apiURL;
  public user: UsersModel;
  constructor(private http: HttpClient) { }

  updatePreferences(user: UsersModel) {
    return this.http.put<UsersModel>(this.apiURL + '/updatePreferences', user , {
      observe: 'response'
    });
  }
  setUserPreferences(user: UsersModel) {
  this.user = user;
  }

  getUserPreferences() {
    return this.user;
  }
}
