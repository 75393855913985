import { MapModel } from './map.model';
export class Means
{
    id: number;
    label: String;
    maps: MapModel;
    organisationId: number[];
    createDate: Date;
    updateDate: Date;
    ipn: String;
    active: Boolean;
}
