import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CommonServices } from 'src/app/common/common.services';
import { ExternalCoswin } from './external-coswin.model';
@Injectable({
  providedIn: 'root'
})
export class ExternalCoswinService {
  data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public btnName: string;
  externalCoswin: ExternalCoswin;
  constructor(private httpClient: HttpClient, private commonServices: CommonServices) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  getAllExternalCoswin() {
    const externalCoswin = new ExternalCoswin();
    externalCoswin.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    return this.httpClient.post<ExternalCoswin[]>(this.apiURL + '/getExternalCoswinList', externalCoswin).pipe(map(res => res));
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  saveExternalCoswin(externalCoswin: ExternalCoswin) {
    return this.httpClient.post<ExternalCoswin>(this.apiURL + '/saveExternalCoswin', externalCoswin, {
      observe: 'response'
    });
  }

  refreshGrid() {
    this.getAllExternalCoswin().subscribe((result: ExternalCoswin[]) => {
      this.gridOptions.api.setRowData(result);
      this.commonServices.externalCoswinData = result;
    });
  }

  updateExternalCoswinOrganisationList(externalCoswin: ExternalCoswin) {
    return this.httpClient.put<ExternalCoswin>(this.apiURL + '/updateExternalCoswinOrganisationList', externalCoswin, {
      observe: 'response'
    });
  }

  setExternalCoswin(gridExternalCoswin: ExternalCoswin) {
    this.externalCoswin = gridExternalCoswin;
    if (this.externalCoswin) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
}
