import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Testbedtype } from './testbedtype.model';
import { GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class TestbedtypeService {

  data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public testbedtype: Testbedtype;
  public btnName: string;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllTestbedtypes(testbedtype: Testbedtype) {
    return this.httpClient.post<Testbedtype[]>(this.apiURL + '/getTestbedtypeList', testbedtype).pipe(map(res => res));
  }

  updateTestbedtypesOrganisationList(testbedtype: Testbedtype) {
    return this.httpClient.put<Testbedtype>(this.apiURL + '/updateTestbedtypeOrganisationList', testbedtype, {
      observe: 'response'
    });
  }
  updateTestbedtypesList(testbedtype: Testbedtype) {
    return this.httpClient.put<Testbedtype>(this.apiURL + '/updateTestbedtypeList', testbedtype, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  createTestbedtypeList(testbedtype: Testbedtype) {
    return this.httpClient.post<Testbedtype>(this.apiURL + '/createTestbedtypeList', testbedtype, {
      observe: 'response'
    });
  }


  refreshGrid(testbedtype: Testbedtype) {
    this.getAllTestbedtypes(testbedtype).subscribe((result: Testbedtype[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  setTestbed(gridCampaign: Testbedtype) {
    this.testbedtype = gridCampaign;

    if (this.testbedtype != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateTestbedtype() {
    return this.testbedtype;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
