import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators/tap';
import { catchError } from 'rxjs/operators/catchError';
import { PiwikConfig } from './piwik.config';
import { environment } from 'src/environments/environment';
/**
 * Idp config resolver.
 */
@Injectable({
    providedIn: 'root'
})
export class PiwikConfigResolver {
    apiUrl: string = environment.apiURL;
    constructor(private http: HttpClient) { }

    resolve(envName: string): Observable<PiwikConfig> {
        return this.http.get(this.apiUrl + '/getPiwikConfig/' + envName ).pipe(
            tap((piwikConfig: PiwikConfig) => {
            }),
            catchError((error: any) => {
                console.debug('loading piwik config failed!', error);
                return Observable.throw('loading piwik config failed!');
            })
        );
    }


}
