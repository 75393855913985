import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {

//     // Url of the Identity Provider
    issuer: environment.authConfig.loginUrl,

    loginUrl: environment.authConfig.loginUrl,

    oidc: true,

//     // URL of the SPA to redirect the user to after login
    redirectUri: environment.authConfig.redirectUri,

//     // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: environment.authConfig.clientId,

//     // set the scope for the permissions the client should request
//     // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: environment.authConfig.scope,

    logoutUrl: environment.authConfig.logoutUrl


};

