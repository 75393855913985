import { EventEmitter, Injectable, ViewChild } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';
import { RollActivity } from './rollActivity.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FailureActivity } from '../activate/failureActivity.model';
import { BarActivity } from './barActivity.model';
import { Activity, ActivityEnum } from '../activate/activity.model';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { ActivateService } from '../activate/activate.service';
import { RowOrder } from '../activate/rowOrder.model';
import { BarCopActivity } from './barCopActivity.model';
import { BarMixActivity } from './barMixActivity.model';
import { ActivitycommonService } from '../activate/activitycommon.service';
import { RollActivityGridWrapper } from '../activate/rollActivityGridWrapper.model';
import { isThisSecond } from 'date-fns';
import { EngineActivity } from '../activate/engineActivity.model';
import { CustomPaginationComponent } from '../custom-pagination/custom-pagination.component';
import { BarCreateService } from './bar-activity-create/bar-create.service';

@Injectable({
  providedIn: 'root'
})
export class BarActivityService {
  public gridOptions: GridOptions;
  public gridApi: GridApi;
  public apiURL: string = environment.apiURL;
  public testBedFormData: any;
  public newActivityDate: any;
  public formFlag: any;
  public copPresVal: any;
  public barFormChangeCheck: Boolean;
  public displayDt: Date;
  page: number;
  size: number
  totalRecords: number;

  receivedTotalRecordsEmitter: EventEmitter<number> = new EventEmitter<number>();

  constructor(private httpClient: HttpClient,
    public activityService: ActivateService,
    private activitycommonService: ActivitycommonService,
    public barCreateService: BarCreateService) { }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }
  getGridOptions() {
    return this.gridOptions;
  }
  setPageAndPageSize(page: any, size: any) {
    this.page = page;
    this.size = size;
  }
  getAllRollActivity(roll: RollActivity) {
    return this.httpClient.post<RollActivityGridWrapper[]>(this.apiURL + '/getRollActivityList', roll).pipe(map(res => res));
  }
  getAllRollActivityCount(roll: RollActivity) {
    // return this.httpClient.post<Number>(this.apiURL + '/getFilteredRollActivityDataCount', roll).pipe(map(res => res));


    return this.httpClient.post<any>(this.apiURL + '/getFilteredRollActivityDataCount', roll);
  }


  saveBarActivity(bar: BarActivity) {
    return this.httpClient.post<BarActivity[]>(this.apiURL + '/saveBarActivity', bar, {
      observe: 'response'
    });
  }

  refreshGrid(mapVal?) {
    const roll = new RollActivity();
    const activity = new Activity();
    const testbedId = this.activityService.getTestBedFormData()['selectedTestBed'];
    const testbed = new Testbed();
    roll.activity = activity;
    roll.activity.testBedId = testbed;
    roll.activity.testBedId.id = testbedId;
    roll.activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    roll.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    console.log(this.getDisplayDt());
    if (mapVal === ActivityEnum.EmissionCD) {
      mapVal = ActivityEnum.EmissionCDLabel;
    } else {
      mapVal = ActivityEnum.TuningEDLabel;
    }
    this.activityService.getFailureActivityDataByTestBedId(testbedId, mapVal).subscribe((failureActivity_RollAct: FailureActivity[]) => {
      this.activityService.setFailureActivityList(failureActivity_RollAct);
      if (this.getDisplayDt() === undefined) {
        this.refreshRollActivity(roll).subscribe((result: RollActivity[]) => {
          console.log('refreshgrdi' + result);
          if (this.gridOptions && this.gridOptions.api) {
            this.gridOptions.context = {
              failureActivityData: failureActivity_RollAct.filter(res => res.rollActivity != null && res.ot != null),
              otData: failureActivity_RollAct.filter(res => res.rollActivity != null)
            };
            if (this.gridOptions && this.gridOptions.api) {
              this.gridOptions.api.setRowData(result);
            }
          }
          if (this.gridApi != null) {
            this.barCreateService.sendMessage('columnDefs');
            this.gridApi.sizeColumnsToFit();
          }
          // this.commonServices.setGridAPi(this.gridApi);
          // this.commonServices.setRollActivityData(result);
          this.activitycommonService.selectLastRowGrid(this.gridOptions);  // to select last row
        });
      } else {
        this.getFilteredRollActivityDataByOffsetAndLimit(roll).subscribe((result: RollActivityGridWrapper[]) => {
          this.gridOptions.context = {
            failureActivityData: failureActivity_RollAct.filter(res => res.rollActivity != null && res.ot != null),
            otData: failureActivity_RollAct.filter(res => res.rollActivity != null)
          };
          this.gridOptions.api.setRowData(result);
          if (this.gridApi != null) {
            this.barCreateService.sendMessage('columnDefs');
            this.gridApi.sizeColumnsToFit();
          }
          //this.getTotalRecordsEmitter(result['totalElements']); // Emit the Total Reocrds
          this.activitycommonService.selectLastRowGrid(this.gridOptions); // 
        });
      }
    });
  }

  // selectLastRowGrid() {
  //   if (this.gridOptions && this.gridOptions.api) {
  //     const rowcount = this.gridOptions.api.getDisplayedRowCount();
  //     let modifyRowIndex = parseInt(this.activityService.getModifyRowIndex());
  //     if (!modifyRowIndex) {
  //       modifyRowIndex = rowcount - 1;
  //     }
  //     this.gridOptions.api.forEachNode(function (node) {
  //       if (node.rowIndex === modifyRowIndex) {
  //         node.setSelected(true);
  //       }
  //     });

  //     this.gridOptions.api.ensureIndexVisible(modifyRowIndex, 'bottom');
  //   }
  // }

  setBarActivityFormData(value: any) {
    this.newActivityDate = value;
  }
  getBarActivityFormData() {
    return this.newActivityDate;
  }

  refreshRollActivity(roll: RollActivity) {
    return this.httpClient.post<RollActivity[]>(this.apiURL + '/getRollActivityList', roll).pipe(map(res => res));
  }
  getAllAstralRollActivity(testbedId: number, updateAstralDate: String) {
    return this.httpClient.get<RollActivity[]>(this.apiURL + '/getAndSaveAstralData/' + testbedId + '/' + updateAstralDate).pipe(map(res => res));
  }
  updateBarActivity(bar: BarActivity) {
    return this.httpClient.put<BarActivity[]>(this.apiURL + '/updateBarActivityData', bar, {
      observe: 'response'
    });
  }
  setFormFlag(value: any) {
    this.formFlag = value;
  }
  getFormFlag() {
    return this.formFlag;
  }

  getAllBarCopActivity(testbedId: number, updateAstralDate: String) {
    return this.httpClient.get<RollActivity[]>(this.apiURL + '/getAndSaveAstralUData/' + testbedId + '/' + updateAstralDate);
  }

  getAllBarMixActivity(testbedId: number, updateAstralDate: String) {
    return this.httpClient.get<RollActivity[]>(this.apiURL + '/getAndSaveAstralBarMixData/' + testbedId + '/' + updateAstralDate);
  }

  saveRowOrder(rowOrder: RowOrder[]) {
    return this.httpClient.put<RowOrder[]>(this.apiURL + '/saveRowOrderBar', rowOrder, {
      observe: 'response'
    });
  }
  saveBarCopActivity(barCop: BarCopActivity) {
    console.log('barCop', barCop);
    return this.httpClient.post<BarCopActivity[]>(this.apiURL + '/saveBarCopActivity', barCop, {
      observe: 'response'
    });
  }
  setBarCopPresData(value: any) {
    this.copPresVal = value;
  }
  getBarCopPresData() {
    return this.copPresVal;
  }
  /* Changed for Update Test Barcop
      This section of code was changed for the User story
      US271 - As an operator/administrator I want to update a testbed activity (test)
       linked to emissions chassis/dyno ‘BARCOP’
      */
  updateBarCopActivity(barCopActivity: BarCopActivity) {
    return this.httpClient.put<BarCopActivity>(this.apiURL + '/updateBarCopActivity', barCopActivity, {
      observe: 'response'
    });
  }
  setBarFormDirty(barFormChangeCheck: Boolean) {
    this.barFormChangeCheck = barFormChangeCheck;
  }
  getBarFormDirty() {
    return this.barFormChangeCheck;
  }
  saveBarMixActivity(barMixActivity: BarMixActivity) {
    return this.httpClient.post<BarMixActivity[]>(this.apiURL + '/saveBarMixActivity', barMixActivity, {
      observe: 'response'
    });
  }

  updateBarMixActivity(barMixActivity: BarMixActivity) {
    return this.httpClient.post<BarMixActivity[]>(this.apiURL + '/updateBarMixActivity', barMixActivity, {
      observe: 'response'
    });
  }

  setDisplayDt(displayDt: Date) {
    this.displayDt = displayDt;
  }

  getDisplayDt() {
    return this.displayDt;
  }

  getFilteredRollActivityData(rollActivity: RollActivity) {

    const rolesAssigned = sessionStorage.getItem('role');

    const roleAction: string = sessionStorage.getItem('roleAction');

    var roleActionValue = roleAction === '"W"' ? true : false;

    if(roleActionValue && rolesAssigned.toLowerCase().includes('tpm2-admin')) {

      return this.httpClient.post<RollActivityGridWrapper[]>(this.apiURL + '/getFilteredRollActivityDataForAdmin/', rollActivity);

    } else {
      return this.httpClient.post<RollActivityGridWrapper[]>(this.apiURL + '/getFilteredRollActivityData/', rollActivity);
    }
  }

  getFilteredRollActivityDataCount(engineActivity: EngineActivity) {
    return this.httpClient.post<any>(this.apiURL + '/getFilteredActivityDataCount', engineActivity);
  }

  getFilteredRollActivityDataByOffsetAndLimit(roll: RollActivity) {
    // this.page = this.page === undefined ? 0 : this.page;
    // this.size = this.size === undefined ? 10 : this.size;

    const rolesAssigned = sessionStorage.getItem('role');

    const roleAction: string = sessionStorage.getItem('roleAction');

    var roleActionValue = roleAction === '"W"' ? true : false;

    if(roleActionValue && rolesAssigned.toLowerCase().includes('tpm2-admin')) {

      return this.httpClient.post<RollActivityGridWrapper[]>(this.apiURL + '/getFilteredRollActivityDataForAdmin/', roll).pipe(map(res => res));

    }
    else {
      return this.httpClient.post<RollActivityGridWrapper[]>(this.apiURL + '/getFilteredRollActivityData/', roll).pipe(map(res => res));
    }
  }
  getAllRollActivityByOffsetAndLimit(roll: RollActivity) {
    return this.httpClient.post<RollActivityGridWrapper[]>(this.apiURL + '/getRollActivityList', roll).pipe(map(res => res));
  }

  getTotalRecordsEmitter(totalRecords: number): void {
    return this.receivedTotalRecordsEmitter.emit(totalRecords);
  }

}