import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { I18nLangService } from '../../i18n/i18n-lang.service';

@Injectable()
@Component({
  selector: 'bredcrumb',
  templateUrl: './bredcrumb.component.html',
  styleUrls: ['./bredcrumb.component.css']
})
export class BredcrumbComponent implements OnInit {

  constructor(public translate: TranslateService, private router: Router, private langService: I18nLangService) { }

  routerVal: String;
  splitRoute: String[];
  splitUrl: String[];

  ngOnInit() {
    this.routerVal = this.router.url;
    this.splitRoute = this.routerVal.split('/');
    this.splitUrl = this.breadcrumbChange(this.splitRoute);
  }

  breadcrumbChange(splitRoute: String[]) {
    if (splitRoute && splitRoute.length > 0) {
      if (splitRoute[1] === 'administration') {
        if (splitRoute[2] === 'referentiel') {
          return (['Administration.Title', 'Administration.Referential.Title']);
        } else if (splitRoute[2] === 'levelsReferential') {
          return (['Administration.Title', 'Administration.LevelsReferential.Title']);
        } else if (splitRoute[2] === 'prestationsAndProcedures') {
          return (['Administration.Title', 'Administration.Prestations_And_Procedures.Title']);
        } else if (splitRoute[2] === 'barReferentiel') {
          return (['Administration.Title', 'Administration.BarReferentiel.Title']);
        } else if (splitRoute[2] === 'TestBedReferential') {
          return (['Administration.Title', 'Administration.TestBedReferential.Title']);
        } else if (splitRoute[2] === 'TestBedMigration') {
          return (['Administration.Title', 'Administration.TestBedMigration.Title']);
        }
      } else if (splitRoute[1] === 'help') {
        if (splitRoute[2] === 'aide') {
          return (['Help.help.Title']);
        } else if (splitRoute[2] === 'Directory') {
          return (['Help.Directory.Title']);
        } else {
            return (['Help.Preference.Title']);
        }
      } else if (splitRoute[1] === 'MPM') {
        if (splitRoute[2] === 'Referential') {
          return (['MPM.Title', 'MPM.Referential.Title']);
        } else if (splitRoute[2] === 'TestBedSlots') {
          return (['MPM.Title', 'MPM.Testbedslots.Title']);
        } else if (splitRoute[2] === 'TaskReport') {
          return (['MPM.Title', 'MPM.TaskReports.Title']);
        }
      }
      else if (splitRoute[1] === 'ValidationSpecimen') {
        return (['ValidationSpecimen.Title']);
      }
      
      else if(splitRoute[1] === 'TestMeansStatus'){
       if(splitRoute[2] === 'TestbedStatus'){
          return (['TestMeansStatus.Title','TestMeansStatus.Testbed_Status.Title']);
        }
       else if(splitRoute[2] === 'Hazardsmanagement'){
        return (['TestMeansStatus.Title','TestMeansStatus.Hazardsmanagement.Title']);
        } else if(splitRoute[2] === 'Hazardshistory'){
          return (['TestMeansStatus.Title','TestMeansStatus.Hazardshistory.Title']);
          }
      }
    }
  }
}
