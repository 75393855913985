import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nLangService } from '../i18n/i18n-lang.service';
import { CommonServices } from '../common.services';
import { PreferencesService } from '../help/preferences/preferences.service';
import { UsersModel } from '../agreement-popup/users-model';
declare var $: any;
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
  browserLang;
  @ViewChild('frenchBtn') frenchBtn: ElementRef;
  @ViewChild('englishBtn') englishBtn: ElementRef;
  @ViewChild('russianBtn') russianBtn: ElementRef;

  constructor(public translate: TranslateService, public i18nservice: I18nLangService, public commonService: CommonServices,
    private preferenceService: PreferencesService) {
    // this.browserLang = translate.getBrowserLang();
    const browserCurrLang = translate.getBrowserLang();
    this.browserLang = browserCurrLang.match(/en|fr|ru/) ? browserCurrLang : 'en';
    this.translate.setDefaultLang(this.browserLang);
    this.commonService.setLanguage(this.browserLang);
  }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
    this.preferredLanguage();
  }

  changeLanguage(lang) {
    console.log('current language' + lang);
    this.translate.use(lang);
    this.browserLang = lang;
    // TODO: delete only for local
    this.i18nservice.sendMessage(lang);
    this.commonService.setLanguage(lang);
  }

  preferredLanguage() {
    let user = new UsersModel();
    user = this.preferenceService.getUserPreferences();
    if (user !== undefined && user.language !== this.browserLang) {
      if (user.language === 'fr') {
        const french: HTMLElement = this.frenchBtn.nativeElement as HTMLElement;
        french.click();
      } else if (user.language === 'en') {
        const english: HTMLElement = this.englishBtn.nativeElement as HTMLElement;
        english.click();
      }
    }
  }
}
