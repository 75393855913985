import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class ActivitycommonService {

  public modifyRowIndex: any;
  public updatedSelRow: any;
  public lastActivityRow: any;
  public lockDate: any;
  public astralDate: any;
  public techValidationCheckBox: any;
  constructor() { }

  setModifyRowIndex(value: any) {
    this.modifyRowIndex = value;
  }

  getModifyRowIndex() {
    return this.modifyRowIndex;
  }

  setupdatedSelRow(value: any) {
    this.updatedSelRow = value;
  }

  getupdatedSelRow() {
    return this.updatedSelRow;
  }

  selectLastRowGrid(gridOptions: GridOptions) {
    if (gridOptions && gridOptions.api) {
      let rowData = [];
      let model = gridOptions.api.getModel();
      model['rowsToDisplay'].forEach((row: any) => rowData.push(row.data));
      if (rowData.length !== 0) {
        this.lastActivityRow = rowData[rowData.length - 1];
      } else {
        this.lastActivityRow = null;
      }
      const rowcount = gridOptions.api.getDisplayedRowCount();
      const tempselrow = this.getupdatedSelRow();
      console.log('selectedRow>>>', tempselrow);
      let modifyRowIndex = Number(this.getModifyRowIndex());
      if (tempselrow) {
        let selectedActivtiy = tempselrow.rollActivity ? tempselrow.rollActivity : tempselrow.engineActivity;
        if (!selectedActivtiy) {
          selectedActivtiy = tempselrow.data;
        }
        console.log('selectedActivtiy>>>', selectedActivtiy);
        gridOptions.api.forEachNode(function (node) {
          if (node.data.activityId === selectedActivtiy.activity.id) {
            node.setSelected(true);
            gridOptions.api.ensureIndexVisible(node.rowIndex, 'bottom');
            return;
          }
        });
      } else {
        console.log('in else lastrow');
        if (!modifyRowIndex || Number.isNaN(modifyRowIndex) || modifyRowIndex === 0) {
          modifyRowIndex = rowcount - 1;
        }


        gridOptions.api.forEachNode(function (node) {
          if (node.rowIndex === modifyRowIndex) {
            node.setSelected(true);
          }
        });
        if (modifyRowIndex > 0) {
          gridOptions.api.ensureIndexVisible(modifyRowIndex, 'bottom');
        }
        this.setModifyRowIndex(null);
      }
    }
  }
}
