export class TestbedTechnicalInfo {
    public id: number;
    public brake: string;
    public technicalType: string;
    public power: string;
    public starter: boolean;
    public exhaust: boolean;
    public vibratorySignature: boolean;
    public distribution: string;
    public itPiloting: string;
    public cameo: string;
    public roadLaw: string;
    public respController: boolean;
    public clutch: boolean;
    public freeAccel: boolean;
    public rtc: boolean;
    public can: boolean;
    public cellTemperature: string;
    public maceration: string;
    public combustiveAir: string;
    public upstreamCold: string;
    public upstreamCH4Cold: string;
    public upstreamHot: string;
    public downstreamCold: string;
    public downstreamHot: string;
    public diluted: string;
    public fumimeter: string;
    public opacimeter: string;
    public tunnel: string;
    public hlms: string;
}