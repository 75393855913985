import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Batch } from './batch.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BatchService {
  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public batch: Batch;
  public btnName: string;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllBatch(batch: Batch) {
    return this.httpClient.post<Batch[]>(this.apiURL + '/getBatchList', batch).pipe(map(res => res));
  }

  updateBatchOrganisationList(batch: Batch) {
    return this.httpClient.put<Batch>(this.apiURL + '/updateBatchOrganisationList', batch, {
      observe: 'response'
    });
  }

  createBatchList(batch: Batch) {

    return this.httpClient.post<Batch>(this.apiURL + '/createBatchList', batch, {
      observe: 'response'
    });
  }

  updateBatchList(batch: Batch) {
    return this.httpClient.put<Batch>(this.apiURL + '/updateBatchList', batch, {
      observe: 'response'
    });
  }

  getActiveBatchList(batch: Batch) {
    return this.httpClient.post<Batch>(this.apiURL + '/getActiveBatchByOrganisation', batch);
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(batch: Batch) {
    this.getAllBatch(batch).subscribe((result: Batch[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  setBatch(gridBatch: Batch) {
    this.batch = gridBatch;

    if (this.batch != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateBatch() {
    return this.batch;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
