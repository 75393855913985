import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PreventivemaintenanceDeleteService {

    public showDeleteBtn: boolean;
    public deletepreventivemaintenanceSupplier: any;

    constructor() {
    //   this.showDeleteBtn  
    }

    showPreventivemaintenanceDelete(showBtn: any) {
        this.showDeleteBtn = showBtn;
    }

    setPreventivemaintenance(supplier: any) {
        this.deletepreventivemaintenanceSupplier = supplier;
    }
}