import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { TestBedSlots } from './test-bed-slots-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceSupplier } from '../referential/maintenancesupplier/maintenancesupplier.model';
import { MaintenanceTask } from '../referential/maintenancetask/maintenancetask.model';
import { SlotTasksAssociation } from './slot-task-fixer/slot-tasks-association.model';
import { ColouredSlots } from './coloured-slots.model';
import { CommonTestBed } from 'src/app/administration/testbedreferential/testbed/commonTestbedModel.model';

@Injectable({
  providedIn: 'root'
})
export class TestBedSlotsService {
public baseString: string;
public testbedSlots = new Array<TestBedSlots>();
public colouredSlots: ColouredSlots[];
public filterMsg: string;
  constructor(private http: HttpClient) {
    this.baseString = environment.apiURL;
   }

  getTestbedComboList(testbed: Testbed) {
    return this.http.post<Testbed>(this.baseString + '/getTestbedComboList/', testbed, {
      observe: 'response'
    });
  }

  getTestbedSlotList(testbedSlots: TestBedSlots):Observable<ColouredSlots[]> {
    return this.http.post<ColouredSlots[]>(this.baseString + '/getTestbedslotsList/', testbedSlots).pipe(map
      (res => res));
  }


  saveTestbedSlot(testbedSlot: TestBedSlots[]) {
    return this.http.post<Testbed[]>(this.baseString + '/saveTestbedSlot/', testbedSlot, {
      observe: 'response'
    });
  }

  setTestbedSlotsData(testbedSlot: ColouredSlots[]) {
   let loopValue: number;
   for (loopValue = 0; loopValue < testbedSlot.length; loopValue++) {
    this.testbedSlots[loopValue] = testbedSlot[loopValue].testbedSlots;
   }
  }

  getTestbedSlots() {
    return this.testbedSlots;
  }

  getTestbedSlotListbyTestbed(testbedId: number): Observable<ColouredSlots[]> {
    return this.http.get<ColouredSlots[]>(this.baseString + '/getTestbedSlotsByTestBed/' + testbedId);
  }

  deleteTestbedSlot(testbedSlotId: number) {
  return this.http.delete<void> (this.baseString + '/deleteTestbedSlot/' + testbedSlotId);
  }

  getMaintenanceSupplierListFromTask() {
    return this.http.get<MaintenanceSupplier[]>(this.baseString + '/getMaintenanceSupplierFromTasks/').pipe(map(res => res));
  }

  getMaintenanceTaskFromSupplier(maintenanceSupplier: MaintenanceSupplier): Observable<MaintenanceTask[]> {
    return this.http.post<MaintenanceTask[]>(this.baseString + '/getMaintenanceTaskFromSupplier/', maintenanceSupplier);
  }

  setfilterMsg(value: string) {
    this.filterMsg = value;
  }

  getfilterMsg() {
    return this.filterMsg;
  }

  updateSlotAcceptStatus(slotTasksAssociation: SlotTasksAssociation) {
    return this.http.post<SlotTasksAssociation[]>(this.baseString + '/acceptRejectStatusChange/', slotTasksAssociation, {
      observe: 'response'
    });
  }

  getSlotTaskAssociationList(slotTasksAssociation: SlotTasksAssociation) {
    return this.http.post<SlotTasksAssociation[]>(this.baseString + '/getSlotTaskAssociationList', slotTasksAssociation, {
      observe: 'response'
    });
  }
  // This method is changed to display same tasks with different durations
  getKeyValue(obj: any[], value: string) {
    let resultStr: string[];
    resultStr = value.split('|');
    for (let loop = 0; loop < obj.length; loop++) {
        if (((obj[loop].task).toUpperCase().trim() === resultStr[0].toUpperCase().trim()) &&
            ((obj[loop].duration.label) === parseFloat(resultStr[1])) &&
            ((obj[loop].periodicity).toUpperCase().trim() === resultStr[2].toUpperCase().trim()) &&
            ((obj[loop].type).toUpperCase().trim() === resultStr[3].toUpperCase().trim())
        ) {
            return obj[loop];
        }
    }
}
saveSlotTasksAssociation(slotTasksAssociation: SlotTasksAssociation[]) {
  return this.http.post<boolean>(this.baseString + '/saveSlotTasksAssociation', slotTasksAssociation, {
    observe: 'response'
  });
}
deleteAll(slotTasksAssociation: SlotTasksAssociation) {
  return this.http.post<boolean>(this.baseString + '/deleteSlotTasksAssociation' , slotTasksAssociation, {
    observe: 'response'
  });
}

getMaintenanceSlots(testbedSlot: TestBedSlots) {
  return this.http.post<SlotTasksAssociation[]>(this.baseString + '/getMaintenanceSlots/', testbedSlot).pipe(map
    (res => res));
 }

 getTestbedOrgID(testbedId: number): Observable<CommonTestBed> {
  return this.http.get<CommonTestBed>(this.baseString + '/getTestbedDetail/' + testbedId);
}
}
