import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MaintenanceTask } from './maintenancetask.model';
import { GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MaintenanceTaskService {

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public maintenancetask: MaintenanceTask;
  public label1: string;
  public label2: string;
  public label3: string;
  public active: boolean;
  public btnName: string;
  public id: number;
  public showDeleteBtn: Boolean = true;

  getMaintenanceTaskServiceList(maintenancetask: MaintenanceTask) {
    return this.httpClient.post<MaintenanceTask[]>(this.apiURL + '/getMaintenanceTaskList', maintenancetask).pipe(map(res => res));
  }

  updateMaintenanceTaskOrganisationList(maintenancetask: MaintenanceTask) {
    return this.httpClient.put<MaintenanceTask>(this.apiURL + '/updateMaintenanceTaskOrganisationList', maintenancetask, {
      observe: 'response'
    });
  }

  deleteMaintenanceTaskData(id: number): Observable<void> {
    return this.httpClient.delete<void>(this.apiURL + '/deleteMaintenanceTaskData/' + id);
  }

  getGridOptions() {
    return this.gridOptions;
  }

  refreshListGrid(result: MaintenanceTask[]) {
    this.gridOptions.api.setRowData(result);
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  saveMaintenanceTask(maintenancetask: MaintenanceTask) {
    return this.httpClient.post<MaintenanceTask>(this.apiURL + '/saveMaintenanceTask', maintenancetask, {
      observe: 'response'
    });
  }

  updateMaintenanceTask(maintenancetask: MaintenanceTask) {
    return this.httpClient.put<MaintenanceTask>(this.apiURL + '/updateMaintenanceTask', maintenancetask, {
      observe: 'response'
    });
  }


  setMaintenanceTask(gridmaintenancetask: MaintenanceTask) {
    this.maintenancetask = gridmaintenancetask;
    if (this.maintenancetask != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getupdateMaintenanceTask() {
    return this.maintenancetask;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getMaintenanceTaskDelete() {
    return this.showDeleteBtn;
  }
}