import { Injectable } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Building } from 'src/app/administration/referentiel/buildings/building.model';
import { Site } from 'src/app/administration/referentiel/sites/site.model';
import { LevelsAdmin } from '../administration/levelreferential/levelsadmin/levelsadmin.model';
import { SitesService } from '../administration/referentiel/sites/sites.service';
import { BuildingsService } from '../administration/referentiel/buildings/buildings.service';
import { ImportService } from './import/import.services';
import { MeansService } from '../administration/referentiel/means/means.service';
import { Level0Service } from '../administration/levelreferential/level0/level0.service';
import { Level1Service } from '../administration/levelreferential/level1/level1.service';
import { Level2Service } from '../administration/levelreferential/level2/level2.service';
import { LevelsAdminService } from '../administration/levelreferential/levelsadmin/levelsadmin.service';
import { Means } from '../administration/referentiel/means/means.model';
import { Level0 } from '../administration/levelreferential/level0/level0.model';
import { Level1 } from '../administration/levelreferential/level1/level1.model';
import { Level2 } from '../administration/levelreferential/level2/level2.model';
import { Campaign } from '../administration/referentiel/campaign/campaign.model';
import { CampaignService } from '../administration/referentiel/campaign/campaign.service';
import { Batch } from '../administration/referentiel/batch/batch.model';
import { BatchService } from '../administration/referentiel/batch/batch.service';
import { Prestation } from '../administration/prestationsandprocedures/prestation/prestation.model';
import { Procedure } from '../administration/prestationsandprocedures/procedure/procedure.model';
import { Teams } from '../administration/referentiel/teams/teams.model';
import { Services } from '../administration/referentiel/services/services.model';
import { Perimeters } from '../administration/referentiel/perimeters/perimeters.model';
import { CustomerDirection } from '../administration/referentiel/customer/customer.model';
import { Shifts } from '../administration/referentiel/shifts/shifts.model';
import { ServicesService } from '../administration/referentiel/services/services.service';
import { PerimetersService } from '../administration/referentiel/perimeters/perimeters.service';
import { PrestationService } from '../administration/prestationsandprocedures/prestation/prestation.service';
import { ProcedureService } from '../administration/prestationsandprocedures/procedure/procedure.service';
import { PrestationadminService } from '../administration/prestationsandprocedures/prestationadmin/prestationadmin.service';
import { PrestationAdmin } from '../administration/prestationsandprocedures/prestationadmin/prestationadmin.model';
import { TeamsService } from '../administration/referentiel/teams/teams.service';
import { CustomerDirectionService } from '../administration/referentiel/customer/customer.service';
import { ShiftsService } from '../administration/referentiel/shifts/shifts.service';
import { BarProgramLines } from '../administration/barreferentiel/barprogramlines/barprogramlines.model';
import { BarProgramLinesService } from '../administration/barreferentiel/barprogramlines/barprogramlines.service';
import { BarPrestation } from '../administration/barreferentiel/bwrprestation/bwrprestation.model';
import { BarPrestationService } from '../administration/barreferentiel/bwrprestation/bwrprestation.service';
import { FailureActivity } from '../saisie/activate/failureActivity.model';
import { ActivateService } from '../saisie/activate/activate.service';
import { EvEngine } from '../saisie/activate/evEngine.model';
import { EngineReliability } from '../saisie/activate/engineReliabilityActivity.model';
import { TuningEdActivity } from '../saisie/activate/tuningEdActivity.model';
import { ActivityWrapper } from '../saisie/activate/activityWrapper.model';
import { Activity } from '../saisie/activate/activity.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CampaignAssociation } from '../administration/referentiel/campaignadministration/campaignadministration.model';
import { CampaignadministrationService } from '../administration/referentiel/campaignadministration/campaignadministration.service';
import { RollActivity } from '../saisie/bar-activity/rollActivity.model';
import { Directory } from './help/help.model';
import { Profiles } from './help/profiles/profiles.model';
import { Testbedtype } from '../administration/testbedreferential/testbedtype/testbedtype.model';
import { TestbedtypeService } from '../administration/testbedreferential/testbedtype/testbedtype.service';
import { Testbed } from '../administration/testbedreferential/testbed/testbed-model';
import { TestbedService } from '../administration/testbedreferential/testbed/testbed-services';
import { Exploitable } from '../administration/levelreferential/levelsadmin/exploitable.model';
import { Sector } from '../administration/testbedreferential/sector/sector.model';
import { SectorService } from '../administration/testbedreferential/sector/sector.service';
import { TestbedComplement } from '../administration/testbedreferential/testbedcomplement/testbedcomplement.model';
import { TestbedComplementService } from '../administration/testbedreferential/testbedcomplement/testbedcomplement.service';
import { TestbedComplementAssociation } from '../administration/testbedreferential/testbedtypecomplementassociation/testbedtypecomplementassociation.model';
import { TestbedComplementAssociationService } from '../administration/testbedreferential/testbedtypecomplementassociation/testbedtypecomplementassociation.service';
import { BarActivity } from '../saisie/bar-activity/barActivity.model';
import { BarCopActivity } from '../saisie/bar-activity/barCopActivity.model';
import { MaintenanceSupplier } from '../mpm/referential/maintenancesupplier/maintenancesupplier.model';
import { MaintenanceSupplierService } from '../mpm/referential/maintenancesupplier/maintenancesupplier.service';
import { PreventiveMaintenance } from '../mpm/referential/preventivemaintenance/preventivemaintenance.model';
import { PreventiveMaintenanceService } from '../mpm/referential/preventivemaintenance/preventivemaintenance.service';
import { ValidationSpecimen } from '../validation-specimen/validation-specimen.model';
import { ValidationSpecimenService } from '../validation-specimen/validation-specimen.service';
import { StopDuration } from '../mpm/referential/stop-duration/stop-duration.model';
import { StopDurationService } from '../mpm/referential/stop-duration/stop-duration.service';
import { Prestationcompanies } from '../mpm/referential/prestationcompanies/prestationcompanies.model';
import { PrestationcompaniesService } from '../mpm/referential/prestationcompanies/prestationcompanies.service';
import { BarMixActivity } from '../saisie/bar-activity/barMixActivity.model';
import { Exploitants } from '../administration/testbedreferential/exploitants/exploitants.model';
import { MaintenanceTask } from '../mpm/referential/maintenancetask/maintenancetask.model';
import { MaintenanceTaskService } from '../mpm/referential/maintenancetask/maintenancetask.service';
import { ValidationSpecimenSequence } from 'src/app/validation-specimen/validation-specimen-ts-list/validation-specimen-ts-list.model';
import { ValidationSpecimenSequenceService } from 'src/app/validation-specimen/validation-specimen-ts-list/validation-specimen-ts-list.service';
import { CoswinTestbed } from '../administration/testbedreferential/testbed/coswinTestbed/coswinTestbed.model';
import { AstralTestbed } from '../administration/testbedreferential/testbed/astralTestbed.model';
import { GpsTestbed } from '../administration/testbedreferential/testbed/gpsTestbed.model';
import { SpecimenType } from '../administration/referentiel/specimen-type/specimen-type.model';
import { SpecimenTypeService } from '../administration/referentiel/specimen-type/specimen-type.service';
import { RsType } from '../administration/referentiel/rs-type/rs-type.model';
import { RsTypeService } from '../administration/referentiel/rs-type/rs-type.service';
import { MobileDevice } from '../administration/referentiel/mobile-device/mobile-device.model';
import { MobileDeviceService } from '../administration/referentiel/mobile-device/mobile-device.service';
import { Milestone } from '../administration/referentiel/milestone/milestone.model';
import { MilestoneService } from '../administration/referentiel/milestone/milestone.service';
import { MacroActivity } from '../administration/prestationsandprocedures/macro-activity/macro-activity.model';
import { MacroActivityService } from '../administration/prestationsandprocedures/macro-activity/macro-activity.service';
import { constants } from '../administration/referentiel/referentiel.model';
import { MqmChannelService } from '../saisie/crrpp/mqm-channels/mqmChannels.service';
import { MqmChannels } from '../saisie/crrpp/model/mqm-channels.model';
import { VehicleActivities } from '../saisie/vehicle-activities/vehicle-activities.model';
import { VhTechnicalParam } from '../administration/testbedreferential/vhtechnicalparam/vhtechnicalparam.model';
import { Intersystem } from '../administration/testbedreferential/intersystem/intersystem.model';
import { Fuel, errorMessages } from '../administration/referentiel/fuel/fuel.model';
import { FuelService } from '../administration/referentiel/fuel/fuel.service';
import { ExternalCoswin } from '../administration/testbedreferential/external-coswin/external-coswin.model';
import { TestType } from '../administration/testbedreferential/test-type/test-type.model';
import { OsVersion } from '../administration/testbedreferential/os-version/os-version.model';
import { ExpertiseDomain } from '../administration/testbedreferential/expertise-domain/expertise-domain.model';



@Injectable({
  providedIn: 'root'
})
export class CommonServices {

  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public gridApi: GridApi;
  public currentTab: String;
  public language: String;
  public siteData: Site[];
  public buildData: Building[];
  public meanData: Means[];
  public level0Data: Level0[];
  public level1Data: Level1[];
  public level2Data: Level2[];
  public levelAdminData: LevelsAdmin[];
  public validationSpecimenData: ValidationSpecimen[];
  public campaignData: Campaign[];
  public batchData: Batch[];
  public prestationData: Prestation[];
  public procedureData: Procedure[];
  public teamData: Teams[];
  public servicesData: Services[];
  public perimeterData: Perimeters[];
  public customerData: CustomerDirection[];
  public shifts: Shifts[];
  public prestationAdminData: PrestationAdmin[];
  public campaignAssociationData: CampaignAssociation[];
  public meansData: Means[];
  public teamsData: Teams[];
  public barProgramLinesData: BarProgramLines[];
  public barprestationData: BarPrestation[];
  public testBedData: Testbed[];
  public failureActivityData: FailureActivity[];
  public failureActivityDataEmissionCD: FailureActivity[];
  public filterActivityFormData: any;
  public currentActivityOperation: any;
  public evEngineData: EvEngine[];
  public testBedFormData: any;
  public engineReliabilityActivityData: EngineReliability[];
  public engineTuningActivityData: TuningEdActivity[];
  public activityWrapperData: ActivityWrapper[];
  public alreadyUsedData: Boolean;
  public mean: Means;
  public formdirty: boolean;
  public filterFormDirty: boolean;
  public rollActivityData: RollActivity[];
  public functionType: any;
  public directoryData: Directory[];
  public profileData: Profiles[];
  public testbedtypeData: Testbedtype[];
  public exploitableData: Exploitable[];
  public sectorData: Sector[];
  public testbedcomplementData: TestbedComplement[];
  public testbedComplementAssociationData: TestbedComplementAssociation[];
  public barActivityData: BarActivity[];
  public barCopActivityData: BarCopActivity[];
  public maintenancesupplierData: MaintenanceSupplier[];
  public preventivemaintenanceData: PreventiveMaintenance[];
  public stopDurationData: StopDuration[];
  public maintenancetaskData: MaintenanceTask[];
  public sacreData: any;

  public prestationCompaniesData: Prestationcompanies[];
  public barMixActivityData: BarMixActivity[];
  public currentUserRole;
  public exploitantData: Exploitants[];
  public failureActivityforEmission: FailureActivity[];
  public validationSequenceData: ValidationSpecimenSequence[];
  coswinTestbedData: CoswinTestbed[];
  public astralTestbedData: AstralTestbed[];
  gpsTestbedData: GpsTestbed[];
  specimenTypeData: SpecimenType[];
  rsTypeData: RsType[];
  mobileDeviceData: MobileDevice[];
  milestoneData: Milestone[];
  macroActivityData: MacroActivity[];
  mqmChannelsData: MqmChannels[];
  vehicleActivity: VehicleActivities[];
  vehicleFailureActivityData: FailureActivity[];
  VhTechnicalParamList: VhTechnicalParam[];
  interSystemUseList: Intersystem[];
  stoppageData: any;
  fuel: Fuel[];
  externalCoswinData: ExternalCoswin[];
  testTypeData: TestType[];
  osVersion: OsVersion[];
  expertiseDomainData: ExpertiseDomain[];

  constructor(
    public importService: ImportService,
    public meanservice: MeansService,
    public level0Service: Level0Service,
    public level1Service: Level1Service,
    public level2Service: Level2Service,
    public levelsAdminService: LevelsAdminService,
    public validationSpecimenService: ValidationSpecimenService,
    public campaignService: CampaignService,
    public batchService: BatchService,
    public siteService: SitesService,
    public servicesService: ServicesService,
    public perimeterService: PerimetersService,
    public buildingService: BuildingsService,
    public prestationService: PrestationService,
    public procedureService: ProcedureService,
    public prestationAdminService: PrestationadminService,
    public campaignAssociationService: CampaignadministrationService,
    public meansService: MeansService,
    public teamsService: TeamsService,
    public customerDirectionsService: CustomerDirectionService,
    public shiftService: ShiftsService,
    public barProgramLinesService: BarProgramLinesService,
    public barprestationService: BarPrestationService,
    public testBedService: TestbedService,
    public activityService: ActivateService,
    public testbedtypeService: TestbedtypeService,
    public sectorService: SectorService,
    public testbedComplementService: TestbedComplementService,
    public testbedComplementAssociationService: TestbedComplementAssociationService,
    public maintenanceSupplierService: MaintenanceSupplierService,
    public preventivemaintenanceService: PreventiveMaintenanceService,
    public stopDurationService: StopDurationService,
    public prestationCompaniesServices: PrestationcompaniesService,
    public maintenancetaskServices: MaintenanceTaskService,
    private httpClient: HttpClient,
    public validationSequenceService: ValidationSpecimenSequenceService,
    public specimenTypeServ: SpecimenTypeService,
    public rsTypeService: RsTypeService,
    public mobileService: MobileDeviceService,
    public milestoneService: MilestoneService,
    public macroService: MacroActivityService,
    public mqmChannelsService: MqmChannelService,
    public fuelService: FuelService) { }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }
  getGridApi() {
    return this.gridApi;
  }

  setGridAPi(gridApi) {
    this.gridApi = gridApi;
  }
  getCurrentTab() {
    return this.currentTab;
  }
  setCurrentTab(currentTab) {
    this.currentTab = currentTab;
  }
  getLanguage() {
    return this.language;
  }
  setLanguage(language) {
    this.language = language;
  }
  getSiteData() {
    return this.siteData;
  }
  setSiteData(siteData: Site[]) {
    this.siteData = siteData;
  }
  getMeanData() {
    return this.meanData;
  }
  setBuildingData(buildingData: Building[]) {
    this.buildData = buildingData;
  }
  getBuildingData() {
    return this.buildData;
  }
  setMeanData(meanData: Means[]) {
    this.meanData = meanData;
  }
  getLevel0Data() {
    return this.level0Data;
  }

  setLevel0Data(level0Data: Level0[]) {
    this.level0Data = level0Data;
  }

  getLevel1Data() {
    return this.level1Data;
  }

  setLevel1Data(level1Data: Level1[]) {
    this.level1Data = level1Data;
  }

  getLevel2Data() {
    return this.level2Data;
  }

  setLevel2Data(level2Data: Level2[]) {
    this.level2Data = level2Data;
  }

  getLevelAdminData() {
    return this.levelAdminData;
  }

  setLevelAdminData(levelsAdmin: LevelsAdmin[]) {
    this.levelAdminData = levelsAdmin;
  }

  getValidationSpecimenData() {
    return this.validationSpecimenData;
  }

  setValidationSpecimenData(validationSpecimenData: ValidationSpecimen[]) {
    this.validationSpecimenData = validationSpecimenData;
  }

  getPrestationData() {
    return this.prestationData;
  }

  setPrestationData(prestation: Prestation[]) {
    this.prestationData = prestation;
  }
  getProcedureData() {
    return this.procedureData;
  }

  setProcedureData(procedure: Procedure[]) {
    this.procedureData = procedure;
  }
  getTeamData() {
    return this.teamData;
  }

  setTeamData(team: Teams[]) {
    this.teamData = team;
  }
  getServicesData() {
    return this.servicesData;
  }

  setServicesData(services: Services[]) {
    this.servicesData = services;
  }

  getSpecimenTypeData() {
    return this.specimenTypeData;
  }

  setSpecimenTypeData(specimenType: SpecimenType[]) {
    this.specimenTypeData = specimenType;
  }

  setRsTypeData(rsType: RsType[]) {
    this.rsTypeData = rsType;
  }

  getRsTypeData() {
    return this.rsTypeData;
  }

  getMobileDeviceData() {
    return this.mobileDeviceData;
  }
  setMobileDeviceData(mobile: MobileDevice[]) {
    this.mobileDeviceData = mobile;
  }

  setMilestoneData(milestone: Milestone[]) {
    this.milestoneData = milestone;
  }
  getMilestoneData() {
    return this.milestoneData;
  }

  getPerimeterData() {
    return this.perimeterData;
  }

  setPerimeterData(perimeter: Perimeters[]) {
    this.perimeterData = perimeter;
  }

  setMacroActivityData(macro: MacroActivity[]) {
    this.macroActivityData = macro;
  }
  getMacroActivityData() {
    return this.macroActivityData;
  }

  getCustomerData() {
    return this.customerData;
  }

  setCustomerData(customer: CustomerDirection[]) {
    this.customerData = customer;
  }

  getShifts() {
    return this.shifts;
  }

  setShifts(shifts: Shifts[]) {
    this.shifts = shifts;
  }

  setCampaignData(campaign: Campaign[]) {
    this.campaignData = campaign;
  }
  getCampaignData() {
    return this.campaignData;
  }
  setBatchData(batch: Batch[]) {
    this.batchData = batch;
  }
  getBatchData() {
    return this.batchData;
  }
  getPrestationAdminData() {
    return this.prestationAdminData;
  }

  setPrestationAdminData(prestationAdmin: PrestationAdmin[]) {
    this.prestationAdminData = prestationAdmin;
  }
  setCampaignAssociationData(campaignAssociationData: CampaignAssociation[]) {
    this.campaignAssociationData = campaignAssociationData;
  }
  getCampaignAssociationData() {
    return this.campaignAssociationData;
  }
  setBarProgramLinesData(barProgramLinesData: BarProgramLines[]) {
    this.barProgramLinesData = barProgramLinesData;
  }
  getBarProgramLinesData() {
    return this.barProgramLinesData;
  }
  getBarprestationData() {
    return this.barprestationData;
  }
  setBarprestationData(barprestationData: BarPrestation[]) {
    this.barprestationData = barprestationData;
  }
  getTestBedData() {
    return this.testBedData;
  }
  setTestBedData(testBedData: Testbed[]) {
    this.testBedData = testBedData;
  }
  setCoswinTestbedData(coswinTestbedData: CoswinTestbed[]) {
    this.coswinTestbedData = coswinTestbedData;
  }
  getCoswinTestbedData() {
    return this.coswinTestbedData;
  }
  setAstralTestbedData(astralTestbedData: AstralTestbed[]) {
    this.astralTestbedData = astralTestbedData;
  }
  getAstralTestbedData() {
    return this.astralTestbedData;
  }
  setGpsTestbedData(gpsTestbedData: GpsTestbed[]) {
    this.gpsTestbedData = gpsTestbedData;
  }
  getGpsTestbedData() {
    return this.gpsTestbedData;
  }

  getFailureActivityData() {
    return this.failureActivityData;
  }

  setFailureActivityData(failureActivityData: FailureActivity[]) {
    this.failureActivityData = failureActivityData;
  }

  getfailureActivityDataEmissionCD() {
    return this.failureActivityDataEmissionCD;
  }

  setfailureActivityDataEmissionCD(failureActivityDataEmissionCD: FailureActivity[]) {
    this.failureActivityDataEmissionCD = failureActivityDataEmissionCD;
  }
  getEvEngineData() {
    return this.evEngineData;
  }
  setEvEngineData(evEngineData: EvEngine[]) {
    this.evEngineData = evEngineData;
  }

  getFilterActivityFormData() {
    return this.filterActivityFormData;
  }
  setFilterActivityFormData(filterActivityFormData: any) {
    this.filterActivityFormData = filterActivityFormData;
  }

  getCurrentActivityOperation() {
    return this.currentActivityOperation;
  }

  setCurrentActivityOperation(currentActivityOperation: any) {
    this.currentActivityOperation = currentActivityOperation;
  }
  setSacreData(sacreData: any) {
    this.sacreData = sacreData;
  }

  getSacreData() {
    return this.sacreData;
  }


  setEngineReliabilityActivityData(engineReliabilityActivityData: EngineReliability[]) {
    this.engineReliabilityActivityData = engineReliabilityActivityData;
  }
  getEngineReliabilityActivityData() {
    return this.engineReliabilityActivityData;
  }
  setEngineTuningActivityData(engineTuningActivityData: TuningEdActivity[]) {
    this.engineTuningActivityData = engineTuningActivityData;
  }
  getEngineTuningActivityData() {
    return this.engineTuningActivityData;
  }
  setActivityData(activity: ActivityWrapper[]) {
    this.activityWrapperData = activity;
  }
  getActivityData() {
    return this.activityWrapperData;
  }
  getAlreadyUsedData() {
    return this.alreadyUsedData;
  }
  setAlreadyUsedData(alreadyUsedData: Boolean) {
    this.alreadyUsedData = alreadyUsedData;
  }
  setRollActivityData(rollActivityData: RollActivity[]) {
    this.rollActivityData = rollActivityData;
  }
  getRollActivityData() {
    return this.rollActivityData;
  }
  getDirectoryData() {
    return this.directoryData;
  }

  getProfileData() {
    return this.profileData;
  }

  setDirectoryData(directoryData: Directory[]) {
    this.directoryData = directoryData;
  }

  setProfileData(profileData: Profiles[]) {
    this.profileData = profileData;
  }

  getmean() {
    return this.mean;
  }

  setmean(mean: Means) {
    this.mean = mean;
  }
  setTestbedtypeData(testbedtype: Testbedtype[]) {
    this.testbedtypeData = testbedtype;
  }
  getTestbedtypeData() {
    return this.testbedtypeData;
  }

  setSectorData(sector: Sector[]) {
    this.sectorData = sector;
  }
  getSectorData() {
    return this.sectorData;
  }

  setExploitantData(exploitant: Exploitants[]) {
    this.exploitantData = exploitant;
  }
  getExploitantData() {
    return this.exploitantData;
  }

  setTestbedcomplementData(testbedcomplement: TestbedComplement[]) {
    this.testbedcomplementData = testbedcomplement;
  }
  getTestbedcomplementData() {
    return this.testbedcomplementData;
  }

  setTestbedComplementAssociationData(testbedComplementAssociation: TestbedComplementAssociation[]) {
    this.testbedComplementAssociationData = testbedComplementAssociation;
  }
  getTestbedComplementAssociationData() {
    return this.testbedComplementAssociationData;
  }

  setMaintenanceSupplierData(maintenancesupplier: MaintenanceSupplier[]) {
    this.maintenancesupplierData = maintenancesupplier;
  }
  getMaintenanceSupplierData() {
    return this.maintenancesupplierData;
  }

  setPreventiveMaintenanceData(preventivemaintenance: PreventiveMaintenance[]) {
    this.preventivemaintenanceData = preventivemaintenance;
  }
  getPreventiveMaintenanceData() {
    return this.preventivemaintenanceData;
  }

  setStopDurationData(stopDuration: StopDuration[]) {
    this.stopDurationData = stopDuration;
  }
  getStopDurationData() {
    return this.stopDurationData;
  }

  setPrestationCompaniesData(prestationCompanies: Prestationcompanies[]) {
    this.prestationCompaniesData = prestationCompanies;
  }
  getPrestationCompaniesData() {
    return this.prestationCompaniesData;
  }

  setMaintenanceTaskData(maintenanceTask: MaintenanceTask[]) {
    this.maintenancetaskData = maintenanceTask;
  }
  getMaintenanceTaskData() {
    return this.maintenancetaskData;
  }

  getKeyValue(obj: any[], value: string) {
    for (let i = 0; i < obj.length; i++) {
      if (!isNaN(obj[i]) || !isNaN(parseInt(value))) {
        if ((obj[i].label) === value) {
          return obj[i].id;
        }
      } else {
        if (value !== undefined && (obj[i].label).toUpperCase().trim() === value.toUpperCase().trim()) {
          return obj[i].id;
        }
      }
    }
  }

  getMeans() {
    const means = new Means();
    means.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.meanservice.getAllMeans(means).subscribe((result: Means[]) => this.setMeanData(result));
  }
  getLevel0() {
    const level0 = new Level0();
    level0.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.level0Service.getLevel0Data(level0).subscribe((result: Level0[]) => this.setLevel0Data(result));
  }
  getLevel1() {
    const level1 = new Level1();
    level1.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.level1Service.getAllLevel1(level1).subscribe((result: Level1[]) => this.setLevel1Data(result));
  }
  getLevel2() {
    const level2 = new Level2();
    level2.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.level2Service.getAllLevel2(level2).subscribe((result: Level2[]) => this.setLevel2Data(result));
  }
  getBatch() {
    const batch = new Batch();
    batch.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.batchService.getAllBatch(batch).subscribe((result: Batch[]) => this.setBatchData(result));
  }
  getServices() {
    const services = new Services();
    services.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.servicesService.getAllServices(services).subscribe((result: Services[]) => this.setServicesData(result));
  }
  getSpecimenType() {
    const specimenType = new SpecimenType();
    specimenType.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.specimenTypeServ.getAllSpecimenType(specimenType).subscribe((result: SpecimenType[]) => this.setSpecimenTypeData(result));
  }
  getRsType() {
    const rsType = new RsType();
    rsType.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.rsTypeService.getAllRsType(rsType).subscribe((result: RsType[]) => this.setRsTypeData(result));
  }

  getMobileDevice() {
    const mobileDevice = new MobileDevice();
    mobileDevice.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.mobileService.getAllMobileDevice(mobileDevice).subscribe((result: MobileDevice[]) => this.setMobileDeviceData(result));
  }

  getMilestone() {
    const milestone = new Milestone();
    milestone.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.milestoneService.getAllMilestone(milestone).subscribe((result: Milestone[]) => this.setMilestoneData(result));
  }

  getPerimeter() {
    const perimeter = new Perimeters();
    perimeter.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.perimeterService.getAllPerimeters(perimeter).subscribe((result: Perimeters[]) => this.setPerimeterData(result));
  }
  getBuilding() {
    const building = new Building();
    building.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.buildingService.getAllBuildings(building).subscribe((result: Building[]) => this.setBuildingData(result));
  }
  getCampaign() {
    const campaign = new Campaign();
    campaign.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.campaignService.getAllCampaigns(campaign).subscribe((result: Campaign[]) => this.setCampaignData(result));
  }
  getPrestation() {
    const prestation = new Prestation();
    prestation.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.prestationService.getAllPrestation(prestation).subscribe((result: Prestation[]) => this.setPrestationData(result));
  }
  getProcedure() {
    const procedure = new Procedure();
    procedure.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.procedureService.getAllProcedure(procedure).subscribe((result: Procedure[]) => this.setProcedureData(result));
  }
  getMacroActivity() {
    const macro = new MacroActivity();
    macro.organisationId = JSON.parse(sessionStorage.getItem(constants.orgId));
    this.macroService.getAllMacroActivity(macro).subscribe((result: MacroActivity[]) => this.setMacroActivityData(result));
  }
  getTeams() {
    const team = new Teams();
    team.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.teamsService.getAllTeams(team).subscribe((result: Teams[]) => this.setTeamData(result));
  }
  getCustomerDirections() {
    const customerDirection = new CustomerDirection();
    customerDirection.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.customerDirectionsService.getAllCustomers(customerDirection).subscribe((result: CustomerDirection[]) => {
      this.setCustomerData(result)
    });
  }

  getShiftDetails() {
    const shifts = new Shifts;
    shifts.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.shiftService.getAllShifts(shifts).subscribe((result: Shifts[]) => {
      this.setShifts(result)
    });
  }

  getTestbedtype() {
    const testbedtype = new Testbedtype();
    testbedtype.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.testbedtypeService.getAllTestbedtypes(testbedtype).subscribe((result: Testbedtype[]) => this.setTestbedtypeData(result));

  }

  getTestBedDataList() {
    this.testBedService.getTestBedList().subscribe((result: Testbed[]) => this.setTestBedData(result));
  }
  getCoswinTestbed() {
    this.testBedService.getAllCoswinData().subscribe((result: CoswinTestbed[]) => this.setCoswinTestbedData(result));
  }
  getAstralTestbed() {
    this.testBedService.getAstralTestbedList().subscribe((result: AstralTestbed[]) => this.setAstralTestbedData(result));
  }
  getGpsTestbed() {
    this.testBedService.getGpsTestbedList().subscribe((result: GpsTestbed[]) => this.setGpsTestbedData(result));
  }

  getTestbedComplement() {
    const testbedcomplement = new TestbedComplement();
    testbedcomplement.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.testbedComplementService.getAllTestbedComplements(testbedcomplement).subscribe((result: TestbedComplement[]) => this.setTestbedcomplementData(result));
  }

  getTestbedComplementAssociation() {
    const testbedComplementAssociation = new TestbedComplementAssociation();
    testbedComplementAssociation.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.testbedComplementAssociationService.getAllTestbedComplementAssociation(testbedComplementAssociation).subscribe((result: TestbedComplementAssociation[]) => this.setTestbedComplementAssociationData(result));
  }

  getMaintenanceSupplier() {
    const maintenancesupplier = new MaintenanceSupplier();
    maintenancesupplier.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.maintenanceSupplierService.getMaintenanceSupplierList(maintenancesupplier).subscribe((result: MaintenanceSupplier[]) => this.setMaintenanceSupplierData(result));
  }
  getPreventiveMaintenance() {
    const preventivemaintenance = new PreventiveMaintenance();
    preventivemaintenance.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    // tslint:disable-next-line: max-line-length
    this.preventivemaintenanceService.getPreventiveMaintenanceList(preventivemaintenance).subscribe((result: PreventiveMaintenance[]) => this.setPreventiveMaintenanceData(result));
  }
  getMaintenanceTask() {
    const maintenanceTask = new MaintenanceTask();
    maintenanceTask.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    // tslint:disable-next-line: max-line-length
    this.maintenancetaskServices.getMaintenanceTaskServiceList(maintenanceTask).subscribe((result: MaintenanceTask[]) => this.setMaintenanceTaskData(result));
  }
  getValidationSpecimen() {
    const validationSpecimen = new ValidationSpecimen();
    validationSpecimen.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.validationSpecimenService.getValidationSpecimenData(validationSpecimen).
      subscribe((result: ValidationSpecimen[]) => this.setValidationSpecimenData(result));
  }

  getStopDuration() {
    const stopDuration = new StopDuration();
    stopDuration.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.stopDurationService.getStopDurationList(stopDuration).subscribe((result: StopDuration[]) => this.setStopDurationData(result));
  }

  getPrestationCompanies() {
    const prestationCompanies = new Prestationcompanies();
    prestationCompanies.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.prestationCompaniesServices.getPrestationcompaniesList(prestationCompanies).subscribe(
      (result: Prestationcompanies[]) => this.setPrestationCompaniesData(result));
  }

  getFuel() {
    const fuel = new Fuel();
    fuel.organisationId = JSON.parse(sessionStorage.getItem(errorMessages.orgId));
    this.fuelService.getAllFuel(fuel).subscribe((result: Fuel[]) => this.fuel = result);
  }

  checkDuplicate(dataList, checkVal, optionalCheck?): boolean {
    if (dataList) {
      const duplicateList = dataList.filter(dataVal => dataVal.label.toUpperCase() === checkVal.label.toUpperCase());
      if (duplicateList && duplicateList.length > 0) {
        if (optionalCheck) {
          const duplicateOptionalList = duplicateList.filter(childDataVal => childDataVal.siteId.id === parseInt(checkVal.siteId));
          if (duplicateOptionalList && duplicateOptionalList.length > 0) {
            if (duplicateOptionalList[0].id !== checkVal.id) {
              return true;
            }
          }
        } else if (duplicateList.length > 1) {
          return true;
        } else if (duplicateList[0].id !== checkVal.id) {
          return true;
        }
      }
    }
    return false;
  }

  checkDuplicateShifts(dataList, checkVal) {
    if (dataList) {
      const duplicates = dataList.filter(data => data.teams === checkVal.teams && data.hours === checkVal.hours);
      if (duplicates.length > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkDuplicateForNumbers(dataList, checkVal, optionalCheck?): boolean {
    if (dataList) {
      const duplicateList = dataList.filter(dataVal => dataVal.label === checkVal.label);
      if (duplicateList && duplicateList.length > 0) {
        if (optionalCheck) {
          const duplicateOptionalList = duplicateList.filter(childDataVal => childDataVal.siteId.id === parseInt(checkVal.siteId));
          if (duplicateOptionalList && duplicateOptionalList.length > 0) {
            if (duplicateOptionalList[0].id !== checkVal.id) {
              return true;
            }
          }
        } else if (duplicateList.length > 1) {
          return true;
        } else if (duplicateList[0].id !== checkVal.id) {
          return true;
        }
      }
    }
    return false;
  }

  getFormattedTime() {
    const today = new Date();
    return '_' + today.getFullYear() + (today.getMonth() + 1) +
      today.getDate() + today.getHours() + today.getMinutes() + today.getSeconds();
  }

  filterByOrg(resultList) {
    const checkArr = JSON.parse(sessionStorage.getItem('organisationIDs'));
    if (checkArr) {
      const filterArr = resultList.filter(org => {
        const res = checkArr.filter(f => org.organisationId.includes(f));
        if (res.length > 0) {
          return org;
        }
      });
      resultList = filterArr;
    }
    return resultList;
  }

  checkActivityAlreadyMapped(activity: Activity): Observable<boolean> {
    return this.httpClient.post<boolean>(this.apiURL + '/checkActivityAlreadyMapped', activity).pipe(map(res => res));
  }
  setFormChanged(value: boolean) {
    this.formdirty = value;
  }


  getFormChanged() {
    return this.formdirty;
  }
  setFilterFormData(value: boolean) {
    this.filterFormDirty = value;
  }
  getFilterFormData() {
    return this.filterFormDirty;
  }

  getFunctionType() {
    return this.functionType;
  }

  setFunctionType(functionType: any) {
    this.functionType = functionType;
  }

  getExploitableList() {
    this.levelsAdminService.getExploitableList().subscribe((result: Exploitable[]) => {
      this.setExploitableData(result);
    });

  }

  setExploitableData(exploitable: Exploitable[]) {
    this.exploitableData = exploitable;

  }

  getExploitableData() {
    return this.exploitableData;
  }
  setBarActivityData(barActivityData: BarActivity[]) {
    this.barActivityData = barActivityData;
  }
  getBarActivityData() {
    return this.barActivityData;
  }
  setBarCopActivityData(barCopActivityData: BarCopActivity[]) {
    this.barCopActivityData = barCopActivityData;
  }
  setEmisionFailureActivitesData(failureActivity: FailureActivity[]) {
    this.failureActivityforEmission = failureActivity;
  }
  getBarCopActivityData() {
    return this.barCopActivityData;
  }
  setBarMixActivityData(barMixActivityData: BarMixActivity[]) {
    this.barMixActivityData = barMixActivityData;
  }
  getBarMixActivityData() {
    return this.barMixActivityData;
  }

  getEmissionFailureActivity() {
    return this.failureActivityforEmission;
  }


  getValidationSequenceData() {
    return this.validationSpecimenData;
  }

  setValidationSequenceData(validationSequenceData: ValidationSpecimenSequence[]) {
    this.validationSequenceData = validationSequenceData;
  }

  getValidationSequence() {
    const validationSequence = new ValidationSpecimen();
    this.validationSequenceService.getValidationSequenceData(validationSequence).
      subscribe((result: ValidationSpecimenSequence[]) => this.setValidationSequenceData(result));
  }

  setMqmChannelsData(mqmChannels: MqmChannels[]) {
    this.mqmChannelsData = mqmChannels;
  }

  getMqmChannelsData() {
    return this.mqmChannelsData;
  }

  getVehicleActivityData() {
    return this.vehicleActivity;
  }

  setVehicleActivityData(vehicleActivity: VehicleActivities[]) {
    this.vehicleActivity = vehicleActivity;
  }

  getVehicleFailiureActivityData() {
    return this.vehicleFailureActivityData;
  }

  setVehicleFailiureActivityData(vhFailureActivityData: FailureActivity[]) {
    this.vehicleFailureActivityData = vhFailureActivityData;
  }
}
