import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Level1 } from './level1.model';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Level1Service {
  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public level1: Level1;
  public btnName: string;
  public id: number;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllLevel1(level1: Level1) {
    return this.httpClient.post<Level1[]>(this.apiURL + '/getLevel1List', level1).pipe(map(res => res));
  }

  updateLevel1OrganisationList(level1: Level1) {
    return this.httpClient.put<Level1>(this.apiURL + '/updateLevel1OrganisationList', level1, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(level1: Level1) {
    this.getAllLevel1(level1).subscribe((result: Level1[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  createLevel1List(level1: Level1) {
    return this.httpClient.post<Level1>(this.apiURL + '/saveLevel1', level1, {
      observe: 'response'
    });
  }

  checkDuplicateLevel1(level1: Level1) {

    return this.httpClient.post<Level1>(this.apiURL + '/checkDuplicateLevel1', level1, {
      observe: 'response'
    });
  }
  updateLevel1List(level1: Level1) {
    return this.httpClient.put<Level1>(this.apiURL + '/updateLevel1', level1, {
      observe: 'response'
    });
  }
  setLevel1(gridLevel1: Level1) {
    this.level1 = gridLevel1;
    if (this.level1 != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateLevel1() {
    return this.level1;
  }
  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
