import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Sign } from './Sign.model';
import { BarCopActivityDuration } from './barcopactivityduration.model';
import { environment } from 'src/environments/environment';
import { GridOptions } from 'ag-grid-community';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BarcopactivitydurationService {
  public btnName: string;
  public sign:Sign;
  public gridOptions: GridOptions;
  public barCopDuration: BarCopActivityDuration;
  public barCopDurationArray: BarCopActivityDuration[];
    
  public apiURL: string = environment.apiURL;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
   }
   initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  setBarCopDuration(gridbarCopDuration: BarCopActivityDuration) {
    this.barCopDuration = gridbarCopDuration;
    if (this.barCopDuration != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  setBarCopDurationArray(barCopDurationArray: BarCopActivityDuration[]) {
    this.barCopDurationArray = barCopDurationArray; 
  }
  updateBarCopDuration(barCopDuration: BarCopActivityDuration) {
    return this.httpClient.put<BarCopActivityDuration>(this.apiURL + '/updateBarCopActivityDuration', barCopDuration, {
      observe: 'response'
    });
    
  }
  
  updateBarCopDurationOrganisationList(barCopDuration: BarCopActivityDuration) {
    return this.httpClient.put<BarCopActivityDuration>(this.apiURL + '/updateBarCopDurationOrganisationList', barCopDuration, {
      observe: 'response'
    });
  }
  getUpdateBarCopDuration() {
    return this.barCopDuration;
}
  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  getAllBarCopActivityDurationList(barcopactivityduration: BarCopActivityDuration) {
    return this.httpClient.post<BarCopActivityDuration[]>(this.apiURL + '/getBarCopActivityDurationList', barcopactivityduration).pipe(map(res => res));

  }

  getDurationRuleList(barcopactivityduration: BarCopActivityDuration) {
    return this.httpClient.post<BarCopActivityDuration>(this.apiURL + '/getDurationRuleList', barcopactivityduration).pipe(map(res => res));

  }

  
  getFunctionalityList(sign: Sign) {
    return this.httpClient.post<Sign[]>(this.apiURL + '/getFunctionalityList', sign).pipe(map(res => res));
  }

  createBarCopDuration(barCopDuration: BarCopActivityDuration) {
    return this.httpClient.post<BarCopActivityDuration>(this.apiURL + '/saveBarCopActivityDuration', barCopDuration , {
      observe: 'response'
    });
  }
}
