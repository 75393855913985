import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrganisationMapModel } from './organisation-map.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn : 'root'
})
export class OrganisationMapService {

    private baseString: string;
    public organizationIds: number[];

    constructor(private http: HttpClient) {
        this.baseString = environment.apiURL;
    }

    getOrganisationList(): Observable<OrganisationMapModel[]> {
        return this.http.get<OrganisationMapModel[]>(this.baseString + '/getOrganisationList');
    }

    getOrganisationRegId(organisationMapModel: OrganisationMapModel): Observable<number[]> {
        return this.http.post<number[]>(this.baseString + '/getOrganisationRegId/', organisationMapModel);
    }

    getOrganizationIds() {
        return this.organizationIds;
    }

    setOrganizationIds(organizationIds: number[]) {
        this.organizationIds = organizationIds;
    }
}