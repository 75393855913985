import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BaseFile } from './dynamic-components/base-file';
import { BaseControlService } from './dynamic-components/base-control.service';
import { CommonServices } from '../common.services';
import { TuningEdActivity } from 'src/app/saisie/activate/tuningEdActivity.model';
import { EngineActivity } from 'src/app/saisie/activate/engineActivity.model';
import { Activity, ActivityEnum } from 'src/app/saisie/activate/activity.model';
import { Level0 } from 'src/app/administration/levelreferential/level0/level0.model';
import { Level1 } from 'src/app/administration/levelreferential/level1/level1.model';
import { Level2 } from 'src/app/administration/levelreferential/level2/level2.model';
import { Procedure } from 'src/app/administration/prestationsandprocedures/procedure/procedure.model';
import { Campaign } from 'src/app/administration/referentiel/campaign/campaign.model';
import { CustomerDirection } from 'src/app/administration/referentiel/customer/customer.model';
import { Prestation } from 'src/app/administration/prestationsandprocedures/prestation/prestation.model';
import { ActivateService } from 'src/app/saisie/activate/activate.service';
import { SnackBarService } from '../snackbar/snackbar.component.service';
import { FailureActivity } from 'src/app/saisie/activate/failureActivity.model';
import { OT } from 'src/app/saisie/activate/OT.model';
import { OtType } from 'src/app/saisie/activate/otType.model';
import { EngineReliability } from 'src/app/saisie/activate/engineReliabilityActivity.model';
import { EvEngine } from 'src/app/saisie/activate/evEngine.model';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/common/shared/confirmation-dialog/confirmation-dialog.component';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { Exploitable } from 'src/app/administration/levelreferential/levelsadmin/exploitable.model';
import { BarActivityService } from 'src/app/saisie/bar-activity/bar-activity.service';
import { ActivitycommonService } from 'src/app/saisie/activate/activitycommon.service';
import { OtStatusService } from 'src/app/saisie/ot-status/ot-status.service';
import { RsType } from 'src/app/administration/referentiel/rs-type/rs-type.model';
import { DynamicConstants, DynamicDataTransfer } from './dynamic-form.model';
import { MacroActivity } from 'src/app/administration/prestationsandprocedures/macro-activity/macro-activity.model';
import { EngineActivityGridWrapper } from 'src/app/saisie/activate/engineActivityGridWrapper.model';
import { Fuel } from 'src/app/administration/referentiel/fuel/fuel.model';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [BaseControlService]
})
export class DynamicFormComponent implements OnInit {

  @Input() baseFile: BaseFile<any>[] = [];
  @Output() closeDynamicForm = new EventEmitter();
  @Output() flagVal = new EventEmitter();
  @Input() public disableFlag;
  public submitted: Boolean;
  @Output() cancelDynamicForm = new EventEmitter();

  form: FormGroup;
  payLoad = '';
  public tuningEdActivity: TuningEdActivity;
  public activity: Activity;
  public engineActivity: EngineActivity;
  public level0: Level0;
  public level1: Level1;
  public level2: Level2;
  public prestation: Prestation;
  public macroActivity: MacroActivity;
  public procedure: Procedure;
  public campaign: Campaign;
  public customerDirection: CustomerDirection;
  public testbedGlobalInfo: Testbed;
  public tuningRowData: TuningEdActivity;
  public hidedata: boolean;
  public failureActivity: FailureActivity;
  public failureRowData: FailureActivity;
  public reliabilityRowData: EngineReliability;
  public reliability: EngineReliability;
  public electricalRowData: EvEngine;
  public electrical: EvEngine;
  otValGrid: any;
  dataTrans = new DynamicDataTransfer();
  totalRecords: number;

  constructor(private baseControlService: BaseControlService,
    private commonService: CommonServices, private activityService: ActivateService, private snackbarService: SnackBarService,
    public dialog: MatDialog, private barService: BarActivityService,
    private activitycommonService: ActivitycommonService,
    private otStatusService: OtStatusService) { }

  ngOnInit() {
    // this.activityService.setSacreFlag(false);
    this.form = this.baseControlService.toFormGroup(this.baseFile);
    // this.disableFlag = true;
    this.setDataTransfer();
    this.onValueChanges();

  }

  onValueChanges(): void {
    this.form.valueChanges.subscribe(val => {
      this.dataTrans.comments = val.comment;
      this.dataTrans.engineNo = val.engineNb;
      this.dataTrans.febNb = val.febNb;
      this.dataTrans.engineDt = val.engineDt;
      this.dataTrans.calibration = val.calibration;
      this.dataTrans.soft = val.soft;
      this.dataTrans.testSupport = val.testSupport;
      this.dataTrans.di = val.di;
      this.dataTrans.dcm = val.dcm;
      this.dataTrans.testNumber = val.testNumber;
      this.activityService.dataTrans = this.dataTrans;
    });
  }

  setDataTransfer() {
    this.payLoad = JSON.parse(JSON.stringify(this.form.value));
    this.dataTrans.comments = this.payLoad['comment'];
    this.dataTrans.engineNo = this.payLoad['engineNb'];
    this.dataTrans.febNb = this.payLoad['febNb'];
    this.dataTrans.engineDt = this.payLoad['engineDT'];
    this.dataTrans.calibration = this.payLoad['calibration'];
    this.dataTrans.soft = this.payLoad['soft'];
    this.dataTrans.testSupport = this.payLoad['testSupport'];
    this.dataTrans.di = this.payLoad['di'];
    this.dataTrans.dcm = this.payLoad['dcm'];
    this.dataTrans.testNumber = this.payLoad['testNumber'];
    this.activityService.dataTrans = this.dataTrans;

  }

  /** For Sacre Data Save/update */
  assignSacreData() {
    const engineActivity = new EngineActivity();
    const sacreData = this.commonService.getSacreData();
    if (sacreData != null) {
      engineActivity.rlcePre = sacreData.previsionnalRLCE;
      engineActivity.rlceReal = sacreData.realRLCE;
      engineActivity.milestone = sacreData.milestoneCode;
      engineActivity.status = sacreData.testStatus;
      if (sacreData.targetStartDate != null && sacreData.targetStartDate != undefined && sacreData.targetStartDate != '') {
        engineActivity.begTarget = moment(sacreData.targetStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      if (sacreData.targetEndDate != null && sacreData.targetEndDate != undefined && sacreData.targetEndDate != '') {
        engineActivity.endTarget = moment(sacreData.targetEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      engineActivity.testType = sacreData.testType;
      engineActivity.projectCode = sacreData.labelPI;
      if (sacreData.milestoneMaxDate != null && sacreData.milestoneMaxDate != undefined && sacreData.milestoneMaxDate != '') {
        engineActivity.milestoneMaxD = moment(sacreData.milestoneMaxDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      if (sacreData.milestoneMinDate != null && sacreData.milestoneMinDate != undefined && sacreData.milestoneMinDate != '') {
        engineActivity.milestoneMinD = moment(sacreData.milestoneMinDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      // engineActivity.rsType = sacreData.typeRS;

      engineActivity.essNBGps = sacreData.codeESS;
    }
    engineActivity.engNumber = this.payLoad['engineNb'] ? this.payLoad['engineNb'] : '';
    return engineActivity;

  }

  onSubmit() {
    // this.disableFlag = false;
    this.submitted = true;

    const temp = this.activityService.getFilterActivityForm();
    if (!temp.valid) {
      this.flagVal.emit('true');
      this.snackbarService.showSnackBar('Errormessage.common_form_invalid', 'F');
    }

    if (this.form.valid && temp.valid) {
      this.payLoad = JSON.parse(JSON.stringify(this.form.value));
      //Added for feb Number implementation in Tuning activity
      const isTuningBench = this.commonService.mean.maps.label === ActivityEnum.TuningActivity ? true : false;
      if (this.commonService.getCurrentActivityOperation() === 'TuningEngine Creation - test') {
        const tuningEdActivity = new TuningEdActivity();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const procedure = new Procedure();
        const macroActivity = new MacroActivity();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const exploitable = new Exploitable();
        const rsType = new RsType();
        tuningEdActivity.cameo = this.payLoad['EssaiCAMEO'];
        tuningEdActivity.engDt = this.payLoad['engineDT'];
        tuningEdActivity.febNb = this.payLoad['febNb'];
        tuningEdActivity.pointsNb = this.commonService.getFilterActivityFormData()['pointsNumber'];
        if (this.commonService.getFilterActivityFormData()['rsType'] != null) {
          rsType.id = this.commonService.getFilterActivityFormData()['rsType'];
          engineActivity.rsTypeId = rsType;
        } else {
          engineActivity.rsTypeId = null;
        }
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }

        tuningEdActivity.soft = this.payLoad['soft'];
        tuningEdActivity.dcm = this.payLoad['dcm'];
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        engineActivity.essNB = this.payLoad['testNumber'];
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        exploitable.id = this.commonService.getFilterActivityFormData()['exploitable'];
        activity.exploitable = exploitable;
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.procedures = procedure;
        activity.macroActivity = macroActivity;
        activity.campaigns = campaign;
        activity.means = this.commonService.getmean();
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = this.payLoad['calibration'];
        if (this.activityService.updateFlagForSeq && this.activityService.getactivityRowData() && this.dateCheckForSeq()) {
          activity.seqNum = this.activityService.getactivityRowData().seqNum ?
            this.activityService.getactivityRowData().seqNum : null;
        }
        if (this.commonService.getFunctionType() === DynamicConstants.duplicate) {
          activity.seqNum = null;
        }
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        tuningEdActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
        field to be visible for test activities & map essNBGps with essNb
        */
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//
        tuningEdActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        engineActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//

        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        tuningEdActivity.engineActivity = engineActivity;
        this.saveTuningEdActvity(tuningEdActivity);
      } else if (this.commonService.getCurrentActivityOperation() === 'TuningEngine Updation - test') {
        let rowData = new EngineActivityGridWrapper();
        rowData = this.activityService.getactivityRowData();
        this.hidedata = false;
        const tuningEdActivity = new TuningEdActivity();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const macroActivity = new MacroActivity();
        const procedure = new Procedure();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const exploitable = new Exploitable();
        const rsType = new RsType();
        this.tuningRowData = this.commonService.getEngineTuningActivityData()[0];
        tuningEdActivity.id = this.tuningRowData.id;
        tuningEdActivity.cameo = this.payLoad['EssaiCAMEO'];
        tuningEdActivity.engDt = this.payLoad['engineDT'];
        tuningEdActivity.febNb = this.payLoad['febNb'];
        tuningEdActivity.pointsNb = this.commonService.getFilterActivityFormData()['pointsNumber'];
        tuningEdActivity.soft = this.payLoad['soft'];
        tuningEdActivity.dcm = this.payLoad['dcm'];
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        activity.seqNum = rowData.seqNum;
        if (this.commonService.getFilterActivityFormData()['rsType'] != null) {
          rsType.id = this.commonService.getFilterActivityFormData()['rsType'];
          engineActivity.rsTypeId = rsType;
        }
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        engineActivity.essNB = this.payLoad['testNumber'];
        engineActivity.di = '';
        engineActivity.id = this.tuningRowData.engineActivity.id;
        activity.id = this.tuningRowData.engineActivity.activity.id;
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        exploitable.id = this.commonService.getFilterActivityFormData()['exploitable'];
        activity.exploitable = exploitable;
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.macroActivity = macroActivity;
        activity.procedures = procedure;
        activity.campaigns = campaign;
        activity.means = this.commonService.getmean();
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = this.payLoad['calibration'];
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        tuningEdActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
                field to be visible for test activities & map essNBGps with essNb
        */
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];

        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//
        tuningEdActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        engineActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//

        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        tuningEdActivity.engineActivity = engineActivity;
        this.commonService.setEngineTuningActivityData([tuningEdActivity]);
        if (this.commonService.getFunctionType() === 'duplicate') {
          tuningEdActivity.id = null;
          tuningEdActivity.engineActivity.id = null;
          tuningEdActivity.engineActivity.activity.id = null;
          tuningEdActivity.engineActivity.activity.seqNum = null;
          this.saveTuningEdActvity(tuningEdActivity);
        } else {
          this.updateTuningEdActvity(tuningEdActivity);
        }
      } else if (this.commonService.getCurrentActivityOperation() === 'Faliure Creation - test') {
        console.log('...failure creation test ... 339 ...');
        const failureActivity = new FailureActivity();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const procedure = new Procedure();
        const macroActivity = new MacroActivity();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const otTable = new OT();
        const otType = new OtType();
        const rsType = new RsType();
        const otGridVal = this.otStatusService.getOtGridValue();
        const testbedCoswinVal = this.activityService.getTestbedValue();
        if (testbedCoswinVal[0].testbedGlobalInfo.siteId.coswin === true) {
          if (this.otStatusService.getOtGridValue() !== undefined && this.otStatusService.getOtGridValue() !== null) {

            otType.id = otGridVal.ot_type ? otGridVal.ot_type.id : null;
            otTable.ot_type = otType;
            otTable.id = otGridVal ? otGridVal.id : null;
            otTable.status = otGridVal ? otGridVal.status : null;
            failureActivity.ot = otTable;
          } else {
            otTable.ot_type = null;
            otTable.id = null;
          }
        } else {
          failureActivity.otGlobal = this.commonService.getFilterActivityFormData()['otGlobal'];
        }
        failureActivity.engDt = this.payLoad['engineDT'];
        failureActivity.febNb = isTuningBench ? this.payLoad[ActivityEnum.FebNum] : null;
        if (this.commonService.getFilterActivityFormData()['rsType'] !== null) {

          rsType.id = this.commonService.getFilterActivityFormData()['rsType'];
          engineActivity.rsTypeId = rsType;
        } else {
          engineActivity.rsTypeId = null;
        }
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        failureActivity.soft = this.payLoad['soft'];
        failureActivity.dcm = this.payLoad['dcm'];
        failureActivity.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];

        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.macroActivity = macroActivity;
        activity.procedures = procedure;
        activity.campaigns = campaign;
        activity.typeOfTest = this.commonService.getFilterActivityFormData()['typeOfTest'];
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = this.payLoad['calibration'];
        activity.means = this.commonService.getmean();
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        failureActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
        field to be visible for test activities & map essNBGps with essNb
        */
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        activity.comment = this.payLoad['comment'];

        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//
        engineActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//

        if (this.activityService.updateFlagForSeq && this.activityService.getactivityRowData() && this.dateCheckForSeq()) {
          activity.seqNum = this.activityService.getactivityRowData().seqNum ?
            this.activityService.getactivityRowData().seqNum : null;
        }
        if (this.commonService.getFunctionType() === DynamicConstants.duplicate) {
          activity.seqNum = null;
        }
        engineActivity.activity = activity;
        failureActivity.engineActivity = engineActivity;
        this.saveFailureActvity(failureActivity);
      } else if (this.commonService.getCurrentActivityOperation() === 'Failure Updation - test') {
        console.log('...failure updation test ... 443 ...');
        let rowData = new EngineActivityGridWrapper();
        rowData = this.activityService.getactivityRowData();
        console.log('rowData===+++', rowData);
        const fullSelectedData = this.commonService.getFailureActivityData();
        console.log('rowData===+++', fullSelectedData);
        if (this.activityService.getOtValFlag() === true) {

          this.otValGrid = this.otStatusService.getOtGridValue();
        }
        const failureActivity = new FailureActivity();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const macroActivity = new MacroActivity();
        const procedure = new Procedure();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const otTable = new OT();
        const otType = new OtType();
        const rsType = new RsType();
        this.failureRowData = this.commonService.getFailureActivityData()[0];
        failureActivity.id = this.failureRowData.id;
        // for new OT pop-up
        const testbedCoswinVal = this.activityService.getTestbedValue();
        failureActivity.engDt = this.payLoad['engineDT'];
        failureActivity.febNb = isTuningBench ? this.payLoad[ActivityEnum.FebNum] : null;
        if (this.commonService.getFilterActivityFormData()['rsType'] !== null) {
          rsType.id = this.commonService.getFilterActivityFormData()['rsType'];
          engineActivity.rsTypeId = rsType;
        } else {
          engineActivity.rsTypeId = null;
        }
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        failureActivity.soft = this.payLoad['soft'];
        failureActivity.dcm = this.payLoad['dcm'];
        failureActivity.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];


        activity.seqNum = rowData.seqNum;
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        engineActivity.id = this.failureRowData.engineActivity.id;
        engineActivity.di = '';
        engineActivity.essNB = '';
        activity.id = this.failureRowData.engineActivity.activity.id;
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.procedures = procedure;
        activity.macroActivity = macroActivity;
        activity.campaigns = campaign;
        activity.typeOfTest = this.commonService.getFilterActivityFormData()['typeOfTest'];
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = this.payLoad['calibration'];
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        failureActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        activity.means = this.commonService.getmean();
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
        field to be visible for test activities & map essNBGps with essNb
        */
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];

        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//
        engineActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//
        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        failureActivity.engineActivity = engineActivity;
        //clearing OT for Panne me duplicate activity
        const level0Data = this.activityService.levelsAdmin;
        const selectedLevel0Data = level0Data.filter(res => (res.level0.id === failureActivity.engineActivity.activity.level0.id &&
          res.level1.id === failureActivity.engineActivity.activity.level1.id &&
          res.level2.id === failureActivity.engineActivity.activity.level2.id));
        if (testbedCoswinVal[0].testbedGlobalInfo.siteId.coswin === true) {
          if (this.commonService.getFunctionType() === 'duplicate' && selectedLevel0Data[0].level0.label === 'PANNE ME') {
            if (this.otValGrid === undefined || this.otValGrid === null) {
              otTable.ot_type = null;
              otTable.id = null;
              otTable.status = null;
            } else {
              otType.id = this.otValGrid.ot_type ? this.otValGrid.ot_type.id : null;
              otTable.id = this.otValGrid ? this.otValGrid.id : null;
              otTable.status = this.otValGrid ? this.otValGrid.status : null;
              otTable.ot_type = otType;
              failureActivity.ot = otTable;
            }
          } else {
            if ((this.otValGrid === undefined || this.otValGrid === null) && fullSelectedData[0].ot === null) {
              otTable.ot_type = null;
              otTable.id = null;
            } else {
              if (this.otValGrid === undefined || this.otValGrid === null) {
                otType.id = fullSelectedData[0].ot ? fullSelectedData[0].ot.ot_type ? fullSelectedData[0].ot.ot_type.id : null : null;
                otTable.id = fullSelectedData[0].ot ? fullSelectedData[0].ot.id : null;
                otTable.status = fullSelectedData[0].ot ? fullSelectedData[0].ot.status : null;
              } else {
                otType.id = this.otValGrid.ot_type ? this.otValGrid.ot_type.id : null;
                otTable.id = this.otValGrid ? this.otValGrid.id : null;
                otTable.status = this.otValGrid ? this.otValGrid.status : null;
              }
              otTable.ot_type = otType;
              failureActivity.ot = otTable;
            }
          }
        } else {
          failureActivity.otGlobal = this.commonService.getFilterActivityFormData()['otGlobal'];
        }
        if (this.commonService.getFunctionType() === 'duplicate') {
          failureActivity.id = null;
          failureActivity.engineActivity.id = null;
          failureActivity.engineActivity.activity.id = null;
          failureActivity.engineActivity.activity.seqNum = null;
          this.saveFailureActvity(failureActivity);
        } else {
          this.activityService.updateFailureActvity(failureActivity).subscribe((response) => {
            if (response.body) {
              this.activitycommonService.setupdatedSelRow(null);
              this.activitycommonService.setupdatedSelRow(failureActivity);
              this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
              this.commonService.setFormChanged(false);
              this.closeDynamicForm.emit('close');
            } else {
              this.snackbarService.showSnackBar('Common_Buttons.Fail', 'F');
              this.activityService.refreshGrid(this.commonService.getmean().maps.label);
            }
          });
        }
      } else if (this.commonService.getCurrentActivityOperation() === 'TuningEngine Creation - nontest') {
        const tuningEdActivity = new TuningEdActivity();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const macroActivity = new MacroActivity();
        const procedure = new Procedure();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const rsType = new RsType();
        tuningEdActivity.cameo = '';
        tuningEdActivity.engDt = this.payLoad['engineDT'];
        tuningEdActivity.febNb = this.payLoad['febNb'];
        tuningEdActivity.pointsNb = null;
        tuningEdActivity.soft = '';
        tuningEdActivity.dcm = this.payLoad['dcm'];
        engineActivity.essNB = '';
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        if (this.commonService.getFilterActivityFormData()['rsType'] !== null) {
          rsType.id = this.commonService.getFilterActivityFormData()['rsType'];
          engineActivity.rsTypeId = rsType;
        } else {
          engineActivity.rsTypeId = null;
        }
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber
              field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.di = this.payLoad['di'] ? this.payLoad['di'] : '';
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.macroActivity = macroActivity;
        activity.procedures = procedure;
        activity.campaigns = campaign;
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = '';
        activity.means = this.commonService.getmean();
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        tuningEdActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        activity.comment = this.payLoad['comment'];
        if (this.activityService.updateFlagForSeq && this.activityService.getactivityRowData() && this.dateCheckForSeq()) {
          activity.seqNum = this.activityService.getactivityRowData().seqNum ?
            this.activityService.getactivityRowData().seqNum : null;
        }
        if (this.commonService.getFunctionType() === DynamicConstants.duplicate) {
          activity.seqNum = null;
        }
        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//
        tuningEdActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        engineActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//

        engineActivity.activity = activity;
        tuningEdActivity.engineActivity = engineActivity;
        this.saveTuningEdActvity(tuningEdActivity);

      } else if (this.commonService.getCurrentActivityOperation() === 'TuningEngine Updation - nontest') {
        let rowData = new EngineActivityGridWrapper();
        rowData = this.activityService.getactivityRowData();
        this.hidedata = false;
        const tuningEdActivity = new TuningEdActivity();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const procedure = new Procedure();
        const macroActivity = new MacroActivity();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const rsType = new RsType();
        this.tuningRowData = this.commonService.getEngineTuningActivityData()[0];
        tuningEdActivity.id = this.tuningRowData.id;
        tuningEdActivity.cameo = '';
        tuningEdActivity.engDt = this.payLoad['engineDT'];
        tuningEdActivity.febNb = this.payLoad['febNb'];
        tuningEdActivity.pointsNb = null;
        tuningEdActivity.soft = '';
        tuningEdActivity.dcm = '';
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        // engineActivity.engNumber = this.payLoad['engineNb'] ? this.payLoad['engineNb'] : '';
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber
              field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.di = this.payLoad['di'] ? this.payLoad['di'] : '';
        engineActivity.essNB = '';
        activity.seqNum = rowData.seqNum;
        if (this.commonService.getFilterActivityFormData()['rsType'] !== null) {
          rsType.id = this.commonService.getFilterActivityFormData()['rsType'];
          engineActivity.rsTypeId = rsType;
        } else {
          engineActivity.rsTypeId = null;
        }
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        engineActivity.id = this.tuningRowData.engineActivity.id;
        activity.id = this.tuningRowData.engineActivity.activity.id;
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.procedures = procedure;
        activity.macroActivity = macroActivity;
        activity.campaigns = campaign;
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.exploitable = this.commonService.getFilterActivityFormData()['exploitable'];
        activity.calibration = '';
        activity.means = this.commonService.getmean();
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        tuningEdActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];

        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//
        tuningEdActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        engineActivity.tuningTOH = this.commonService.getFilterActivityFormData()['tuningTOH'];
        // ADDED FOR US506- ON ADDITION OF YES/NO SELECTOR FOR TUNING//

        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        tuningEdActivity.engineActivity = engineActivity;

        if (this.commonService.getFunctionType() === 'duplicate') {
          tuningEdActivity.id = null;
          tuningEdActivity.engineActivity.id = null;
          tuningEdActivity.engineActivity.activity.id = null;
          tuningEdActivity.engineActivity.activity.seqNum = null;
          this.saveTuningEdActvity(tuningEdActivity);
        } else {
          this.updateTuningEdActvity(tuningEdActivity);
        }

      } else if (this.commonService.getCurrentActivityOperation() === 'Reliability Creation - test') {
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const procedure = new Procedure();
        const macroActivity = new MacroActivity();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const reliability = new EngineReliability();
        const exploitable = new Exploitable();
        reliability.soft = this.payLoad['soft'];
        reliability.dcm = this.payLoad['dcm'];
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];

        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.essNB = this.payLoad['testNumber'];
        engineActivity.di = '';
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        exploitable.id = this.commonService.getFilterActivityFormData()['exploitable'];
        activity.exploitable = exploitable;
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.procedures = procedure;
        activity.macroActivity = macroActivity;
        activity.campaigns = campaign;
        activity.means = this.commonService.getmean();
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = this.payLoad['calibration'];
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        reliability.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        engineActivity.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        // Failure activity visualization & reliability has been modified for US501//
        reliability.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];

        if (this.activityService.updateFlagForSeq && this.activityService.getactivityRowData() && this.dateCheckForSeq()) {
          activity.seqNum = this.activityService.getactivityRowData().seqNum ?
            this.activityService.getactivityRowData().seqNum : null;
        }
        if (this.commonService.getFunctionType() === DynamicConstants.duplicate) {
          activity.seqNum = null;
        }
        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        reliability.engineActivity = engineActivity;
        this.saveReliabilityEdActivity(reliability);
      } else if (this.commonService.getCurrentActivityOperation() === 'Reliability Updation - test') {
        this.hidedata = false;
        let rowData = new EngineActivityGridWrapper();
        rowData = this.activityService.getactivityRowData();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const procedure = new Procedure();
        const macroActivity = new MacroActivity();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const reliability = new EngineReliability();
        const exploitable = new Exploitable();
        this.reliabilityRowData = this.commonService.getEngineReliabilityActivityData()[0];
        reliability.id = this.reliabilityRowData.id;
        reliability.soft = this.payLoad['soft'];
        reliability.cust_req = this.payLoad['customerRequest'];
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
       
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.essNB = this.payLoad['testNumber'];
        engineActivity.di = '';
        engineActivity.id = this.reliabilityRowData.engineActivity.id;
        activity.id = this.reliabilityRowData.engineActivity.activity.id;
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.seqNum = rowData.seqNum;
        exploitable.id = this.commonService.getFilterActivityFormData()['exploitable'];
        activity.exploitable = exploitable;
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.procedures = procedure;
        activity.macroActivity = macroActivity;
        activity.campaigns = campaign;
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = this.payLoad['calibration'];
        activity.means = this.commonService.getmean();
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        reliability.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        engineActivity.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        // Failure activity visualization & reliability has been modified for US501//
        reliability.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];

        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        reliability.engineActivity = engineActivity;

        if (this.commonService.getFunctionType() === 'duplicate') {
          reliability.id = null;
          reliability.engineActivity.id = null;
          reliability.engineActivity.activity.id = null;
          reliability.engineActivity.activity.seqNum = null;
          this.saveReliabilityEdActivity(reliability);
        } else {
          this.updateReliabilityEdActivity(reliability);
        }

      } else if (this.commonService.getCurrentActivityOperation() === 'Reliability Creation - nontest') {
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const procedure = new Procedure();
        const macroActivity = new MacroActivity();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const reliability = new EngineReliability();
        reliability.soft = '';
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.di = this.payLoad['di'] ? this.payLoad['di'] : '';
        engineActivity.essNB = '';
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.procedures = procedure;
        activity.macroActivity = macroActivity;
        activity.campaigns = campaign;
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.exploitable = this.commonService.getFilterActivityFormData()['exploitable'];
        activity.calibration = '';
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        reliability.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        activity.means = this.commonService.getmean();
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        // Failure activity visualization & reliability has been modified for US501//
        reliability.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];
        engineActivity.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];
        if (this.activityService.updateFlagForSeq && this.activityService.getactivityRowData() && this.dateCheckForSeq()) {
          activity.seqNum = this.activityService.getactivityRowData().seqNum ?
            this.activityService.getactivityRowData().seqNum : null;
        }
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        if (this.commonService.getFunctionType() === DynamicConstants.duplicate) {
          activity.seqNum = null;
        }
        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        reliability.engineActivity = engineActivity;
        this.saveReliabilityEdActivity(reliability);
      } else if (this.commonService.getCurrentActivityOperation() === 'Reliability Updation - nontest') {
        this.hidedata = false;
        let rowData = new EngineActivityGridWrapper();
        rowData = this.activityService.getactivityRowData();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const procedure = new Procedure();
        const macroActivity = new MacroActivity();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const reliability = new EngineReliability();
        this.reliabilityRowData = this.commonService.getEngineReliabilityActivityData()[0];
        reliability.id = this.reliabilityRowData.id;
        reliability.soft = '';
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        engineActivity.di = this.payLoad['di'] ? this.payLoad['di'] : '';
        engineActivity.essNB = '';
        engineActivity.id = this.reliabilityRowData.engineActivity.id;
        activity.id = this.reliabilityRowData.engineActivity.activity.id;
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.seqNum = rowData.seqNum;
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.macroActivity = macroActivity;
        activity.procedures = procedure;
        activity.campaigns = campaign;
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = '';
        activity.means = this.commonService.getmean();
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        reliability.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        reliability.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];
        // Failure activity visualization & reliability has been modified for US501//
        engineActivity.bvRlce = this.commonService.getFilterActivityFormData()['bvRlce'];

        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        reliability.engineActivity = engineActivity;
        if (this.commonService.getFunctionType() === 'duplicate') {
          reliability.id = null;
          reliability.engineActivity.id = null;
          reliability.engineActivity.activity.id = null;
          reliability.engineActivity.activity.seqNum = null;
          this.saveReliabilityEdActivity(reliability);
        } else {
          this.updateReliabilityEdActivity(reliability);
        }
      } else if (this.commonService.getCurrentActivityOperation() === 'Electrical Creation - test') {
        const electrical = new EvEngine();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const macroActivity = new MacroActivity();
        const procedure = new Procedure();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const exploitable = new Exploitable();
        electrical.realNb = this.payLoad['realNb'];
        electrical.testSupport = this.payLoad['testSupport'];
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.essNB = this.payLoad['testNumber'];
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        exploitable.id = this.commonService.getFilterActivityFormData()['exploitable'];
        activity.exploitable = exploitable;
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.macroActivity = macroActivity;
        activity.procedures = procedure;
        activity.campaigns = campaign;
        activity.typeOfTest = this.commonService.getFilterActivityFormData()['typeOfTest'];
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = '';
        activity.means = this.commonService.getmean();
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        electrical.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.testedVS = this.payLoad['vsNb'];
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        if (this.activityService.updateFlagForSeq && this.activityService.getactivityRowData() && this.dateCheckForSeq()) {
          activity.seqNum = this.activityService.getactivityRowData().seqNum ?
            this.activityService.getactivityRowData().seqNum : null;
        }
        if (this.commonService.getFunctionType() === DynamicConstants.duplicate) {
          activity.seqNum = null;
        }
        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        electrical.engineActivity = engineActivity;
        this.saveEvEngine(electrical);
      } else if (this.commonService.getCurrentActivityOperation() === 'Electrical Creation - nontest') {
        const electrical = new EvEngine();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const macroActivity = new MacroActivity();
        const procedure = new Procedure();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        electrical.realNb = this.payLoad['realNb'];
        electrical.testSupport = this.payLoad['testSupport'];
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        // engineActivity.engNumber = this.payLoad['engineNb'] ? this.payLoad['engineNb'] : '';
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.di = this.payLoad['di'] ? this.payLoad['di'] : '';
        engineActivity.essNB = '';
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.macroActivity = macroActivity;
        activity.procedures = procedure;
        activity.campaigns = campaign;
        activity.typeOfTest = this.commonService.getFilterActivityFormData()['typeOfTest'];
        activity.means = this.commonService.getmean();
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = '';
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        electrical.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.testedVS = '';
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        if (this.activityService.updateFlagForSeq && this.activityService.getactivityRowData() && this.dateCheckForSeq()) {
          activity.seqNum = this.activityService.getactivityRowData().seqNum ?
            this.activityService.getactivityRowData().seqNum : null;
        }
        if (this.commonService.getFunctionType() === DynamicConstants.duplicate) {
          activity.seqNum = null;
        }
        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        electrical.engineActivity = engineActivity;
        this.saveEvEngine(electrical);
      } else if (this.commonService.getCurrentActivityOperation() === 'Electrical Updation - test') {
        let rowData = new EngineActivityGridWrapper();
        rowData = this.activityService.getactivityRowData();
        this.hidedata = false;
        const electrical = new EvEngine();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const macroActivity = new MacroActivity();
        const procedure = new Procedure();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        const exploitable = new Exploitable();
        this.electricalRowData = this.commonService.getEvEngineData()[0];
        electrical.id = this.electricalRowData.id;
        electrical.realNb = this.payLoad['realNb'];
        electrical.testSupport = this.payLoad['testSupport'];
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber 
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.essNB = this.payLoad['testNumber'];
        engineActivity.di = '';
        engineActivity.id = this.electricalRowData.engineActivity.id;
        activity.id = this.electricalRowData.engineActivity.activity.id;
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        exploitable.id = this.commonService.getFilterActivityFormData()['exploitable'];
        activity.exploitable = exploitable;
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.macroActivity = macroActivity;
        activity.procedures = procedure;
        activity.campaigns = campaign;
        activity.typeOfTest = this.commonService.getFilterActivityFormData()['typeOfTest'];
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = '';
        activity.means = this.commonService.getmean();
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        electrical.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.testedVS = this.payLoad['vsNb'];
        activity.seqNum = rowData.seqNum;
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber
      field to be visible for test activities & map essNBGps with essNb
      */
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];

        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        electrical.engineActivity = engineActivity;
        if (this.commonService.getFunctionType() === DynamicConstants.duplicate) {
          electrical.id = null;
          electrical.engineActivity.id = null;
          electrical.engineActivity.activity.id = null;
          electrical.engineActivity.activity.seqNum = null;
          this.saveEvEngine(electrical);
        } else {
          this.updateEvEngine(electrical);
        }
      } else if (this.commonService.getCurrentActivityOperation() === 'Electrical Updation - nontest') {
        let rowData = new EngineActivityGridWrapper();
        rowData = this.activityService.getactivityRowData();
        this.hidedata = false;
        const electrical = new EvEngine();
        const engineActivity = this.assignSacreData();
        const activity = new Activity();
        const level0 = new Level0();
        const level1 = new Level1();
        const level2 = new Level2();
        const prestation = new Prestation();
        const macroActivity = new MacroActivity();
        const procedure = new Procedure();
        const customerDirection = new CustomerDirection();
        const campaign = new Campaign();
        const testbedGlobalInfo = new Testbed();
        this.electricalRowData = this.commonService.getEvEngineData()[0];
        electrical.id = this.electricalRowData.id;
        electrical.realNb = this.payLoad['realNb'];
        electrical.testSupport = this.payLoad['testSupport'];
        engineActivity.engHours = this.commonService.getFilterActivityFormData()['engHours'];
        
        const fuel = new Fuel();
        if (this.commonService.getFilterActivityFormData()['fuel'] !== null) {
          fuel.id = this.commonService.getFilterActivityFormData()['fuel'];
          engineActivity.fuel = fuel;
        } else {
          engineActivity.fuel = null;
        }
        /* US385 - As a user I want the DI field to be visible for non-test activities & testNumber
        field to be visible for test activities & map essNBGps with essNb */
        engineActivity.di = this.payLoad['di'] ? this.payLoad['di'] : '';
        engineActivity.essNB = '';
        engineActivity.id = this.electricalRowData.engineActivity.id;
        activity.id = this.electricalRowData.engineActivity.activity.id;
        level0.id = this.commonService.getFilterActivityFormData()['activityTypeDropDown'];
        level1.id = this.commonService.getFilterActivityFormData()['level1'];
        level2.id = this.commonService.getFilterActivityFormData()['level2'];
        prestation.id = this.commonService.getFilterActivityFormData()['prestation'];
        macroActivity.id = this.commonService.getFilterActivityFormData()['macroActivity'];
        procedure.id = this.commonService.getFilterActivityFormData()['procedure'];
        customerDirection.id = this.commonService.getFilterActivityFormData()['customerDirection'];
        campaign.id = this.commonService.getFilterActivityFormData()['campaign'];
        activity.level0 = level0;
        activity.level1 = level1;
        activity.level2 = level2;
        activity.customerDirection = customerDirection;
        activity.prestations = prestation;
        activity.macroActivity = macroActivity;
        activity.procedures = procedure;
        activity.campaigns = campaign;
        activity.typeOfTest = this.commonService.getFilterActivityFormData()['typeOfTest'];
        activity.means = this.commonService.getmean();
        activity.ipn = this.commonService.getFilterActivityFormData()['ipn'];
        activity.seqNum = rowData.seqNum;
        activity.activityDate = this.commonService.getFilterActivityFormData()['engineDate'];
        activity.duration = this.commonService.getFilterActivityFormData()['activityDuration'];
        activity.customerRequest = this.commonService.getFilterActivityFormData()['customerRequest'];
        activity.calibration = '';
        testbedGlobalInfo.id = this.activityService.getTestBedFormData()['selectedTestBed'];
        activity.testBedId = testbedGlobalInfo;
        activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        electrical.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.testedVS = '';
        engineActivity.programLine = this.commonService.getFilterActivityFormData()['pgmLines'];
        // engineActivity.essNB = this.commonService.getFilterActivityFormData()['essNb'];
        engineActivity.essNBGps = this.commonService.getFilterActivityFormData()['essNb'];
        activity.comment = this.payLoad['comment'];
        engineActivity.activity = activity;
        electrical.engineActivity = engineActivity;
        if (this.commonService.getFunctionType() === 'duplicate') {
          electrical.id = null;
          electrical.engineActivity.id = null;
          electrical.engineActivity.activity.id = null;
          electrical.engineActivity.activity.seqNum = null;
          this.saveEvEngine(electrical);
        } else {
          this.updateEvEngine(electrical);
        }
      }
      this.commonService.setSacreData(null);
      this.activityService.setSacreFlag(false);
    } else {
      this.snackbarService.showSnackBar('Errormessage.common_form_invalid', 'F');
    }

  }
  onCancel() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Cancel.Warningmessage'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.setSacreData(null);
        this.activityService.setSacreFlag(false);
        this.activityService.sansOtUpdateFlag = false;
        this.barService.setBarFormDirty(false);
        this.cancelDynamicForm.emit('close');
      }
    });
  }

  saveEvEngine(electrical) {
    this.activityService.saveEvEngine(electrical).subscribe((response) => {
      if (response.body) {
        this.activitycommonService.setupdatedSelRow(null);
        this.activitycommonService.setupdatedSelRow(response.body);
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        this.commonService.setFormChanged(false);
        this.closeDynamicForm.emit('close');
      } else {
        this.snackbarService.showSnackBar('Common_Buttons.Fail', 'F');
        this.activityService.refreshGrid(this.commonService.getmean().maps.label);
      }
    });
  }

  saveTuningEdActvity(tuningEdActivity) {
    this.activityService.saveTuningEdActvity(tuningEdActivity).subscribe((response) => {
      if (response.body) {
        this.activitycommonService.setupdatedSelRow(null);
        this.activitycommonService.setupdatedSelRow(response.body);
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        this.commonService.setFormChanged(false);
        if (this.commonService.getFunctionType() === 'duplicate') {
          this.commonService.setFilterFormData(false);
        }
        this.closeDynamicForm.emit('close');
      } else {
        this.snackbarService.showSnackBar('Common_Buttons.Fail', 'F');
        this.commonService.setFormChanged(false);
        this.activityService.refreshGrid(this.commonService.getmean().maps.label);
      }
    });
  }

  saveFailureActvity(failureActivity) {
    this.activityService.saveFailureActvity(failureActivity).subscribe((response) => {
      if (response.body) {
        this.activitycommonService.setupdatedSelRow(null);
        this.activitycommonService.setupdatedSelRow(response.body);
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        this.commonService.setFormChanged(false);
        this.closeDynamicForm.emit('close');
      } else {
        this.snackbarService.showSnackBar('Common_Buttons.Fail', 'F');
        this.commonService.setFormChanged(false);
        this.activityService.refreshGrid(this.commonService.getmean().maps.label);
      }
    });
  }
  saveReliabilityEdActivity(reliability) {
    this.activityService.saveReliabilityEdActivity(reliability).subscribe((response) => {
      if (response.body) {
        this.activitycommonService.setupdatedSelRow(null);
        this.activitycommonService.setupdatedSelRow(response.body);
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        this.commonService.setFormChanged(false);
        this.closeDynamicForm.emit('close');
      } else {
        this.snackbarService.showSnackBar('Common_Buttons.Fail', 'F');
        this.commonService.setFormChanged(false);
      }
    });
  }
  updateTuningEdActvity(tuningEdActivity) {
    this.activityService.updateTuningEdActvity(tuningEdActivity).subscribe((response) => {
      if (response.body) {
        this.activitycommonService.setupdatedSelRow(null);
        this.activitycommonService.setupdatedSelRow(tuningEdActivity);
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        this.commonService.setFormChanged(false);
        this.closeDynamicForm.emit('close');
      } else {
        this.snackbarService.showSnackBar('Common_Buttons.Fail', 'F');
        this.activityService.refreshGrid(this.commonService.getmean().maps.label);
      }
    });
  }
  updateReliabilityEdActivity(reliability) {
    this.activityService.updateReliabilityEdActivity(reliability).subscribe((response) => {
      if (response.body) {
        this.activitycommonService.setupdatedSelRow(null);
        this.activitycommonService.setupdatedSelRow(reliability);
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        this.commonService.setFormChanged(false);
        this.closeDynamicForm.emit('close');
      } else {
        this.snackbarService.showSnackBar('Common_Buttons.Fail', 'F');
        this.activityService.refreshGrid(this.commonService.getmean().maps.label);
      }
    });
  }
  updateEvEngine(electrical) {
    this.activityService.updateEvEngine(electrical).subscribe((response) => {
      if (response.body) {
        this.activitycommonService.setupdatedSelRow(null);
        this.activitycommonService.setupdatedSelRow(electrical);
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        this.commonService.setFormChanged(false);
        this.closeDynamicForm.emit('close');
      } else {
        this.snackbarService.showSnackBar('Common_Buttons.Fail', 'F');
        this.activityService.refreshGrid(this.commonService.getmean().maps.label);
      }
    });
  }
  dateCheckForSeq(): boolean {
    const formactivityDate = moment(this.commonService.getFilterActivityFormData()['engineDate']).format('YYYY-MM-DD');
    const activityDate = moment(this.activityService.getactivityRowData().activityDate).format('YYYY-MM-DD');

    console.log('formactivityDate>>>>>', formactivityDate, ' activityDate>>>>', activityDate);
    if (activityDate === formactivityDate) {
      return true;
    } else {
      return false;
    }
  }
}