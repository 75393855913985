import { Level0 } from "../level0/level0.model";

export class StoppageType {
    id: number;
    activityTypeId: Level0;
    label: String;
    organisationId: number[];
    active: Boolean;
    createDate: Date;
    updateDate: Date;
    ipn: String;
    typeOfStoppage: string;
}

export enum StoppageTypeEng {
    PANNEME = 'PANNE ME',
    PANOUTIL = 'PANOUTIL',
    PANSUPPCL = 'PANSUPPCL',
    CLIENT = 'CLIENT',
    BANCVIDE = 'BANC VIDE',
    ATTENTE3M = 'ATTENTE 3M',
    create = 'create',
    update = 'update',
    successBtn = 'Common_Buttons.Success',
    stoppageType = 'StoppageType',
    existing = 'Errormessage.stoppage_exist',
    success = 'S',
    fail = 'F',
    orgId = 'organisationIDs',
    commonUpdate = 'Common_Buttons.Update',
    uid = 'uid',
    true = 'true',
    user = 'User',
    ENVIR = 'ENVIR',
}