import { Site } from '../sites/site.model';

import { Campaign } from '../campaign/campaign.model';

import { Means } from '../means/means.model';

export class CampaignAssociation {
    id: number;
    sites: Site;
    campaigns: Campaign;
    means: Means;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: Boolean;
    organisationId: number[];
}
