import { CrrppInit } from './crrppInit.model';

export class MqmChannels{
   public id: number;
   public crrppId: CrrppInit;
   public label: String;
   public expirationDate: Date;
   public dateAqm: boolean;
   public createDate: Date;
   public updateDate: Date;
   public ipn: string;
}

export enum constants {
   uid = 'uid',
   user = 'User',
   successMsg = 'Common_Buttons.Success',
   dateFormat = 'DD/MM/YYYY',
   updateBtn = 'Common_Buttons.Update',
   createBtn = 'Common_Buttons.Add_Record',
   mqmChannels = 'CRRP.MQM Channels.MQM Channels',
   expirationDate = 'CRRP.MQM Channels.Expiration date',
   expirationDateValue = 'expirationDate',
   true = 'true',
   dateAqm = 'CRRP.MQM Channels.Mqm_checkbox',
   false = 'false'
}

export enum errorMessages {
   mqmChannelExist = 'CRRP.MQM Channels.Already_Exist',
   fail = 'F',
   success = 'S'
}
