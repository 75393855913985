import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TestbedGlobalInfo } from 'src/app/administration/testbedreferential/testbed/global-info/testbed-global-info.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MapModel } from 'src/app/administration/referentiel/means/map.model';
import { MultiSelectModel } from './muti-select.model';

@Injectable({
  providedIn: 'root'
})
export class MultiSelectService {
  public mapData: any;
  private baseString: string;
  meanIdMapping: any[];
  siteIdMapping: any[];
  mapIdMapping: any[];
  BuildingIdMapping: any[];
  constructor(private http: HttpClient) {
    this.baseString = environment.apiURL;
  }

  setMapData(value: any) {
    this.mapData = value;
  }
  getMapData() {
    return this.mapData;
  }
  // When map value is chosen the site value gets filtered
  getFilterSite(filterSite: TestbedGlobalInfo) {
    return this.http.post(this.baseString + '/getFilterSite', filterSite).pipe(map(res => res));
  }

  getMeansComboListStatus(): Observable<TestbedGlobalInfo[]> {
    const orgId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getMeanComboListStatus/' + orgId);
  }

  getFilterMean(filterMean: TestbedGlobalInfo) {
    return this.http.post(this.baseString + '/getFilterMean', filterMean).pipe(map(res => res));
  }
  getFilterBulding(filterBulding: TestbedGlobalInfo) {
    return this.http.post(this.baseString + '/getFilterBuilding', filterBulding).pipe(map(res => res));
  }
}
