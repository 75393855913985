import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'redirectaction',
  templateUrl: './redirectaction.component.html',
  styleUrls: ['./redirectaction.component.css']
})
export class RedirectactionComponent implements OnInit {
  gcpRedirectionEnvUrl: string;
  constructor() { }

  ngOnInit() {
    const envName: string = sessionStorage.getItem('envName');
    this.getUrlBasedonEnv(envName);
  }

  getUrlBasedonEnv(envName: string) {
    console.log(envName, 'envName');
     if (envName === 'DEV') {
       this.gcpRedirectionEnvUrl = 'https://tp2-dev.gke2.dev.gcp.renault.com';
     } else if (envName === 'QUA') {
       this.gcpRedirectionEnvUrl = 'https://tp2-qua.gke2.dev.gcp.renault.com';
     } else if (envName === 'RE7') {
       this.gcpRedirectionEnvUrl = 'https://tp2-re7.gke2.int.gcp.renault.com';
     } else if (envName === 'OPE') {
       this.gcpRedirectionEnvUrl = 'https://tp2-ope.gke2.ope.gcp.renault.com';
     } else if (envName === 'STA') {
       this.gcpRedirectionEnvUrl = 'https://tp2-sta.gke2.dev.gcp.renault.com';
     } else {
       console.log('test1');
       this.gcpRedirectionEnvUrl = 'https://int.tp2.dev.aws.renault.com';
     }

 }

}
