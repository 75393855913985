import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from './menu.services';
import { MenuEnum, MenuModel } from './menu.model';
import { SnackBarService } from '../snackbar/snackbar.component.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public deatvMenuAcess: boolean;
  public adminCheck: boolean;

  constructor(public translate: TranslateService,
    public menuService: MenuService,
    public snackbarService: SnackBarService,
    private messageService: MessageService) {
  }

  menuAccess(menuHeader: string, menuName: string) {
    sessionStorage.setItem('roleAction', '');
    const menuAccess = new MenuModel();
    menuAccess.menuHeader = menuHeader;
    menuAccess.menuName = menuName;
    menuAccess.rolesNames = JSON.parse(sessionStorage.getItem('role'));
    const onlyRoles = sessionStorage.getItem('onlyRoles');
    if (onlyRoles === 'true') {
      this.menuService.getMenuActionByRoles(menuAccess).subscribe((response) => {
        sessionStorage.setItem('roleAction', JSON.stringify(response.menuAction));
      });
    } else if (onlyRoles === 'false') {
      this.snackbarService.showSnackBar('Roles_Access.errMsg', 'F');
      return false;
    } else {
      this.messageService.sendMessage('logout');
    }
  }

  ngOnInit() {
    const orgId: number[] = JSON.parse(sessionStorage.getItem(MenuEnum.OrgId));
    this.deatvMenuAcess = (orgId && (orgId.length === 1 && orgId[0] === 2)) ? false : true;
    //const rolesAssigned = JSON.parse(sessionStorage.getItem('role'));
    const rolesAssigned = sessionStorage.getItem('role');
    if (rolesAssigned.toLowerCase().includes('tpm2-admin')) {
      this.adminCheck = true;
    } else {
      this.adminCheck = false;
    }
  }

}
