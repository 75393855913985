import { Site } from 'src/app/administration/referentiel/sites/site.model';
import { Means } from 'src/app/administration/referentiel/means/means.model';
import { Perimeters } from 'src/app/administration/referentiel/perimeters/perimeters.model';
import { Building } from 'src/app/administration/referentiel/buildings/building.model';
import { Teams } from 'src/app/administration/referentiel/teams/teams.model';
import { Batch } from 'src/app/administration/referentiel/batch/batch.model';
import { Services } from 'src/app/administration/referentiel/services/services.model';
import { Exploitants } from '../../exploitants/exploitants.model';


export class TestbedGlobalInfo {

    public id: number;
    public siteId: Site;
    public meanId: Means;
    public process: string;
    public perimeterId: Perimeters;
    public buildingId: Building;
    public teamId: Teams;
    public batch: Batch;
    public functioning: string;
    public vsd: boolean;
    public serviceId: Services;
    /*     the teamnumber variable was changed from organisation for the
        story US393 - As a user I want to specify the testbed's organisation while creating it */
    public teamNumber: number;
    public exploitant: Exploitants;
    public s3m: string;
    public designation: string;
    public expertiseDomain: string;
    public testType: string;
    public year: number;
    public technicalParameter: string;
    public fermetureStart: Date;
    public fermetureEnd: Date;

}
