import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavTabsComponent } from './nav-tabs.component';
import { I18nModule } from '../i18n/i18n.module';

@NgModule({
  declarations: [NavTabsComponent],
  imports: [
    I18nModule,
    CommonModule
  ],
  exports: [NavTabsComponent],
})
export class NavTabsModule { }
