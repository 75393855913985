import { Component, OnInit } from '@angular/core';
declare var $: any;
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-common-button',
  templateUrl: './common-button.component.html',
  styleUrls: ['./common-button.component.css']
})
export class CommonButtonComponent implements OnInit {
  sitecreate;
  constructor(public translate: TranslateService) { }

  ngOnInit() {
    $('[data-role="popover"]').popover();
    $('.selectpicker').selectpicker();
  }

}
