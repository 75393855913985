import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridNg2 } from 'ag-grid-angular';
import { OrganisationMapModel } from './organisation-map.model';
import { Site } from 'src/app/administration/referentiel/sites/site.model';
import { OrganisationMapService } from './organisation-map.service';
import { SitesService } from 'src/app/administration/referentiel/sites/sites.service';
import { TeamsService } from 'src/app/administration/referentiel/teams/teams.service';
import { Teams } from 'src/app/administration/referentiel/teams/teams.model';
import { Level0 } from 'src/app/administration/levelreferential/level0/level0.model';
import { Level0Service } from 'src/app/administration/levelreferential/level0/level0.service';
import { LevelsAdmin } from 'src/app/administration/levelreferential/levelsadmin/levelsadmin.model';
import { LevelsAdminService } from 'src/app/administration/levelreferential/levelsadmin/levelsadmin.service';
import { Building } from 'src/app/administration/referentiel/buildings/building.model';
import { Means } from 'src/app/administration/referentiel/means/means.model';
import { Perimeters } from 'src/app/administration/referentiel/perimeters/perimeters.model';
import { BuildingsService } from 'src/app/administration/referentiel/buildings/buildings.service';
import { MeansService } from 'src/app/administration/referentiel/means/means.service';
import { PerimetersService } from 'src/app/administration/referentiel/perimeters/perimeters.service';
import { GridApi, RowNode } from 'ag-grid-community';
import { CampaignService } from 'src/app/administration/referentiel/campaign/campaign.service';
import { Campaign } from 'src/app/administration/referentiel/campaign/campaign.model';
import { CustomerDirectionService } from 'src/app/administration/referentiel/customer/customer.service';
import { CustomerDirection } from 'src/app/administration/referentiel/customer/customer.model';
import { ShiftsService } from 'src/app/administration/referentiel/shifts/shifts.service';
import { Shifts } from 'src/app/administration/referentiel/shifts/shifts.model';

import { TranslateService } from '@ngx-translate/core';
import { Level2Service } from 'src/app/administration/levelreferential/level2/level2.service';
import { Level2 } from 'src/app/administration/levelreferential/level2/level2.model';
import { Level1Service } from 'src/app/administration/levelreferential/level1/level1.service';
import { Level1 } from 'src/app/administration/levelreferential/level1/level1.model';
import { ProcedureService } from 'src/app/administration/prestationsandprocedures/procedure/procedure.service';
import { Procedure } from 'src/app/administration/prestationsandprocedures/procedure/procedure.model';
import { Prestation } from 'src/app/administration/prestationsandprocedures/prestation/prestation.model';
import { PrestationService } from 'src/app/administration/prestationsandprocedures/prestation/prestation.service';
import { PrestationadminService } from 'src/app/administration/prestationsandprocedures/prestationadmin/prestationadmin.service';
import { PrestationAdmin } from 'src/app/administration/prestationsandprocedures/prestationadmin/prestationadmin.model';
import { BatchService } from 'src/app/administration/referentiel/batch/batch.service';
import { Batch } from 'src/app/administration/referentiel/batch/batch.model';
import { SnackBarService } from '../../snackbar/snackbar.component.service';
import { BarProgramLines } from 'src/app/administration/barreferentiel/barprogramlines/barprogramlines.model';
import { BarProgramLinesService } from 'src/app/administration/barreferentiel/barprogramlines/barprogramlines.service';
import { ServicesService } from 'src/app/administration/referentiel/services/services.service';
import { Services } from 'src/app/administration/referentiel/services/services.model';
import { CampaignadministrationService } from 'src/app/administration/referentiel/campaignadministration/campaignadministration.service';
import { CampaignAssociation } from 'src/app/administration/referentiel/campaignadministration/campaignadministration.model';
import { TestbedService } from 'src/app/administration/testbedreferential/testbed/testbed-services';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { CommonServices } from '../../common.services';
import { MaintenanceSupplierService } from 'src/app/mpm/referential/maintenancesupplier/maintenancesupplier.service';
import { MaintenanceSupplier } from 'src/app/mpm/referential/maintenancesupplier/maintenancesupplier.model';
import { MaintenanceDeleteService } from 'src/app/mpm/referential/maintenancesupplier/maintenancesupplier-delete/maintenancesupplier-delete.services';
import { PreventiveMaintenanceService } from 'src/app/mpm/referential/preventivemaintenance/preventivemaintenance.service';
import { PreventiveMaintenance } from 'src/app/mpm/referential/preventivemaintenance/preventivemaintenance.model';
import { BarPrestationService } from 'src/app/administration/barreferentiel/bwrprestation/bwrprestation.service';
import { BarPrestation } from 'src/app/administration/barreferentiel/bwrprestation/bwrprestation.model';
import { TestbedtypeService } from 'src/app/administration/testbedreferential/testbedtype/testbedtype.service';
import { Testbedtype } from 'src/app/administration/testbedreferential/testbedtype/testbedtype.model';
import { SectorService } from 'src/app/administration/testbedreferential/sector/sector.service';
import { Sector } from 'src/app/administration/testbedreferential/sector/sector.model';
import { TestbedComplementService } from 'src/app/administration/testbedreferential/testbedcomplement/testbedcomplement.service';
import { TestbedComplement } from 'src/app/administration/testbedreferential/testbedcomplement/testbedcomplement.model';
import { TestbedComplementAssociation } from 'src/app/administration/testbedreferential/testbedtypecomplementassociation/testbedtypecomplementassociation.model';
import { TestbedComplementAssociationService } from 'src/app/administration/testbedreferential/testbedtypecomplementassociation/testbedtypecomplementassociation.service';
import { PreventivemaintenanceDeleteService } from 'src/app/mpm/referential/preventivemaintenance/preventivemaintenance-delete/preventivemaintenance-delete.services';
import { ValidationSpecimenService } from 'src/app/validation-specimen/validation-specimen.service';
import { ValidationSpecimen } from 'src/app/validation-specimen/validation-specimen.model';
import { BarCopActivityDuration } from 'src/app/administration/barreferentiel/barcopactivityduration/barcopactivityduration.model';
import { BarcopactivitydurationService } from 'src/app/administration/barreferentiel/barcopactivityduration/barcopactivityduration.service';
import { StopDuration } from 'src/app/mpm/referential/stop-duration/stop-duration.model';
import { StopDurationService } from 'src/app/mpm/referential/stop-duration/stop-duration.service';
import { PrestationcompaniesService } from 'src/app/mpm/referential/prestationcompanies/prestationcompanies.service';
import { Prestationcompanies } from 'src/app/mpm/referential/prestationcompanies/prestationcompanies.model';
import { Exploitants } from 'src/app/administration/testbedreferential/exploitants/exploitants.model';
import { ExploitantsService } from 'src/app/administration/testbedreferential/exploitants/exploitants.service';
import { MaintenanceTaskService } from 'src/app/mpm/referential/maintenancetask/maintenancetask.service';
import { MaintenanceTask } from 'src/app/mpm/referential/maintenancetask/maintenancetask.model';
import { MaintenancetaskDeleteService } from 'src/app/mpm/referential/maintenancetask/maintenancetask-delete/maintenancetask-delete.services';
import { SpecimenType } from 'src/app/administration/referentiel/specimen-type/specimen-type.model';
import { SpecimenTypeService } from 'src/app/administration/referentiel/specimen-type/specimen-type.service';
import { RsType } from 'src/app/administration/referentiel/rs-type/rs-type.model';
import { RsTypeService } from 'src/app/administration/referentiel/rs-type/rs-type.service';
import { MobileDeviceService } from 'src/app/administration/referentiel/mobile-device/mobile-device.service';
import { MobileDevice } from 'src/app/administration/referentiel/mobile-device/mobile-device.model';
import { Milestone } from 'src/app/administration/referentiel/milestone/milestone.model';
import { MilestoneService } from 'src/app/administration/referentiel/milestone/milestone.service';
import { MacroActivity } from 'src/app/administration/prestationsandprocedures/macro-activity/macro-activity.model';
import { MacroActivityService } from 'src/app/administration/prestationsandprocedures/macro-activity/macro-activity.service';
import { VhtechnicalparamService } from 'src/app/administration/testbedreferential/vhtechnicalparam/vhtechnicalparam.service';
import { VhTechnicalParam, VhTechnicalParamEnum } from 'src/app/administration/testbedreferential/vhtechnicalparam/vhtechnicalparam.model';
import { StoppageTypeService } from 'src/app/administration/levelreferential/stoppage-type/stoppage-type.service';
import { StoppageType } from 'src/app/administration/levelreferential/stoppage-type/stoppage-type.model';
import { FuelService } from 'src/app/administration/referentiel/fuel/fuel.service';
import { Fuel, errorMessages } from 'src/app/administration/referentiel/fuel/fuel.model';
import { ExternalCoswin } from 'src/app/administration/testbedreferential/external-coswin/external-coswin.model';
import { ExternalCoswinService } from 'src/app/administration/testbedreferential/external-coswin/external-coswin.service';
import { TestTypeService } from 'src/app/administration/testbedreferential/test-type/test-type.service';
import { TestType } from 'src/app/administration/testbedreferential/test-type/test-type.model';
import { OsVersionService } from 'src/app/administration/testbedreferential/os-version/os-version.service';
import { OsVersion } from 'src/app/administration/testbedreferential/os-version/os-version.model';
import { ExpertiseDomain } from 'src/app/administration/testbedreferential/expertise-domain/expertise-domain.model';
import { ExpertiseDomainService } from 'src/app/administration/testbedreferential/expertise-domain/expertise-domain.service';
import { Intersystem } from 'src/app/administration/testbedreferential/intersystem/intersystem.model';
import { IntersystemService } from 'src/app/administration/testbedreferential/intersystem/intersystem.service';




declare var $: any;

@Component({
  selector: 'organisation-map',
  templateUrl: './organisation-btn.component.html',
  styleUrls: ['./organisation-btn.component.css']
})
export class OrganisationMapComponent implements OnInit {

  @ViewChild('agGrid') agGrid: AgGridNg2;

  columnDefs = [
    { headerName: 'ID', field: 'id', sortable: true, filter: true, width: 100, checkboxSelection: true },
    { headerName: 'ORGANISATION', field: 'label', sortable: true, filter: true, width: 200 }
  ];

  public gridApi: GridApi;
  rowData: any;
  enableSaveBtn = true;
  private unHideOrganisationBtn = true;

  constructor(private http: HttpClient,
    public orgMapModel: OrganisationMapModel,
    private orgMapService: OrganisationMapService,
    private buildingService: BuildingsService,
    private meanService: MeansService,
    private perimeterService: PerimetersService,
    private siteService: SitesService,
    private teamService: TeamsService,
    private campaignService: CampaignService,
    private customerService: CustomerDirectionService,
    private shiftService: ShiftsService,
    private barProgramLinesService: BarProgramLinesService,
    private level0Service: Level0Service,
    private levelsAdminService: LevelsAdminService,
    private level2Service: Level2Service,
    private level1Service: Level1Service,
    public translate: TranslateService,
    private procedureService: ProcedureService,
    private snackbarService: SnackBarService,
    private prestationService: PrestationService,
    private prestationadminService: PrestationadminService,
    private batchService: BatchService,
    private campaignAssociationService: CampaignadministrationService,
    private barcopactivitydurationService: BarcopactivitydurationService,
    private servicesService: ServicesService,
    private testbedService: TestbedService,
    public organisationMap: OrganisationMapModel,
    private commonServices: CommonServices,
    private maintenanceSupplierService: MaintenanceSupplierService,
    private maintenanceDeleteService: MaintenanceDeleteService,
    private preventivemaintenanceService: PreventiveMaintenanceService,
    private preventivemaintenanceDeleteService: PreventivemaintenanceDeleteService,
    private maintenancetaskDeleteService: MaintenancetaskDeleteService,
    private barPrestationService: BarPrestationService,
    private testbedtypeService: TestbedtypeService,
    private sectorService: SectorService,
    private testbedComplementService: TestbedComplementService,
    private testbedComplementAssociationService: TestbedComplementAssociationService,
    private validateSpecimenService: ValidationSpecimenService,
    private stopDurationService: StopDurationService,
    private prestationCompaniesService: PrestationcompaniesService,
    private exploitantsService: ExploitantsService,
    private maintenanceTaskService: MaintenanceTaskService,
    private specimenTypeService: SpecimenTypeService,
    private rsTypeService: RsTypeService,
    private mobileService: MobileDeviceService,
    private milestoneService: MilestoneService,
    private macroService: MacroActivityService,
    private vhtechnicalparamService: VhtechnicalparamService,
    private stoppageService: StoppageTypeService,
    private fuelService: FuelService,
    private externalCoswinService: ExternalCoswinService,
    private testTypeService: TestTypeService,
    private osVersionService: OsVersionService,
    private expertiseDomainService: ExpertiseDomainService,
    private interSystemService: IntersystemService
  ) {

  }

  onRowSelected(event: any) {
    if (this.gridApi.getSelectedRows().length === 0) {
      this.enableSaveBtn = true;
    } else {
      this.enableSaveBtn = false;
    }
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  updateOrganisationList() {
    if (this.gridApi.getSelectedRows().length > 0) {
      const selectedOrganisation = this.gridApi.getSelectedRows();
      let organisationID: Array<number>;
      organisationID = selectedOrganisation.map(orgId => orgId.id);
      const tableName = this.orgMapModel.getTableName();

      if (tableName === 'Building') {
        this.buildingUpdate(organisationID);
      } else if (tableName === 'Site') {
        this.siteUpdate(organisationID);
      } else if (tableName === 'Mean') {
        this.meanUpdate(organisationID);
      } else if (tableName === 'Perimeters') {
        this.perimetersUpdate(organisationID);
      } else if (tableName === 'Teams') {
        this.teamsUpdate(organisationID);
      } else if (tableName === 'Campaign') {
        this.campaignUpdate(organisationID);
      } else if (tableName === 'CustomerDirection') {
        this.customerUpdate(organisationID);
      } else if (tableName === 'Shifts') {
        this.shiftUpdate(organisationID);
      } else if (tableName === 'BarProgramLines') {
        this.barProgramLinesUpdate(organisationID);
      } else if (tableName === 'Level_0') {
        this.level0Update(organisationID);
      } else if (tableName === 'Levels_Admin') {
        this.levelsAdminUpdate(organisationID);
      } else if (tableName === 'level2') {
        this.level2Update(organisationID);
      } else if (tableName === 'Level1') {
        this.Level1Update(organisationID);
      } else if (tableName === 'Procedure') {
        this.procedureUpdate(organisationID);
      } else if (tableName === 'Prestation') {
        this.prestationUpdate(organisationID);
      } else if (tableName === 'PrestationAdmin') {
        this.prestationAdminUpdate(organisationID);
      } else if (tableName === 'Batch') {
        this.batchUpdate(organisationID);
      } else if (tableName === 'CampaignAssociationList') {
        this.campaignAssociationUpdate(organisationID);
      } else if (tableName === 'Services') {
        this.ServicesUpdate(organisationID);
      } else if (tableName === 'SpecimenType') {
        this.specimenTypeUpdate(organisationID);
      } else if (tableName === 'MaintenanceSupplier') {
        this.maintenancesupplierUpdate(organisationID);
      } else if (tableName === 'PreventiveMaintenance') {
        this.preventivemaintenanceUpdate(organisationID);
      } else if (tableName === 'StopDuration') {
        this.stopDurationUpdate(organisationID);
      } else if (tableName === 'Testbed') {
        //   this.testbedUpdate(organisationID);
        this.orgMapModel.enableOrganisationBtn(false);
        return;
      } else if (tableName === 'BarPrestation') {
        this.barPrestationUpdate(organisationID);
      } else if (tableName === 'Testbedtype') {
        this.testbedtypeUpdate(organisationID);
      } else if (tableName === 'Sector') {
        this.sectorUpdate(organisationID);
      } else if (tableName === 'TestbedComplement') {
        this.testbedComplementUpdate(organisationID);
      } else if (tableName === 'TestbedComplementAssociation') {
        this.testbedComplementAssociationUpdate(organisationID);
      } else if (tableName === 'ValidationSpecimen') {
        this.validateSpecimenUpdate(organisationID);
      } else if (tableName === 'PrestationCompanies') {
        this.prestationCompaniesUpdate(organisationID);
      } else if (tableName === 'Exploitants') {
        this.exploitantUpdate(organisationID);
      } else if (tableName === 'MaintenanceTask') {
        this.maintenanceTaskUpdate(organisationID);
      } else if (tableName === 'BarCopActivityDuration') {
        this.barCopDurationDurationUpdate(organisationID);
      } else if (tableName === 'RsType') {
        this.rsTypeUpdate(organisationID);
      } else if (tableName === 'MobileDevice') {
        this.mobileDeviceUpdate(organisationID);
      } else if (tableName === 'Milestone') {
        this.milestoneUpdate(organisationID);
      } else if (tableName === 'MacroActivity') {
        this.macroActivityUpdate(organisationID);
      } else if (tableName === VhTechnicalParamEnum.vhTechnicalParam) {
        this.vhTechnicalParamUpdate(organisationID);
      } else if (tableName === 'StoppageType') {
        this.stoppageUpdate(organisationID);
      } else if (tableName === 'Fuel') {
        this.fuelUpdate(organisationID);
      } else if (tableName === 'ExternalCoswin') {
        this.externalCoswinUpdate(organisationID);
      } else if (tableName === 'TestType') {
        this.testTypeUpdate(organisationID);
      } else if (tableName === 'OsVersion') {
        this.osVersionUpdate(organisationID);
      } else if (tableName === 'ExpertiseDomain') {
        this.expertiseDomainUpdate(organisationID);
      } else if (tableName === 'Intersystem') {
        this.interSystemUpdate(organisationID);
      }

      this.orgMapModel.enableOrganisationBtn(true);
    }

  }

  buildingUpdate(organisationID) {
    const building = new Building();
    building.id = this.orgMapModel.getGridId();
    building.organisationId = organisationID;
    building.ipn = sessionStorage.getItem('uid');
    this.buildingService.updateBuildingOrganisationList(building).subscribe((response) => {
      if (response.status === 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        building.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.buildingService.refreshGrid(building);
        this.buildingService.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  level0Update(organisationID) {
    const level0 = new Level0();
    level0.id = this.orgMapModel.getGridId();
    level0.organisationId = organisationID;
    level0.ipn = sessionStorage.getItem('uid');
    this.level0Service.updateLevel0OrganisationList(level0).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        level0.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.level0Service.refreshGrid(level0);
        this.level0Service.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  levelsAdminUpdate(organisationID) {
    const levelsAdmin = new LevelsAdmin();
    levelsAdmin.id = this.orgMapModel.getGridId();
    levelsAdmin.organisationId = organisationID;
    levelsAdmin.ipn = sessionStorage.getItem('uid');
    this.levelsAdminService.updateLevelsAdminOrganisationList(levelsAdmin).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        levelsAdmin.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.levelsAdminService.getLevelsAdminData(levelsAdmin).subscribe((result: LevelsAdmin[]) => {
          this.levelsAdminService.gridOptions.api.setRowData(result);
          this.commonServices.setLevelAdminData(result);
          this.levelsAdminService.initCreate();
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  meanUpdate(organisationID) {
    const mean = new Means();
    mean.id = this.orgMapModel.getGridId();
    mean.organisationId = organisationID;
    mean.ipn = sessionStorage.getItem('uid');
    this.meanService.updateMeanOrganisationList(mean).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        mean.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.meanService.refreshGrid(mean);
        this.meanService.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  perimetersUpdate(organisationID) {
    const perimeter = new Perimeters();
    perimeter.id = this.orgMapModel.getGridId();
    perimeter.organisationId = organisationID;
    perimeter.ipn = sessionStorage.getItem('uid');
    this.perimeterService.updatePerimeterOrganisationList(perimeter).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        perimeter.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.perimeterService.refreshGrid(perimeter);
        this.perimeterService.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  siteUpdate(organisationID) {
    const site = new Site();
    site.id = this.orgMapModel.getGridId();
    site.organisationId = organisationID;
    site.ipn = sessionStorage.getItem('uid');
    this.siteService.updateOrganisationList(site).subscribe((response) => {
      if (response.status === 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        site.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.siteService.getAllSites(site).subscribe((result: Site[]) => {
          this.siteService.gridOptions.api.setRowData(result);
          this.commonServices.setSiteData(result);
          this.siteService.initCreate();
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  teamsUpdate(organisationID) {
    const team = new Teams();
    team.id = this.orgMapModel.getGridId();
    team.organisationId = organisationID;
    team.ipn = sessionStorage.getItem('uid');
    this.teamService.updateTeamOrganisationList(team).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        team.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.teamService.refreshGrid(team);
        this.teamService.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  campaignUpdate(organisationID) {
    const campaign = new Campaign();
    campaign.id = this.orgMapModel.getGridId();
    campaign.organisationId = organisationID;
    campaign.ipn = sessionStorage.getItem('uid');
    this.campaignService.updateCampaignOrganisationList(campaign).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        campaign.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.campaignService.refreshGrid(campaign);
        this.campaignService.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  customerUpdate(organisationID) {
    const customer = new CustomerDirection();
    customer.id = this.orgMapModel.getGridId();
    customer.organisationId = organisationID;
    customer.ipn = sessionStorage.getItem('uid');
    this.customerService.updateCustomerOrganisationList(customer).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        customer.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.customerService.refreshGrid(customer);
        this.customerService.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  shiftUpdate(organisationID) {
    const shifts = new Shifts();
    shifts.id = this.orgMapModel.getGridId();
    shifts.organisationId = organisationID;
    shifts.ipn = sessionStorage.getItem('uid');
    this.shiftService.updateShiftOrganisationList(shifts).subscribe((response) => {
      if (response.body) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        shifts.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.shiftService.refreshGrid(shifts);
        this.shiftService.initCreate();
      }
    })
  }

  barProgramLinesUpdate(organisationID) {
    const barProgramLines = new BarProgramLines();
    barProgramLines.id = this.orgMapModel.getGridId();
    barProgramLines.organisationId = organisationID;
    barProgramLines.ipn = sessionStorage.getItem('uid');
    this.barProgramLinesService.updateBarProgramLinesOrganisationList(barProgramLines).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        barProgramLines.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.barProgramLinesService.getAllBarProgramLines(barProgramLines).subscribe((result: BarProgramLines[]) => {
          this.barProgramLinesService.gridOptions.api.setRowData(result);
          this.commonServices.setBarProgramLinesData(result);
          this.barProgramLinesService.initCreate();
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }
  level2Update(organisationID) {
    const level2 = new Level2();
    level2.id = this.orgMapModel.getGridId();
    level2.organisationId = organisationID;
    level2.ipn = sessionStorage.getItem('uid');
    this.level2Service.updateLevel2OrganisationList(level2).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        level2.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.level2Service.refreshGrid(level2);
        this.level2Service.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  Level1Update(organisationID) {
    const level1 = new Level1();
    level1.id = this.orgMapModel.getGridId();
    level1.organisationId = organisationID;
    level1.ipn = sessionStorage.getItem('uid');
    this.level1Service.updateLevel1OrganisationList(level1).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        level1.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.level1Service.refreshGrid(level1);
        this.level1Service.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }
  procedureUpdate(organisationID) {
    const procedure = new Procedure();
    procedure.id = this.orgMapModel.getGridId();
    procedure.organisationId = organisationID;
    procedure.ipn = sessionStorage.getItem('uid');
    this.procedureService.updateProcedureOrganisationList(procedure).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        procedure.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.procedureService.refreshGrid(procedure);
        this.procedureService.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }
  prestationUpdate(organisationID) {
    const prestation = new Prestation();
    prestation.id = this.orgMapModel.getGridId();
    prestation.organisationId = organisationID;
    prestation.ipn = sessionStorage.getItem('uid');
    this.prestationService.updatePrestationOrganisationList(prestation).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        prestation.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.prestationService.refreshGrid(prestation);
        this.prestationService.initCreate();
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  prestationAdminUpdate(organisationID) {
    const admin = new PrestationAdmin();
    admin.id = this.orgMapModel.getGridId();
    admin.organisationId = organisationID;
    admin.ipn = sessionStorage.getItem('uid');
    this.prestationadminService.updatePrestationAdminOrganisationList(admin).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        admin.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.prestationadminService.getAllPrestationAdmin(admin).subscribe((result: PrestationAdmin[]) => {
          this.prestationadminService.gridOptions.api.setRowData(result);
          this.commonServices.setPrestationAdminData(result);
          this.prestationadminService.initCreate();
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }
  batchUpdate(organisationID) {
    const batch = new Batch();
    batch.id = this.orgMapModel.getGridId();
    batch.organisationId = organisationID;
    batch.ipn = sessionStorage.getItem('uid');
    this.batchService.updateBatchOrganisationList(batch).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        batch.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.batchService.refreshGrid(batch);
        this.batchService.initCreate();
      }
    });
  }
  ServicesUpdate(organisationID) {
    const services = new Services();
    services.id = this.orgMapModel.getGridId();
    services.organisationId = organisationID;
    services.ipn = sessionStorage.getItem('uid');
    this.servicesService.updateServicesOrganisationList(services).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        services.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.servicesService.refreshGrid(services);
        this.servicesService.initCreate();
      }
    });
  }
  specimenTypeUpdate(organisationID) {
    const specimenType = new SpecimenType();
    specimenType.id = this.orgMapModel.getGridId();
    specimenType.organisationId = organisationID;
    specimenType.ipn = sessionStorage.getItem('uid');
    this.specimenTypeService.updateSpecimenTypeOrganisationList(specimenType).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        specimenType.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.specimenTypeService.refreshGrid(specimenType);
        this.specimenTypeService.initCreate();
      }
    });
  }

  maintenancesupplierUpdate(organisationID) {
    const maintenancesupplier = new MaintenanceSupplier();
    maintenancesupplier.id = this.orgMapModel.getGridId();
    maintenancesupplier.organisationId = organisationID;
    maintenancesupplier.ipn = sessionStorage.getItem('uid');
    this.maintenanceSupplierService.updateMaintenanceSupplierOrganisationList(maintenancesupplier).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        maintenancesupplier.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.maintenanceSupplierService.getMaintenanceSupplierList(maintenancesupplier).subscribe((result: MaintenanceSupplier[]) => {
          this.maintenanceSupplierService.gridOptions.api.setRowData(result);
          this.commonServices.setMaintenanceSupplierData(result);
          this.maintenanceDeleteService.showMaintainenceSupplierDelete(false);
          this.maintenanceSupplierService.initCreate();

        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  preventivemaintenanceUpdate(organisationID) {
    const preventivemaintenance = new PreventiveMaintenance();
    preventivemaintenance.id = this.orgMapModel.getGridId();
    preventivemaintenance.organisationId = organisationID;
    preventivemaintenance.ipn = sessionStorage.getItem('uid');
    this.preventivemaintenanceService.updatePreventiveMaintenanceOrganisationList(preventivemaintenance).subscribe((response) => {
      if (response.status === 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        preventivemaintenance.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.preventivemaintenanceService.getPreventiveMaintenanceList(preventivemaintenance)
          .subscribe((result: PreventiveMaintenance[]) => {
            this.preventivemaintenanceService.gridOptions.api.setRowData(result);
            this.commonServices.setPreventiveMaintenanceData(result);
            this.preventivemaintenanceDeleteService.showPreventivemaintenanceDelete(false);
            this.preventivemaintenanceService.initCreate();

          });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  barPrestationUpdate(organisationID) {
    const barPrestation = new BarPrestation();
    barPrestation.id = this.orgMapModel.getGridId();
    barPrestation.organisationId = organisationID;
    barPrestation.ipn = sessionStorage.getItem('uid');
    this.barPrestationService.updateBarProgramLinesOrganisationList(barPrestation).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        barPrestation.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.barPrestationService.getAllBarProgramLines(barPrestation).subscribe((result: BarPrestation[]) => {
          this.barPrestationService.gridOptions.api.setRowData(result);
          this.commonServices.setBarprestationData(result);
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  testbedtypeUpdate(organisationID) {
    const testbedtype = new Testbedtype();
    testbedtype.id = this.orgMapModel.getGridId();
    testbedtype.organisationId = organisationID;
    testbedtype.ipn = sessionStorage.getItem('uid');
    this.testbedtypeService.updateTestbedtypesOrganisationList(testbedtype).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        testbedtype.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.testbedtypeService.getAllTestbedtypes(testbedtype).subscribe((result: Testbedtype[]) => {
          this.testbedtypeService.gridOptions.api.setRowData(result);
          this.commonServices.setTestbedtypeData(result);
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  sectorUpdate(organisationID) {
    const sector = new Sector();
    sector.id = this.orgMapModel.getGridId();
    sector.organisationId = organisationID;
    sector.ipn = sessionStorage.getItem('uid');
    this.sectorService.updateSectorOrganisationList(sector).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        sector.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.sectorService.getAllSectors(sector).subscribe((result: Sector[]) => {
          this.sectorService.gridOptions.api.setRowData(result);
          this.commonServices.setSectorData(result);
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  testbedComplementUpdate(organisationID) {
    const testbedComplement = new TestbedComplement();
    testbedComplement.id = this.orgMapModel.getGridId();
    testbedComplement.organisationId = organisationID;
    testbedComplement.ipn = sessionStorage.getItem('uid');
    this.testbedComplementService.updateTestbedComplementOrganisationList(testbedComplement).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        testbedComplement.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.testbedComplementService.getAllTestbedComplements(testbedComplement).subscribe((result: TestbedComplement[]) => {
          this.testbedComplementService.gridOptions.api.setRowData(result);
          this.commonServices.setTestbedcomplementData(result);
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  testbedComplementAssociationUpdate(organisationID) {
    const testbedComplementAssociation = new TestbedComplementAssociation();
    testbedComplementAssociation.id = this.orgMapModel.getGridId();
    testbedComplementAssociation.organisationId = organisationID;
    testbedComplementAssociation.ipn = sessionStorage.getItem('uid');
    this.testbedComplementAssociationService.updateTestbedComplementAssociationOrganisationList(testbedComplementAssociation).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        testbedComplementAssociation.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.testbedComplementAssociationService.getAllTestbedComplementAssociation(testbedComplementAssociation).subscribe((result: TestbedComplementAssociation[]) => {
          this.testbedComplementAssociationService.gridOptions.api.setRowData(result);
          this.commonServices.setTestbedComplementAssociationData(result);
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }


  campaignAssociationUpdate(organisationID) {
    const admin = new CampaignAssociation();
    admin.id = this.orgMapModel.getGridId();
    admin.organisationId = organisationID;
    admin.ipn = sessionStorage.getItem('uid');
    this.campaignAssociationService.updateCampaignAssociationOrganisationList(admin).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        admin.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.campaignAssociationService.getAllCampaignAssociations(admin).subscribe((result: CampaignAssociation[]) => {
          this.campaignAssociationService.gridOptions.api.setRowData(result);
          this.commonServices.setCampaignAssociationData(result);
          this.campaignAssociationService.initCreate();
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  barCopDurationDurationUpdate(organisationID) {
    const admin = new BarCopActivityDuration();
    admin.id = this.orgMapModel.getGridId();
    admin.organisationId = organisationID;
    admin.ipn = sessionStorage.getItem('uid');
    this.barcopactivitydurationService.updateBarCopDurationOrganisationList(admin).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        admin.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.barcopactivitydurationService.getAllBarCopActivityDurationList(admin).subscribe((result: BarCopActivityDuration[]) => {
          this.barcopactivitydurationService.gridOptions.api.setRowData(result);
          this.barcopactivitydurationService.setBarCopDurationArray(result);
          this.barcopactivitydurationService.initCreate();
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  validateSpecimenUpdate(organisationID) {
    const admin = new ValidationSpecimen();
    admin.id = this.orgMapModel.getGridId();
    admin.organisationId = organisationID;
    admin.ipn = sessionStorage.getItem('uid');
    this.validateSpecimenService.updateValidationSpecimenOrganisationList(admin).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        admin.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.validateSpecimenService.getValidationSpecimenData(admin).subscribe((result: ValidationSpecimen[]) => {
          this.validateSpecimenService.gridOptions.api.setRowData(result);
          this.commonServices.setValidationSpecimenData(result);
          this.validateSpecimenService.initCreate();
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  stopDurationUpdate(organisationID) {
    const stopDuration = new StopDuration();
    stopDuration.id = this.orgMapModel.getGridId();
    stopDuration.organisationId = organisationID;
    stopDuration.ipn = sessionStorage.getItem('uid');
    this.stopDurationService.updateStopDurationOrganisationList(stopDuration).subscribe((response) => {
      if (response.status === 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        stopDuration.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.stopDurationService.getStopDurationList(stopDuration)
          .subscribe((result: StopDuration[]) => {
            this.stopDurationService.gridOptions.api.setRowData(result);
            this.commonServices.setStopDurationData(result);
            // this.maintenanceDeleteService.showMaintainenceSupplierDelete(false);
            this.stopDurationService.initCreate();
          });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  prestationCompaniesUpdate(organisationID) {
    const prestationcompanies = new Prestationcompanies();
    prestationcompanies.id = this.orgMapModel.getGridId();
    prestationcompanies.organisationId = organisationID;
    prestationcompanies.ipn = sessionStorage.getItem('uid');
    this.prestationCompaniesService.updatePrestationcompaniesOrganisation(prestationcompanies).subscribe((response) => {
      if (response.status === 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        prestationcompanies.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.prestationCompaniesService.getPrestationcompaniesList(prestationcompanies)
          .subscribe((result: Prestationcompanies[]) => {
            this.prestationCompaniesService.gridOptions.api.setRowData(result);
            this.commonServices.setPrestationCompaniesData(result);
            this.prestationCompaniesService.initCreate();
          });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });
  }

  maintenanceTaskUpdate(organisationID) {
    const maintenanceTaskUpdate = new MaintenanceTask();
    maintenanceTaskUpdate.id = this.orgMapModel.getGridId();
    maintenanceTaskUpdate.organisationId = organisationID;
    maintenanceTaskUpdate.ipn = sessionStorage.getItem('uid');
    this.maintenanceTaskService.updateMaintenanceTaskOrganisationList(maintenanceTaskUpdate).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        maintenanceTaskUpdate.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.maintenanceTaskService.getMaintenanceTaskServiceList(maintenanceTaskUpdate).subscribe((result: MaintenanceTask[]) => {
          this.maintenanceTaskService.gridOptions.api.setRowData(result);
          this.commonServices.setMaintenanceTaskData(result);
          this.maintenancetaskDeleteService.showmaintenancetaskDelete(false);
          this.maintenanceTaskService.initCreate();
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  onClickLoadOrganisationList() {
    // this.hideSaveBtn = false;
    // this.hideFailureBtn = true;
    // this.hideSuccessBtn = true;
    this.orgMapService.getOrganisationList().subscribe(data => {
      this.rowData = data;
      this.gridApi.setRowData(data);
      this.selectCheckBox(this.rowData);
    });
    this.enableSaveBtn = true;
  }

  selectCheckBox(data) {
    const arrSelectedOrgId: number[] = this.orgMapModel.getAlreadyMappedOrgId();
    setTimeout(() => {
      this.gridApi.forEachNode(node => {
        arrSelectedOrgId.forEach(element => {
          if (element === node.data.id) {
            node.setSelected(true);
          }
        });
      });
    }, 200);
  }

  exploitantUpdate(organisationID) {
    const exploitant = new Exploitants();
    exploitant.id = this.orgMapModel.getGridId();
    exploitant.organisationId = organisationID;
    exploitant.ipn = sessionStorage.getItem('uid');
    this.exploitantsService.updateExploitantOrganisationList(exploitant).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        exploitant.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.exploitantsService.getAllExploitant(exploitant).subscribe((result: Exploitants[]) => {
          this.exploitantsService.gridOptions.api.setRowData(result);
          this.commonServices.setExploitantData(result);
        });
        // this.hideSaveBtn=true;
        // this.hideSuccessBtn = false;
      } else {
        // this.hideSaveBtn=true;
        // this.hideFailureBtn=false;
      }
    });

  }

  rsTypeUpdate(organisationID) {
    const rsType = new RsType();
    rsType.id = this.orgMapModel.getGridId();
    rsType.organisationId = organisationID;
    rsType.ipn = sessionStorage.getItem('uid');
    this.rsTypeService.updateRsTypeOrganisationList(rsType).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        rsType.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.rsTypeService.refreshGrid(rsType);
        this.rsTypeService.initCreate();
      }
    });
  }
  mobileDeviceUpdate(organisationID) {
    const mobileDevice = new MobileDevice();
    mobileDevice.id = this.orgMapModel.getGridId();
    mobileDevice.organisationId = organisationID;
    mobileDevice.ipn = sessionStorage.getItem('uid');
    this.mobileService.updateMobileDeviceOrganisationList(mobileDevice).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        mobileDevice.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.mobileService.refreshGrid(mobileDevice);
        this.mobileService.initCreate();
      }
    });
  }
  milestoneUpdate(organisationID) {
    const milestone = new Milestone();
    milestone.id = this.orgMapModel.getGridId();
    milestone.organisationId = organisationID;
    milestone.ipn = sessionStorage.getItem('uid');
    this.milestoneService.updateMilestoneOrganisationList(milestone).subscribe((respone) => {
      if (respone.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        milestone.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.milestoneService.refreshGrid(milestone);
        this.milestoneService.initCreate();
      }
    });
  }
  stoppageUpdate(organisationID) {
    const stoppage = new StoppageType();
    stoppage.id = this.orgMapModel.getGridId();
    stoppage.organisationId = organisationID;
    stoppage.ipn = sessionStorage.getItem('uid');
    this.stoppageService.updateStoppageOrganisationList(stoppage).subscribe((respone) => {
      if (respone.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        stoppage.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.stoppageService.refreshGrid();
        this.stoppageService.initCreate();
      }
    });
  }
  macroActivityUpdate(organisationID) {
    const macro = new MacroActivity();
    macro.id = this.orgMapModel.getGridId();
    macro.organisationId = organisationID;
    macro.ipn = sessionStorage.getItem('uid');
    this.macroService.updateMacroActivityOrganisationList(macro).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        macro.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.macroService.refreshGrid(macro);
        this.macroService.initCreate();
      }
    });
  }

  vhTechnicalParamUpdate(organisationID) {
    const vhTechnicalParam = new VhTechnicalParam();
    vhTechnicalParam.id = this.orgMapModel.getGridId();
    vhTechnicalParam.organisationId = organisationID;
    vhTechnicalParam.ipn = sessionStorage.getItem('uid');
    this.vhtechnicalparamService.updateVhTechParamOrganisationList(vhTechnicalParam).subscribe((response) => {
      if (response.status == 200) {
        this.snackbarService.showSnackBar('Common_Buttons.Success', 'S');
        vhTechnicalParam.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.vhtechnicalparamService.getAllVhTechnicalParam(vhTechnicalParam).subscribe((result: VhTechnicalParam[]) => {
          this.vhtechnicalparamService.gridOptions.api.setRowData(result);
          this.commonServices.VhTechnicalParamList = result;
        });
      }
    });
  }

  fuelUpdate(organisationID) {
    const fuel = new Fuel();
    fuel.id = this.orgMapModel.getGridId();
    fuel.organisationId = organisationID;
    fuel.ipn = sessionStorage.getItem('uid');
    this.fuelService.updateFuelOrganisationList(fuel).subscribe((respone) => {
      if (respone.status === 200) {
        this.snackbarService.showSnackBar(errorMessages.successMsg, errorMessages.success);
        fuel.organisationId = JSON.parse(sessionStorage.getItem(errorMessages.orgId));
        this.fuelService.refreshGrid(fuel);
        this.fuelService.initCreate();
      }
    });
  }
  externalCoswinUpdate(organisationID) {
    const externalCoswin = new ExternalCoswin();
    externalCoswin.id = this.orgMapModel.getGridId();
    externalCoswin.organisationId = organisationID;
    externalCoswin.ipn = sessionStorage.getItem('uid');
    this.externalCoswinService.updateExternalCoswinOrganisationList(externalCoswin).subscribe((respone) => {
      if (respone.status === 200) {
        this.snackbarService.showSnackBar(errorMessages.successMsg, errorMessages.success);
        externalCoswin.organisationId = JSON.parse(sessionStorage.getItem(errorMessages.orgId));
        this.externalCoswinService.refreshGrid();
        this.externalCoswinService.initCreate();
      }
    });
  }
  testTypeUpdate(organisationID) {
    const testType = new TestType();
    testType.id = this.orgMapModel.getGridId();
    testType.organisationId = organisationID;
    testType.ipn = sessionStorage.getItem('uid');
    this.testTypeService.updateTestTypeOrganisationList(testType).subscribe((respone) => {
      if (respone.status === 200) {
        this.snackbarService.showSnackBar(errorMessages.successMsg, errorMessages.success);
        testType.organisationId = JSON.parse(sessionStorage.getItem(errorMessages.orgId));
        this.testTypeService.refreshGrid();
        this.testTypeService.initCreate();
      }
    });
  }
  expertiseDomainUpdate(organisationID) {
    const expertiseDomain = new ExpertiseDomain();
    expertiseDomain.id = this.orgMapModel.getGridId();
    expertiseDomain.organisationId = organisationID;
    expertiseDomain.ipn = sessionStorage.getItem('uid');
    this.expertiseDomainService.updateExpertiseDomainOrganisationList(expertiseDomain).subscribe((respone) => {
      if (respone.status === 200) {
        this.snackbarService.showSnackBar(errorMessages.successMsg, errorMessages.success);
        expertiseDomain.organisationId = JSON.parse(sessionStorage.getItem(errorMessages.orgId));
        this.expertiseDomainService.refreshGrid();
        this.expertiseDomainService.initCreate();
      }
    });
  }

  osVersionUpdate(organisationID) {
    const osVersion = new OsVersion();
    osVersion.id = this.orgMapModel.getGridId();
    osVersion.organisationId = organisationID;
    osVersion.ipn = sessionStorage.getItem('uid');
    this.osVersionService.updateOsVersionOrganisationList(osVersion).subscribe((response) => {
      if (response.status === 200) {
        this.snackbarService.showSnackBar(errorMessages.successMsg, errorMessages.success);
        osVersion.organisationId = JSON.parse(sessionStorage.getItem(errorMessages.orgId));
        this.osVersionService.refreshGrid();
        this.osVersionService.initCreate();
      }
    });
  }

  interSystemUpdate(organisationID) {
    const interSystem = new Intersystem();
    interSystem.id = this.orgMapModel.getGridId();
    interSystem.organisationId = organisationID;
    interSystem.ipn = sessionStorage.getItem('uid');
    this.interSystemService.updateInterSystemOrganisationList(interSystem).subscribe((response) => {
      if (response.status === 200) {
        this.snackbarService.showSnackBar(errorMessages.successMsg, errorMessages.success);
        interSystem.organisationId = JSON.parse(sessionStorage.getItem(errorMessages.orgId));
        this.interSystemService.refreshGrid();
        this.interSystemService.initCreate();
      }
    });
  }


  /** refreshGrid(tableName: String) {
    this.orgMapModel.enableOrganisationBtn(true);
    console.log(' Table --> ', tableName);
    if(tableName === 'Site') {
      this.siteService.refreshGrid();
    }
    if(tableName === 'Campaign') {
      this.campaignService.refreshGrid();
    }
    if(tableName === 'Building') {
      this.buildingService.refreshGrid();
    }
    if(tableName === 'Mean') {
      this.meanService.refreshGrid();
    }
    if(tableName === 'BarCampaign') {
      this.barCampaignService.refreshGrid();
    }
    if(tableName === 'BarProgramLines') {
      this.barProgramLinesService.refreshGrid();
    }
    if(tableName === 'CustomerDirection') {
      this.customerService.refreshGrid();
    }
    if(tableName === 'Teams') {
      this.teamService.refreshGrid();
    }
    if(tableName === 'Perimeters') {
      this.perimeterService.refreshGrid();
    }
  } */

  ngOnInit() {
    $('[data-role="popover"]').popover();
    $('.selectpicker').selectpicker();
  }
}
