import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'primeng/colorpicker';
// import {TabViewModule} from 'primeng/tabview';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';



@NgModule({
  declarations: [],
  imports: [
    ColorPickerModule,
    // TabViewModule,
    CodeHighlighterModule,
    TableModule,
    CalendarModule,
    CarouselModule
  ],
  exports: [
    ColorPickerModule,
    // TabViewModule,
    CodeHighlighterModule,
    TableModule,
    CalendarModule,
    CarouselModule
  ],
  providers: [

  ]
})
export class PrimeNgModule { }
