import { Component, EventEmitter, IterableDiffers, Input, Output, OnInit } from '@angular/core';
import { DualListComponent } from 'angular-dual-listbox';
import { TestBedSlotsService } from 'src/app/mpm/test-bed-slots/test-bed-slots.service';
import { DistributionListService } from 'src/app/saisie/distribution-list/distribution-list.service';
import { Constants } from './custom-listbox.model'

@Component({
	selector: 'app-custom-listbox',
	templateUrl: './custom-listbox.component.html',
	styleUrls: ['./custom-listbox.component.css']
})
export class CustomListboxComponent extends DualListComponent implements OnInit  {

	@Input() sourceName = '';
	@Input() targetName = '';
	@Output() selectChange = new EventEmitter();
	public distributionListFlag: boolean;

	public displaymsg: string;
	ngOnInit() {
		this.displaymsg = this.testbedSlotService.getfilterMsg();
		this.distributionListFlag = this.distributionListService !== undefined ? this.distributionListService.distributionListFlag : false;
	}

	constructor(differs:IterableDiffers, private testbedSlotService:TestBedSlotsService, public distributionListService : DistributionListService) {
		super(differs);
	}

	moveAll() {
		this.selectAll(this.available);
		this.moveItem(this.available, this.confirmed);
	}

	removeAll() {
		this.selectAll(this.confirmed);
		this.moveItem(this.confirmed, this.available);
	}

	CheckboxChanged(item: any, event: any) {
		this.destinationChange.emit(this.confirmed.list.map(m=>m._name));
		if (item != null && event != null && item != undefined && event != undefined) {
			this.distributionListService.directoryData
				.filter((res: any) => item._id === res[Constants.directoryIpn] + ' - ' + res[Constants.fname] + ' ' + res[Constants.lname] + ' - ' + res.profileNames)
				.map((data: any) => data.dailyMailFlag = event.target.checked);
		}
	}

	checkDistList(name: string) {
		let data = this.distributionListService.directoryData.filter((res: any) => name === res[Constants.directoryIpn] + ' - ' + res[Constants.fname] + ' ' + res[Constants.lname] + ' - ' + res.profileNames);
		if(data.length > 0)  return data[0][Constants.dailyMailFlag] === undefined ? false : data[0][Constants.dailyMailFlag];
		return false;
	}


	// Override function in DualListComponent to add custom selectChange event.
	selectItem(list:Array<any>, item:any) {
		const pk = list.filter( (e:any) => {
			return Object.is(e, item);
		});
		if (pk.length > 0) {
			// Already in list, so deselect.
			for (let i = 0, len = pk.length; i < len; i += 1) {
				const idx = list.indexOf(pk[i]);
				if (idx !== -1) {
					list.splice(idx, 1);
					this.selectChange.emit( { key: item._id, selected: false });
				}
			}
		} else {
			list.push(item);
			this.selectChange.emit( { key: item._id, selected: true });
		}
	}

	/* private unpick(source: any) {
		for (let i = source.pick.length - 1; i >= 0; i -= 1) {
			if (source.sift.indexOf(source.pick[i]) === -1) {
				source.pick.splice(i, 1);
			}
		}
	}

	clearFilter(source: any) {
		if (source) {
			source.picker = '';
			this.onFilter(source);
		}
	}

	onFilter(source: any) {
		if (source.picker.length > 0) {
			try {
				const filtered = source.list.filter( (item: any) => {
					if (Object.prototype.toString.call(item) === '[object Object]') {
						if (item._name !== undefined) {
							// @ts-ignore: remove when d.ts has locale as an argument.
							return item._name.toLocaleLowerCase(this.format.locale).indexOf(source.picker.toLocaleLowerCase(this.format.locale)) !== -1;
						} else {
							// @ts-ignore: remove when d.ts has locale as an argument.
							return JSON.stringify(item).toLocaleLowerCase(this.format.locale).indexOf(source.picker.toLocaleLowerCase(this.format.locale)) !== -1;
						}
					} else {
						// @ts-ignore: remove when d.ts has locale as an argument.
						return item.toLocaleLowerCase(this.format.locale).indexOf(source.picker.toLocaleLowerCase(this.format.locale)) !== -1;
					}
				});
				source.sift = filtered;
				this.unpick(source);
			} catch (e) {
				if (e instanceof RangeError) {
					this.format.locale = undefined;
				}
				source.sift = source.list;
			}
		} else {
			source.sift = source.list;
		}
	}
 */
}