import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from './common/message.service';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AgreementPopupComponent } from './common/agreement-popup/agreement-popup.component';
import { AgreementService } from './common/agreement-popup/agreement.service';
import { UsersModel } from './common/agreement-popup/users-model';
import { HttpResponse } from '@angular/common/http';
import { OrganisationMapService } from './common/common-button/organisation-map-btn/organisation-map.service';
import { OrganisationMapModel } from './common/common-button/organisation-map-btn/organisation-map.model';
import { UsersRoleModel } from './common/agreement-popup/user-role-model';
import { roleModel } from './common/agreement-popup/role-model';
import { UserIdleTimeOutService } from './common/userIdleTimeOut/user-idle-time-out.service';
import { SpinnerLoadingService } from './common/spinner-loading/spinner-loading.service';
import { SnackBarService } from './common/snackbar/snackbar.component.service';
import { HttpCancelService } from './common/interceptor/http-cancel.service';
import { PreferencesService } from './common/help/preferences/preferences.service';
import { PannelLoadingMessageService } from './common/spinner-loading/pannel-loading-message.service';
import {AuthorizationService} from './shared/authorization/authorization.service';
import { OAuthService } from 'angular-oauth2-oidc';
import {authConfig} from '../app/shared/authorization/config/authConfig';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  showloading = true;
  showSpinner = true;
  showPannelSpinner = false;
  navigationSpinner = false;
  showHeader = false;
  showDbError = false;
  showAgreementPopup = true;
  title = 'TPM2';
  subscription: Subscription;
  loadingSubscription: Subscription;
  pannelSubscription: Subscription;
  userModel: UsersModel;

  constructor(private router: Router, public translate: TranslateService,
    private messageService: MessageService,
    private agreementService: AgreementService,
    public dialog: MatDialog,
    private orgMapService: OrganisationMapService,
    private userIdleTimeOutService: UserIdleTimeOutService,
    private spinnerLoadingService: SpinnerLoadingService,
    private pannelLoadingMessageService: PannelLoadingMessageService,
    private snackbarService: SnackBarService,
    private httpCancelService: HttpCancelService,
    private preferenceService: PreferencesService,
     private authorizationService: AuthorizationService ,
    private oauthService: OAuthService , 
  ) {

    this.configureOpenId();
   console.log('oidc app component .59.');

   //this.oauthService.tokenValidationHandler = new JwksValidationHandler();

   //this.authorizationService.configureOidcAndLogin();

   


    // TODO: To run local only uncomment this block --- Start
   // sessionStorage.setItem('uid', 'z026865');
   // sessionStorage.setItem('userName', 'Test');
    //sessionStorage.setItem('role', JSON.stringify(['tpm2-admin', 'tpm2-lead', 'tpm2-deatm']));
    //console.log(' 75 app.component role...'+sessionStorage.getItem('role'));
  // sessionStorage.setItem('roleAction', '');
   //sessionStorage.setItem('organisationIDs', JSON.stringify([1,2]));

  // sessionStorage.setItem('onlyRoles', 'true');

    // TODO: To run local only uncomment this block --- End

    // for language ---- Start
    translate.addLangs(['en', 'fr']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    // for language ---- End

    const isOrgIdEmpty: string = sessionStorage.getItem('isOrgIdEmpty');
    console.log('isOrgIdEmpty ----> ', isOrgIdEmpty);
    if (isOrgIdEmpty === 'true') {
      this.showHeader = false;
      this.snackbarService.showSnackBar('Org_Access.errMsg', 'F');
    }
    setTimeout(() => {
      this.subscription = this.messageService.getMessage().subscribe(message => {
        if (message === 'false') {
          this.showloading = false;
          //this.showAgreement();
        } else if (message === 'logout') {
          this.showloading = true;
          this.showSpinner = false;
          this.router.navigate(['/logout']);
          // event.preventDefault();
        } else if (sessionStorage.getItem('uid') && sessionStorage.getItem('role')) {
          this.showloading = false;
          //this.showAgreement();
        }
      });
    }, 500
    );
    this.loadingSubscription = this.spinnerLoadingService.getMessage().subscribe(message => {
      setTimeout(() => {
        if (message === 'show') {
          this.showSpinner = true;
        } else {
          this.showSpinner = false;
        }
      });
    });
    this.pannelSubscription = this.pannelLoadingMessageService.getMessage().subscribe(message => {
      setTimeout(() => {
        if (message === 'show') {
          this.showPannelSpinner = true;
        } else {
          this.showPannelSpinner = false;
        }
      });
    });

  }

  ngOnInit() {

    console.log('oidc app component onint .128.');
    this.userIdleTimeOutService.setUserIdleCheck();

    const curl = window.location.href;
    const strUrlArr = curl.split('/');

    console.log('oidc app component .139 strUrlArr...........'+strUrlArr);
   
    if (strUrlArr.length > 4 && !strUrlArr[4].startsWith('callback')) {
      this.router.navigate(['/tp2']);
    }

    for (let i = 0; i < strUrlArr.length; i++) {
      console.log(' strUrlArr =  '+ i + '  '+strUrlArr[i]);
    }

    console.log('oidc app component .146 before callback.............');



    // if (strUrlArr.length > 4 && strUrlArr[4].startsWith('callback') && sessionStorage.getItem('loggedIn')!='true') {
    //   // This is for redirect to home page -- local
    //   // this.router.navigate(['tp2']);
    //   // This is for redirect to home page -- INT, QUA, PROD, Oper
    //  // sessionStorage.setItem('loggedIn','true');

    //   console.log('oidc app component .inside 157 before callback.............');
    //    this.router.navigate(['/tp2']);
    // } 

    // this.router.events.subscribe(event => {
    //   if (event instanceof RouteConfigLoadStart) {
    //     this.navigationSpinner = true;
    //     this.resetUserIdleSession();
    //   } else if (event instanceof RouteConfigLoadEnd) {
    //     this.navigationSpinner = false;
    //     this.resetUserIdleSession();
    //   }
    //   /* if (event instanceof ActivationEnd) {
    //     console.log('calling canceling request');
    //     this.httpCancelService.cancelPendingRequests();
    //   } */
    // });
  }

  showAgreement() {
    if (sessionStorage.getItem('uid') && sessionStorage.getItem('role')) {
      this.showDbError = false;
      const sessionRoles = JSON.parse(sessionStorage.getItem('role')) || [];
      const userIpn = sessionStorage.getItem('uid');
      let user: UsersModel = new UsersModel();
      const userRole: roleModel[] = [];
      const userOrgMap: any[] = [];
      const orglabel: any[] = [];
      this.orgMapService.getOrganisationList().subscribe(data => {
        const orgMap: OrganisationMapModel[] = data;
        if (sessionRoles) {
          sessionRoles.forEach(role => {
            const roleVal = role.substring(5);
            const itemArr = orgMap.filter(org => org.label.toLowerCase() !== roleVal);
            if (itemArr.length < orgMap.length && userOrgMap && userOrgMap.indexOf(itemArr[0].id) <= 0) {
              userOrgMap.push(itemArr[0].id);
              orglabel.push(role);
            } else if (orglabel.indexOf(role) <= 0 &&
              userRole.find((roleTest) => roleTest.label === role) === undefined) {
              const roleMap: roleModel = new roleModel();
              roleMap.label = role;
              userRole.push(roleMap);
            }
          });
          sessionStorage.setItem('OrganizationMap', userOrgMap.toString());
          this.agreementService.getUserByIpn(user).subscribe((res: HttpResponse<UsersModel[]>) => {
            if (res.status === 200 && res.body.length > 0) {
              user = res.body[0];
              if (!user.alreadyConn) {
                this.openDialog(user, userRole);
              } else {
                this.userModel = user;
                const usersRoleModel = new UsersRoleModel();
                usersRoleModel.users = user;
                usersRoleModel.role = userRole;
                this.agreementService.updateUserRoles(usersRoleModel).subscribe((res) => {
                  console.log(res);
                });
                this.showHeader = true;
              }
              this.preferenceService.setUserPreferences(user);
            } else if (res.status === 200 && res.body.length === 0) {
              this.openDialog(user, userRole);
            }
          }, error => this.showDbError = true);

        }
      }, error => this.showDbError = true);
      user.ipn = userIpn;
      user.organisationId = userOrgMap;
      sessionStorage.setItem('OrganizationMap', userOrgMap.toString());
    }
  }

  isOnlyRolesExists(): boolean {
    const roleExists: string = sessionStorage.getItem('onlyRoles');
    if (roleExists === 'true') {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.pannelSubscription.unsubscribe();
    this.userIdleTimeOutService.stopWatching();
  }

  /**
  * Configuration of OpenId / ARCA
  */
   private configureOpenId() {

    for (const key in environment.redirectUrls) {
      if (key) {
        console.log('..257 key...'+key)
        console.log('..258  .window.location.origin. '+window.location.origin)
        console.log('..259.window.location.origin.search(key)...'+window.location.origin.search(key))
        console.log('..260.!environment.authConfig.redirectUri....'+!environment.authConfig.redirectUri)
        if (window.location.origin.search(key) !== -1 && !environment.redirectUri) {
          this.setEnvironmentName(key);
          environment.redirectUri = environment.redirectUrls[key];
          authConfig.redirectUri = environment.redirectUrls[key];
          environment.silentRefreshRedirectUri = environment.silentRefreshUrls[key];
          authConfig.silentRefreshRedirectUri = environment.silentRefreshUrls[key];
          environment.issuer = environment.issuerUrls[key];
          authConfig.issuer = environment.issuerUrls[key];
          environment.loginUrl = environment.loginUrls[key];
          authConfig.loginUrl = environment.loginUrls[key];
          environment.userinfoEndpoint = environment.userinfoEndpoints[key];
          authConfig.userinfoEndpoint = environment.userinfoEndpoints[key];
          environment.clientId = environment.clientIds[key];
          authConfig.clientId = environment.clientIds[key];
        }
      }
    }
    console.log('...configure open id 263...'+environment.redirectUri)
  }

  openDialog(user, userRole): void {
    if (this.showAgreementPopup) {
      this.showAgreementPopup = false;
      const usersRoleModel = new UsersRoleModel();
      usersRoleModel.users = user;
      usersRoleModel.role = userRole;
      const dialogRef = this.dialog.open(AgreementPopupComponent, {
        width: '90%',
        data: usersRoleModel,
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        this.showHeader = true;
        this.showAgreementPopup = false;
        this.showDbError = false;
      });
    }
  }

  // for userIdle check ---- Start
  @HostListener('mouseup', ['$event'])
  @HostListener('mousemove')
  refreshUserState() {
    this.resetUserIdleSession();
  }

  @HostListener('keydown', ['$event']) onkeydown(event) {
    this.resetUserIdleSession();
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetUserIdleTimeCheck() {
    this.resetUserIdleSession();
  }

  resetUserIdleSession() {
    const restTimerCheck = sessionStorage.getItem('restTimerCheck');
    if (restTimerCheck && restTimerCheck === 'true') {
      sessionStorage.setItem('userIdelchk', 'true');
      // this.userIdleTimeOutService.stop();
    }
  }
  // for userIdle check ---- End

  setEnvironmentName(key: string) {
    if (key === 'int.tp2.dev') {
      sessionStorage.setItem('envName', 'DEV');
    } else if (key === 'qua.tp2.dev') {
      sessionStorage.setItem('envName', 'QUA');
    } else if (key === 'sta.tp2.dev') {
      sessionStorage.setItem('envName', 'STA');
    } else if (key === 're7.tp2.re7') {
      sessionStorage.setItem('envName', 'RE7');
    } else if (key === 'ope.tp2.ope') {
      sessionStorage.setItem('envName', 'OPE');
    } else {
      sessionStorage.setItem('envName', 'local');
    }
  }
}
