import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceDeleteService {

    public showDeleteBtn: boolean;
    public deleteMaintenanceSupplier: any;

    constructor() {
    //   this.showDeleteBtn  
    }

    showMaintainenceSupplierDelete(showBtn: any) {
        this.showDeleteBtn = showBtn;
    }

    setMaintainenceSupplier(supplier: any) {
        this.deleteMaintenanceSupplier = supplier;
    }
}