export class Shifts {
    id: number;
    teams: number;
    hours: number;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: boolean;
    organisationId: number[];
}
