import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgreementService } from './agreement.service';
import { SnackBarService } from '../snackbar/snackbar.component.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'agreement-popup',
  templateUrl: './agreement-popup.component.html',
  styleUrls: ['./agreement-popup.component.css']
})
export class AgreementPopupComponent implements OnInit {
  orgMap;
  agreementForm: FormGroup;
  submitted = false;
  error;

  constructor(private agreementService: AgreementService,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private snackbarService: SnackBarService,
    private dialogRef: MatDialogRef<AgreementPopupComponent>, private fb: FormBuilder) { }

  ngOnInit() {
    this.agreementForm = this.fb.group({
      userAgreeCheck: [{value: true, disabled: true}]
    });
  }

  get f() { return this.agreementForm.controls; }

  acceptAgreement() {
    this.submitted = true;
    this.agreementService.saveUsers(this.data).subscribe((res) => {
      if (res.body && res.status === 200 ) {
        this.dialogRef.close();
      } else {
        this.snackbarService.showSnackBar('Errormessage.db_common_Error_Msg', 'F');
      }
    }, (error) => {
      this.error = error;
      this.snackbarService.showSnackBar('Errormessage.db_common_Error_Msg', 'F');
    });
  }
}
