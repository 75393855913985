export class Level0 {
    id: number;
    label: string;
    level0_test: Boolean;
    failure: Boolean;
    organisationId: number[];
    createDate: Date;
    updateDate: Date;
    ipn: String;
    active: Boolean;
}