import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TbfCalculation } from '../model/tbfCalculation.model';
import { TbfDataWrapper } from '../model/tbfDataWrapper';
import { Milestone } from 'src/app/administration/referentiel/milestone/milestone.model';
import { map } from 'rxjs/operators';
import { TbfConsumption } from '../model/tbfConsumption.model';
import { GridCalculation } from '../model/gridCalculation.model';
import { TbfConsumptionComponent } from '../tbf-consumption/tbf-consumption.component';
import { GridOptions, GridApi } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class TbfService {

  private baseString: string;
  public mileStone: Milestone[];
  public tbfConsumptionData: TbfConsumption[];
  public saveConsumtionData: TbfConsumption[];
  public tbfDirtyChecker: boolean;
  public params: any;
  public tbfWrapperData: TbfDataWrapper;
  lineChart: any;
  options: any;
  public endDateweekNumber: number;
  public endDateYear: number;
  public satrtDateWeekNumber: number;
  public startDateYear: number;

  constructor(private http: HttpClient) {
    this.baseString = environment.apiURL;
    this.saveConsumtionData = new Array<TbfConsumption>();
  }

  saveTbfCalculation(tbfDataWrapper: TbfDataWrapper) {
    return this.http.post<any>(this.baseString + '/saveTbfCalculationData', tbfDataWrapper);
  }

  getTbfCalculation(tbfCalculation: TbfCalculation) {
    return this.http.post<TbfDataWrapper>(this.baseString + '/getTbfCalculationData', tbfCalculation);
  }

  getMileStoneList(mileStone: Milestone) {
    return this.http.post<Milestone[]>(this.baseString + '/getActiveMilestoneList', mileStone).pipe(map(res => res));
  }

  getTbfDurationData(tbfDataWrapper: TbfDataWrapper) {
    return this.http.post<GridCalculation[]>(this.baseString + '/getTbfConsumptionData', tbfDataWrapper);
  }

}
