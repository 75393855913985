import { Injectable } from '@angular/core';
import { OT } from '../activate/OT.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OtStatusService {

  otGridValue: any;
  public apiURL: string = environment.apiURL;
  constructor(private httpClient: HttpClient) { }

  getOtStatusList (): Observable<OT[]> {
    return this.httpClient.get<OT[]>(this.apiURL + '/getOtStatusList').pipe(map(res => res));  }


setOtGridValue (value: any) {
 this.otGridValue = value;
}

getOtGridValue () {
  return this.otGridValue;
}

}
