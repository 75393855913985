import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'map-mecanique',
  templateUrl: './map-mecanique.component.html',
  styleUrls: ['./map-mecanique.component.css']
})
export class MapMecaniqueComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MapMecaniqueComponent>,) { }

  ngOnInit() {
  }
  onClose(event?: any): void {
    this.dialogRef.close(event);
  }

  bmsMeca() {
    window.open(environment.exploitationMapMechaniqueMecaOpeUrl);
  }

  receptionUo() {
    window.open(environment.exploitationMapMechaniqueReceptionUoOpeUrl);
  }

  cleon() {
    window.open(environment.exploitationMapMechaniqueCleonOpeUrl);
  }

  nvh() {
    window.open(environment.exploitationMapMechaniqueNvhOpeUrl);
  }

}
