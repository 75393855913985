import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerDirection } from './customer.model';
import { GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class CustomerDirectionService {

  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public customer: CustomerDirection;
  public btnName: string;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllCustomers(customerDirection: CustomerDirection) {
    return this.httpClient.post<CustomerDirection[]>(this.apiURL + '/getCustomerDirectionList', customerDirection).pipe(map(res => res));
  }

  updateCustomerOrganisationList(customer: CustomerDirection) {
    return this.httpClient.put<CustomerDirection>(this.apiURL + '/updateCustomerOrganisationList', customer, {
      observe: 'response'
    });
  }
  createCustomerList(customer: CustomerDirection) {

    return this.httpClient.post<CustomerDirection>(this.apiURL + '/createCustomerDirectionList', customer, {
      observe: 'response'
    });
  }
  updateCustomerList(customer: CustomerDirection) {
    return this.httpClient.put<CustomerDirection>(this.apiURL + '/updateCustomerDirectionList', customer, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(customerDirection: CustomerDirection) {
    this.getAllCustomers(customerDirection).subscribe((result: CustomerDirection[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  setCustomer(gridCustomer: CustomerDirection) {
    this.customer = gridCustomer;

    if (this.customer != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateCustomer() {
    return this.customer;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getActiveCustomerDirection(customerDirection: CustomerDirection) {
    return this.httpClient.post<CustomerDirection[]>
    (this.apiURL + '/getActiveCustomerDirectionList', customerDirection).pipe(map(res => res));
  }

}
