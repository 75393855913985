import {NgModule} from '@angular/core';
import {AuthorizationService} from './authorization.service';
import {AuthGuard} from './authguard/authguard.service';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule
  ],
  declarations: [
  LogoutComponent],
  providers: [
    AuthGuard
  ],
  exports: [
    RouterModule,
  ]
})
export class AuthorizationModule {
  public static forRoot() {
    return {
      ngModule: AuthorizationModule,
      providers: [AuthorizationService]
    };
  }
}
