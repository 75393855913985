import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Building } from '../../administration/referentiel/buildings/building.model';
import { LevelsAdmin } from '../../administration/levelreferential/levelsadmin/levelsadmin.model';
import { Level2 } from 'src/app/administration/levelreferential/level2/level2.model';
import { Level1 } from 'src/app/administration/levelreferential/level1/level1.model';
import { Prestation } from 'src/app/administration/prestationsandprocedures/prestation/prestation.model';
import { Procedure } from 'src/app/administration/prestationsandprocedures/procedure/procedure.model';
import { PrestationAdmin } from 'src/app/administration/prestationsandprocedures/prestationadmin/prestationadmin.model';
import { MacroActivity } from 'src/app/administration/prestationsandprocedures/macro-activity/macro-activity.model';

@Injectable({
  providedIn: 'root'
})

export class ImportService {

  public apiURL: string = environment.apiURL;

  constructor(private httpClient: HttpClient) {
  }

  importLevelsAdmin(levelsAdmin: Array<LevelsAdmin>[]) {

    return this.httpClient.post<Array<LevelsAdmin>[]>(this.apiURL + '/importLevelsAdmin', levelsAdmin, {
      observe: 'response'
    });
  }

  importBuilding(building: Array<Building>[]) {

    return this.httpClient.post<Array<Building>[]>(this.apiURL + '/importBuilding', building, {
      observe: 'response'
    });
  }

  importLevel1(level1: Array<Level1>[]) {

    return this.httpClient.post<Array<Level1>[]>(this.apiURL + '/importLevel1', level1, {
      observe: 'response'
    });
  }

  importLevel2(level2: Array<Level2>[]) {

    return this.httpClient.post<Array<Level2>[]>(this.apiURL + '/importLevel2', level2, {
      observe: 'response'
    });
  }
  importPrestation(prestation: Array<Prestation>[]) {
    return this.httpClient.post<Array<Prestation>[]>(this.apiURL + '/importPrestation', prestation, {
      observe: 'response'
    });
  }
  importProcedure(procedure: Array<Procedure>[]) {
    return this.httpClient.post<Array<Procedure>[]>(this.apiURL + '/importProcedure', procedure, {
      observe: 'response'
    });
  }
  importPrestationAdmin(prestationAdmin: Array<PrestationAdmin>[]) {
    return this.httpClient.post<Array<PrestationAdmin>[]>(this.apiURL + '/importPrestationAdmin', prestationAdmin, {
      observe: 'response'
    });
  }

  importMacroActivity(macro: Array<MacroActivity>[]) {
    return this.httpClient.post<Array<MacroActivity>[]>(this.apiURL + '/importMacroActivity', macro, {
      observe: 'response'
    });
  }
}
