import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { StoppageType } from './stoppage-type.model';
@Injectable({
  providedIn: 'root'
})
export class StoppageTypeService {

  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  // public level1: Level1;
  public btnName: string;
  public id: number;
  public gridDataStoppage: any;
  public stoppageType: StoppageType;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }


  getAllStoppageType() {
    const stoppageType = new StoppageType();
    stoppageType.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    return this.httpClient.post<StoppageType[]>(this.apiURL + '/getStoppageTypeList', stoppageType).pipe(map(res => res));
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  createOrUpdateStoppage(stoppageType: StoppageType) {
    return this.httpClient.post<StoppageType>(this.apiURL + '/createOrUpdateStoppage', stoppageType).pipe(map(res => res));
  }

  refreshGrid() {
    this.getAllStoppageType().subscribe((result: StoppageType[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  updateStoppageOrganisationList(stoppage: StoppageType) {
    return this.httpClient.put<StoppageType>(this.apiURL + '/updateStoppageOrganisationList', stoppage, {
      observe: 'response'
    });
  }

  setStoppageType(gridStoppage: StoppageType) {
    this.stoppageType = gridStoppage;
    if (this.stoppageType) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
}
