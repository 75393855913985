export enum CrrppStaticValues {
    English = 'en',
    French = 'fr',
    LogOutUrl = '/logout',
    CrrpUrl = '/Input/Activate',
    AgGridRowMultiple = 'multiple',
    AgGridRowSingle = 'single',
    RoleAction = 'roleAction',
    CrrppId = 'crrpId',
    True = 'true',
    UID = 'uid',
    User = 'User',
    OrgId = 'organisationIDs',
    FirstName = 'fname',
    LastName = 'lname',
    DateFormat = 'dd/MM/yyyy',
    WeekFormat = 'ww',
    Locale = 'fr-FR',
    difference = 'differenceValue',
    selectMileStone = 'Select Milestone',
    openBracket = ' (',
    closeBracket = ') ',
    sommeTbf = 'SOMME TBF',
    tbfCible = 'TBF CIBLE',
    sommeTbfColor = '#4bc0c0',
    tbfCibleColor = '#565656',
    dataSets = 'datasets',
    volumeTBF = 'VOLUME DE TBF',
    semaines = 'SEMAINES',
    biWeeklyAddition = 420,
    weekCalculation = 7,
    previous = 'previous',
    next = 'next',
    TBFFollowUp = 'TBFFollowUp',
    testMean = 'planification',
    Prerequisites = 'Prerequisites',
    AnalyseCampaign = 'AnalyseCampaign',
    SuiviMoteur = 'SuiviMoteur',
    Voies = 'Voies',
    hours = "Hours ",
    mousemove = 'mousemove',
    mouseout = 'mouseout',
    touchstart = 'touchstart',
    touchmove = 'touchmove',
    label = 'label',
    tbfVolume = 'CRRP.TBFVolume',
    crrpWeeks = 'CRRP.Weeks',
    CRRPtbfSomme = 'CRRP.TBFSomme',
    CRRPtbfCible = 'CRRP.TbfCible',
    refreshMessage = 'refresh',
    user = 'User',
    id = 'uid',
    AnalyseFerme = 'AnalyseFerme',
    week = 'Week:',
    toWeek = ' to Week: ',
    fname = 'fname',
    lname = 'lname',
    role = 'role',
    active = 'active',
    crrpp = 'CRRPP',
    xlsx = '.xlsx',
    fermeCause = 'CRRP.AnalyseFerme.fermeCause',
    clientCause = 'CRRP.AnalyseFerme.clientCause',
    duration = 'CRRP.AnalyseFerme.total_duration',
    Excel = 'Excel',
    PDF = 'PDF',
    Mail = 'Mail',
    hoursTranslation = 'CRRP.AnalyseFerme.hours',
    boolean = 'boolean',
    pdf = '.pdf',
}
