import { EngineActivity } from './engineActivity.model';

export class EngineReliability {
    id: number;
    engineActivity: EngineActivity;
    soft: string;
    dcm: string;
    cust_req: string;
    test: boolean;
    organisationId: number[];
    bvRlce:boolean;
}
