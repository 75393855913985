export class UsersModel {
    id: number;
    ipn: string;
    alreadyConn: boolean;
    organisationId: number[];
    language: String;
    site: number;
    mean: number;
    testbed: number;
}

