import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CommonServices } from 'src/app/common/common.services';
import { ExpertiseDomain } from './expertise-domain.model';

@Injectable({
  providedIn: 'root'
})
export class ExpertiseDomainService {

  data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public btnName: string;
  expertiseDomain: ExpertiseDomain;
  constructor(private httpClient: HttpClient, private commonServices: CommonServices) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  getAllExpertiseDomain() {
    const expertiseDomain = new ExpertiseDomain();
    expertiseDomain.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    return this.httpClient.post<ExpertiseDomain[]>(this.apiURL + '/getExpertiseDomainList', expertiseDomain).pipe(map(res => res));
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  saveExpertiseDomain(expertiseDomain: ExpertiseDomain) {
    return this.httpClient.post<ExpertiseDomain>(this.apiURL + '/saveExpertiseDomain', expertiseDomain, {
      observe: 'response'
    });
  }

  refreshGrid() {
    this.getAllExpertiseDomain().subscribe((result: ExpertiseDomain[]) => {
      this.gridOptions.api.setRowData(result);
      this.commonServices.expertiseDomainData = result;
    });
  }

  updateExpertiseDomainOrganisationList(expertiseDomain: ExpertiseDomain) {
    return this.httpClient.put<ExpertiseDomain>(this.apiURL + '/updateExpertiseDomainOrganisationList', expertiseDomain, {
      observe: 'response'
    });
  }

  setExpertiseDomain(gridExpertiseDomain: ExpertiseDomain) {
    this.expertiseDomain = gridExpertiseDomain;
    if (this.expertiseDomain) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
}
