import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Sector } from './sector.model';
import { GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class SectorService {

  data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public sector: Sector;
  public btnName: string;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllSectors(sector: Sector) {
    return this.httpClient.post<Sector[]>(this.apiURL + '/getSectorList', sector).pipe(map(res => res));
  }

  updateSectorOrganisationList(sector: Sector) {
    return this.httpClient.put<Sector>(this.apiURL + '/updateSectorOrganisationList', sector, {
      observe: 'response'
    });
  }
  updateSectorList(sector: Sector) {
    return this.httpClient.put<Sector>(this.apiURL + '/updateSectorList', sector, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  createSectorList(sector: Sector) {
    return this.httpClient.post<Sector>(this.apiURL + '/createSectorList', sector, {
      observe: 'response'
    });
  }

  setSector(gridCampaign: Sector) {
    this.sector = gridCampaign;

    if (this.sector != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateSector() {
    return this.sector;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
