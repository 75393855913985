import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GridOptions } from 'ag-grid-community';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Level2 } from './level2.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class Level2Service {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public level2: Level2;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllLevel2(level2: Level2) {
    return this.httpClient.post<Level2[]>(this.apiURL + '/getLevel2List', level2).pipe(map(res => res));
  }

  updateLevel2OrganisationList(level2: Level2) {
    return this.httpClient.put<Level2>(this.apiURL + '/updateLevel2OrganisationList', level2, {
      observe: 'response'
    });
  }
  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(level2: Level2) {
    this.getAllLevel2(level2).subscribe((result: Level2[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  createLevel2List(level2: Level2) {

    return this.httpClient.post<Level2>(this.apiURL + '/saveLevel2', level2, {

    observe: 'response'

    });

    }

    updateLevel2List(level2: Level2) {
      return this.httpClient.put<Level2>(this.apiURL + '/updateLevel2', level2, {
        observe: 'response'
      });
    }

    setLevel2(gridLevel2: Level2) {
      this.level2 = gridLevel2;
      if (this.level2 != null) {
        this.initUpdate();
      } else {
        this.initCreate();
      }
    }

    getUpdateLevel2() {
      return this.level2;
    }

    initUpdate() {
      this.btnName = 'Common_Buttons.Update';
    }

    initCreate() {
      this.btnName = 'Common_Buttons.Add_Record';
    }

  }



