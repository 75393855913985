import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'spot-fire-maintenance',
  templateUrl: './spot-fire-maintenance.component.html',
  styleUrls: ['./spot-fire-maintenance.component.css']
})
export class SpotFireMaintenanceComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SpotFireMaintenanceComponent>, public dialog: MatDialog) { }

  ngOnInit() {
  }
  onClose(event?: any): void {
    this.dialogRef.close(event);
  }

  ftr() {
    window.open(environment.maintenanceFtrOpeUrl);
  }

  moyenEssais() {
    window.open(environment.maintenanceMoyenOpeUrl);
  }

  temps() {
    window.open(environment.maintenanceTempsOpeUrl);
  }

  logPuma() {
    window.open(environment.maintenanceLogPumaOpeUrl);
  }


}
