import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackBarService } from '../snackbar/snackbar.component.service';
import { SpinnerLoadingService } from '../spinner-loading/spinner-loading.service';
//import { OpenIdService } from 'src/app/auth/open-id.service';
import { UserIdleTimeOutService } from '../userIdleTimeOut/user-idle-time-out.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private snackbarService: SnackBarService, 
        private spinnerLoadingService: SpinnerLoadingService,
        private userIdleTimeOutService: UserIdleTimeOutService) {
        this.spinnerLoadingService.sendMessage('show');
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const sessionRoles = JSON.parse(sessionStorage.getItem('role')) || [];
        // To provide all Organisation Data visualisation access to admin users-both Deatm & Deatv
        if (request.body && sessionRoles.includes('tpm2-admin') && !sessionStorage.getItem('testbedExtract')) {
            console.log('http intercept if starts ..');
            console.log(request.body);
            console.log(request.url);
            const urlFinder: string = request.url.split('api/')[1];
            console.log(urlFinder);
            console.log('http intercept if ends ..');
            if (urlFinder != undefined && (urlFinder.startsWith('get') || urlFinder.startsWith('check')) && (!urlFinder.startsWith('getDirectoryData'))) {
                request.body.organisationId = (request.body.organisationId !== undefined) ? [1, 2] : undefined;
                request.body.organisation_reg_id = (request.body.organisation_reg_id !== undefined) ? [1, 2] : undefined;
                request.body.orgId = (request.body.orgId !== undefined) ? [1, 2] : undefined;

            }
        }
        this.spinnerLoadingService.sendMessage('show');
        const token: string = sessionStorage.getItem('access_token');
        // for userIdle check ---- start
        const restTimerCheck = sessionStorage.getItem('restTimerCheck');
        if (restTimerCheck && restTimerCheck === 'true') {
            sessionStorage.setItem('userIdelchk', 'true');
            // this.userIdleTimeOutService.stop();
        }
        // for userIdle check ---- End

        if (token) {
            if (request.url.indexOf("token") !== -1) {
                console.log('request.url--->>>'+request.url);
            } else {
                console.log('...inside if if else token.......');
                request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
            }
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.spinnerLoadingService.sendMessage('hide');
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // TODO: Delete --- start
                let data = {};
                const errorStatus = error.status;
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: errorStatus
                };
                // TODO: Delete --- end
                this.spinnerLoadingService.sendMessage('hide');
                if (errorStatus === 401) {
                    this.snackbarService.showSnackBar('Errormessage.api_Unauthorize_Msg', 'F');
                   // this.openIdService.initOpenIdAuthent();
                } else {
                    this.snackbarService.showSnackBar('Errormessage.db_common_Error_Msg', 'F');
                }
                return throwError(error);
            }));
    }
}
