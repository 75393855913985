import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { vehicleActivityEnum, VehicleActivities } from './vehicle-activities.model';
import * as moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { GridOptions } from 'ag-grid-community';
import { EngineActivity } from '../activate/engineActivity.model';
import { Means } from 'src/app/administration/referentiel/means/means.model';
import { Level0 } from 'src/app/administration/levelreferential/level0/level0.model';
import { Level1 } from 'src/app/administration/levelreferential/level1/level1.model';
import { Level2 } from 'src/app/administration/levelreferential/level2/level2.model';
import { LevelsAdmin } from 'src/app/administration/levelreferential/levelsadmin/levelsadmin.model';
import { DatePipe } from '@angular/common';
import { FailureActivity } from '../activate/failureActivity.model';
import { VehicleActivityWrapper } from './vehicleActivityWrapper.model';
import { RowOrder } from '../activate/rowOrder.model';
import { VehicleActivityMultipleModel } from './multiple-update/vh-act-constants.model';
import { MultipleDuplicateActivityModel } from '../activate/muliple-duplicate/multiple-duplicate-activity';
import { VhMultipleDuplicateActivityModel } from './vh-multiple-duplicate/vhmultiple-duplicate-activity';

@Injectable({
  providedIn: 'root'
})
export class VhActService {
  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();
  private baseString: string;
  public modifyRowIndex: any;
  public updatedSelRow: any;
  public selectedVhActivity: any;
  public mean: Means;
  public level0: Level0[];
  public level1: Level1[];
  public level2: Level2[];
  public levelsAdminData: LevelsAdmin[];
  public otValFlag: any;
  public failureActivityList: FailureActivity[];
  public testbedId: any;
  public testBed: Testbed[];
  public dateLang: String;
  public formdirty: boolean;
  public onRowSelectCount = 0;
  public testBedCombo: any;
  public gridOptions: GridOptions;
  public lockDate: any;
  public techValidationCheckBox: any;
  public otStatus: any;
  public page: number;
  public pageSize: number;

  constructor(private http: HttpClient,
    private datePipe: DatePipe) {
    this.baseString = environment.apiURL;
  }

  getSumOfVehicleActivityHourByDate(id: number, activityDate: string) {
    return this.http.get<any>(this.baseString + '/getSumOfVehicleActivityHourByDate?id=' + id + '&activityDate='
      + this.convetStdDateFormat(activityDate));
  }

  getTestbedById(testbedId: number): Observable<Testbed> {
    return this.http.get<Testbed>(this.baseString + '/getTestbedDetail/' + testbedId);
  }

  saveVehicleActivity(vehicleActivities: VehicleActivities) {
    return this.http.post<VehicleActivities>(this.baseString + '/saveVehicleActivity', vehicleActivities, {
      observe: 'response'
    });
  }

  setPageAndPageSize(page: any, pageSize: any) {
    this.page = page;
    this.pageSize = pageSize
  }

  getVehicleActivityListByTestBedId(vehicleActivities: VehicleActivities) {
    // this.page = this.page === undefined ? 0 : this.page;
    // this.pageSize = this.pageSize === undefined ? 10 : this.pageSize;
    return this.http.post<VehicleActivities[]>(this.baseString + '/getVehicleActivityListByTestBedId/', vehicleActivities);
  }

  convetStdDateFormat(input) {
    return moment(input).format(vehicleActivityEnum.stdDateFromat);
  }

  convetUtcDateFormat(input) {
    return moment.utc(input).format(vehicleActivityEnum.stdDateFromat);
  }

  convetPipeDateFormat(input) {
    return this.datePipe.transform(input, vehicleActivityEnum.DateFormat);
  }

  getNextAvailableDate(testBedId: number): Observable<Date> {
    return this.http.get<Date>(this.baseString + '/getNextAvailableDate/' + testBedId);
  }

  setupdatedSelRow(value: any) {
    this.updatedSelRow = value;
  }

  getupdatedSelRow() {
    return this.updatedSelRow;
  }

  selectLastRowGrid(gridOptions: GridOptions) {
    if (gridOptions && gridOptions.api) {
      const rowcount = gridOptions.api.getDisplayedRowCount();
      const tempselrow = this.getupdatedSelRow();;
      console.log('selectedRow>>>', tempselrow);
      let modifyRowIndex = Number(this.modifyRowIndex);
      if (tempselrow) {
        let selectedActivtiy = tempselrow.vehicleActivity ? tempselrow.vehicleActivity : tempselrow;
        if (!selectedActivtiy) {
          selectedActivtiy = tempselrow.data;
        }
        console.log('selectedActivtiy>>>', selectedActivtiy);
        gridOptions.api.forEachNode(function (node) {
          if (node.data.activity.id === selectedActivtiy.activity.id) {
            node.setSelected(true);
            gridOptions.api.ensureIndexVisible(node.rowIndex, 'bottom');
            return;
          }
        });
      } else {
        console.log('in else lastrow');
        if (!modifyRowIndex || Number.isNaN(modifyRowIndex) || modifyRowIndex === 0) {
          modifyRowIndex = rowcount - 1;
        }
        gridOptions.api.forEachNode(function (node) {
          if (node.rowIndex === modifyRowIndex) {
            node.setSelected(true);
          }
        });
        if (modifyRowIndex > 0) {
          gridOptions.api.ensureIndexVisible(modifyRowIndex, 'bottom');
        }
        this.setModifyRowIndex(null);
      }
    }
  }
  
  setModifyRowIndex(value: any) {
    this.modifyRowIndex = value;
  }

  saveFailureActvity(failureActivity: FailureActivity) {
    return this.http.post<FailureActivity>(this.baseString + '/saveFailureActivity', failureActivity, {
      observe: 'response'
    });
  }

  updateFailureActvity(failureActivity: FailureActivity) {
    return this.http.put<FailureActivity>(this.baseString + '/updateFailureActivity', failureActivity, {
      observe: 'response'
    });
  }

  deleteFaliureSave(failureActivity: FailureActivity) {
    return this.http.post<FailureActivity>(this.baseString + '/deleteFaliureSave', failureActivity, {
      observe: 'response'
    });
  }

  getFailureActivityDataByTestBedId(testBedId: number, mapName: String): Observable<FailureActivity[]> {
    return this.http.get<FailureActivity[]>(this.baseString + '/getFailureActivityDataByTestBedId/' + testBedId + '/' + mapName);
  }

  getVHFailureActivityData(activityId: number): Observable<FailureActivity[]> {
    return this.http.get<FailureActivity[]>(this.baseString + '/getVHFailureActivityData/' + activityId);
  }

  getTestBed() {
    return this.testBed;
  }

  setTestBed(testBed: any) {
    this.testBed = testBed;
  }

  setDateLang(dateLang: String) {
    this.dateLang = dateLang;
  }
  getLang() {
    return this.dateLang;
  }

  extractVehicleActivity(beginDt: String, endDt: String, testId: String) {
    return this.http.get<VehicleActivityWrapper[]>(this.baseString + '/extractVehicleActivity/?startDate=' + beginDt + '&endDate=' +
      endDt + '&testId=' + testId);
  }
  deleteVehicleActivityData(activityId: number, seqUpdateCheck: boolean): Observable<void> {
    return this.http.delete<void>(this.baseString + '/deleteVehicleActivityData/?id=' + activityId
      + '&seqUpdateCheck=' + seqUpdateCheck);
  }

  multipleDeleteVehicleActivity(params: VehicleActivities[]) {
    return this.http.post<any>(this.baseString + '/multipleDeleteVehicleActivity/', params);
  }

  deleteFailureActivityVehicleActData(activityId: any) {
    return this.http.post<Boolean>(this.baseString + '/deleteFailureActivityVehicleActData/', activityId);
  }

  getVehicleActivityWithSeqNum(vehicleActivity: VehicleActivities) {
    // this.page = this.page === undefined ? 0 : this.page;
    // this.pageSize = this.pageSize === undefined ? 10 : this.pageSize;
    return this.http.post<VehicleActivities[]>(this.baseString + '/getVehicleActivityData/', vehicleActivity);
  }

  saveVhRowOrder(rowOrder: RowOrder[]) {
    return this.http.put<RowOrder[]>(this.baseString + '/saveVhRowOrder', rowOrder, {
      observe: 'response'
    });
  }
  getvehicleActivityWithSeqNum(vehicleActivity: VehicleActivities) {
    return this.http.post<VehicleActivities[]>(this.baseString + '/getvehicleActivityList/', vehicleActivity);
  }

  bulkUpdateVehicleActvity(multiUpdateModel: VehicleActivityMultipleModel) {
    // tslint:disable-next-line: max-line-length
    return this.http.post<any>(this.baseString + '/multipleUpdateVehicle/', multiUpdateModel);
  }

  multipleDuplicateVehicleActivities(vhmultipleDuplicateActivity: VhMultipleDuplicateActivityModel) {
    return this.http.put<any>(this.baseString + '/multipleDuplicateVehicleActivities', vhmultipleDuplicateActivity, {
      observe: 'response'
    });
  }

  getFilteredVehicleActivity(vehicleActivity: VehicleActivities) {
    // this.page = this.page === undefined ? 0 : this.page;
    // this.pageSize = this.pageSize === undefined ? 10 : this.pageSize;
    return this.http.post<VehicleActivities[]>(this.baseString + '/getFilteredVehicleActivity/', vehicleActivity);
  }

  getFilteredVehicleActivityDataCount(vehicleActivity: VehicleActivities) {
    return this.http.post<any>(this.baseString + '/getFilteredVehicleActivityDataCount',vehicleActivity);
  }

  deleteModifiedFailureActivity(vehicleActivityId: number) {
    return this.http.post<Boolean>(this.baseString + '/deleteModifiedFailureActivity/', vehicleActivityId);
  }

  extractNewVehicleActivity(beginDt: String, endDt: String, testId: any[]) {
    return this.http.get<VehicleActivityWrapper[]>(this.baseString + '/extractNewVehicleActivity/?startDate=' + beginDt + '&endDate=' +
      endDt + '&testId=' + testId);
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
}
