import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Milestone } from './milestone.model';
import { constants } from '../referentiel.model';

@Injectable({
  providedIn: 'root'
})
export class MilestoneService {
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public buttonName: string;
  milestone: Milestone;
  constructor(private httpClient: HttpClient) {
    this.buttonName = 'Common_Buttons.Add_Record'; }

  getGridOptions() {
    return this.gridOptions;
  }
  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }
  setMilestone(gridServices: Milestone) {
    this.milestone = gridServices;
    if (this.milestone != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateMilestone() {
    return this.milestone;
  }

  initUpdate() {
    this.buttonName = constants.updateBtn;
  }
  initCreate() {
    this.buttonName = 'Common_Buttons.Add_Record';
  }

  getAllMilestone (milestone: Milestone) {
    return this.httpClient.post<Milestone[]>(this.apiURL + '/getMilestoneList', milestone).pipe(map(res => res));
  }

  checkColorDuplicate(dataList, checkVal): boolean {
    if (dataList) {
      const duplicateList = dataList.filter(dataVal => dataVal.color === checkVal.color);
      if (duplicateList && duplicateList.length > 0) {
    if (duplicateList.length > 1) {
        return true;
      } else if (duplicateList[0].id !== checkVal.id) {
        return true;
      }
    }}
}

refreshGrid(milestone: Milestone) {
  milestone.organisationId = JSON.parse(sessionStorage.getItem(constants.orgId));
  this.getAllMilestone(milestone).subscribe((result: Milestone[]) => {
    this.gridOptions.api.setRowData(result);
  });
}

createMilestoneList(milestone: Milestone) {
  return this.httpClient.post<Milestone>(this.apiURL + '/createMilestoneList', milestone, {
    observe: 'response'
  });
}

updateMilestoneList(milestone: Milestone){
  return this.httpClient.put<Milestone>(this.apiURL + '/updateMilestoneList', milestone, {
    observe: 'response'
  });
}

updateMilestoneOrganisationList(milestone: Milestone){
  return this.httpClient.put<Milestone>(this.apiURL + '/updateMilestoneOrganisationList', milestone, {
    observe: 'response'
  });
}
}
