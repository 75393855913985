import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EngineActivity } from './engineActivity.model';
import { FailureActivity } from './failureActivity.model';
import { EvEngine } from './evEngine.model';
import { TuningEdActivity } from './tuningEdActivity.model';
import { GridOptions, GridApi } from 'ag-grid-community';
import { EngineReliability } from './engineReliabilityActivity.model';
import { RowOrder } from './rowOrder.model';
import { FormGroup } from '@angular/forms';
import { ActivityWrapper } from './activityWrapper.model';
import { Activity, ActivityEnum } from './activity.model';
import { TestbedGlobalInfo } from 'src/app/administration/testbedreferential/testbed/global-info/testbed-global-info.model';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { BarActivity } from '../bar-activity/barActivity.model';
import { RollActivity } from '../bar-activity/rollActivity.model';
import { BarCopActivity } from '../bar-activity/barCopActivity.model';
import { BarMixActivity } from '../bar-activity/barMixActivity.model';
import { ActivitycommonService } from './activitycommon.service';
import { StopWarning } from '../stop-warning/stop-warning.model';
import { DynamicDataTransfer } from 'src/app/common/dynamic-form/dynamic-form.model';
import { CommonTestBed } from 'src/app/administration/testbedreferential/testbed/commonTestbedModel.model';
import * as moment from 'moment';
import { formatDate, DatePipe } from '@angular/common';
import { MultipleDuplicateActivityModel } from './muliple-duplicate/multiple-duplicate-activity';
import { EngineActivityGridWrapper } from './engineActivityGridWrapper.model';
import { MultipleDeleteWrapper } from './multipleDeleteWrapper.model';
import { RollActivityGridWrapper } from './rollActivityGridWrapper.model';
import { LevelsAdmin } from 'src/app/administration/levelreferential/levelsadmin/levelsadmin.model';
import { PrestationAdmin } from 'src/app/administration/prestationsandprocedures/prestationadmin/prestationadmin.model';
import { CampaignAssociation } from 'src/app/administration/referentiel/campaignadministration/campaignadministration.model';
import { CustomerDirection } from 'src/app/administration/referentiel/customer/customer.model';
import { BarActivityService } from '../bar-activity/bar-activity.service';


@Injectable({
  providedIn: 'root'
})
export class ActivateService {

  private baseString: string;
  public gridOptions: GridOptions;
  public gridApi: GridApi;
  public params: any;
  public testBedFormData: any;
  public activityRowData: any;
  public filterActivityForm: FormGroup;
  public dateLang: String;
  public displayDt: Date;
  public testbedid: number;
  public barIndicator: any;
  public newApi: any;
  public barUpdate: any;
  public barValue: any;
  public barTest: any;
  public barFail: any;
  public barflagVal: boolean;
  public emissionFailureUpdate: boolean;
  public sacreFlag: boolean;
  public sansOtUpdateFlag: boolean;
  public barSeqResult: any;
  public barSeqVal: boolean;
  public barDate: any;
  public campaignId: any;
  public siteIdVal: any;
  public testBed: Testbed[];
  public stopWarning: any;
  public stopData: any;
  public newDurationGrid: any;
  public updateFlagForSeq: boolean;
  failureActivityList: FailureActivity[];
  otValFlag: any;
  valueOfTesbed: any;
  page: number;
  size: number;
  public dataTrans: DynamicDataTransfer;
  public levelsAdmin: LevelsAdmin[];
  public prestationAdmin: PrestationAdmin[];
  public campaignadministration: CampaignAssociation[];
  public customerDirection: CustomerDirection[];
  totalRecords: number;
  engineActivityDate: any;
  public meanType : String;
  receivedTotalRecordsEmitter: EventEmitter<number> = new EventEmitter<number>();
  constructor(
    private http: HttpClient, 
    private activitycommonService: ActivitycommonService) {
    this.baseString = environment.apiURL;
  }

  /*TO Delete*/
  // getSiteComboListService(testbed: Testbed) {
  //   return this.http.post<Testbed>(this.baseString + '/getSiteComboListNew', testbed);
  // }

  // getMeansComboListService(testbed: Testbed) {
  //   return this.http.post<Testbed>(this.baseString + '/getMeansComboListNew', testbed);
  // }
  /*TO Delete*/
  getSiteComboListService() {
    const orgId = [2];
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getSiteComboList/' + orgId);
  }

  getMeansComboListService() {
    const orgId = [2];
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getEngineMeanComboList/' + orgId);
  }

  getSiteComboList(): Observable<TestbedGlobalInfo[]> {
    const orgId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    console.log('orgId', orgId);
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getSiteComboList/' + orgId);
  }
  getEngineSiteComboList(): Observable<TestbedGlobalInfo[]> {
    const orgId = [1];
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getSiteComboList/' + orgId);
  }
  getEngineMeansComboList(): Observable<TestbedGlobalInfo[]> {
    const orgId = [1];
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getEngineMeanComboList/' + orgId);
  }

  getMeansComboList(): Observable<TestbedGlobalInfo[]> {
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getMeanComboList');
  }
  getMeansComboListForExtract(): Observable<TestbedGlobalInfo[]> {
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getMeanComboListForExtract');
  }

  getTestbedComboList(testbed: Testbed) {
    return this.http.post<Testbed>(this.baseString + '/getTestbedComboList/', testbed, {
      observe: 'response'
    });
  }

  getTestbedById(testbedId: number): Observable<Testbed> {
    return this.http.get<Testbed>(this.baseString + '/getTestbedDetail/' + testbedId);
  }

/*   getEngineActivityData(engineActivity: EngineActivity) {
    // this.page = this.page === undefined ? 0 : this.page;
    // this.size = this.size === undefined ? 10 : this.size;
    return this.http.post<EngineActivityGridWrapper[]>(this.baseString + '/getFilteredActivityData/', engineActivity);
  } */
  // getDateFilteredEngineActivityData(engineActivity: EngineActivity) {
  //   return this.http.post<EngineActivity[]>(this.baseString + '/getDateFilteredEngineActivityData/', engineActivity);
  // }

  getFailureData(activityId: number): Observable<FailureActivity[]> {
    return this.http.get<FailureActivity[]>(this.baseString + '/getFailureActivityData/' + activityId);
  }

  getFailureDataEmissionCD(activityId: number): Observable<FailureActivity[]> {
    return this.http.get<FailureActivity[]>(this.baseString + '/getFailureActivityDataEmissionCD/' + activityId);
  }
  getTestbedGlobalInfoList(): Observable<TestbedGlobalInfo[]> {
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getTestbedGlobalInfoList/');
  }
  getEvEngineData(activityId: number): Observable<EvEngine[]> {
    return this.http.get<EvEngine[]>(this.baseString + '/getEvEngineData/' + activityId);
  }

  getFilterActivityForm() {
    return this.filterActivityForm;
  }

  getSacreData(essNo: String): Observable<any> {

    return this.http.get<any>(this.baseString + '/getSacreData/' + essNo);
  }

  setFilterActivityForm(filterActivityForm: FormGroup) {
    this.filterActivityForm = filterActivityForm;
  }


  saveFailureActvity(failureActivity: FailureActivity) {
    return this.http.post<FailureActivity>(this.baseString + '/saveFailureActivity', failureActivity, {
      observe: 'response'
    });
  }
  updateFailureActvity(failureActivity: FailureActivity) {
    return this.http.put<FailureActivity>(this.baseString + '/updateFailureActivity', failureActivity, {
      observe: 'response'
    });
  }

  deleteFaliureSave(failureActivity: FailureActivity) {
    return this.http.post<FailureActivity>(this.baseString + '/deleteFaliureSave', failureActivity, {
      observe: 'response'
    });
  }

  saveTuningEdActvity(tuningEdActivity: TuningEdActivity) {
    return this.http.post<TuningEdActivity>(this.baseString + '/saveTuningEdActvity', tuningEdActivity, {
      observe: 'response'
    });
  }
  updateTuningEdActvity(tuningEdActivity: TuningEdActivity) {
    return this.http.put<TuningEdActivity>(this.baseString + '/updateTuningTestActivity', tuningEdActivity, {
      observe: 'response'
    });
  }

  saveReliabilityEdActivity(reliability: EngineReliability) {
    return this.http.post<EngineReliability>(this.baseString + '/saveReliabilityEdActivity', reliability, {
      observe: 'response'
    });
  }

  updateReliabilityEdActivity(reliability: EngineReliability) {
    return this.http.put<EngineReliability>(this.baseString + '/updateReliabilityEdActivityData', reliability, {
      observe: 'response'
    });
  }
  saveEvEngine(electrical: EvEngine) {
    return this.http.post<EvEngine>(this.baseString + '/saveEvEngine', electrical, {
      observe: 'response'
    });
  }
  updateEvEngine(electrical: EvEngine) {
    return this.http.put<EvEngine>(this.baseString + '/updateEvEngine', electrical, {
      observe: 'response'
    });
  }

  getUnixDatelocal(localDate) {
    let replaceDateSrt = null;
    if (localDate) {
      const dateStr = new Date(localDate).toISOString();
      const dateSplitA = dateStr.split('T');
      replaceDateSrt = dateSplitA[0];
    }
    return replaceDateSrt;
  }

  getDateFormat(inputDate) {
    if (inputDate !== null) {
      const dateObj = inputDate.split('-');
      return dateObj[2] + '/' + dateObj[1] + '/' + dateObj[0];
    } else {
      return null;
    }
  }

  datelocalSuffix(localDate?) {
    if (!localDate) {
      localDate = new Date();
    }
    const dateStr = JSON.stringify(new Date());
    const dateSplitA = dateStr.split('T');
    const replaceDateSrt = 'T' + dateSplitA[1].substr(0, dateSplitA[1].length - 1);
    return replaceDateSrt;
  }

  getSumOfEngineActivityHourByDate(id: number, activityDate: string) {
    return this.http.get<any>(this.baseString + '/getSumOfEngineActivityHourByDate?id=' + id + '&activityDate='
      + this.convetStdDateFormat(activityDate));
  }

  getGridOptions() {
    return this.gridOptions;
  }

  getGridApi() {
    return this.gridApi;
  }

  setGridApi(gridApi) {
    this.gridApi = gridApi;
  }
  getParams() {
    return this.params;
  }
  setParams(params) {
    this.params = params;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }


  getTestBedFormData() {
    return this.testBedFormData;
  }

  setTestBedFormData(testBedFormData: any) {
    this.testBedFormData = testBedFormData;
  }

  getactivityRowData() {
    return this.activityRowData;
  }

  setactivityRowData(activityRowData: any) {
    this.activityRowData = activityRowData;
  }

  getEmissionFailureUpdate() {
    return this.emissionFailureUpdate;
  }
  setSacreFlag(value: any) {
    this.sacreFlag = value;
  }

  getSacreFlag() {
    console.log(this.sacreFlag);
    return this.sacreFlag;
  }




  setEmissionFailureUpdate(emissionFailureUpdate: any) {
    this.emissionFailureUpdate = emissionFailureUpdate;
  }

  refreshGrid(mapVal?) {
    const testbedId = this.getTestBedFormData()['selectedTestBed'];
    const engineActivity = new EngineActivity();
    const activity = new Activity();
    const testbed = new Testbed();
    engineActivity.activity = activity;
    engineActivity.activity.testBedId = testbed;
    engineActivity.activity.testBedId.id = testbedId;
    console.log(this.getDisplayDt());
    if (mapVal === ActivityEnum.EmissionCD) {
      mapVal = ActivityEnum.EmissionCDLabel;
    } else {
      mapVal = ActivityEnum.TuningEDLabel;
    }
    this.getFailureActivityDataByTestBedId(testbedId, mapVal).subscribe((failureActivity_EngAct: FailureActivity[]) => {
      // this.setFailureActivityList(failureActivityData);
      if (this.getDisplayDt() === undefined) {
        engineActivity.activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.getEngineActivityData(engineActivity).subscribe((result: EngineActivityGridWrapper[]) => {
          // result = result.sort(function (a, b) { return a.seqNum - b.seqNum; });
          this.gridOptions.context = {
            failureActivityData: failureActivity_EngAct.filter(res => res.engineActivity != null && res.ot != null),
            otData: failureActivity_EngAct.filter(res => res.engineActivity != null)
          };
          if (this.gridOptions && this.gridOptions.api) {
            this.gridOptions.api.setRowData(result);
          }
          //this.getTotalRecordsEmitter(result['totalElements']); // Emit the Total Elements
          this.activitycommonService.selectLastRowGrid(this.gridOptions); // to select last row
        });
      } else {
        engineActivity.activity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        engineActivity.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
        this.getEngineActivityData(engineActivity).subscribe((result: EngineActivityGridWrapper[]) => {
          // result = result.sort(function (a, b) { return a.seqNum - b.seqNum; });
          this.gridOptions.context = {
            failureActivityData: failureActivity_EngAct.filter(res => res.engineActivity != null && res.ot != null),
            otData: failureActivity_EngAct.filter(res => res.engineActivity != null)
          };
          if (this.gridOptions && this.gridOptions.api) {
            this.gridOptions.api.setRowData(result);
          }
          //this.getTotalRecordsEmitter(result['totalElements']); // Emit the Total Elements
          this.activitycommonService.selectLastRowGrid(this.gridOptions); // to select last row
        });
      }
    });
  }
  getEngineReliabilityData(activityId: number): Observable<EngineReliability[]> {
    // console.log('Inside->>>>>', this.http.get<EngineReliability[]>(this.baseString + '/getReliabilityEdActivityData/' + activityId));
    return this.http.get<EngineReliability[]>(this.baseString + '/getReliabilityEdActivityData/' + activityId);

  }

  getEngineTuningData(activityId: number): Observable<TuningEdActivity[]> {
    // console.log('Inside->>>>>', this.http.get<EngineReliability[]>(this.baseString + '/getReliabilityEdActivityData/' + activityId));
    return this.http.get<TuningEdActivity[]>(this.baseString + '/getTuningEdActivityData/' + activityId);

  }
  deleteEngineReliabilityData(activityId: number, seqUpdateCheck: boolean): Observable<void> {
    return this.http.delete<void>(this.baseString + '/deleteReliabilityEdActivityData/?id=' + activityId
      + '&seqUpdateCheck=' + seqUpdateCheck);
  }
  deleteEngineTuningData(activityId: number, seqUpdateCheck: boolean): Observable<void> {
    return this.http.delete<void>(this.baseString + '/deleteTuningEdActivityData/?id=' + activityId
      + '&seqUpdateCheck=' + seqUpdateCheck);
  }
  deleteFailureActivityData(activityId: number, seqUpdateCheck: boolean): Observable<void> {
    return this.http.delete<void>(this.baseString + '/deleteFailureActivityData/?id=' + activityId
      + '&seqUpdateCheck=' + seqUpdateCheck);
  }
  deleteEvEngineData(activityId: number, seqUpdateCheck: boolean): Observable<void> {
    return this.http.delete<void>(this.baseString + '/deleteEvEngineData/?id=' + activityId
      + '&seqUpdateCheck=' + seqUpdateCheck);
  }
  extractFailureActivity(beginDt: String, endDt: String) {
    return this.http.get<FailureActivity[]>(this.baseString + '/extractFailureActivityData/?startDate=' + beginDt + '&endDate=' + endDt);
  }
  extractTuningActivity(beginDt: String, endDt: String) {
    return this.http.get<FailureActivity[]>(this.baseString + '/extractTuningEdActivityData/?startDate=' + beginDt + '&endDate=' + endDt);
  }
  extractReliabilityActivity(beginDt: String, endDt: String) {
    // tslint:disable-next-line: max-line-length
    return this.http.get<FailureActivity[]>(this.baseString + '/extractReliabilityEdActivityData/?startDate=' + beginDt + '&endDate=' + endDt);
  }
  extractEvEngineActivity(beginDt: String, endDt: String, testId: String) {
    return this.http.get<ActivityWrapper[]>(this.baseString + '/extractActivityData/?startDate=' + beginDt + '&endDate=' +
      endDt + '&testId=' + testId);
  }

  extractNewEvEngineActivity(beginDt: String, endDt: String, testId: any[]) {
    return this.http.get<ActivityWrapper[]>(this.baseString + '/extractNewActivityData/?startDate=' + beginDt + '&endDate=' +
      endDt + '&testId=' + testId);
  }

setCampaign(campaign:any)
{
this.campaignId=campaign;
}

  saveRowOrder(rowOrder: RowOrder[]) {
    return this.http.put<RowOrder[]>(this.baseString + '/saveRowOrder', rowOrder, {
      observe: 'response'
    });
  }

  bulkUpdateTuningEdActvity(beginDt: String, endDt: String, test: Boolean, chooseField: String, value: String, value2: String,
    value3: String, testBedId: String, bulkUpdate: Boolean, level0Val: String, orgId: any, from_seq: any, to_seq: any,
    valueTOHToggle: Boolean, valueToggle?: Boolean, mapA?: string[],) {
    return this.http.put<any>(this.baseString + '/bulkUpdateTuningEdActvity/?startDate=' + beginDt + '&endDate=' + endDt
      + '&test=' + test + '&chooseField=' + chooseField + '&value=' + value + '&value2=' + value2 + '&value3=' + value3 + '&testBedId='
      + testBedId + '&bulkUpdate=' + bulkUpdate + '&level0Val=' + level0Val + '&orgId=' + orgId + '&fromSeq='
      + from_seq + '&toSeq=' + to_seq + '&valueTOHToggle=' + valueTOHToggle + '&valueToggle=' + valueToggle +'&campaign='+this.campaignId+ '&mapA=' + mapA, {
      observe: 'response'
    });
  }

  bulkUpdateEvEngineActvity(beginDt: String, endDt: String, test: Boolean, chooseField: String, value: String, value2: String,
    value3: String, testBedId: String, bulkUpdate: Boolean, level0Val: String, orgId: any, from_seq: any, to_seq: any, 
    mapA?: string[]) {
    // tslint:disable-next-line: max-line-length
    return this.http.put<any>(this.baseString + '/bulkUpdateEvEngineActvity/?startDate=' + beginDt + '&endDate='
      + endDt + '&test=' + test + '&chooseField=' + chooseField + '&value=' + value + '&value2=' + value2 + '&value3=' + value3 + '&testBedId='
      + testBedId + '&bulkUpdate=' + bulkUpdate + '&level0Val=' + level0Val + '&orgId=' + orgId + '&fromSeq=' + from_seq
      + '&toSeq=' + to_seq + '&mapA=' + mapA, {
      observe: 'response'
    });
  }

  bulkUpdateEngReliabilityActvity(beginDt: String, endDt: String, test: Boolean, chooseField: String, value: String, value2: String,
    value3: String, testBedId: String, bulkUpdate: Boolean, level0Val: String, orgId: any, from_seq: any,
    to_seq: any, valueToggle: Boolean, campaign:any,mapA?: string[]) {
    // tslint:disable-next-line: max-line-length
    return this.http.put<any>(this.baseString + '/bulkUpdateReliablitityActvity/?startDate=' + beginDt + '&endDate='
      + endDt + '&test=' + test + '&chooseField=' + chooseField + '&value=' + value + '&value2=' + value2 + '&value3=' + value3 + '&testBedId='
      + testBedId + '&bulkUpdate=' + bulkUpdate + '&level0Val=' + level0Val + '&orgId=' + orgId + '&fromSeq=' + from_seq
      + '&toSeq=' + to_seq + '&valueToggle=' + valueToggle +'&campaign='+campaign+'&mapA=' + mapA, {
      observe: 'response'
    });
  }

  bulkUpdateFailureActvity(beginDt: String, endDt: String, test: Boolean, chooseField: String, value: String, value2: String, testBedId: String,
    bulkUpdate: Boolean, level0Val: String, orgId: any, from_seq: any, to_seq: any, valueToggle: Boolean) {
    // tslint:disable-next-line: max-line-length
    return this.http.put<any>(this.baseString + '/bulkUpdateFailureActivity/?startDate=' + beginDt + '&endDate=' + endDt + '&test=' + test + '&chooseField=' + chooseField + '&value=' + value + '&value2=' + value2 + '&testBedId=' + testBedId + '&bulkUpdate=' + bulkUpdate + '&level0Val=' + level0Val + '&orgId=' + orgId + '&fromSeq=' + from_seq + '&toSeq=' + to_seq + '&valueToggle=' + valueToggle, {
      observe: 'response'
    });
  }

  mulipleDuplicateEngineActivities(multipldeDuplicateActivity: MultipleDuplicateActivityModel) {
    return this.http.put<any>(this.baseString + '/mulipleDuplicateEngineActivities', multipldeDuplicateActivity, {
      observe: 'response'
    });
  }

  bulkUpdateFailureActivityEmission(beginDt: String, endDt: String, test: Boolean, chooseField: String, value: String, value2: String, testBedId: String,
    bulkUpdate: Boolean, level0Val: String, orgId: any, from_seq: any, to_seq: any) {
    // tslint:disable-next-line: max-line-length
    return this.http.put<any>(this.baseString + '/bulkUpdateFailureActivityEmission/?startDate=' + beginDt + '&endDate=' + endDt + '&test=' + test + '&chooseField=' + chooseField + '&value=' + value + '&value2=' + value2 + '&testBedId=' + testBedId + '&bulkUpdate=' + bulkUpdate + '&level0Val=' + level0Val + '&orgId=' + orgId + '&fromSeq=' + from_seq + '&toSeq=' + to_seq, {
      observe: 'response'
    });
  }
  setDateLang(dateLang: String) {
    this.dateLang = dateLang;
  }
  getLang() {
    return this.dateLang;
  }
  setPageAndPageSize(page: any, size: any) {
    this.page = page;
    this.size = size;
  }
  updateTestBedFreezeDate(lockDt: string, testbedIdArray: number[], testBedId: number, siteId: number) {
    return this.http.put<any>(this.baseString + '/updateLockDate/?lockDate=' + lockDt + '&testbedIdArray=' + testbedIdArray + '&testBedId=' + testBedId + '&siteId=' + siteId, {
      observe: 'response'
    });

  }

  getLockDate(id: number) {

    const rolesAssigned = sessionStorage.getItem('role');

    const roleAction: string = sessionStorage.getItem('roleAction');

    var roleActionValue = roleAction === '"W"' ? true : false;

    if(roleActionValue && rolesAssigned.toLowerCase().includes('tpm2-admin')) {
      return this.http.get<Date>(this.baseString + '/getLockDateForAdmin/' + id);
    } else {
      return this.http.get<Date>(this.baseString + '/getLockDate/' + id);
    }
  }

  getEngineActivityData(engineActivity: EngineActivity) {

    const rolesAssigned = sessionStorage.getItem('role');

    const roleAction: string = sessionStorage.getItem('roleAction');

    var roleActionValue = roleAction === '"W"' ? true : false;

    if(roleActionValue && rolesAssigned.toLowerCase().includes('tpm2-admin')) {

      return this.http.post<EngineActivityGridWrapper[]>(this.baseString + '/getFilteredActivityDataForAdmin/', engineActivity);
    }else {

      return this.http.post<EngineActivityGridWrapper[]>(this.baseString + '/getFilteredActivityData/', engineActivity);
    }
  }


  setDisplayDate(displayDt: Date) {
    this.displayDt = displayDt;
  }
  getDisplayDt() {
    return this.displayDt;
  }

  getEngineActivityWithSeqNum(engineActivity: EngineActivity) {
    // this.page = this.page === undefined ? 0 : this.page;
    // this.size = this.size === undefined ? 5000 : this.size;
    return this.http.post<EngineActivityGridWrapper[]>(this.baseString + '/getActivityData/', engineActivity);
  }
  setTestBedId(testBedId: number) {
    this.testbedid = testBedId;
  }

  getTestBedId() {
    return this.testbedid;
  }
  /* for bar activity component starts here*/
  setNewApi(value: any) {
    this.newApi = value;
  }
  getNewApi() {
    return this.newApi;
  }
  getSumOfEngineActivityHourByDateBar(id: number, activityDate: string) {
    return this.http.get<any>(this.baseString + '/getSumOfEngineActivityHourByDateBar?id=' + id + '&activityDate=' +
      this.convetStdDateFormat(activityDate));
  }
  getBarData(activityId: number): Observable<BarActivity[]> {
    return this.http.get<BarActivity[]>(this.baseString + '/getBarActivityData/' + activityId);
  }
  setBarUpdate(updateVal: any) {
    this.barUpdate = updateVal;
  }
  getBarUpdate() {
    return this.barUpdate;
  }
  setResult(resultVal: any) {
    this.barValue = resultVal;
  }
  getResult() {
    return this.barValue;
  }
  setTestVal(value: any) {
    this.barTest = value;
  }
  getTestVal() {
    return this.barTest;
  }
  setFailVal(value: any) {
    this.barFail = value;
  }
  getFailVail() {
    return this.barFail;
  }
  setBarFlag(value: any) {
    this.barflagVal = value;
  }
  getBarFlag() {
    return this.barflagVal;
  }
  getRollActivityWithSeqNum(rollActivity: RollActivity) {
    return this.http.post<RollActivityGridWrapper[]>(this.baseString + '/getRollActivityList', rollActivity);
  }
  setSeqFlag(value: any) {
    this.barSeqVal = value;
  }
  getSeqFlag() {
    return this.barSeqVal;
  }
  setSeqResult(value: any) {
    this.barSeqResult = value;
  }
  getSeqResult() {
    return this.barSeqResult;
  }
  setBardate(value: any) {
    this.barDate = value;
  }
  getBardate() {
    return this.barDate;
  }
  getBarCopData(activityId: number): Observable<BarCopActivity[]> {
    return this.http.get<BarCopActivity[]>(this.baseString + '/getBarCopActivityData/' + activityId);
  }
  bulkUpdateBarCopActvity(beginDt: String, endDt: String, test: Boolean, chooseField: String, value: String,
    value2: String, value3: String, testBedId: String, bulkUpdate: Boolean, level0Val: String, orgId: any, from_seq: any, to_seq: any, campaign:any,mapA?: string[]) {

    return this.http.put<any>(this.baseString + '/bulkUpdateBarCopActvity/?startDate=' + beginDt + '&endDate=' + endDt
      + '&test=' + test + '&chooseField=' + chooseField + '&value=' + value + '&value2=' + value2 + '&value3=' + value3 + '&testBedId='
      + testBedId + '&bulkUpdate=' + bulkUpdate + '&level0Val=' + level0Val + '&orgId=' + orgId + '&fromSeq='
      + from_seq + '&toSeq=' + to_seq +'&campaign='+campaign+ '&mapA=' + mapA, {
      observe: 'response'
    });
  }
  bulkUpdateBarActvity(beginDt: String, endDt: String, test: Boolean, chooseField: String, value: String, value2: String, value3: String, testBedId: String,
    bulkUpdate: Boolean, level0Val: String, orgId: any, from_seq: any, to_seq: any,campaign:any, mapA?: string[]) {
    return this.http.put<any>(this.baseString + '/bulkUpdateBarActvity/?startDate=' + beginDt + '&endDate=' + endDt + '&test='
      + test + '&chooseField=' + chooseField + '&value=' + value + '&value2=' + value2 + '&value3=' + value3 + '&testBedId=' + testBedId + '&bulkUpdate='
      + bulkUpdate + '&level0Val=' + level0Val + '&orgId=' + orgId + '&fromSeq=' + from_seq + '&toSeq=' + to_seq +'&campaign='+campaign+ '&mapA=' + mapA, {
      observe: 'response'
    });
  }
  bulkUpdateBarMixActvity(beginDt: String, endDt: String, test: Boolean, chooseField: String, value: String,
    value2: String, value3: String, testBedId: String, bulkUpdate: Boolean, level0Val: String, orgId: any, from_seq: any, to_seq: any,campaign:any, mapA?: string[]) {
    return this.http.put<any>(this.baseString + '/bulkUpdateBarMixActvity/?startDate=' + beginDt + '&endDate=' + endDt + '&test='
      + test + '&chooseField=' + chooseField + '&value=' + value + '&value2=' + value2 + '&value3=' + value3 + '&testBedId=' + testBedId + '&bulkUpdate='
      + bulkUpdate + '&level0Val=' + level0Val + '&orgId=' + orgId + '&fromSeq=' + from_seq + '&toSeq=' + to_seq +'&campaign='+campaign + '&mapA=' + mapA, {
      observe: 'response'
    });
  }
  setSiteId(value: any) {
    this.siteIdVal = value;
  }
  getSiteId() {
    return this.siteIdVal;
  }
  /* for bar activity component ends here*/

  deleteEmissionCdActivity(activityId: any): Observable<void> {
    const activityID: number = activityId.rollActivity.activity.id;
    const meansLabel: string = activityId.rollActivity.activity.means.label;
    return this.http.delete<void>(this.baseString + '/deleteEmissionCdActivity/' + activityID + '/' + meansLabel);
  }
  /*
     Method Description: This method is used to Set the validated field in the
     column Tp015_Validated to True which is present in the table TP015_Activity
     User Story:US403 - As an operator/administrator I want to fix an activity on a BAR bench
  */
  fixOrReleaseActivity(activity: Activity) {
    return this.http.put<Activity[]>(this.baseString + '/fixOrReleaseActivity', activity);
  }

  getTestBed() {
    return this.testBed;
  }

  setTestBed(testBed: any) {
    this.testBed = testBed;
  }

  getBarMixData(activityId: number): Observable<BarMixActivity[]> {
    return this.http.get<BarMixActivity[]>(this.baseString + '/getBarMixActivityData/' + activityId);
  }

  deleteFailureActivityEmissionCDData(activityId: any) {
    return this.http.post<Boolean>(this.baseString + '/deleteFailureActivityEmissionCDData/', activityId);
  }

  getStopWarning(stopWarning: StopWarning) {
    return this.http.post<StopWarning[]>(this.baseString + '/getStopWarning', stopWarning);
  }

  getNextAvailableDate(testBedId: number): Observable<Date> {
    return this.http.get<Date>(this.baseString + '/getNextAvailableDate/' + testBedId);
  }

  setStopValue(value: any) {
    this.stopWarning = value;
  }

  getStopValue() {
    return this.stopWarning;
  }

  setStopData(value: any) {
    this.stopData = value;
  }

  getStopData() {
    return this.stopData;
  }

  getBuildingsComboList(): Observable<TestbedGlobalInfo[]> {
    
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getBuildingComboList');
  }
  getBuildingsList(): Observable<TestbedGlobalInfo[]> {
    const orgId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    return this.http.get<TestbedGlobalInfo[]>(this.baseString + '/getBuildingList/' + orgId);
  }

  setActivtyDurationGridFlag(value: any) {
    this.newDurationGrid = value;
  }
  getActivtyDurationGridFlag() {
    return this.newDurationGrid;
  }

  getAllFailureActivityList(): Observable<FailureActivity[]> {
    return this.http.get<FailureActivity[]>(this.baseString + '/getAllFailureActivityList');
  }

  getAllFailureActivityEntityList(): Observable<FailureActivity[]> {
    return this.http.get<FailureActivity[]>(this.baseString + '/getAllFailureActivityEntityList');
  }

  setFailureActivityList(result: FailureActivity[]) {
    this.failureActivityList = result;
  }

  getFailureActivityList() {
    return this.failureActivityList;
  }

  setOtValFlag(value: any) {
    this.otValFlag = value;
  }
  getOtValFlag() {
    return this.otValFlag;
  }
  setTestbedValue(value: any) {
    this.valueOfTesbed = value;
  }
  getTestbedValue() {
    return this.valueOfTesbed;
  }

  getHazardViewData(stopwarning: StopWarning) {
    return this.http.post(this.baseString + '/getHazardViewData', stopwarning);
  }

  getCommonTestbed(testbedId: number): Observable<CommonTestBed> {
    return this.http.get<CommonTestBed>(this.baseString + '/getTestbedDetail/' + testbedId);
  }

  convetStdDateFormat(input) {
    return moment(input).format(ActivityEnum.stdDateFromat);
  }

  convetUtcDateFormat(input) {
    return moment.utc(input).format(ActivityEnum.stdDateFromat);
  }

  getFailureActivityDataByTestBedId(testBedId: number, mapName: String): Observable<FailureActivity[]> {
    return this.http.get<FailureActivity[]>(this.baseString + '/getFailureActivityDataByTestBedId/' + testBedId + '/' + mapName);
  }


  multipleDeleteActivityEmissionCD(params: MultipleDeleteWrapper) {
    return this.http.post<any>(this.baseString + '/multipleDeleteActivityEmissionCD/', params);
  }

  multipleDeleteActivityNonEmissionCD(params: MultipleDeleteWrapper) {
    return this.http.post<any>(this.baseString + '/multipleDeleteActivityNonEmissionCD/', params);
  }

  getTotalRecordsEmitter(totalRecords: number): void {
    return this.receivedTotalRecordsEmitter.emit(totalRecords);
  }

  setEnginerActivityFormData(value: any) {
    this.engineActivityDate = value;
  }
  getEngineActivityFormData() {
    return this.engineActivityDate;
  }
}