import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TestbedComplementAssociation } from './testbedtypecomplementassociation.model';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestbedComplementAssociationService {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public gridApi: GridApi;
  public testbedcomplementassociation: TestbedComplementAssociation;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllTestbedComplementAssociation(testbedcomplementassociation: TestbedComplementAssociation) {
    return this.httpClient.post<TestbedComplementAssociation[]>(this.apiURL + '/getTestbedComplementAssociationList', testbedcomplementassociation).pipe(map(res => res));
  }
  

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  refreshGrid(testbedcomplementassociation: TestbedComplementAssociation) {
    this.getAllTestbedComplementAssociation(testbedcomplementassociation).subscribe((result: TestbedComplementAssociation[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  createTestbedComplementAssociationList(testbedcomplementassociation: TestbedComplementAssociation) {

    return this.httpClient.post<TestbedComplementAssociation>(this.apiURL + '/createTestbedComplementAssociationList', testbedcomplementassociation, {
      observe: 'response'
    });
  }

  updateTestbedComplementAssociationList(testbedcomplementassociation: TestbedComplementAssociation) {
    return this.httpClient.put<TestbedComplementAssociation>(this.apiURL + '/updateTestbedComplementAssociationList', testbedcomplementassociation, {
      observe: 'response'
    });
  }

  updateTestbedComplementAssociationOrganisationList(testbedcomplementassociation: TestbedComplementAssociation) {
    return this.httpClient.put<TestbedComplementAssociation>(this.apiURL + '/updateTestbedComplementAssociationOrganisationList', testbedcomplementassociation, {
      observe: 'response'
    });
  }


  setTestbedComplementAssociation(gridLevelsAdmin: TestbedComplementAssociation) {
    this.testbedcomplementassociation = gridLevelsAdmin;
    if (this.testbedcomplementassociation != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getTestbedComplementAssociation() {
    return this.testbedcomplementassociation;
  }

  getUpdateTestbedComplementAssociation() {
    return this.testbedcomplementassociation;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }


}
