import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'energetique-banc-moteur',
  templateUrl: './energetique-banc-moteur.component.html',
  styleUrls: ['./energetique-banc-moteur.component.css']
})
export class EnergetiqueBancMoteur implements OnInit {

  constructor(public dialogRef: MatDialogRef<EnergetiqueBancMoteur>) { }

  ngOnInit() {
  }

  onClose(event?: any): void {
    this.dialogRef.close(event);
  }

  bilanExploitation() {
    window.open(environment.energetiqueBancMoteurBilanExplOpeUrl);
  }

  vsdAndMonitoring() {
    window.open(environment.energetiqueBancMoteurVsdAndMonitoringOpeUrl);
  }

  rTech() {
    window.open(environment.energetiqueBancMoteurRtechOpeUrl);
  }

  reception() {
    window.open(environment.energetiqueBancMoteurReceptionUoOpeUrl);
  }

  sda() {
    window.open(environment.energetiqueBancMoteurSdaOpeUrl);
  }
}
