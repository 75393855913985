import { Activity } from './activity.model';
import { RsType } from 'src/app/administration/referentiel/rs-type/rs-type.model';
import { Fuel } from 'src/app/administration/referentiel/fuel/fuel.model';

export class EngineActivity {
    id: number;
    activity: Activity;
    engNumber: string;
    engHours: number;
    
    essNB: string;
    // US385 - As a user I want the DI field to be visible for non-test activities
    di: string;
    essNBGps: string;
    projectCode: string;
    testedVS: string;
    // Rs Type field changed to drop-down
    rsTypeId: RsType;
    testType: string;
    begTarget: string;
    endTarget: string;
    status: string;
    rlce: string;
    milestone: string;
    milestoneDate: Date;
    programLine: string;
    updateDate: Date;
    organisationId: number[];
    milestoneMaxD: string;
    milestoneMinD: string;
    rlceReal: string;
    rlcePre: string;
    bvRlce: boolean;
    tuningTOH: boolean;
    fuel: Fuel;
    // seqNum: number;
}
