import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StarRatingModel } from './star-rating.model';

@Injectable({
  providedIn: 'root'
})
export class StarRatingService {
public apiURL: string = environment.apiURL;
  constructor(private http: HttpClient) { }

  saveStarRating(starRating: StarRatingModel) {
    return this.http.post<StarRatingModel>(`${this.apiURL}/saveStarRating`, starRating);
  }

  checkStarRating(starRating: StarRatingModel) {
    return this.http.get<StarRatingModel[]>(`${this.apiURL}/getStarRating?ipn=`+ starRating.ipn);
    // return this.http.get(`${this.apiURL}/getStarRating`);
  }
}
