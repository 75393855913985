import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { TbfService } from 'src/app/saisie/crrpp/tbf-calculation/tbf.service';
import { TbfConsumption } from 'src/app/saisie/crrpp/model/tbfConsumption.model';
import { CrrppStaticValues } from 'src/app/saisie/crrpp/model/crrppStaticValues';
import { TbfConsumptionDataTransferService } from 'src/app/saisie/crrpp/tbf-consumption/tbf-consumption-data-transfer.service';

@Component({
  selector: 'custom-aggrid-dropdown',
  templateUrl: './custom-aggrid-dropdown.component.html',
  styleUrls: ['./custom-aggrid-dropdown.component.css']
})
export class CustomAggridDropdownComponent implements AgRendererComponent {

  public params: ICellRendererParams;
  public contents = new Array<String>();
  public label: String;
  public saveTbfConsumptionData: TbfConsumption[];
  public labelDisable: boolean;

  agInit(params: any) {
    this.params = params;
    this.label = this.params.data ? this.params.data.milestoneId ? this.params.data.milestoneId.label : '-' : '-';
    if (this.label) {
      this.labelDisable = true;
    } else {
      this.labelDisable = false;
    }
  }

  constructor(private tbfService: TbfService, public tbfDataService: TbfConsumptionDataTransferService) {
    this.tbfService.mileStone.forEach(res => (this.contents.push(res.label)));
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
  }
  refresh(params: any): boolean {
    this.tbfService.tbfDirtyChecker = true;
    this.label = params.value;
    this.params.data.milestoneId = this.tbfService.mileStone.find((res) => res.label === this.label);
    this.findChangedData(this.params);
    this.labelDisable = true;
    return false;
  }

  findChangedData(params: any) {
    let tbfConsumption = new TbfConsumption();
    tbfConsumption = params.data;
    tbfConsumption.ipn = sessionStorage.getItem(CrrppStaticValues.UID) ?
      sessionStorage.getItem(CrrppStaticValues.UID) : CrrppStaticValues.User;
    this.saveTbfConsumptionData = this.tbfService.saveConsumtionData;
    if (this.saveTbfConsumptionData.length === 0) {
      this.saveTbfConsumptionData.push(tbfConsumption);
    } else {
      const tbfConsumptionLength = this.saveTbfConsumptionData.length;
      for (let loop = 0; loop < tbfConsumptionLength; loop++) {
        if ((this.saveTbfConsumptionData[loop].weekNumber === params.data.weekNumber) &&
          (new Date(this.saveTbfConsumptionData[loop].startDate).getFullYear() === new Date(params.data.startDate).getFullYear())) {
          this.saveTbfConsumptionData[loop] = tbfConsumption;
          break;
        } else {
          if (loop === tbfConsumptionLength - 1) {
            this.saveTbfConsumptionData.push(tbfConsumption);
          } else {
            continue;
          }
        }
      }
    }
    this.tbfDataService.changeMessage(CrrppStaticValues.refreshMessage);
    this.tbfService.saveConsumtionData = this.saveTbfConsumptionData;
  }

}
