import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { RsType } from './rs-type.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RsTypeService {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public buttonName: string;
  rsType: RsType;

  constructor(private httpClient: HttpClient) {
    this.buttonName = 'Common_Buttons.Add_Record'; }

  getGridOptions() {
    return this.gridOptions;
  }
  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  getAllRsType (rsType: RsType) {
    return this.httpClient.post<RsType[]>(this.apiURL + '/getRsTypeList', rsType).pipe(map(res => res));
  }

  setRsType(gridServices: RsType) {
    this.rsType = gridServices;
    if (this.rsType != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  getUpdateRsType() {
    return this.rsType;
  }

  initUpdate() {
    this.buttonName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.buttonName = 'Common_Buttons.Add_Record';
  }

  refreshGrid(rsType: RsType) {
    rsType.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    this.getAllRsType(rsType).subscribe((result: RsType[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }

  createRsTypeList(rsType: RsType) {
    return this.httpClient.post<RsType>(this.apiURL + '/createRsTypeList', rsType, {
      observe: 'response'
    });
  }

  updateRsTypeList(rsType: RsType) {
    return this.httpClient.put<RsType>(this.apiURL + '/updateRsTypeList', rsType, {
      observe: 'response'
    });
  }

  getActiveRsTypeList(rsType: RsType): Observable<RsType> {
    return this.httpClient.post<RsType>(this.apiURL + '/getRsTypeByOrganisation', rsType);
  }
  updateRsTypeOrganisationList(rsType: RsType) {
    return this.httpClient.put<RsType>(this.apiURL + '/updateRsTypeOrganisationList', rsType, {
      observe: 'response'
    });
  }

  getRsTypeAfterSacre (rsType: RsType) {
    return this.httpClient.post<RsType[]>(this.apiURL + '/createRsTypeFromSacre', rsType).pipe(map(res => res));
  }
}
