import { Activity } from '../activate/activity.model';

export class VehicleActivities {
    id?: number;
    activity?: Activity;
    activityTypeDropDown?: number;
    level1?: number;
    level2?: number;
    ipn?: string;
    engineDate?: Date;
    activityDuration?: number;
    comment?: string;
    testRequestNumber?: string;
    programLine?: string;
    project?: string;
    projectMilestone?: string;
    testProcedureNumber?: string;
    logoSR?: string;
    ddmrsRequest?: string;
    requesterDepartment?: string;
    vhEntity?: string;
    testSupportType?: string;
    testSupportNumber?: string;
    testStart?: Date;
    testEnd?: Date;
    nbofCycles?: string;
    nbofParts?: string;
    softCalibRef?: string;
    preparationBox?: string;
    powerTrain?: string;
    pollutionRegulation?: string;
    organisationId?: number[];
    updateDate?: Date;
}

export enum vehicleActivityEnum {
    site = 'Site',
    mean = 'Mean',
    testbed = 'TestBed',
    TestBed = 'testbed',
    selectedTestBed = 'selectedTestBed',
    selectedSite = 'selectedSite',
    selectedMean = 'selectedMean',
    teamlead = 'tpm2-team leader',
    tpmadmin = 'tpm2-admin',
    role = 'role',
    stdDateFromat = 'YYYY-MM-DD',
    DateFormat = 'dd/MM/yyyy',
    view = 'view',
    create = 'create',
    modify = 'modify',
    duplicate = 'duplicate',
    save = 'save',
    emptyStringVal = '',
    vehicleReliability = 5,
    thermalSys = 6,
    passiveSafety = 7,
    accelFuelECO = 8,
    uid = 'uid',
    User = 'User',
    vehicleReliabilityLbl = 'VehicleReliability',
    extract = 'extract',
    closure = 'closure',
    autoFill = 'autoFill',
    alertEmail = 'alertEmail',
    instructions = 'instructions',
    dailyMail = 'dailyMail',
    distributionList = 'distributionList',
    yes = 'Yes',
    Essai_Banc = 'Essai Banc',
    Xlsx = 'xlsx',
    Close = 'Close',
    formRefresh = 'RefreshForm',
    aggridRefresh = 'AgGridForm',
    meanFilter = 'MeanFilter',
    siteFilter = 'SiteFilter',
    testbedFilter = 'TestbedFilter',
    Create = 'Create',
    multipleDuplicate = 'MultipleDuplicate',
    multipleUpdate = 'MultipleUpdate',
    enableDetailScreen = 'enableDetailScreen',
    onRowSel = 'onRowSel',
    screenCall = 'screenCall',
    successMsg = 'Common_Buttons.Success',
    fail = 'F',
    success = 'S',
    failureMsg = 'Common_Buttons.Fail',
    simpleDateFormat = 'DD/MM/YYYY',
    organisationIDs = 'organisationIDs',
    red = 'dot_red',
    green = 'dot_green',
    SansOt = 'SANS OT'
}

export enum exportLang {
    english = 'en',
    vehicleActivity_En = 'VehicleActivity',
    vehicleActivity_Fr = 'Activités du véhicule',
    Sequence_Number_En = 'Sequence Number',
    Sequence_Number_Fr = 'N° de séquence'
}
