export class DynamicDataTransfer {
    comments: String;
    engineNo: String;
    rsType: String;
    febNb: String;
    engineDt: String;
    calibration: String;
    soft: String;
    testSupport: string;
    di: string;
    dcm : string;
    testNumber: string;
}

export enum DynamicConstants {
    duplicate = 'duplicate',
    Brap = 'BRAP',
    BrapEngHours = 'Brap.engHours',
    BrapEngNum = 'Brap.engNum'
}