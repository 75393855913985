import { Injectable } from '@angular/core';
import { Fuel, errorMessages } from './fuel.model';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FuelService {
  public btnName: string;
  public data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public fuel = new Fuel;

  constructor(private httpClient: HttpClient) {
    this.btnName = errorMessages.add;
   }

  initUpdate() {
    this.btnName = errorMessages.update;
  }
  initCreate() {
    this.btnName = errorMessages.add;
  }
  getAllFuel (fuel: Fuel) {
    return this.httpClient.post<Fuel[]>(this.apiURL + '/getAllFuel', fuel).pipe(map(res => res));
  }
  getGridOptions() {
    return this.gridOptions;
  }
  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }
  setFuel(fuel: Fuel) {
    this.fuel = fuel;
    if (this.fuel != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }
  refreshGrid(fuel: Fuel) {
    fuel.organisationId = JSON.parse(sessionStorage.getItem(errorMessages.orgId));
    this.getAllFuel(fuel).subscribe((result: Fuel[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  createFuelList(fuel: Fuel) {
    return this.httpClient.post<Fuel>(this.apiURL + '/createFuelList', fuel, {
      observe: 'response'
    });
  }
  updateFuelOrganisationList(fuel: Fuel){
    return this.httpClient.put<Fuel>(this.apiURL + '/updateFuelOrganisationList', fuel, {
      observe: 'response'
    });
  }
}
