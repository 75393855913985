import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'spinner-loading',
  templateUrl: './spinner-loading.component.html',
  styleUrls: ['./spinner-loading.component.css']
})
export class SpinnerLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
