export class Teams {
    id: number;
    label: String;
    organisationId: number[];
    createDate: Date;
    updateDate: Date;
    ipn: String;
    active: Boolean;

}
