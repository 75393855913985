import { EngineActivity } from './engineActivity.model';
import { RsType } from 'src/app/administration/referentiel/rs-type/rs-type.model';

export class TuningEdActivity {
    id: number;
    engineActivity: EngineActivity;
    rsType: RsType;
    febNb: string;
    engDt: string;
    cameo: string;
    test: boolean;
    soft: string;
    dcm: string;
    pointsNb: number;
    organisationId: number[];
    tuningTOH:boolean;
}
