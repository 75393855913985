
import { Level0 } from 'src/app/administration/levelreferential/level0/level0.model';

export class EngineActivityGridWrapper {
 activityId: number;
 seqNum: number;
 activityDate: Date;
 engHours: number;
 level0: Level0;
 activityDuration: number;
 exploitable: string;
 level1: string;
 level2: string;
 procedures: string;
 ot: string;
 essNb: string;
 comment: string;
 engNum: string;
 validated: Boolean;
 ipn: string;
 mapVal: string;
 engineActivityId: number;
}
