import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseFile } from './base-file';

@Injectable()
export class BaseControlService {
  constructor() { }

  toFormGroup(baseFile: BaseFile<any>[] ) {
    let group: any = {};

    baseFile.forEach(req => {
      group[req.key] = req.required ? new FormControl(req.value || '', Validators.required)
                                              : new FormControl(req.value || '');
    });
    return new FormGroup(group);
  }
}
