import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsersModel } from './users-model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UsersRoleModel } from './user-role-model';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {
  private apiURL: string = environment.apiURL;
  constructor(private httpClient: HttpClient) { }

  getUserByIpn(users: UsersModel) {
    return this.httpClient.post<UsersModel[]>(this.apiURL + '/getUserByIpn', users, {
      observe: 'response'
    });
  }

  saveUsers(users: UsersRoleModel) {
    return this.httpClient.post<UsersModel[]>(this.apiURL + '/saveUsers', users, {
      observe: 'response'
    });
  }
  updateUserRoles(users: UsersRoleModel) {
    return this.httpClient.put<UsersModel[]>(this.apiURL + '/ChangeUserRole', users, {
      observe: 'response'
    });
  }
}
