import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TestbedGlobalInfo } from 'src/app/administration/testbedreferential/testbed/global-info/testbed-global-info.model';
import { map } from 'rxjs/operators';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { TestbedExtractModel } from './tesbed-extract.model';

@Injectable({
  providedIn: 'root'
})
export class TestbedExtractService {

  private baseString: string;
  meanId: any[];
  testbedId: any[];
  constructor(private http: HttpClient) {
    this.baseString = environment.apiURL;
  }

  getFilteredMeans(filterMean: TestbedExtractModel) {
    return this.http.post(this.baseString + '/getFilteredMean', filterMean).pipe(map(res => res));
  }

  getFilteredTestbed(filterTestbed: TestbedExtractModel) {
    return this.http.post(this.baseString + '/getFilteredTestbed', filterTestbed).pipe(map(res => res));
  }
  setMeanId(value: any[]) {
    this.meanId = value;
  }
  getMeanId() {
    return this.meanId;
  }
  setTestbedId(value: any[]) {
    this.testbedId = value;
  }
  getTestbedId() {
    return this.testbedId;
  }
}
