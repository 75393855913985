import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MobileDevice } from './mobile-device.model';

@Injectable({
  providedIn: 'root'
})
export class MobileDeviceService {
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public buttonName: string;
  mobileDevice: MobileDevice;

  constructor(private httpClient: HttpClient) {
    this.buttonName = 'Common_Buttons.Add_Record'; }

    getGridOptions() {
      return this.gridOptions;
    }
    setGridOptions(gridOptions) {
      this.gridOptions = gridOptions;
    }

    getAllMobileDevice (mobile: MobileDevice) {
      return this.httpClient.post<MobileDevice[]>(this.apiURL + '/getMobileDeviceList', mobile).pipe(map(res => res));
    }

    setMobileDevice(gridServices: MobileDevice) {
      this.mobileDevice = gridServices;
      if (this.mobileDevice != null) {
        this.initUpdate();
      } else {
        this.initCreate();
      }
    }

    initUpdate() {
      this.buttonName = 'Common_Buttons.Update';
    }
    initCreate() {
      this.buttonName = 'Common_Buttons.Add_Record';
    }

    refreshGrid(mobile: MobileDevice) {
      mobile.organisationId = JSON.parse(sessionStorage.getItem('organisationIDs'));
      this.getAllMobileDevice(mobile).subscribe((result: MobileDevice[]) => {
        this.gridOptions.api.setRowData(result);
      });
    }
    createMobileDeviceList(mobile: MobileDevice) {
      return this.httpClient.post<MobileDevice>(this.apiURL + '/createMobileDeviceList', mobile, {
        observe: 'response'
      });
    }
    getUpdateMobileDevice() {
      return this.mobileDevice;
    }

    updateMobileDeviceList(mobile: MobileDevice) {
      return this.httpClient.put<MobileDevice>(this.apiURL + '/updateMobileDeviceList', mobile, {
        observe: 'response'
      });
    }
    getActiveMobileDeviceList(mobile: MobileDevice): Observable<MobileDevice> {
      return this.httpClient.post<MobileDevice>(this.apiURL + '/getActiveMobileDeviceByOrganisation', mobile);
    }
    updateMobileDeviceOrganisationList(mobile: MobileDevice) {
      return this.httpClient.put<MobileDevice>(this.apiURL + '/updateMobileDeviceOrganisationList', mobile, {
        observe: 'response'
      });
    }
}
