import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseFile } from '../dynamic-components/base-file';
import { CommonServices } from '../../common.services';

@Component({
  selector: 'dynamic-form-base',
  templateUrl: './dynamic-form-base.component.html',
  styleUrls: ['./dynamic-form-base.component.css']
})
export class DynamicFormBaseComponent {
  @Input() baseFile: BaseFile<any>;
  @Input() form: FormGroup;
  @Input() public flag;
  constructor(public commonservice: CommonServices) {  }
  get isValid() {
    if (this.form.controls[this.baseFile.key].dirty) {
      this.commonservice.setFormChanged(true);
    }
    return this.form.controls[this.baseFile.key].valid;
  }
}
