import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallbackComponent } from './auth/callback/callback.component';
import {OAuthModule,  ValidationHandler, OAuthModuleConfig} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { AuthHttp, AuthConfig } from 'angular2-jwt';
import { Http, RequestOptions, HttpModule } from '@angular/http';
import { OpenIdService } from './auth/open-id.service';
import { OpenidComponent } from './auth/openid/openid.component';
import { CommontpmModule } from './common/commontpm.module';
import { FormsModule } from '@angular/forms';
import { MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpConfigInterceptor } from './common/interceptor/httpconfig.interceptor';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { PiwikConfigResolver } from './common/piwik/piwik.config.resolver';
import { InnerPageLoadingComponent } from './common/inner-page-loading/inner-page-loading.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { SilentRefreshComponent } from './auth/silent-refresh/silent-refresh.component';
import { DatePipe } from '@angular/common';

import {SharedModule} from './shared/shared.module';
import {RnltErrorsModule} from './shared/error/rnlt-errors.module';
import {AuthorizationModule} from './shared/authorization/authorization.module';
import { oAuthModuleConfig } from './shared/authorization/config/oauth-module.config';

import {OpenIdCallBackComponent} from './shared/authorization/callback/open-id-call-back.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

export function authHttpServiceFactory(http: Http) {
  const authHttp = new AuthHttp(new AuthConfig({
    tokenName: 'access_token',
    noClientCheck: true,
    tokenGetter: (() => sessionStorage.getItem('access_token')),
  }), http);
  return authHttp;
}

export function createLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
     OpenidComponent,
    CallbackComponent, 
    InnerPageLoadingComponent,
    SilentRefreshComponent,
  ],
  imports: [
    CommontpmModule,
    BrowserModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    FormsModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    HttpModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    UserIdleModule.forRoot({
      idle: environment.userIdleConfig.idle, timeout: environment.userIdleConfig.timeout
      , ping: environment.userIdleConfig.ping
    }), AngularDualListBoxModule,
    SharedModule,
    AuthorizationModule.forRoot(),
    RnltErrorsModule,
  ],

  providers: [
    {
      provide: AuthHttp,
      useFactory: authHttpServiceFactory,
      deps: [Http, RequestOptions]
    },
    // OpenIdService,
    // { provide: ValidationHandler, useClass: JwksValidationHandler },
    // { provide: OAuthModuleConfig, useValue: oAuthModuleConfig },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: MatDialogTitle, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MAT_SNACK_BAR_DATA, useValue: [] },
    { provide: MatSnackBarRef, useValue: {} },
    PiwikConfigResolver,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
