import { Level0 } from '../../levelreferential/level0/level0.model';
import { Sign } from './Sign.model';

export class BarCopActivityDuration {
    id: number;
    level0Val: String;
    interplfamVal: String;
    variantVal: String;
    siteVal: String;
    testTypeVal:String;
    duration:number;
    ipn:String;
    level0:Level0;
    interplfamId:Sign;
    variantId:Sign;
    siteId:Sign;
    testTypeId:Sign;
    active: Boolean;
    createDate:Date;
    updateDate:Date;


    organisationId: number[];


}
