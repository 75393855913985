import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GridOptions } from 'ag-grid-community';

import { ValidationSpecimenSequence } from './validation-specimen-ts-list.model';
import { ValidationSpecimen } from '../validation-specimen.model';
import { Observable } from 'rxjs';
import { CommonServices } from 'src/app/common/common.services';




@Injectable({
  providedIn: 'root'
})
export class ValidationSpecimenSequenceService {


  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public validationSpecimen: ValidationSpecimen;
  public validationSequence: ValidationSpecimenSequence;
  public id: number;
  public buttonName: string;
  public displayDt: Date;
  public language: any;
  validationSequenceData: ValidationSpecimenSequence[];
  constructor(private httpClient: HttpClient) {
    this.buttonName = 'Common_Buttons.Add_Record';



  }


  getValidationSequenceData(validationSequence: ValidationSpecimen) {
    return this.httpClient.post<ValidationSpecimenSequence[]>(this.apiURL + '/getValidationSpecimenSeqList', validationSequence).pipe(map(res => res));
  }


  refreshGrid(validationSpecimen) {
    this.getValidationSequenceData(validationSpecimen).subscribe((result: ValidationSpecimenSequence[]) => {
      this.setValidationSeqData(result);
      this.gridOptions.api.setRowData(result);
    });
  }


  getValidationSpecimenSeqList(validationSequence: ValidationSpecimen) {
    return this.httpClient.post<ValidationSpecimenSequence[]>(this.apiURL + '/getValidationSpecimenSeqList', validationSequence).pipe(map(res => res));
  }


  updateValidationSpecimenSeq(validationSequence: ValidationSpecimenSequence) {
    return this.httpClient.put<ValidationSpecimenSequence>(this.apiURL + '/updateValidationSpecimenSeq', validationSequence, {
      observe: 'response'
    });
  }

  createValidationSpecimenList(validationSequence: ValidationSpecimenSequence) {
    return this.httpClient.post<ValidationSpecimenSequence>(this.apiURL + '/saveValidationSpecimenSeq', validationSequence, {
      observe: 'response'
    });
  }


  deleteValidationSpecimenSeqList(id: number): Observable<void> {

    return this.httpClient.delete<void>(this.apiURL + '/deleteValidationSpecimenSeqList/' + id);
  }

  setValidationSpecimenSeqList(gridValidationSpecimen: ValidationSpecimenSequence) {
    this.validationSequence = gridValidationSpecimen;
    if (this.validationSequence != null) {
      this.initUpdate();

    } else {
      this.initCreate();
    }

  }
  getValidationSpecimenSeq() {
    return this.validationSequence;
  }


  initUpdate() {
    this.buttonName = 'Common_Buttons.Update';
  }
  initCreate() {
    this.buttonName = 'Common_Buttons.Add_Record';
  }
  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  setDisplayDt(displayDt: Date) {
    this.displayDt = displayDt;
  }

  getDisplayDt() {
    return this.displayDt;
  }

  generatePDFReport(id: number, language: string) {


    const fileName = "validationreport.pdf";
    return this.httpClient.get<Blob>(this.apiURL + '/generateSequenceListPdf?id=' + id + '&language=' + language, { responseType: 'blob' as 'json' })
      .subscribe((blob: Blob) => {
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        }
        else {
          let link = document.createElement('a');
          if (link.download !== undefined) {
            let blobUrl = URL.createObjectURL(blob);
            link.setAttribute("href", blobUrl);
            link.setAttribute("download", fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

        }



      });
  }

  getValidationSeqData() {
    return this.validationSequenceData;
  }

  setValidationSeqData(validationSequenceData: ValidationSpecimenSequence[]) {
    this.validationSequenceData = validationSequenceData;
  }

}
