
export class Site {
    id: number;
    code: number;
    label: String;
    createDate: Date;
    updateDate: Date;
    ipn: String;
    active: Boolean;
    organisationId: number[];
    coswin: Boolean;
}
