import { Roles } from '../roles/roles.model';

export class MenuModel {
    public id: number;
    public menuHeader: String;
    public menuName: String;
    public roles: Roles;
    public menuAction: String;
    public active: boolean;
    public creationDate: Date;
    public updateDate: Date;
    public rolesNames: Array<string>;
}

export enum MenuEnum{
    OrgId = 'organisationIDs'
}