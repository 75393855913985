import { Activity } from '../activate/activity.model';
import { BarPrestation } from 'src/app/administration/barreferentiel/bwrprestation/bwrprestation.model';
import { PrestationAdmin } from 'src/app/administration/prestationsandprocedures/prestationadmin/prestationadmin.model';

export class RollActivity {
    id?: number;
    activity?: Activity;
    vehDT?: string;
    prestationCOP?: BarPrestation;
    vehReference?: string;
    astralNumber?: number;
    testNumber?: number;
    vetsNumber?: string;
    operator?: string;
    barPrestation?: string;
    norm?: string;
    status3?: string;
    status5?: string;
    message1?: string;
    message4?: string;
    orderNumber?: string;
    mileage?: number;
    service?: string;
    organisationId?: number[];
    procedure?: string;
    vetsOneProjectCode?: string;
    altitude?: string;
    status?: number;
    customerComment?: string;
    requester?: string;
}

export enum RollActivityEnum{
    vetsOneProjectCode = 'vetsOneProjectCode',
    testNumberlimit = 10000
}
