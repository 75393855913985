import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GridOptions } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Means } from '../means/means.model';
import { Site } from '../sites/site.model';
import { Campaign } from '../campaign/campaign.model';
import { CampaignAssociation } from './campaignadministration.model';
import { Testbed } from '../../testbedreferential/testbed/testbed-model';


@Injectable({
  providedIn: 'root'
})
export class CampaignadministrationService {
  data: String;
  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public campaignAssociation: CampaignAssociation;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;
  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllCampaignAssociations(cAssociation: CampaignAssociation) {
    return this.httpClient.post<CampaignAssociation[]>(this.apiURL + '/getCampaignAssociationList', cAssociation).pipe(map(res => res));
  }


  updateCampaignAssociationOrganisationList(campaignAssociation: CampaignAssociation) {
    return this.httpClient.put<CampaignAssociation>(this.apiURL + '/updateCampaignAssociationOrganisationList', campaignAssociation, {
      observe: 'response'
    });
  }

  saveCampaignAssociation(campaignAssociation: CampaignAssociation) {
    return this.httpClient.post<CampaignAssociation>(this.apiURL + '/saveCampaignAssociation', campaignAssociation, {
      observe: 'response'
    });
  }

  updateCampaignAssociation(campaignAssociation: CampaignAssociation) {
    return this.httpClient.put<CampaignAssociation>(this.apiURL + '/updateCampaignAssociation', campaignAssociation, {
      observe: 'response'
    });
  }
  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  setCampaignAssociation(gridCampaignAssociation: CampaignAssociation) {
    this.campaignAssociation = gridCampaignAssociation;

    if (this.campaignAssociation != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getCampaignAssociation() {
    return this.campaignAssociation;
  }

  initUpdate() {

    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }


  getMeanList(mean: Means) {
    return this.httpClient.post<Means[]>(this.apiURL + '/getActiveMeanByOrganisation', mean).pipe(map(res => res));
  }
  getSiteList(site: Site) {
    return this.httpClient.post<Site[]>(this.apiURL + '/getActiveSiteByOrganisation', site).pipe(map(res => res));
  }
  getCampaignList(campaign: Campaign) {
    return this.httpClient.post<Campaign[]>(this.apiURL + '/getActiveCampaignByOrganisation', campaign).pipe(map(res => res));
  }
  getUpdateCampaignAssociation() {
    return this.campaignAssociation;

  }

  directUpdateCampaignAssociation(campaignAssociation: CampaignAssociation) {
    return this.httpClient.put<CampaignAssociation>(this.apiURL + '/directUpdateCampaignAssociation', campaignAssociation, {
      observe: 'response'
    });
  }

  getAllCampaignAssociationsByMean(testbed: Testbed) {
    return this.httpClient.post<CampaignAssociation[]>(this.apiURL + '/getCampaignAssociationsByMean', testbed).pipe(map(res => res));
  }
}
