import { Component, OnInit } from '@angular/core';
import { CommonServices } from '../common.services';
import { MeansService } from 'src/app/administration/referentiel/means/means.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ImportService } from '../import/import.services';
import { LevelsAdminService } from 'src/app/administration/levelreferential/levelsadmin/levelsadmin.service';
import { TranslateService } from '@ngx-translate/core';
import { SectorService } from 'src/app/administration/testbedreferential/sector/sector.service';
import { exportEng, exportFrench, exportConstant } from './eport.model';
import * as enlangData from 'src/assets/i18n/en.json';
import * as frlangData from 'src/assets/i18n/fr.json';
import { VhTechnicalParamEnum } from 'src/app/administration/testbedreferential/vhtechnicalparam/vhtechnicalparam.model';
import { ExternalCoswin } from 'src/app/administration/testbedreferential/external-coswin/external-coswin.model';
import { ExternalCoswinService } from 'src/app/administration/testbedreferential/external-coswin/external-coswin.service';
import { AnimationStyleNormalizer } from '@angular/animations/browser/src/dsl/style_normalization/animation_style_normalizer';

@Component({
  selector: 'export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {
  fileName: any;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  externalCoswinData: ExternalCoswin[];
  extCoswinLabel: any;
  constructor(public commonServices: CommonServices,
    private meanservice: MeansService,
    private importService: ImportService,
    private levelsAdminService: LevelsAdminService,
    private translate: TranslateService,
    private sectorService: SectorService,
    public externalCoswinService: ExternalCoswinService
  ) { }

  ngOnInit() {
    this.getExternalCoswinList();
  }

  onBtExport() {
    // var params={allColumns: true};
    // this.commonServices.getGridApi().exportDataAsCsv(params);
    /* generate worksheet */
    let jsonData = {};
    const resultData = [];
    const browserLang = this.commonServices.getLanguage() ? this.commonServices.getLanguage() : 'en';

    if (this.commonServices.getCurrentTab() === 'Levels Admin') {
      const generatedJson = this.commonServices.getLevelAdminData();
      if (browserLang === 'en') {
        this.fileName = 'Levels Administration';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Mean'] = generatedJson[i].means.label;
          jsonData['Activity Type'] = generatedJson[i].level0.label;
          jsonData['Exploitable'] = this.nullcheck(generatedJson[i].exploitable);
          jsonData['Level 1'] = generatedJson[i].level1.label;
          jsonData['Level 2'] = generatedJson[i].level2.label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Arborescence Des Niveaux';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Moyen'] = generatedJson[i].means.label;
          jsonData['Type activite'] = generatedJson[i].level0.label;
          jsonData['Exploitable'] = this.nullcheck(generatedJson[i].exploitable);
          jsonData['Niveau 1'] = generatedJson[i].level1.label;
          jsonData['Niveau 2'] = generatedJson[i].level2.label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }


    } else if (this.commonServices.getCurrentTab() === 'Batch') {
      const generatedJson = this.commonServices.getBatchData();
      if (browserLang === 'en') {
        this.fileName = 'Batches';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Batch'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Lots';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Lot'] = generatedJson[i].label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }


    } else if (this.commonServices.getCurrentTab() === 'Service') {
      const generatedJson = this.commonServices.getServicesData();
      if (browserLang === 'en') {
        this.fileName = 'Services';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Service'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Services';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Service'] = generatedJson[i].label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === 'Perimeter') {
      const generatedJson = this.commonServices.getPerimeterData();
      if (browserLang === 'en') {
        this.fileName = 'Perimeters';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Perimeter'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Perimetres';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Perimetre'] = generatedJson[i].label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === 'Building') {
      const generatedJson = this.commonServices.getBuildingData();
      if (browserLang === 'en') {
        this.fileName = 'Buildings';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Building'] = generatedJson[i].label;
          jsonData['Site'] = generatedJson[i].siteId.label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Batiments';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Batiment'] = generatedJson[i].label;
          jsonData['Site'] = generatedJson[i].siteId.label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === 'Level 0') {
      const generatedJson = this.commonServices.getLevel0Data();
      if (browserLang === 'en') {
        this.fileName = 'Level 0';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Activity Type'] = generatedJson[i].label;
          jsonData['Test ?'] = generatedJson[i].level0_test;
          jsonData['Failure ?'] = generatedJson[i].failure;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Niveau 0';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Type activite'] = generatedJson[i].label;
          jsonData['Essai ?'] = generatedJson[i].level0_test;
          jsonData['Panne ?'] = generatedJson[i].failure;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'Level 1') {
      const generatedJson = this.commonServices.getLevel1Data();
      if (browserLang === 'en') {
        this.fileName = 'Level 1';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Level 1'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Niveau 1';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Niveau 1'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'Level 2') {
      const generatedJson = this.commonServices.getLevel2Data();
      if (browserLang === 'en') {
        this.fileName = 'Level 2';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Level 2'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Niveau 2';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Niveau 2'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'Sites') {
      const generatedJson = this.commonServices.getSiteData();
      if (browserLang === 'en') {
        this.fileName = 'Sites';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Site'] = generatedJson[i].label;
          jsonData['Site Code'] = generatedJson[i].code;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Sites';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Site'] = generatedJson[i].label;
          jsonData['Code Site'] = generatedJson[i].code;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'Campaign Association') {
      const generatedJson = this.commonServices.getCampaignAssociationData();
      if (browserLang === 'en') {
        this.fileName = 'Campaign Administration';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Campaign'] = generatedJson[i].campaigns.label;
          jsonData['Site'] = generatedJson[i].sites.label;
          jsonData['Mean'] = generatedJson[i].means.label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Arborescence De La Campagne';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Campagne'] = generatedJson[i].campaigns.label;
          jsonData['Site'] = generatedJson[i].sites.label;
          jsonData['Moyen'] = generatedJson[i].means.label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }


    } else if (this.commonServices.getCurrentTab() === 'Means') {
      const generatedJson = this.commonServices.getMeanData();
      if (browserLang === 'en') {
        this.fileName = 'Means';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Mean'] = generatedJson[i].label;
          jsonData['MAP'] = generatedJson[i].maps.label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Moyens';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Moyen'] = generatedJson[i].label;
          jsonData['MAP'] = generatedJson[i].maps.label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'Teams') {
      const generatedJson = this.commonServices.getTeamData();
      if (browserLang === 'en') {
        this.fileName = 'Teams';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Team'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'UET';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['UET'] = generatedJson[i].label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === 'Customer direction') {
      const generatedJson = this.commonServices.getCustomerData();
      if (browserLang === 'en') {
        this.fileName = 'Customer Directions';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Customer Direction'] = generatedJson[i].label;
          jsonData['Cost Center'] = generatedJson[i].costCenter;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Service Client';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Service Client'] = generatedJson[i].label;
          jsonData['Centre De Cout'] = generatedJson[i].costCenter;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }
    } else if (this.commonServices.getCurrentTab() === 'Shifts') {
      const generatedJson = this.commonServices.getShifts();
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.shifts;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.shifts;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.Referential.Shifts.Title] = generatedJson[i].teams + " × " + generatedJson[i].hours;
        jsonData[headerLabels.Administration.Referential.Shifts.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.Referential.Shifts.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.Referential.Shifts.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.Referential.Shifts.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === 'SpecimenType') {
      const generatedJson = this.commonServices.getSpecimenTypeData();
      if (browserLang === 'en') {
        this.fileName = 'Specimen Type';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Type of Specimen'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Type de specimen';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Type de specimen'] = generatedJson[i].label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === 'RsType') {
      const generatedJson = this.commonServices.getRsTypeData();
      if (browserLang === 'en') {
        this.fileName = 'RS Type';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['RS Type'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Type de Rs';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Type de Rs'] = generatedJson[i].label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.mobileTabName) {
      const generatedJson = this.commonServices.getMobileDeviceData();
      if (browserLang === exportEng.english) {
        this.fileName = exportEng.mobile;
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData[exportEng.mobile] = generatedJson[i].label;
          jsonData[exportEng.createDate] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData[exportEng.updateDate] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData[exportEng.updateBy] = generatedJson[i].ipn;
          jsonData[exportEng.active] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = exportFrench.mobile;
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData[exportFrench.mobile] = generatedJson[i].label;
          jsonData[exportFrench.createDate] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData[exportFrench.updateDate] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData[exportFrench.updateBy] = generatedJson[i].ipn;
          jsonData[exportFrench.active] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.milestonTabName) {
      const generatedJson = this.commonServices.getMilestoneData();
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.mileston;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.mileston;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.Referential.Milestone.Title] = generatedJson[i].label;
        jsonData[headerLabels.Administration.Referential.Milestone.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.Referential.Milestone.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.Referential.Milestone.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.Referential.Milestone.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === 'Bar Program Lines') {
      const generatedJson = this.commonServices.getBarProgramLinesData();
      if (browserLang === 'en') {
        this.fileName = 'COP Program Lines';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['COP Program Lines'] = generatedJson[i].label;
          jsonData['Site'] = generatedJson[i].sites.label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Lignes De Programmes COP';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Lignes De Programmes COP '] = generatedJson[i].label;
          jsonData['Site'] = generatedJson[i].sites.label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === 'BarPrestation') {
      const generatedJson = this.commonServices.getBarprestationData();
      if (browserLang === 'en') {
        this.fileName = 'BwRPrestations';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['BwR Prestation'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'BaRPrestations';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['BaR Prestation'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }
    } else if (this.commonServices.getCurrentTab() === 'Sector') {
      const generatedJson = this.commonServices.getSectorData();
      if (browserLang === 'en') {
        this.fileName = 'Sectors';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Sector'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Secteurs';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Secteur'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'TestbedComplement') {
      const generatedJson = this.commonServices.getTestbedcomplementData();
      if (browserLang === 'en') {
        this.fileName = 'Testbedtypecomplements';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Testbed type complement'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Complementdestypesdebancs';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Complement des types de banc'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'TestbedComplementAssociation') {
      const generatedJson = this.commonServices.getTestbedComplementAssociationData();
      if (browserLang === 'en') {
        this.fileName = 'TestbedtypeComplementAssociations';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Testbed type'] = generatedJson[i].testbedtype.label;
          jsonData['Testbed type complement'] = generatedJson[i].testbedcomplement.label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Association de type de banc - complement';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Type de banc'] = generatedJson[i].testbedtype.label;
          jsonData['Type de complement de banc'] = generatedJson[i].testbedcomplement.label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }


    } else if (this.commonServices.getCurrentTab() === 'Campaign') {
      const generatedJson = this.commonServices.getCampaignData();
      if (browserLang === 'en') {
        this.fileName = 'Campaigns';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Campaign'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Campagnes';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Campagne'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'Prestation') {
      const generatedJson = this.commonServices.getPrestationData();
      if (browserLang === 'en') {
        this.fileName = 'Prestations';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Prestation'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Prestations';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Prestation'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === exportEng.macroTabName) {
      const generatedJson = this.commonServices.getMacroActivityData();
      if (browserLang === exportEng.english) {
        this.fileName = exportEng.macro;
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData[exportEng.macro] = generatedJson[i].label;
          jsonData[exportEng.createDate] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData[exportEng.updateDate] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData[exportEng.updateBy] = generatedJson[i].ipn;
          jsonData[exportEng.active] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = exportFrench.macro;
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData[exportFrench.macro] = generatedJson[i].label;
          jsonData[exportFrench.createDate] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData[exportFrench.updateDate] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData[exportFrench.updateBy] = generatedJson[i].ipn;
          jsonData[exportFrench.active] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === 'Procedure') {
      const generatedJson = this.commonServices.getProcedureData();
      if (browserLang === 'en') {
        this.fileName = 'Procedures';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Procedure'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Procedures';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Procedure'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    }
    else if (this.commonServices.getCurrentTab() === 'MaintenanceTask') {
      const generatedJson = this.commonServices.getMaintenanceTaskData();
      if (browserLang === 'en') {
        this.fileName = 'MaintenanceTasks';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Supplier'] = generatedJson[i].maintenanceSupplierID.label;
          jsonData['Periodicity'] = generatedJson[i].periodicity;
          jsonData['Type'] = generatedJson[i].type;
          jsonData['Task'] = generatedJson[i].task;
          jsonData['Duration'] = generatedJson[i].duration.label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      } else {
        this.fileName = 'Tâche_de_maintenances';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Societé'] = generatedJson[i].maintenanceSupplierID.label;
          jsonData['Periodicité'] = generatedJson[i].periodicity;
          jsonData['Type'] = generatedJson[i].type;
          jsonData['Gamme'] = generatedJson[i].task;
          jsonData['Duree'] = generatedJson[i].duration.label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'Prestation Admin') {
      const generatedJson = this.commonServices.getPrestationAdminData();
      if (browserLang === 'en') {
        this.fileName = 'Prestations And Procedures Administration';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Mean'] = generatedJson[i].means.label;
          jsonData['Prestation'] = generatedJson[i].prestations.label;
          jsonData[exportEng.macro] = generatedJson[i].macroActivity ?
            generatedJson[i].macroActivity.label : null;
          jsonData['Procedure'] = generatedJson[i].procedures.label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Arborescence Des Prestations & Procedure';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Moyen'] = generatedJson[i].means.label;
          jsonData['Prestation'] = generatedJson[i].prestations.label;
          jsonData[exportFrench.macro] = generatedJson[i].macroActivity ?
            generatedJson[i].macroActivity.label : null;
          jsonData['Procedure'] = generatedJson[i].procedures.label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'TestBed') {
      this.extCoswinLabel = [];
      const generatedJson = this.commonServices.getTestBedData();
      const generatedCoswinJson = this.commonServices.getCoswinTestbedData();
      const generatedAstralJson = this.commonServices.getAstralTestbedData();
      const generatedGpsJson = this.commonServices.getGpsTestbedData();
      if (browserLang === 'en') {
        this.fileName = 'Testbed';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Site'] = generatedJson[i].testbedGlobalInfo.siteId.label;
          jsonData['Mean'] = generatedJson[i].testbedGlobalInfo.meanId.label;
          jsonData['Bench'] = generatedJson[i].label;
          jsonData['Process'] = generatedJson[i].testbedGlobalInfo.process;
          jsonData['Perimeter'] = this.nullcheck(generatedJson[i].testbedGlobalInfo.perimeterId);
          jsonData['Building'] = this.nullcheck(generatedJson[i].testbedGlobalInfo.buildingId);
          jsonData['Team'] = generatedJson[i].testbedGlobalInfo.teamId.label;
          jsonData['Batch'] = this.nullcheck(generatedJson[i].testbedGlobalInfo.batch);
          jsonData['Function'] = generatedJson[i].testbedGlobalInfo.functioning;
          jsonData['FSS (Friday / Saturday / Sunday)'] = generatedJson[i].testbedGlobalInfo.vsd;
          jsonData['Service'] = generatedJson[i].testbedGlobalInfo.serviceId.label;
          jsonData['Team Number'] = generatedJson[i].testbedGlobalInfo.teamNumber;
          jsonData['Exploitant'] = this.nullcheck(generatedJson[i].testbedGlobalInfo.exploitant);
          jsonData['S3M'] = generatedJson[i].testbedGlobalInfo.s3m;
          jsonData['Designation'] = generatedJson[i].testbedGlobalInfo.designation;
          jsonData['Domain Expertise'] = generatedJson[i].testbedGlobalInfo.expertiseDomain;
          jsonData['Test Type'] = generatedJson[i].testbedGlobalInfo.testType;
          jsonData['Creation Year'] = generatedJson[i].testbedGlobalInfo.year;
          jsonData['Technical Parameter'] = generatedJson[i].testbedGlobalInfo.technicalParameter;
          jsonData['Generator / Brake'] = generatedJson[i].testbedTechnicalInfo.brake;
          jsonData['Technical type'] = generatedJson[i].testbedTechnicalInfo.technicalType;
          jsonData['Power (Kw) / torque (Nm) / speed (rev / min)'] = generatedJson[i].testbedTechnicalInfo.power;
          jsonData['Starter start'] = generatedJson[i].testbedTechnicalInfo.starter;
          jsonData['Exhaust Line Vehicle (not bent)'] = generatedJson[i].testbedTechnicalInfo.exhaust;
          jsonData['Vibratory signature'] = generatedJson[i].testbedTechnicalInfo.vibratorySignature;
          jsonData['Distribution: Flex = Petrol / diesel / Ethanol 100% / Methanol 20%'] =
            generatedJson[i].testbedTechnicalInfo.distribution;
          jsonData['Computer control system'] = generatedJson[i].testbedTechnicalInfo.itPiloting;
          jsonData['Cameo'] = generatedJson[i].testbedTechnicalInfo.cameo;
          jsonData['Option Road Law (Full/Light/N)'] = generatedJson[i].testbedTechnicalInfo.roadLaw;
          jsonData['Options: Adap OnLine / Resp controller / Flex Sweep'] = generatedJson[i].testbedTechnicalInfo.respController;
          jsonData['Option embrayage rig clutch'] = generatedJson[i].testbedTechnicalInfo.clutch;
          jsonData['Free accel'] = generatedJson[i].testbedTechnicalInfo.freeAccel;
          jsonData['Option: Real Time Controller (RTC)'] = generatedJson[i].testbedTechnicalInfo.rtc;
          jsonData['Option CAN'] = generatedJson[i].testbedTechnicalInfo.can;
          jsonData['Cell temperature condition'] = generatedJson[i].testbedTechnicalInfo.cellTemperature;
          jsonData['Maceration rapide de …a … (pistolet azote, souflage)'] = generatedJson[i].testbedTechnicalInfo.maceration;
          jsonData['Combustible air (25 ° / 40%)'] = generatedJson[i].testbedTechnicalInfo.combustiveAir;
          jsonData['Analysis upstream gas 5 gas (cold Nox)'] = generatedJson[i].testbedTechnicalInfo.upstreamCold;
          jsonData['Analysis of upstream gases cata 5 gas + CH4 (cold NOx) + CO2 EGR'] =
            generatedJson[i].testbedTechnicalInfo.upstreamCH4Cold;
          jsonData['Analysis of upstream gases cata 5 gas + CH4 + NO2 (hot NOx) + CO2 EGR'] =
            generatedJson[i].testbedTechnicalInfo.upstreamHot;
          jsonData['Analysis of downstream gas cata 5 gas + CH4 (cold NOx) + CO2 EGR'] =
            generatedJson[i].testbedTechnicalInfo.downstreamCold;
          jsonData['Analysis of downstream gas cata 5 gas + CH4 + NO2 (hot NOx) + CO2 EGR'] =
            generatedJson[i].testbedTechnicalInfo.downstreamHot;
          jsonData['Analysis of the diluted gases (CO + CO2 + NOx + HC)'] = generatedJson[i].testbedTechnicalInfo.diluted;
          jsonData['AVL 415S Smokemeter'] = generatedJson[i].testbedTechnicalInfo.fumimeter;
          jsonData['Opacimeter AVL 439 (particle mass)'] = generatedJson[i].testbedTechnicalInfo.opacimeter;
          jsonData['Tunnel + mass + PN probe (particle number)'] = generatedJson[i].testbedTechnicalInfo.tunnel;
          jsonData['HLMS (measurement 9 gas piping) heated line selector'] = generatedJson[i].testbedTechnicalInfo.hlms;
          jsonData['RS Reel_201X'] = generatedJson[i].testbedPerformance.realRS;
          jsonData['RO Reel_201X'] = generatedJson[i].testbedPerformance.realRO;
          jsonData['RS Cible'] = generatedJson[i].testbedPerformance.targetRS;
          jsonData['RS Eng'] = generatedJson[i].testbedPerformance.commitRS;
          jsonData['RO Cible'] = generatedJson[i].testbedPerformance.targetRO;
          jsonData['RO Eng'] = generatedJson[i].testbedPerformance.commitRO;
          jsonData['Tnc_Eng'] = generatedJson[i].testbedPerformance.commitTnc;
          jsonData['Tnc_cible'] = generatedJson[i].testbedPerformance.targetTnc;
          jsonData['Commit TUM'] = generatedJson[i].testbedPerformance.commitTum;
          jsonData['Target TUM'] = generatedJson[i].testbedPerformance.targetTum;
          jsonData['Time capacity'] = generatedJson[i].testbedPerformance.capability;
          jsonData['Creation Date'] = moment(generatedJson[i].creationDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          if (generatedJson[i].organisation_reg_id[0] === 1) {
            jsonData['Organisation'] = 'DEATM';
          } else if (generatedJson[i].organisation_reg_id[0] === 2) {
            jsonData['Organisation'] = 'DEATV';
          }
          jsonData['Active ?'] = generatedJson[i].active;
          const coswinVal = generatedCoswinJson.filter(res => res.testbedID.id === generatedJson[i].id);

          if (coswinVal.length === 0) {
            jsonData['System'] = '';
            jsonData['External Coswin'] = '';
            jsonData['Family'] = '';
            jsonData['Equipment Code'] = '';
            jsonData['Equipment Label'] = '';
            jsonData['COSWIN Function'] = '';
            jsonData['Area'] = '';
            jsonData['Responsible'] = '';
            jsonData['Typology'] = '';
            jsonData['Operating time'] = '';
            jsonData['Number of days'] = '';
            jsonData['Entity Code'] = '';
          } else {
            if (coswinVal[0].externalCoswin && coswinVal[0].externalCoswin.length > 0) {
              this.extCoswinLabel = this.getExternalCoswinLabel(coswinVal[0].externalCoswin);
            }
            jsonData['System'] = coswinVal[0].system;
            jsonData['External Coswin'] = this.extCoswinLabel;
            jsonData['Family'] = coswinVal[0].family;
            jsonData['Equipment Code'] = coswinVal[0].equipmentCode;
            jsonData['Equipment Label'] = coswinVal[0].equipmentLabel;
            jsonData['COSWIN Function'] = coswinVal[0].function;
            jsonData['Area'] = coswinVal[0].area;
            jsonData['Responsible'] = coswinVal[0].responsible;
            jsonData['Typology'] = coswinVal[0].typology;
            jsonData['Operating time'] = coswinVal[0].operatingTime;
            jsonData['Number of days'] = coswinVal[0].daysNumber;
            jsonData['Entity Code'] = coswinVal[0].entityCode;
          }
          const astralVal = generatedAstralJson.filter(res => res.testBedId === generatedJson[i].id);
          if (astralVal.length === 0) {
            jsonData['Astral Testbed Id'] = '';
            jsonData['Astral Site'] = '';
            jsonData['Label'] = '';
            jsonData['UET'] = '';
            jsonData['Pollu'] = '';
            jsonData['Number of Places'] = '';
            jsonData['Application	'] = '';
          } else {
            jsonData['Astral Testbed Id'] = astralVal[0].astralTestbedId;
            jsonData['Astral Site'] = astralVal[0].site;
            jsonData['Label'] = astralVal[0].label;
            jsonData['UET'] = astralVal[0].uet;
            jsonData['Pollu'] = astralVal[0].pollu;
            jsonData['Number of Places'] = astralVal[0].placesNb;
            jsonData['Application	'] = astralVal[0].application;
          }
          const gpsVal = generatedGpsJson.filter(res => res.testbedId === generatedJson[i].id);
          if (gpsVal.length === 0) {
            jsonData['Testbed type'] = '';
            jsonData['Testbed complement'] = '';
            jsonData['Identifier'] = '';
            jsonData['Is pool'] = '';
            jsonData['Default pool'] = '';
            jsonData['External resource'] = '';
            jsonData['Sector'] = '';
            jsonData['GPS Update date'] = '';
          } else {
            jsonData['Testbed type'] = gpsVal[0].testbedComplementAssociation ?
              gpsVal[0].testbedComplementAssociation.testbedtype.label : '';
            jsonData['Testbed complement'] = gpsVal[0].testbedComplementAssociation ?
              gpsVal[0].testbedComplementAssociation.testbedcomplement.label : '';
            jsonData['Identifier'] = gpsVal[0].identifier;
            jsonData['Is pool'] = gpsVal[0].isPool;
            jsonData['Default pool'] = gpsVal[0].defaultPool;
            jsonData['External resource'] = gpsVal[0].externalResource;
            jsonData['Sector'] = gpsVal[0].sector ? gpsVal[0].sector.label : '';
            jsonData['GPS Update date'] = gpsVal[0].updateDate;
          }
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Essai Banc';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Site'] = generatedJson[i].testbedGlobalInfo.siteId.label;
          jsonData['Moyen'] = generatedJson[i].testbedGlobalInfo.meanId.label;
          jsonData['Banc'] = generatedJson[i].label;
          jsonData['Processus'] = generatedJson[i].testbedGlobalInfo.process;
          jsonData['Perimetre'] = this.nullcheck(generatedJson[i].testbedGlobalInfo.perimeterId);
          jsonData['Batiment'] = generatedJson[i].testbedGlobalInfo.buildingId.label;
          jsonData['UET'] = generatedJson[i].testbedGlobalInfo.teamId.label;
          jsonData['Lot'] = this.nullcheck(generatedJson[i].testbedGlobalInfo.batch);
          jsonData['Fonctionnement'] = generatedJson[i].testbedGlobalInfo.functioning;
          jsonData['VSD (vendredi / samedi / Dimanche)'] = generatedJson[i].testbedGlobalInfo.vsd;
          jsonData['Service'] = generatedJson[i].testbedGlobalInfo.serviceId.label;
          jsonData['Nombre d\'\equipe'] = generatedJson[i].testbedGlobalInfo.teamNumber;
          jsonData['Exploitant'] = this.nullcheck(generatedJson[i].testbedGlobalInfo.exploitant);
          jsonData['S3M'] = generatedJson[i].testbedGlobalInfo.s3m;
          jsonData['Designation'] = generatedJson[i].testbedGlobalInfo.designation;
          jsonData['Domaine d expertise'] = generatedJson[i].testbedGlobalInfo.expertiseDomain;
          jsonData['Type de test'] = generatedJson[i].testbedGlobalInfo.testType;
          jsonData['Annee de creation'] = generatedJson[i].testbedGlobalInfo.year;
          jsonData['Parametre technique'] = generatedJson[i].testbedGlobalInfo.technicalParameter;
          jsonData['Generatrice/Frein'] = generatedJson[i].testbedTechnicalInfo.brake;
          jsonData['Type technique'] = generatedJson[i].testbedTechnicalInfo.technicalType;
          jsonData['Puissance(Kw)/couple(Nm)/regime(Tr/min)'] = generatedJson[i].testbedTechnicalInfo.power;
          jsonData['Demarrage par demarreur'] = generatedJson[i].testbedTechnicalInfo.starter;
          jsonData['Ligne Echappement Vehicule (non coude)'] = generatedJson[i].testbedTechnicalInfo.exhaust;
          jsonData['Signature vibratoire'] = generatedJson[i].testbedTechnicalInfo.vibratorySignature;
          jsonData['Distribution: Flex = Essence / diesel / Ethanol 100% / Methanol 20%'] =
            generatedJson[i].testbedTechnicalInfo.distribution;
          jsonData['systeme de pilotage informatique'] = generatedJson[i].testbedTechnicalInfo.itPiloting;
          jsonData['Cameo'] = generatedJson[i].testbedTechnicalInfo.cameo;
          jsonData['Option Loi de route(Full/Light/N)'] = generatedJson[i].testbedTechnicalInfo.roadLaw;
          jsonData['Options : Adap OnLine / Resp controller / Flex Sweep'] = generatedJson[i].testbedTechnicalInfo.respController;
          jsonData['Option embrayage rig clutch'] = generatedJson[i].testbedTechnicalInfo.clutch;
          jsonData['Free accel'] = generatedJson[i].testbedTechnicalInfo.freeAccel;
          jsonData['Option RTC (Real Time Controlers)'] = generatedJson[i].testbedTechnicalInfo.rtc;
          jsonData['Option CAN'] = generatedJson[i].testbedTechnicalInfo.can;
          jsonData['Condition de temperature cellule'] = generatedJson[i].testbedTechnicalInfo.cellTemperature;
          jsonData['Maceration rapide de …a … (pistolet azote, souflage)'] = generatedJson[i].testbedTechnicalInfo.maceration;
          jsonData['Air comburant(25°/40%)'] = generatedJson[i].testbedTechnicalInfo.combustiveAir;
          jsonData['Analyse des gaz amont cata 5 gaz (Nox froid)'] = generatedJson[i].testbedTechnicalInfo.upstreamCold;
          jsonData['Analyse des gaz amont cata 5 gaz + CH4  (Nox froid)  + CO2 EGR'] =
            generatedJson[i].testbedTechnicalInfo.upstreamCH4Cold;
          jsonData['Analyse des gaz amont cata 5 gaz + CH4 + NO2( NOx chaud) +CO2 EGR'] =
            generatedJson[i].testbedTechnicalInfo.upstreamHot;
          jsonData['Analyse des gaz aval cata 5 gaz + CH4  (Nox froid)  + CO2 EGR'] =
            generatedJson[i].testbedTechnicalInfo.downstreamCold;
          jsonData['Analyse des gaz aval cata 5 gaz + CH4 + NO2( NOx chaud) +CO2 EGR'] =
            generatedJson[i].testbedTechnicalInfo.downstreamHot;
          jsonData['Analyse des gaz dilues (CO+CO2+NOx+HC)'] = generatedJson[i].testbedTechnicalInfo.diluted;
          jsonData['Fumimetre AVL 415S'] = generatedJson[i].testbedTechnicalInfo.fumimeter;
          jsonData['Opacimetre AVL 439(masse particule)'] = generatedJson[i].testbedTechnicalInfo.opacimeter;
          jsonData['Tunnel + masse + sonde PN (particule number)'] = generatedJson[i].testbedTechnicalInfo.tunnel;
          jsonData['HLMS (mesure 9 piquage gaz) selecteur de ligne chauffee'] = generatedJson[i].testbedTechnicalInfo.hlms;
          jsonData['RS Reel_201X'] = generatedJson[i].testbedPerformance.realRS;
          jsonData['RO Reel_201X'] = generatedJson[i].testbedPerformance.realRO;
          jsonData['RS Cible'] = generatedJson[i].testbedPerformance.targetRS;
          jsonData['RS Eng'] = generatedJson[i].testbedPerformance.commitRS;
          jsonData['RO Cible'] = generatedJson[i].testbedPerformance.targetRO;
          jsonData['RO Eng'] = generatedJson[i].testbedPerformance.commitRO;
          jsonData['Tnc_Eng'] = generatedJson[i].testbedPerformance.commitTnc;
          jsonData['Tnc_cible'] = generatedJson[i].testbedPerformance.targetTnc;
          jsonData['Commit TUM'] = generatedJson[i].testbedPerformance.commitTum;
          jsonData['Target TUM'] = generatedJson[i].testbedPerformance.targetTum;
          jsonData['Capabilite horraire'] = generatedJson[i].testbedPerformance.capability;
          jsonData['Date De Creation'] = moment(generatedJson[i].creationDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          if (generatedJson[i].organisation_reg_id[0] === 1) {
            jsonData['Organisation'] = 'DEATM';
          } else if (generatedJson[i].organisation_reg_id[0] === 2) {
            jsonData['Organisation'] = 'DEATV';
          }
          jsonData['Actif ?'] = generatedJson[i].active;
          const coswinVal = generatedCoswinJson.filter(res => res.testbedID.id === generatedJson[i].id);
          if (coswinVal.length === 0) {
            jsonData['Système'] = '';
            jsonData['Coswin Externe'] = '';
            jsonData['Famille'] = '';
            jsonData['Code de l’équipement'] = '';
            jsonData['Libellé de l’équipement'] = '';
            jsonData['COSWIN Fonction'] = '';
            jsonData['Zone'] = '';
            jsonData['Responsible'] = '';
            jsonData['Typologie'] = '';
            jsonData['Temps de fonctionnement'] = '';
            jsonData['Nombre de jours'] = '';
            jsonData['Code d\'\entité'] = '';
          } else {
            if (coswinVal[0].externalCoswin && coswinVal[0].externalCoswin.length > 0) {
              this.extCoswinLabel = this.getExternalCoswinLabel(coswinVal[0].externalCoswin);
            }
            jsonData['Système'] = coswinVal[0].system;
            jsonData['Coswin Externe'] = this.extCoswinLabel;
            jsonData['Famille'] = coswinVal[0].family;
            jsonData['Code de l’équipement'] = coswinVal[0].equipmentCode;
            jsonData['Libellé de l’équipement'] = coswinVal[0].equipmentLabel;
            jsonData['COSWIN Fonction'] = coswinVal[0].function;
            jsonData['Zone'] = coswinVal[0].area;
            jsonData['Responsible'] = coswinVal[0].responsible;
            jsonData['Typologie'] = coswinVal[0].typology;
            jsonData['Temps de fonctionnement'] = coswinVal[0].operatingTime;
            jsonData['Nombre de jours'] = coswinVal[0].daysNumber;
            jsonData['Code d\'\entité'] = coswinVal[0].entityCode;
          }
          const astralVal = generatedAstralJson.filter(res => res.testBedId === generatedJson[i].id);
          if (astralVal.length === 0) {
            jsonData['Astral Banc ID'] = '';
            jsonData['Astral Site'] = '';
            jsonData['Libelle'] = '';
            jsonData['UET'] = '';
            jsonData['Pollu'] = '';
            jsonData['Nombre du places'] = '';
            jsonData['Application	'] = '';
          } else {
            jsonData['Astral Banc ID'] = astralVal[0].astralTestbedId;
            jsonData['Astral Site'] = astralVal[0].site;
            jsonData['Libelle'] = astralVal[0].label;
            jsonData['UET'] = astralVal[0].uet;
            jsonData['Pollu'] = astralVal[0].pollu;
            jsonData['Nombre du places'] = astralVal[0].placesNb;
            jsonData['Application	'] = astralVal[0].application;
          }
          const gpsVal = generatedGpsJson.filter(res => res.testbedId === generatedJson[i].id);
          if (gpsVal.length === 0) {
            jsonData['Type de banc'] = '';
            jsonData['Complement de banc'] = '';
            jsonData['Identifier'] = '';
            jsonData['Is pool'] = '';
            jsonData['Pool par défaut'] = '';
            jsonData['Ressource externe'] = '';
            jsonData['Secteur'] = '';
            jsonData['GPS Date du MAJ'] = '';
          } else {
            jsonData['Type de banc'] = gpsVal[0].testbedComplementAssociation ?
              gpsVal[0].testbedComplementAssociation.testbedtype.label : '';
            jsonData['Complement de banc'] = gpsVal[0].testbedComplementAssociation ?
              gpsVal[0].testbedComplementAssociation.testbedcomplement.label : '';
            jsonData['Identifier'] = gpsVal[0].identifier;
            jsonData['Is pool'] = gpsVal[0].isPool;
            jsonData['Pool par défaut'] = gpsVal[0].defaultPool;
            jsonData['Ressource externe'] = gpsVal[0].externalResource;
            jsonData['Secteur'] = gpsVal[0].sector ? gpsVal[0].sector.label : '';
            jsonData['GPS Date du MAJ'] = gpsVal[0].updateDate;
          }
          resultData[i] = jsonData;
          jsonData = {};
        }

      }

    } else if (this.commonServices.getCurrentTab() === 'StopDuration') {
      const generatedJson = this.commonServices.getStopDurationData();
      if (browserLang === 'en') {
        this.fileName = 'Stop duration';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Stop duration'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Durée d\'\arrêt';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Durée d\'\arrêt'] = generatedJson[i].label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }


    } else if (this.commonServices.getCurrentTab() === 'PrestationCompanies') {
      const generatedJson = this.commonServices.getPrestationCompaniesData();
      if (browserLang === 'en') {
        this.fileName = 'Prestation Companies';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Prestation Companies'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Update By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Compagnies de Prestation';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Compagnies de Prestation'] = generatedJson[i].label;
          jsonData['Date De Creation'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }


    } else if (this.commonServices.getCurrentTab() === 'PreventiveMaintenance') {
      const generatedJson = this.commonServices.getPreventiveMaintenanceData();
      if (browserLang === 'en') {
        this.fileName = 'Preventivemaintenances';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Preventive Maintenance'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }

      } else {
        this.fileName = 'Maintenancepreventive';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Maintenance preventive'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === 'Exploitants') {
      const generatedJson = this.commonServices.getExploitantData();
      if (browserLang === 'en') {
        this.fileName = 'Exploitants';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Exploitants'] = generatedJson[i].label;
          jsonData['Creation Date'] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Update Date'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Last Updated By'] = generatedJson[i].ipn;
          jsonData['Active ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      } else {
        this.fileName = 'Exploitants';
        for (let i = 0; i < generatedJson.length; i++) {
          jsonData['Exploitants'] = generatedJson[i].label;
          jsonData['Date De Creation '] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
          jsonData['Date De Maj'] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
          jsonData['Derniere Maj Par'] = generatedJson[i].ipn;
          jsonData['Actif ?'] = generatedJson[i].active;
          resultData[i] = jsonData;
          jsonData = {};
        }
      }
    } else if (this.commonServices.getCurrentTab() === VhTechnicalParamEnum.vhTechnicalParam) {
      const generatedJson = this.commonServices.VhTechnicalParamList;
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.vhTechnicalParam;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.vhTechnicalParam;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.TestBedReferential.VhTechnicalParam.VhTechnicalParamT] = generatedJson[i].label;
        jsonData[headerLabels.Administration.TestBedReferential.VhTechnicalParam.CreationDate] = moment(generatedJson[i].createDate).format('DD/MM/YYYY');
        jsonData[headerLabels.Administration.TestBedReferential.VhTechnicalParam.UpdateDate] = moment(generatedJson[i].updateDate).format('DD/MM/YYYY');
        jsonData[headerLabels.Administration.TestBedReferential.VhTechnicalParam.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.TestBedReferential.VhTechnicalParam.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.Profiles) {
      const generatedJson = this.commonServices.getProfileData();
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.Profiles;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.Profiles;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Help.Profile.Profile] = generatedJson[i].profile;
        jsonData[headerLabels.Help.Profile.Type] = generatedJson[i].type;
        jsonData[headerLabels.Help.Profile.AssociatedActivities] = generatedJson[i].level0;
        jsonData[headerLabels.Help.Profile.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Help.Profile.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Help.Profile.LastUpdateBy] = generatedJson[i].updateBy;
        jsonData[headerLabels.Help.Profile.Active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.stoppageType) {
      const generatedJson = this.commonServices.stoppageData;
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.stoppageTitle;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.stoppageTitle;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.LevelsReferential.StoppageType.stoppage_Type] = generatedJson[i].typeOfStoppage;
        jsonData[headerLabels.Administration.LevelsReferential.StoppageType.failure] = generatedJson[i].label;
        jsonData[headerLabels.Administration.LevelsReferential.StoppageType.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.LevelsReferential.StoppageType.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.LevelsReferential.StoppageType.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Common_Buttons.Active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.fuel) {
      const generatedJson = this.commonServices.fuel;
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.fuel;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.fuel;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.Referential.Fuel.Title] = generatedJson[i].label;
        jsonData[headerLabels.Administration.Referential.Fuel.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.Referential.Fuel.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.Referential.Fuel.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.Referential.Fuel.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.extCoswinTab) {
      const generatedJson = this.commonServices.externalCoswinData;
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.externalCoswin;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.externalCoswin;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.TestBedReferential.ExternalCoswin.Title] = generatedJson[i].label;
        jsonData[headerLabels.Administration.TestBedReferential.ExternalCoswin.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.ExternalCoswin.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.ExternalCoswin.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.TestBedReferential.ExternalCoswin.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.testTypeTab) {
      const generatedJson = this.commonServices.testTypeData;
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.testType;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.testType;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.TestBedReferential.TestType.Title] = generatedJson[i].label;
        jsonData[headerLabels.Administration.TestBedReferential.TestType.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.TestType.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.TestType.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.TestBedReferential.TestType.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.osVersionTab) {
      const generatedJson = this.commonServices.osVersion;
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.osVersion;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.osVersion;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.TestBedReferential.OsVersion.Title] = generatedJson[i].label;
        jsonData[headerLabels.Administration.TestBedReferential.OsVersion.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.OsVersion.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.OsVersion.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.TestBedReferential.OsVersion.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.expDomainTab) {
      const generatedJson = this.commonServices.expertiseDomainData;
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.expDomain;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.expDomain;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.TestBedReferential.ExpertiseDomain.Title] = generatedJson[i].label;
        jsonData[headerLabels.Administration.TestBedReferential.ExpertiseDomain.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.ExpertiseDomain.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.ExpertiseDomain.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.TestBedReferential.ExpertiseDomain.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    } else if (this.commonServices.getCurrentTab() === exportEng.interSystemTab) {
      const generatedJson = this.commonServices.interSystemUseList;
      let headerLabels;
      if (browserLang === exportEng.english) {
        headerLabels = enlangData;
        this.fileName = exportEng.interSystem;
      } else {
        headerLabels = frlangData;
        this.fileName = exportFrench.interSystem;
      }
      headerLabels = headerLabels.default;
      for (let i = 0; i < generatedJson.length; i++) {
        jsonData[headerLabels.Administration.TestBedReferential.Intersystem.Title] = generatedJson[i].label;
        jsonData[headerLabels.Administration.TestBedReferential.Intersystem.CreationDate] = moment(generatedJson[i].createDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.Intersystem.UpdateDate] = moment(generatedJson[i].updateDate).format(exportConstant.dateFormat);
        jsonData[headerLabels.Administration.TestBedReferential.Intersystem.LastUpdateBy] = generatedJson[i].ipn;
        jsonData[headerLabels.Administration.TestBedReferential.Intersystem.active] = generatedJson[i].active;
        resultData[i] = jsonData;
        jsonData = {};
      }
    }


    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(resultData)));

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName + this.commonServices.getFormattedTime() + '.xlsx');
  }


  nullcheck(id: any) {
    if (id === null) {
      return null;
    } else {
      return id.label;
    }
  }
  getExternalCoswinList() {
    this.externalCoswinService.getAllExternalCoswin().subscribe((result: ExternalCoswin[]) => {
      this.externalCoswinData = result;
    });
  }
  getExternalCoswinLabel(extCoswinData: number[]) {
    let tempArray = [];
    extCoswinData.forEach(res => {
      tempArray.push(this.externalCoswinData.filter(filterData => filterData.id === res)[0].label);
    })
    return tempArray.toString();
  }
}
