import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Help, Directory } from './help.model';
import { Observable } from 'rxjs';
import { GridOptions } from 'ag-grid-community';
import { Profiles } from './profiles/profiles.model';
@Injectable({
  providedIn: 'root'
})
export class HelpService {

  public gridOptions: GridOptions;
  public apiURL: string = environment.apiURL;
  directory: Directory;
  directories: Directory[];
  profiles: Profiles[];
  level0Id: any;
  level0Name: any;
  constructor(private httpClient: HttpClient) { }

  putMultiPartUpload(formdata: FormData) {
    /* const formdata: FormData = new FormData();
    formdata.append('file', fileToUpload);
    const headers: any = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    , headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')*/

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.httpClient.post(this.apiURL + '/fileUpload', formdata, {
      observe: 'response', headers: headers
    });
  }

  getFilesList() {
    return this.httpClient.get(this.apiURL + '/getFilesList').pipe(map(res => res));
  }

  getHelpList(help: Help) {
    return this.httpClient.get(this.apiURL + '/getHelpList').pipe(map(res => res));
    // return this.httpClient.post<Help[]>(this.apiURL + '/getHelpList', help).pipe(map(res => res));
  }

  getLanguageList() {
    return this.httpClient.get(this.apiURL + '/getLanguageList').pipe(map(res => res));
  }

  saveHelp(help: Help) {
    return this.httpClient.post<Help>(this.apiURL + '/saveHelp', help, {
      observe: 'response'
    });
  }

  getDuplicateHelpCheck(help: Help) {
    return this.httpClient.post<Help>(this.apiURL + '/getDuplicateHelpCheck', help, {
      observe: 'response'
    });
  }
  getDirectoryList(directory: Directory) {
    return this.httpClient.post(this.apiURL + '/getDirectoryList', directory).pipe(map(res => res));
  }

  deleteDirectoryData(id: number): Observable<void> {
    console.log('delete', id);
    return this.httpClient.delete<void>(this.apiURL + '/deleteDirectoryData/' + id);
  }

  setDirectories(directories: Directory[]) {
    this.directories = directories;
  }

  getDirectories() {
    return this.directories;
  }

  setProfiles(profiles: Profiles[]) {
    this.profiles = profiles;
  }

  getProfiles() {
    return this.profiles;
  }

  setDirectory(gridDirectory: Directory) {
    this.directory = gridDirectory;

  }
  getDirectory() {
    return this.directory;

  }
  getUpdateDirectory() {
    return this.directory;
  }
  refreshGrid(directory: Directory) {
    this.getDirectoryList(directory).subscribe((result: Directory[]) => {
      this.gridOptions.api.setRowData(result);
    });
  }
  updateDirectoryList(directory: Directory) {
    return this.httpClient.put<Directory>(this.apiURL + '/updateDirectoryList', directory, {
      observe: 'response'
    });
  }

  getDirectoryDetails(zid: String) {
    console.log('here');
    return this.httpClient.post(this.apiURL + '/getDirectoryData', zid).pipe(map(res => res));
  }

  getDirectoryDetailsByFirstName(fname: String) {
    console.log('here getDirectoryDetailsByFirstName '+fname);
    return this.httpClient.post(this.apiURL + '/getDirectoryDataByFname', fname).pipe(map(res => res));
  }

  getDirectoryDetailsByLastName(lname: String) {
    console.log('here getDirectoryDetailsByLastName '+lname);
    return this.httpClient.post(this.apiURL + '/getDirectoryDataByLname', lname).pipe(map(res => res));
  }
  
  saveDirectoryList(directory: Directory) {
    return this.httpClient.post<Directory>(this.apiURL + '/saveDirectoryList', directory, {
      observe: 'response'
    });
  }

  getRolesData(zid: any) {
    const directory = new Directory();
    directory.ipn = zid;
    return this.httpClient.post(this.apiURL + '/getRolesData', directory).pipe(map(res => res));
  }

  getDirectoryComment(zid: any) {
    const directory = new Directory();
    directory.ipn = zid;
    return this.httpClient.post(this.apiURL + '/getDirectoryComment', directory).pipe(map(res => res));
  }

  setLevel0Id(value: any) {
    this.level0Id = value;
  }
  getLevel0Id() {
    return this.level0Id;
  }
  setSelectedLevel0(value: any) {
    this.level0Name = value;
  }
  getSelectedLevel0() {
    return this.level0Name;
  }
  downloadHelpFile(fileName) {
    return this.httpClient.get<Blob>(this.apiURL + '/getObject?objectKey=' + fileName,
      { responseType: 'blob' as 'json' })
      .subscribe((blob: Blob) => {
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            const blobUrl = URL.createObjectURL(blob);
            link.setAttribute('href', blobUrl);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
  }

  previewFile(fileName) {
    return this.httpClient.get<Blob>(this.apiURL + '/getObject?objectKey=' + fileName,
      { responseType: 'blob' as 'json' });
  }

  deleteFile(fileName: any): Observable<any> {
    return this.httpClient.delete<any>(this.apiURL + '/removeObject?objectKey=' + fileName);
  }
}
