import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SnackbarComponent } from './snackbar.component';
import { SnackBarModel } from './snackbar.model';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {

    constructor(
        private snackBar: MatSnackBar) {
        this.snackBarClass = '';
        this.showSuccess = false;
        this.showWarning = false;
        this.showFailed = false;
    }

    public snackBarClass: string;
    public message: string;

    public showSuccess: boolean;
    public showWarning: boolean;
    public showFailed: boolean;

    showOldSnackBar(msg: string, action: string) {

        if (action === 'S') {
            this.showSuccess = true;
        } else if (action === 'W') {
            this.showWarning = true;
        } else if (action === 'F') {
            this.showFailed = true;
        }

        this.snackBarClass = 'show';
        this.message = msg;
        setTimeout(() => {
            this.snackBarClass = '';

            if (action === 'S') {
                this.showSuccess = false;
            } else if (action === 'W') {
                this.showWarning = false;
            } else if (action === 'F') {
                this.showFailed = false;
            }
        }, 2000);
    }


    showSnackBar(msg: string, action: string) {
        this.message = msg;
        const snackBarModel = new SnackBarModel();
        snackBarModel.message = msg;
        snackBarModel.showStatus = action;

        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            data: snackBarModel
          });
    }
}
