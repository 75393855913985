import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';
import { HomeStaticValues } from '../../home/homeStaticFields';

@Component({
  selector: 'spot-fire-performance',
  templateUrl: './spot-fire-performance.component.html',
  styleUrls: ['./spot-fire-performance.component.css']
})
export class SpotFirePerformanceComponent implements OnInit {

  envName: string;

  constructor(public dialogRef: MatDialogRef<SpotFirePerformanceComponent>, public dialog: MatDialog) { }

  ngOnInit() {
    this.envName = sessionStorage.getItem(HomeStaticValues.envName);
  }

  onClose(event?: any): void {
    this.dialogRef.close(event);
  }

  mapEnergetique() {
    if (this.envName === HomeStaticValues.RE7 || this.envName === HomeStaticValues.OPE) {
      window.open(environment.mapEnergeticOpeUrl);
    } else {
      window.open(environment.mapEnergeticQuaUrl);
    }

  }

  mapMecanique() {
    if (this.envName === HomeStaticValues.RE7 || this.envName === HomeStaticValues.OPE) {
      window.open(environment.mapMecaniqueOpeUrl);
    } else {
      window.open(environment.mapMecaniqueQuaUrl);
    }
  }

  mapElectrique() {
    if (this.envName === HomeStaticValues.RE7 || this.envName === HomeStaticValues.OPE) {
      window.open(environment.mapElectriqueOpeUrl);
    } else {
      window.open(environment.mapElectriqueQuaUrl);
    }
  }

  nonRsGlobal() {
    /* if (this.envName === HomeStaticValues.RE7 || this.envName === HomeStaticValues.OPE) {
      window.open(environment.nonRsGlobalOpeUrl);
    } else {
      window.open(environment.nonRsGlobalQuaUrl);
    } */
    window.open(environment.nonRsGlobalOpeUrl);
  }

  top3NonRs() {
    /* if (this.envName === HomeStaticValues.RE7 || this.envName === HomeStaticValues.OPE) {
      window.open(environment.top3NonRsOpeUrl);
    } else {
      window.open(environment.top3NonRsQuaUrl);
    } */
    window.open(environment.top3NonRsOpeUrl);
  }

  panneSupportClient() {
    /* if (this.envName === HomeStaticValues.RE7 || this.envName === HomeStaticValues.OPE) {
      window.open(environment.panneSupportClientOpeUrl);
    } else {
      window.open(environment.panneSupportClientQuaUrl);
    } */
  }
}
