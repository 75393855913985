export class TestbedPerformance {
    
    public id: number;
    public realRS: string;
    public targetRS: string;
    public commitRS: string;
    public realRO: string;
    public targetRO: string;
    public commitRO: string;
    public targetTnc: string;
    public commitTnc: string;
    public targetTum: string;
    public commitTum: string;
    public capability: string;
}