import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'spot-fire-projet',
  templateUrl: './spot-fire-projet.component.html',
  styleUrls: ['./spot-fire-projet.component.css']
})
export class SpotFireProjetComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SpotFireProjetComponent>, public dialog: MatDialog) { }

  ngOnInit() {
  }
  onClose(event?: any): void {
    this.dialogRef.close(event);
  }
  avanProjet() {
    window.open(environment.projectAvancementOpeUrl);
  }
  pilotBudgetaire() {
    window.open(environment.projectPilotageBudgetaireOpeUrl);
  }

}
