import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Roles } from './roles.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    private baseString: string;

    constructor(private http: HttpClient) {
        this.baseString = environment.apiURL;
    }

    isOnlyRolesExists(roles: Roles) {
        return this.http.post<Roles>(this.baseString + '/isOnlyRolesExists/', roles);
    }
}
