import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OpenIdService } from '../open-id.service';
import { MessageService } from 'src/app/common/message.service';

@Component({
  selector: 'callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(private router: Router, private openIdService: OpenIdService, private messageService: MessageService) {}

  ngOnInit() {
    this.openIdService.getUserinfo();
  }
}
