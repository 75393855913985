import { Site } from '../sites/site.model';

export class Building {
    id: number;
    label: string;
    siteId: Site;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: boolean;
    organisationId: number[];
}
