import { CrrppInit } from './crrppInit.model';
import { Milestone } from 'src/app/administration/referentiel/milestone/milestone.model';

export class TbfConsumption{
    id: number;
    crrppId: CrrppInit;
    weekNumber: number;
    startDate: Date;
    milestoneId: Milestone;
    sumValue: number;
    differenceValue: number;
    organisationId: number[];
    createDate: Date;
    updateDate: Date;
    ipn: string;
}
