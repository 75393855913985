import { OtType } from './otType.model';

export class OT {
    id: number;
    label: number;
    status: string;
    ot_type: OtType;
    updateDate: Date;
    organisationId: number[];
    parentCode: number;
    ipn: string;
    createDate: Date;
    system: any;
    equipmentName: any;
}
