import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Site } from './site.model';
import { GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class SitesService {

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public site: Site;
  public btnName: string;


  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getAllSites(site: Site) {
    return this.httpClient.post(this.apiURL + '/getSiteList', site).pipe(map(res => res));
  }

  getActiveSiteByOrganisation(site: Site) {
    return this.httpClient.post<Site[]>(this.apiURL + '/getActiveSiteByOrganisation', site);
  }

  updateOrganisationList(site: Site) {
    return this.httpClient.put<Site>(this.apiURL + '/updateOrganisationList', site, {
      observe: 'response'
    });
  }

  createSiteList(site: Site) {
    return this.httpClient.post<Site>(this.apiURL + '/createSiteList', site, {
      observe: 'response'
    });
  }

  updateSiteList(site: Site) {
    return this.httpClient.put<Site>(this.apiURL + '/updateSiteList', site, {
      observe: 'response'
    });
  }

  getActiveSiteList(site: Site) {
    return this.httpClient.post<Site>(this.apiURL + '/getActiveSiteByOrganisation', site);
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  setSite(gridSite: Site) {
    this.site = gridSite;

    if (this.site != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateSite() {
    return this.site;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  getSiteComboList(){
    const orgId = JSON.parse(sessionStorage.getItem('organisationIDs'));
    return this.httpClient.get<Site[]>(this.apiURL + '/getSiteComboList/' + orgId).pipe(map(res => res));
  }
}
