import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';
import { MapElectriqueComponent } from './map-electrique/map-electrique.component';
import { MapEnergetiqueComponent } from './map-energetique/map-energetique.component';
import { MapMecaniqueComponent } from './map-mecanique/map-mecanique.component';

@Component({
  selector: 'spot-fire-exploitation',
  templateUrl: './spot-fire-exploitation.component.html',
  styleUrls: ['./spot-fire-exploitation.component.css']
})
export class SpotFireExploitationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SpotFireExploitationComponent>, public dialog: MatDialog) { }

  ngOnInit() {
  }
  onClose(event?: any): void {
    this.dialogRef.close(event);
  }

  mapEnergetique() {
    this.dialogRef.updateSize('2000px', '200px');
    if (this.dialog.openDialogs.length === 2) {
      const dialogRef = this.dialog.open(MapEnergetiqueComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        } else {
          this.dialogRef.updateSize('2000px', 'auto');
        }
      });
    }
  }

  mapMecanique() {
    // window.open(environment.exploitationMecaniqueOpeUrl);
    //Mecanique screen
    this.dialogRef.updateSize('2000px', '200px');
    if (this.dialog.openDialogs.length === 2) {
      const dialogRef = this.dialog.open(MapMecaniqueComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        } else {
          this.dialogRef.updateSize('2000px', 'auto');
        }
      });
    }
  }

  mapElectrique() {
    // window.open(environment.exploitationElectriqueOpeUrl);
    this.dialogRef.updateSize('2000px', '200px');
    if (this.dialog.openDialogs.length === 2) {
      const dialogRef = this.dialog.open(MapElectriqueComponent, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        } else {
          this.dialogRef.updateSize('2000px', 'auto');
        }
      });
    }
  }
}
