export class BaseFile<T> {
    value: T;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    flag: boolean;
    mandatory: boolean;
    maxLength: number;
    pattern: string;
    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        required?: boolean,
        order?: number,
        controlType?: string,
        flag?: boolean,
        mandatory?: boolean,
        maxLength?: number,
        pattern?: string,
      } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label || '';
      this.required = !!options.required;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.flag = options.flag === undefined ? false : options.flag;
      this.mandatory = options.mandatory === undefined ? false : options.mandatory;
      this.maxLength = options.maxLength === undefined ? null : options.maxLength;
      this.pattern = options.pattern === undefined ? null : options.pattern;
    }
  }
