import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

@Injectable({
    providedIn: 'root'
})
export class I18nLangService {
    private subject = new BehaviorSubject<any>('');
    language;
    constructor(private translate: TranslateService) {
        translate.setDefaultLang('en');
        this.setLanguage('en');
    }
    getLanguage() {
        return this.language;
    }
    setLanguage(lang) {
        this.language = lang;
        sessionStorage.setItem('language', lang);
    }
    instantChange(key) {
        return this.translate.instant(key);
    }
    changeLanguage(language) {
        this.setLanguage(language);
        this.translate.use(language);
    }
    sendMessage(message: string) {
        this.subject.next(message);
    }

    clearMessages() {
        this.subject.next('');
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
