export class MultiSelectModel {
    maps: number[];
    mean: number[];
    sites: number[];
    orgId: number[];
    building:number[];
}
export enum MultiSelectEnum {
    orgId = 'organisationIDs'
}
