import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TestbedGlobalInfo } from './global-info/testbed-global-info.model';
import { Testbed } from './testbed-model';
import { TestbedTechnicalInfo } from './technical-info/testbed-technical-info.model';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { map, startWith } from 'rxjs/operators';
import { Perimeters } from '../../referentiel/perimeters/perimeters.model';
import { Batch } from '../../referentiel/batch/batch.model';
import { TestbedPerformance } from './performance/testbed-performance.model';
import { Building } from '../../referentiel/buildings/building.model';
import { Site } from '../../referentiel/sites/site.model';
import { Means } from '../../referentiel/means/means.model';
import { Teams } from '../../referentiel/teams/teams.model';
import { Services } from '../../referentiel/services/services.model';
import { Exploitants } from '../exploitants/exploitants.model';
import { CoswinCreateUpdate } from './coswinTestbed/createUpdate.model';
import { CoswinTestbed } from './coswinTestbed/coswinTestbed.model';
import { CommonTestBed } from './commonTestbedModel.model';
import { AstralTestbed } from './astralTestbed.model';
import { GpsTestbed } from './gpsTestbed.model';

@Injectable({
    providedIn: 'root'
})
export class TestbedService {

    private api: string;
    public btnName: string;
    public testbed: Testbed;

    public testBedComboOne: Testbed;
    public testBedComboTwo: Testbed;
    public testBedComboThree: Testbed;
    public testBedComboFour: Testbed;
    public testBedComboFive: Testbed;

    public testbedTabOne: Testbed;
    public testbedTabTwo: Testbed;
    public testbedTabThree: Testbed;
    public testbedTabFour: Testbed;
    public testbedTabFive: Testbed;
    public coswinTestbed: CoswinTestbed;
    public coswinTabOne: CoswinTestbed;
    public coswinTabTwo: CoswinTestbed;
    public coswinTabThree: CoswinTestbed;
    public coswinTabFour: CoswinTestbed;
    public coswinTabFive: CoswinTestbed;
    public astralTestbed: AstralTestbed;
    public astralTabOne: AstralTestbed;
    public astralTabTwo: AstralTestbed;
    public astralTabThree: AstralTestbed;
    public astralTabFour: AstralTestbed;
    public astralTabFive: AstralTestbed;
    public gpsTestbed: GpsTestbed;
    public gpsTabOne: GpsTestbed;
    public gpsTabTwo: GpsTestbed;
    public gpsTabThree: GpsTestbed;
    public gpsTabFour: GpsTestbed;
    public gpsTabFive: GpsTestbed;

    public perimeterId: number;
    public siteComboList: Testbed;
    public meansComboList: Testbed;
    public meansComboList2: Testbed;
    public meansComboList3: Testbed;
    public meansComboList4: Testbed;
    public meansComboList5: Testbed;
    public meanData: Testbed;
    public exploitantId: number;

    public refreshingUpdatedData: string;

    public enableDuplicateBtn: boolean;
    public dynamicBancName: string;

    filteredSiteOptions: Observable<any>;

    constructor(private http: HttpClient) {
        this.api = environment.apiURL;
        this.btnName = 'Common_Buttons.Add_Record';
        this.testbed = this.testbedTabOne = this.testbedTabTwo = this.testbedTabThree =
            this.testbedTabFour = this.testbedTabFive = this.init();
        this.coswinTestbed = this.coswinTabOne = this.coswinTabTwo = this.coswinTabThree =
            this.coswinTabFour = this.coswinTabFive = this.initCoswin();
        this.astralTestbed = this.astralTabOne = this.astralTabTwo = this.astralTabThree =
            this.astralTabFour = this.astralTabFive = this.initAstralTestbed();
        this.gpsTestbed = this.gpsTabOne = this.gpsTabTwo = this.gpsTabThree =
            this.gpsTabFour = this.gpsTabFive = this.initGpsTestbed();

    }

    getSiteComboListService(testbed: Testbed) {
        // return this.http.get<TestbedGlobalInfo[]>(this.api + '/getSiteComboList');
        return this.http.post<Testbed>(this.api + '/getSiteComboListNew', testbed);
    }

    getMeansComboListService(testbed: Testbed) {
        return this.http.post<Testbed>(this.api + '/getMeansComboListNew', testbed);
        // return this.http.get<TestbedGlobalInfo[]>(this.api + '/getMeanComboList');
    }

    getTestbedComboList(testbed: Testbed) {
        return this.http.post<Testbed>(this.api + '/getTestbedComboList/', testbed, {
            observe: 'response'
        });
    }

    getTestbedDtl(testbedId: number): Observable<CommonTestBed> {
        return this.http.get<CommonTestBed>(this.api + '/getTestbedDetail/' + testbedId);
    }

    // get coswin testbed data
    getCoswinTestbedData(testbedId: number) {
        return this.http.get<CoswinTestbed>(this.api + '/getCoswinTestbedData' + testbedId);
    }

    /* saveTestbed(testbed: Testbed, coswinTestbed: CoswinTestbed) {
        return this.http.post<any>(this.api + '/saveTestbed/?testbed=' + testbed + '&coswinTestbed=' + coswinTestbed, {
            observe: 'response'
        });
    } */
    saveTestbed(commonTestbedModel: CommonTestBed) {
        console.log('commonTestbedModel', commonTestbedModel);
        return this.http.post<any>(this.api + '/saveTestbed', commonTestbedModel, {
            observe: 'response'
        });
    }
    updateTestbed(commonTestbedModel: CommonTestBed) {
        return this.http.put<any>(this.api + '/updateTestbed', commonTestbedModel, {
            observe: 'response'
        }).catch(this.errorHandler);
    }

    getTestbed() {
        return this.testbed;
    }

    setTestbed(testbed: Testbed) {
        if (testbed.testbedGlobalInfo.perimeterId == null || testbed.testbedGlobalInfo.perimeterId === undefined) {
            testbed.testbedGlobalInfo.perimeterId = new Perimeters();
        }
        if (testbed.testbedGlobalInfo.batch == null || testbed.testbedGlobalInfo.batch === undefined) {
            testbed.testbedGlobalInfo.batch = new Batch();
        }
        if (testbed.testbedGlobalInfo.exploitant == null || testbed.testbedGlobalInfo.exploitant === undefined) {
            testbed.testbedGlobalInfo.exploitant = new Exploitants();
        }
        this.testbed = testbed;
    }

    init(): Testbed {
        const testbed = new Testbed();
        testbed.testbedGlobalInfo = new TestbedGlobalInfo();
        testbed.testbedTechnicalInfo = new TestbedTechnicalInfo();
        testbed.testbedPerformance = new TestbedPerformance();
        testbed.testbedGlobalInfo.batch = new Batch();
        testbed.testbedGlobalInfo.buildingId = new Building();
        testbed.testbedGlobalInfo.siteId = new Site();
        testbed.testbedGlobalInfo.meanId = new Means();
        testbed.testbedGlobalInfo.perimeterId = new Perimeters();
        testbed.testbedGlobalInfo.serviceId = new Services();
        testbed.testbedGlobalInfo.teamId = new Teams();
        testbed.testbedGlobalInfo.exploitant = new Exploitants();
        return testbed;
    }

    initCoswin(): CoswinTestbed {
        /* const testbed = new Testbed();
        testbed.testbedGlobalInfo = new TestbedGlobalInfo();
        testbed.testbedTechnicalInfo = new TestbedTechnicalInfo();
        testbed.testbedPerformance = new TestbedPerformance();
        testbed.testbedGlobalInfo.batch = new Batch();
        testbed.testbedGlobalInfo.buildingId = new Building();
        testbed.testbedGlobalInfo.siteId = new Site();
        testbed.testbedGlobalInfo.meanId = new Means();
        testbed.testbedGlobalInfo.perimeterId = new Perimeters();
        testbed.testbedGlobalInfo.serviceId = new Services();
        testbed.testbedGlobalInfo.teamId = new Teams();
        testbed.testbedGlobalInfo.exploitant = new Exploitants(); */
        return this.coswinTestbed;
    }

    initAstralTestbed(): AstralTestbed {
        return this.astralTestbed;
    }
    initGpsTestbed(): GpsTestbed {
        return this.gpsTestbed;
    }

    getTestBedComboList() {
        const testbed = new Testbed();
        testbed.organisation_reg_id = JSON.parse(sessionStorage.getItem('organisationIDs'));
        testbed.testbedGlobalInfo = new TestbedGlobalInfo();
        testbed.testbedGlobalInfo.buildingId = new Building();
        testbed.testbedGlobalInfo.meanId = new Means();
        this.getTestbedComboList(testbed).subscribe((response) => {
            this.testBedComboOne = this.testBedComboTwo = this.testBedComboThree =
                this.testBedComboFour = this.testBedComboFive = response.body;
        });
    }

    errorHandler(error: HttpErrorResponse) {
        return Observable.throw('Update Failed !');
    }

    getSiteComboList(testbed: Testbed) {
        this.getSiteComboListService(testbed).subscribe(data => {
            this.siteComboList = data;
            /*  this.filteredSiteOptions = this.testBedForm.controls.selectedTestBed.valueChanges
                 .pipe(
                     startWith(''),
                     map(value => this._filter(value))
                 ); */
        });
    }

    /*     private _filter(value: string): Object[] {
            const filterValue = value ? value.toString().toLowerCase() : '';
            const retunch = this.siteComboList.filter(option =>
                option[1].toString().toLowerCase().includes(filterValue)
            );
            return retunch;
        } */

    getMeansComboList(testbed: Testbed) {
        this.getMeansComboListService(testbed).subscribe(data => {
            this.meansComboList = this.meansComboList2 = this.meansComboList3 =
                this.meansComboList4 = this.meansComboList5 = data;
            this.meanData = data; // US484-for mean drop-down data
        });
    }
    getTestBedList() {
        return this.http.get(this.api + '/getTestBedList').pipe(map(res => res));
    }
    getAllCoswinData() {
        return this.http.get(this.api + '/allCoswinData').pipe(map(res => res));
    }

    updateTestbedOrganisationList(testbed: Testbed) {
        return this.http.put<Testbed>(this.api + '/updateTestbedOrganisationList', testbed, {
            observe: 'response'
        });
    }

    sendCoswinData(coswinCreate: CoswinCreateUpdate) {
        return this.http.post(this.api + '/createUpdateCoswin', coswinCreate, {
            observe: 'response'
        });
    }

    getAstralTestbedList() {
        return this.http.get(this.api + '/astralTestbedList').pipe(map(res => res));
    }
    getGpsTestbedList() {
        return this.http.get(this.api + '/gpsTestbedList').pipe(map(res => res));
    }

    // This method is used to handle Http calls to fetch the Testbed Id on clicking create button
    saveForFetchingTestbed(): Observable<Testbed> {
        return this.http.get<Testbed>(this.api + '/saveTestbedForGettingId').pipe(map(res => res));
    }
    /*  This method is used to handle http delete response to delete the record inserted for fetching ID
        It gets called only when the dialog box is closed without saving the record
        it is applicable only for create/Duplicate operations */
    deleteEmptyTestbedCreatedforVisualise(testbedId: number): Observable<void> {
        return this.http.delete<void>(this.api + '/deleteEmptyTestbedRecord/' + testbedId);
    }

    deleteEmptyTestbedOnLoad(): Observable<void> {
        return this.http.delete<void>(this.api + '/deleteEmptyTestbedOnLoad/');
    }

    gpsMailSent(testbedId: any, createOrUpdate: String) {
        return this.http.post<any>(this.api + '/gpsMailSent/?testbedId=' + testbedId + '&value=' + createOrUpdate, {
            observe: 'response'
        });
    }

}
