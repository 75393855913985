import { Injectable } from '@angular/core';
import { DistributionList } from "./distribution-list.model";
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Testbed } from 'src/app/administration/testbedreferential/testbed/testbed-model';
import { Directory } from 'src/app/common/help/help.model';

@Injectable({
    providedIn: 'root'
})

export class DistributionListService {

    private baseString: string;
    public apiURL: string = environment.apiURL;
    public distributionListFlag: boolean = false;
    public directoryData: Directory[];
    constructor(private httpClient: HttpClient) {
    }

    getDistributionList(distributionList: DistributionList) {
        return this.httpClient.post(this.apiURL + '/getDirectoryTestBedList', distributionList).pipe(map(res => res));
    }

    saveDistributionList(distributionList: DistributionList[], testbed: any) {
        return this.httpClient.post<DistributionList[]>(this.apiURL + '/saveDirectoryTestbedList', distributionList, {
            observe: 'response'
        });
    }

    getKeyValue(obj: any[], value: string) {
        let resultStr: String[];
        resultStr = value.split(' - ');
        for (let loop = 0; loop < obj.length; loop++) {
            if ((obj[loop].directoryIpn).toUpperCase().trim() === resultStr[0].toUpperCase().trim()) {
                return obj[loop];
            }
        }
    }

    getTestBedObj(obj: Testbed[], key: any) {
        for (let i = 0; i < obj.length; i++) {
            // tslint:disable-next-line: triple-equals
            if (obj[i].id == key) {
                return obj[i];
            }
        }
    }

    deleteAll(testbed: any) {
        return this.httpClient.delete<boolean>(this.apiURL + '/deleteDirectoryTestbedList/' + testbed);
    }
}