import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OpenIdService } from '../open-id.service';

@Component({
  selector: 'openid',
  templateUrl: './openid.component.html',
  styleUrls: ['./openid.component.css']
})
export class OpenidComponent implements OnInit {
  constructor(private router: Router, private openIdService: OpenIdService) {}

  ngOnInit() {
    this.openIdService.initOpenIdAuthent();
  }

}
