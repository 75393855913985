export class CustomListbox {

}

export enum Constants {
    directoryIpn = 'directoryIpn',
    fname = 'fname',
    lname = 'lname',
    dailyMailFlag = 'dailyMailFlag'
}
