import { SpecimenType } from '../administration/referentiel/specimen-type/specimen-type.model';

export class ValidationSpecimen {
    id: number;
    label: string;
    specimenType: SpecimenType;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: boolean;
    organisationId: number[];
}
