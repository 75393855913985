import { NgModule } from '@angular/core';
import {
  MatDialogModule, MatCheckboxModule, MatGridListModule, MatDatepickerModule, MatChipsModule,
  MatNativeDateModule,
  MatSnackBarModule, MatSidenavModule,
  MatOptionModule,
  MatSelectModule,
  MatTooltipModule,
  MatAutocompleteModule, MatTabsModule,
  MatCardModule,
  DateAdapter,
  MatSlideToggleModule,
  MatExpansionModule,
  MatTableModule,
  MatSortModule, MatProgressBarModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS
} from '@angular/material';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import {
  MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter, MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CustomDateAdapter } from './custom-date-adapter';

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

const modules = [
  MatIconModule,
  MatDialogModule,
  MatCheckboxModule,
  MatGridListModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  MatOptionModule,
  MatSelectModule,
  MatTooltipModule,
  MatChipsModule,
  MatSidenavModule,
  MatAutocompleteModule,
  ScrollingModule,
  MatCardModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatTableModule,
  MatSortModule,
  MatTabsModule,
  MatProgressBarModule
];
@NgModule({
  declarations: [],
  imports: [modules],
  exports: [modules],
  providers: [
    MatDatepickerModule,
    MatFormFieldModule, MatInputModule,
    MatSnackBarModule, MatIconModule, MatCardModule, MatProgressBarModule,
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    // { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
  ]
})
export class MaterialModule { }
