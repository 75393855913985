import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';
import { EnergetiqueBancMoteur } from './energetique-banc-moteur/energetique-banc-moteur.component';

@Component({
  selector: 'map-energetique',
  templateUrl: './map-energetique.component.html',
  styleUrls: ['./map-energetique.component.css']
})
export class MapEnergetiqueComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MapEnergetiqueComponent>, public dialog: MatDialog) { }

  ngOnInit() {
  }

  onClose(event?: any): void {
    this.dialogRef.close(event);
  }

  bancMoteur() {
    this.dialogRef.updateSize('2000px', '200px');
    if (this.dialog.openDialogs.length === 3) {
      const dialogRef = this.dialog.open(EnergetiqueBancMoteur, {
        width: '2000px',
        data: {
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        } else {
          this.dialogRef.updateSize('2000px', 'auto');
        }
      });
    }
  }

  bar() {
    window.open(environment.exploitationEnergetiqueBarOpeUrl);
  }

  brap() {
    window.open(environment.exploitationEnergetiqueBrapOpeUrl);
  }

  barCop() {
    window.open(environment.exploitationEnergetiqueBarCopOpeUrl);
  }
  pems() {
    window.open(environment.exploitationEnergetiquePemsOpeUrl);
  }

}
