export enum exportEng {
    english = 'en',
    mobile = 'Mobile Device',
    createDate = 'Creation Date',
    updateDate = 'Update Date',
    updateBy = 'Last Update By',
    active = 'Active ?',
    mobileTabName = 'MobileDevice',
    mileston = 'Milestones',
    milestonTabName = 'Milestone',
    macroTabName = 'MacroActivity',
    macro = 'Macro Activity',
    shifts = 'Shifts',
    Profiles = 'Profiles',
    vhTechnicalParam = 'Vehicle_Technical_Parameter',
    stoppageType = 'StoppageType',
    stoppageTitle = 'Type of Stoppage',
    fuel = 'Fuel',
    externalCoswin = 'External Coswin',
    extCoswinTab = 'ExternalCoswin',
    testTypeTab = 'TestType',
    testType = 'Test Type',
    osVersionTab = 'OsVersion',
    osVersion = 'OS Versions',
    expDomainTab = 'ExpertiseDomain',
    expDomain = 'Expertise Domain',
    interSystemTab = 'Intersystem',
    interSystem = 'Intersystem uses'
}

export enum exportFrench {
    mobile = 'Appareils mobiles',
    createDate = 'Date De Creation',
    updateDate = 'Date De Maj',
    updateBy = 'Derniere Maj Par',
    active = 'Actif ?',
    mileston = 'Jalons',
    macro = 'Macro Activite',
    shifts = 'Nombres des equipes',
    Profiles = 'Profils',
    vhTechnicalParam = 'Paramètre_Technique_De_Véhicule',
    stoppageTitle = 'Type darrêt',
    fuel = 'Carburant',
    externalCoswin = 'Coswin Externe',
    testType = 'Type d\'\essai',
    osVersion = 'Versions d\'\OS',
    expDomain = ' Domaine d\'\expertise',
    interSystem = 'Les utilisations intersystèmes'
}
export enum exportConstant {
    dateFormat = 'DD/MM/YYYY'
}
