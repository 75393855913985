export class Intersystem {
    id: number;
    label: string;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: boolean;
    organisationId: number[];
}

export enum IntersystemEng {
    orgId = 'organisationIDs',
    True = 'true',
    Uid = 'uid',
    User = 'User',
    interSystem_existing = 'Errormessage.interSystem_existing',
    fail = 'F',
    success = 'S',
    success_msg = 'Common_Buttons.Success',
    update = 'Common_Buttons.Update'
}