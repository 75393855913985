import { EngineActivity } from './engineActivity.model';
import { OT } from './OT.model';
import { RollActivity } from '../bar-activity/rollActivity.model';
import { VehicleActivities } from '../vehicle-activities/vehicle-activities.model';

export class FailureActivity {
    id: number;
    label: number;
    engineActivity: EngineActivity;
    rollActivity: RollActivity;
    vehicleActivity: VehicleActivities;
    soft: string;
    dcm: string;
    febNb: string;
    engDt: string;
    pointsNb: number;
    ot: OT;
    updateDate: Date;
    organisationId: number[];
    otGlobal: string;
    bvRlce: boolean;
}
