import { NativeDateAdapter } from '@angular/material';
import { Injectable } from '@angular/core';
import { format as formatFns } from 'date-fns';
import { formatDate } from '@angular/common';

export const MAT_CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};


@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
  /*  public createDate(year: number, month: number, date: number): Date {
     const localDate = super.createDate(year, month, date);
     const offset = localDate.getTimezoneOffset() * 60000;
     return new Date(localDate.getTime() - offset); // utcDate
   }
  */
  /*  public format(date: Date, displayFormat: any): string {
     if (displayFormat !== MAT_CUSTOM_DATE_FORMATS.display.dateInput) {
       return super.format(date, displayFormat);
     } else {
       return formatFns(date, MAT_CUSTOM_DATE_FORMATS.display.dateInput);
     }
   } */

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd/MM/yyyy', this.locale ? this.locale : 'en-GB');
    } else {
      return date.toDateString();
    }
  }

}
