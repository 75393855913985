import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { SnackBarModel } from './snackbar.model';
declare var $: any;
@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent implements OnInit {
   message: string;
   showSuccess: boolean;
   showWarning: boolean;
   showFailed: boolean;
   showStatus: string;

  constructor( @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarModel,
  public snb: MatSnackBarRef<SnackbarComponent>) { }

  ngOnInit() {
    this.showStatus = this.data.showStatus;
    this.message = this.data.message;
  }

  snackBarClose() {
    this.snb.dismiss();
  }

}
