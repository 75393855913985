import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BarProgramLines } from './barprogramlines.model';
import { GridOptions } from 'ag-grid-community';
@Injectable({
  providedIn: 'root'
})
export class BarProgramLinesService {

  constructor(private httpClient: HttpClient) {
    this.btnName = 'Common_Buttons.Add_Record';
  }

  public apiURL: string = environment.apiURL;
  public gridOptions: GridOptions;
  public barProgramLines: BarProgramLines;
  public label: string;
  public active: boolean;
  public btnName: string;
  public id: number;

  getAllBarProgramLines(barProgramLines: BarProgramLines) {
    return this.httpClient.post<BarProgramLines[]>(this.apiURL + '/getBarProgramLinesList', barProgramLines).pipe(map(res => res));
  }

  updateBarProgramLinesOrganisationList(barProgramLines: BarProgramLines) {
    return this.httpClient.put<BarProgramLines>(this.apiURL + '/updateBarProgramLinesOrganisationList', barProgramLines, {
      observe: 'response'
    });
  }

  getGridOptions() {
    return this.gridOptions;
  }

  setGridOptions(gridOptions) {
    this.gridOptions = gridOptions;
  }

  createBarProgramLinesList(barProgramLines: BarProgramLines) {
    return this.httpClient.post<BarProgramLines>(this.apiURL + '/saveBarProgramLines', barProgramLines , {
      observe: 'response'
    });
  }

  updateBarProgramLinesList(barProgramLines: BarProgramLines) {
    return this.httpClient.put<BarProgramLines>(this.apiURL + '/updateBarProgramLines', barProgramLines, {
      observe: 'response'
    });
  }

  setBarProgramLines(gridBarProgramLines: BarProgramLines) {
    this.barProgramLines = gridBarProgramLines;
    if (this.barProgramLines != null) {
      this.initUpdate();
    } else {
      this.initCreate();
    }
  }

  getUpdateBarProgramLines() {
      return this.barProgramLines;
  }

  initUpdate() {
    this.btnName = 'Common_Buttons.Update';
  }

  initCreate() {
    this.btnName = 'Common_Buttons.Add_Record';
  }
}
