export class VhTechnicalParam {
    id: number;
    label: string;
    createDate: Date;
    updateDate: Date;
    ipn: string;
    active: boolean;
    organisationId: number[];
}



export enum VhTechnicalParamEnum {
    vhTechnicalParam = 'vhTechnicalParam',
    True = 'true',
    Uid = 'uid',
    User = 'User',
    OrganisationIDs = 'organisationIDs'

}

